import { useState, useEffect, useCallback } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

//MUI
import {
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Icon,
	ListItemText,
	Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { blue, grey } from "@mui/material/colors";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import Download from "@components/Downloads/Download";
import MessageAlert from "@components/Downloads/MessageAlert";
import Filter from "@components/Volunteers/Filter";
import VolunteerForm from "./VolunteerForm";

import { isEmptyOrNullObject } from "@utils/validations";
import VolunteerServices from "@services/VolunteerServices";
import middleware from "@middlewares/middleware";

const Volunteers = () => {
	//Constants
	const columns = [
		{ id: "Municipio", label: "Municipio" },
		{ id: "NombreCompleto", label: "Nombre" },
		{ id: "SeccionVota", label: "Sección" },
		{ id: "TieneUsuario", label: "Tiene cuenta" },
		{ id: "Responsabilidad", label: "Asignación" },
	];
	const catalogsOptions = [
		{ id: "regiones", getAll: false },
		{ id: "dl", getAll: false },
		{ id: "df", getAll: false },
		{ id: "municipios", getAll: false },
		{ id: "municipios_dl", getAll: false },
		{ id: "municipios_df", getAll: false },
		{ id: "poligonos", getAll: false },
		{ id: "secciones", getAll: false },
		{ id: "perfiles_hijos", getAll: false },
		{ id: "calidad_representante", getAll: false },
		{ id: "voluntarios", getAll: false },
		{ id: "modulos", getAll: false },
	];

	//States
	const [view, setView] = useState("table");
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isDownload, setIsDownloading] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [successDownload, setSuccessDownload] = useState(false);
	const [volunteers, setVolunteers] = useState([]);
	const [data, setData] = useState({
		page: 0,
		pageSize: 10,
		filtered: [],
		sorted: [],
	});
	const [sortedData, setSortedData] = useState([]);
	const [selectedVolunteer, setSelectedVolunteer] = useState({});

	const { catalogs, load } = useCatalogs({
		catalogsOptions,
		putDefaultOption: true,
	});

	//Functions Api's
	const getVolunteers = useCallback((data) => {
		setLoading(true);

		VolunteerServices.getList(data)
			.then((res) => {
				if (res.results) {
					const volunteers = res.response.data.map((volunteer) => {
						return {
							...volunteer,
							NombreCompleto: middleware.checkMenuAction("Agregar") ? (
								<Button
									disableElevation
									variant="text"
									size="small"
									sx={{
										":hover": {
											bgcolor: "#BDF7BC",
											color: "white",
										},
										p: "0 auto",
										textAlign: "left",
									}}
									startIcon={<Icon>edit</Icon>}
									onClick={(e) => handleEdit(volunteer)}
								>
									<ListItemText
										primary={
											<Typography variant="div" fontWeight={700} fontSize={15}>
												{volunteer.Nombre} {volunteer.Paterno}
											</Typography>
										}
										secondary={
											<Typography fontWeight={500} fontSize={13}>
												{volunteer.Edad} Años
											</Typography>
										}
									/>
								</Button>
							) : (
								<ListItemText
									primary={
										<Typography variant="div" fontWeight={700} fontSize={15}>
											{volunteer.Nombre} {volunteer.Paterno}
										</Typography>
									}
									secondary={
										<Typography
											fontWeight={500}
											fontSize={13}
											color="text.secondary"
										>
											{volunteer.Edad} Años
										</Typography>
									}
								/>
							),
							Responsabilidad: CellResponsibility(volunteer.Responsabilidad),
							TieneUsuario: volunteer.TieneUsuario === 1 ? "Si" : "No",
						};
					});
					setVolunteers(volunteers);
					setTotal(res.response.total);
				}
			})
			.catch((error) => Swal.fire({ title: error, icon: "warning" }))
			.finally(() => setLoading(false));
		//eslint-disable-next-line
	}, []);

	const CellResponsibility = (value) => {
		if (value === null) return "Sin Responsabiliad";

		return (
			<>
				{value.split("|").map((item) => (
					<Typography>{item}</Typography>
				))}
			</>
		);
	};
	useEffect(() => {
		getVolunteers(data);
		//eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (sortedData.length > 0) {
			const params = {
				...data,
				sorted: sortedData,
			};

			getVolunteers(params);
		}
		// eslint-disable-next-line
	}, [sortedData]);

	const handleChangeFilter = (filtered) => {
		setData((prevState) => ({ ...prevState, filtered }));
	};

	//Functions and Handlers
	const handleChangePagination = (pagination) => {
		setData({ ...data, ...pagination });
	};

	const handleExport = () => {
		setIsDownloading((prevState) => !prevState);

		setTimeout(() => {
			setIsDownloading((prevState) => !prevState);
			setShowMessage(true);
			setSuccessDownload(true);
		}, 3000);
	};

	const handleEdit = (volunteer) => {
		setView("form");
		setSelectedVolunteer(volunteer);
	};

	const handleSorted = (dataSorted) => {
		let sorted = [];
		if (!isEmptyOrNullObject(dataSorted)) {
			switch (dataSorted.orderBy) {
				case "NombreCompleto":
					sorted.push({
						id: `CONCAT_WS(' ', compromisos_unicos.Nombre, compromisos_unicos.Paterno, compromisos_unicos.Materno)`,
						value: dataSorted.order,
					});
					break;
				default:
					sorted.push({
						id: dataSorted.orderBy,
						value: dataSorted.order,
					});
					break;
			}
		}

		return sorted;
	};

	const handleRequestSort = (sortData) => {
		if (total > 0) setSortedData(handleSorted(sortData));
	};

	const handleSubmit = (values) => {
		getVolunteers(data);
	};

	return (
		<AdminLayout>
			<Container maxWidth={false} sx={{ backgroundColor: grey }}>
				<ActionHeader title={"Asignar permisos"} />
				{
					{
						table: (
							<Box>
								<Card className="card-primary">
									<CardContent>
										<Filter
											catalogsOptions={catalogsOptions}
											catalogs={catalogs}
											load={load}
											onChangeFilter={(e) => handleChangeFilter(e)}
										/>
									</CardContent>
								</Card>

								<Card className="card-primary">
									<CardContent>
										{middleware.checkMenuAction("Exportar") && (
											<>
												{isDownload ? (
													<Download format={"xlsx"} isDownload={isDownload} />
												) : (
													<Box display={"flex"} justifyContent={"flex-end"}>
														<Button
															variant="outlined"
															color="primaryDark"
															startIcon={<Icon>download</Icon>}
															size="small"
															onClick={handleExport}
															sx={{ borderRadius: "10px" }}
														>
															Exportar
														</Button>
													</Box>
												)}
											</>
										)}

										{showMessage && (
											<Box marginTop={2}>
												<MessageAlert
													showMessage={showMessage}
													successDownload={successDownload}
													setShowMessage={setShowMessage}
												/>
											</Box>
										)}

										<BasicTable
											rows={volunteers}
											hcolumns={columns}
											pageProp={data.page}
											pageSize={data.pageSize}
											total={total}
											handleChangePagination={handleChangePagination}
											isLoading={load || loading}
											stickyHeader={true}
											handleManualSort={handleRequestSort}
										/>
									</CardContent>
								</Card>
							</Box>
						),
						form: (
							<VolunteerForm
								setView={setView}
								volunteer={selectedVolunteer}
								catalogs={catalogs}
								onSubmit={handleSubmit}
							/>
						),
					}[view]
				}
			</Container>
		</AdminLayout>
	);
};

export default Volunteers;
