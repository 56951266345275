import ApiExec, { downloadFile, ApiExecPublic } from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";
import { handleAttrs } from "@utils/Utilities";

class MovilizationServices {
  validateSection = (Seccion) => {
    const section = DEBUG ? Seccion : encryptVars(Seccion);
    return ApiExec({}, `movilization/validate-section/${section}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getBingosList = (params) => {
    return ApiExec(params, "movilization/get-bingos-list", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilization = (params) => {
    return ApiExec(params, "movilization/get-movilizacion-controller", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setBingo = (params) => {
    return ApiExec(params, "movilization/set-bingo", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setMovilization = (params) => {
    return ApiExec(params, "movilization/set-movilizacion-controller", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteBingo = (params) => {
    return ApiExec(params, "movilization/delete-bingo", "DELETE")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getSchedules = (params) => {
    return ApiExec(params, "movilization/get-schedules", "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDetailMobilizedByHour = (params) => {
    return ApiExec(params, "movilization/get-detalle-movilizados-hora", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }
}

const movilizacion = new MovilizationServices();
export default movilizacion;
