import { useEffect, useState } from "react";

// Utilidades
import { numberWithCommas } from "@utils/Utilities/";

// Material UI
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";

//Servicio de api
import Auditoria from "@services/AuditServices";

const TableSeguimiento = (props) => {
  const { change, filtered } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Total");

  // Ordenamiento de las columnas
  const handleSortRequest = (property) => {
    const newOrder = orderBy === property && order === "desc" ? "asc" : "desc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const sortedData = data.sort((a, b) => {
    if (orderBy === "Seguimiento") {
      return order === "asc"
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    } else {
      return order === "asc"
        ? a[orderBy] - b[orderBy]
        : b[orderBy] - a[orderBy];
    }
  });

  // Llamada a la API
  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await Auditoria.getDetailsTracking(filtered);
      const { results, message, response } = result;

      if (results) {
        setData(response.data);
        setLoading(false);
      } else {
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [change]);

  // Titulos de la tabla
  const columns = [
    { id: "Seguimiento", label: "Seguimiento" },
    { id: "Total", label: "Total" },
  ];

  const paddingCell = "5px 20px";

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: "rgba(189, 189, 189, 0.3)" }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sortDirection={orderBy === column.id ? order : false}
                sx={{
                  padding: paddingCell,
                }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => handleSortRequest(column.id)}
                >
                  {column.label.toUpperCase()}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? ( // Mostrar CircularProgress si loading es true
            <TableRow>
              <TableCell colSpan={columns.length} align="center" height="200px">
                <CircularProgress size={50} className="color-componentes"/>
              </TableCell>
            </TableRow>
          ) : (
            sortedData.map((row, index) => (
              <TableRow
                key={row.Seguimiento}
                hover
                tabIndex={-1}
                sx={{
                  padding: 0,
                  backgroundColor: index % 2 ? "#E2FFE4" : "white",
                }}
              >
                <TableCell
                  sx={{
                    padding: paddingCell,
                  }}
                >
                  {row.Seguimiento}
                </TableCell>
                <TableCell
                  sx={{
                    padding: paddingCell,
                    textAlign: "right",
                  }}
                >
                  {numberWithCommas(row.Total)}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSeguimiento;
