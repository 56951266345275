import { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import { Container, CardContent, Chip, Box, Tooltip } from "@mui/material";
import Filter from "@components/Territorial/Filter";
import { useNavigate } from "react-router-dom";
import BasicTable from "@components/Tables/BasicTable";
import Card from "@components/Card/Card";
import CardCommitments from "@components/Card/CardStats";
import Grid from "@mui/material/Unstable_Grid2";
import TerritorialService from "@services//TerritorialServices";
import MessageAlert from "@components/Downloads/MessageAlert";
import Swal from "sweetalert2";
import { EJERCICIO } from "@data/constants";
import { numberWithCommas } from "@utils/Utilities";
import moment from "moment";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import MovingIcon from "@mui/icons-material/Moving";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { isEmptyOrNullObject } from "@utils/validations";

const ProgressSections = (props) => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [loadingCharts, setLoadingChart] = useState(false);
	const [sortedData, setSortedData] = useState([]);
	const [chartCompromisos, setChartCompromisos] = useState({
		type: 13,
		filtered: [],
	});
	let filterFijo = { id: "Ejercicio", value: EJERCICIO, filter: "=" };
	const [tableData, setTableData] = useState({
		page: 0,
		pageSize: 10,
		filtered: [filterFijo],
	});
	const [buttonLoading, setButtonLoading] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [successDownload, setSuccessDownload] = useState(false);
	const [dataTotales, setDataTotales] = useState({
		MetaCompromisos: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		regional: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		municipales: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		poligonal: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		seccionales: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
	});

	const columns = [
		{
			id: "Municipio",
			label: "Municipio",
			orderBy: "Municipio",
			columnAction: false,
		},
		{
			id: "Seccion",
			label: "Sección",
			orderBy: "Seccion",
			columnAction: false,
		},
		{
			id: "MetaCompromisos",
			label: "Meta Promovidos",
			orderBy: "MetaCompromisos",
			columnAction: false,
		},
		{
			id: "AvEsperado",
			label: "Av.Esperado",
			orderBy: false,
			columnAction: false,
		},
		/*   { id: "AvanceCompromisos", label: "Av.Compromisos" },
    { id: "PorcentajeCompromisos", label: "(%) Av.Compromisos" }, */
		{
			id: "AvanceCompromisosUnicos",
			label: "Av.Promovidos",
			orderBy: "AvanceCompromisosUnicos",
			columnAction: false,
		},
		{
			id: "PorcentajeCompromisosUnico",
			label: "(%) Av.Promovidos",
			orderBy: "PorcentajeCompromisosUnico",
			columnAction: false,
		},
		{
			id: "MetaEstructura",
			label: "Meta Estructura",
			orderBy: "MetaEstructura",
			columnAction: false,
		},
		{
			id: "AvanceEstructura",
			label: "Av.Estructura",
			orderBy: "AvanceEstructura",
			columnAction: false,
		},
		{
			id: "PorcentajeEstructura",
			label: "(%) Avance Estructura",
			orderBy: false,
			columnAction: false,
		},
	];

	useEffect(() => {
		setLoading(true);
		setLoadingChart(true);
		getList();
		getTotalCompromiso();
		// eslint-disable-next-line
	}, [tableData, chartCompromisos]);

	useEffect(() => {
		if (sortedData.length > 0) {
			let params = {
				...tableData,
				sorted: sortedData,
			};

			setTableData(params);
		}
		// eslint-disable-next-line
	}, [sortedData]);

	const getList = () => {
		TerritorialService.getProgressSection(tableData)
			.then((res) => {
				if (res.results) {
					setData(
						res.response.data.map((item) => {
							return {
								...item,
								MetaCompromisos: item.MetaCompromisos
									? numberWithCommas(item.MetaCompromisos)
									: "0",
								AvEsperado: item.AvEsperado
									? numberWithCommas(item.AvEsperado)
									: "0",
								AvanceCompromisos: item.AvanceCompromisos
									? item.AvanceCompromisos
									: "0",
								AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
									? numberWithCommas(item.AvanceCompromisosUnicos)
									: "0",
								AvanceEstructura: item.AvanceEstructura
									? numberWithCommas(item.AvanceEstructura)
									: "0",
								PorcentajeEstructura: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeEstructura) >= 100
												? "success"
												: parseInt(item.PorcentajeEstructura) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeEstructura) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeEstructura > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeEstructura}
									/>
								),
								PorcentajeCompromisos: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeCompromisos) >= 100
												? "success"
												: parseInt(item.PorcentajeCompromisos) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeCompromisos) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeCompromisos > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeCompromisos}
									/>
								),
								PorcentajeCompromisosUnico: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeCompromisosUnico) >= 100
												? "success"
												: parseInt(item.PorcentajeCompromisosUnico) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeCompromisosUnico > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeCompromisosUnico}
									/>
								),
							};
						})
					);
					setTotal(res.response.total);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoading(false));
	};

	const getTotalCompromiso = () => {
		TerritorialService.getStatsStruct(chartCompromisos)
			.then((res) => {
				if (res.results) {
					setDataTotales(res.response.data);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoadingChart(false));
	};

	const handleChangePagination = (pagination) => {
		setTableData({ ...tableData, ...pagination });
	};

	const handleChangeFilter = (type) => {
		const {
			Region,
			Municipio,
			responsable,
			Poligono,
			Seccion,
			Celular,
			NombreCompleto,
		} = type;

		let filtered = [filterFijo];
		let filtro = [];

		if (Region > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idDF", filter: "=", value: Region },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idDF", filter: "=", value: Region },
			];
		}
		if (Municipio > 0) {
			filtered = [
				...filtered,
				{
					id: "cat_secciones.idMunicipio",
					filter: "=",
					value: Municipio,
				},
			];

			filtro = [
				...filtro,
				{
					id: "cat_secciones.idMunicipio",
					filter: "=",
					value: Municipio,
				},
			];
		}
		if (Poligono.length > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
			];
		}
		if (Seccion.length > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.Seccion", filter: "IN", value: Seccion },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.Seccion", filter: "IN", value: Seccion },
			];
		}
		if (responsable > 0) {
			filtered = [
				...filtered,
				responsable === 1
					? { id: "estad_secciones.AvanceEstructura", filter: "=", value: "0" }
					: { id: "estad_secciones.AvanceEstructura", filter: ">", value: "0" },
			];
		}
		if (Celular.length > 0) {
			filtered = [
				...filtered,
				{
					id: "usuarios.Celular",
					filter: "LIKE",
					value: Celular,
				},
			];
		}
		if (NombreCompleto.length > 0) {
			filtered = [
				...filtered,
				{
					id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"],
					filter: "LIKE",
					value: NombreCompleto,
				},
			];
		}

		setTableData({
			...tableData,
			page: 0,
			filtered: filtered,
		});

		setChartCompromisos({ type: 13, filtered: filtro });
	};

	const handleRequestSort = (sortData) => {
		if (total > 0) setSortedData(handleSorted(sortData));
	};

	const handleSorted = (dataSorted) => {
		const fields = {
			Municipio: "cat_municipios.id",
			Seccion: "cat_secciones.Seccion",
			MetaCompromisos: "estad_secciones.MetaCompromisos",
			AvanceCompromisos: "estad_secciones.AvanceCompromisos",
			PorcentajeCompromisos:
				"IF(TRUNCATE(((estad_secciones.AvanceCompromisos * 100)/estad_secciones.MetaCompromisos),2)>=100,100,TRUNCATE(((estad_secciones.AvanceCompromisos * 100)/estad_secciones.MetaCompromisos),2))",
			AvanceCompromisosUnicos: "estad_secciones.AvanceCompromisosUnicos",
			PorcentajeCompromisosUnico:
				"IF(TRUNCATE(((estad_secciones.AvanceCompromisosUnicos * 100)/estad_secciones.MetaCompromisos),2)>=100,100,TRUNCATE(((estad_secciones.AvanceCompromisosUnicos * 100)/estad_secciones.MetaCompromisos),2))",
			MetaEstructura: "estad_secciones.MetaEstructura",
			AvanceEstructura: "estad_secciones.AvanceEstructura",
		};

		let sorted = [];

		if (!isEmptyOrNullObject(dataSorted)) {
			sorted.push({
				id: fields[dataSorted.orderBy],
				value: dataSorted.order,
			});
		}

		return sorted;
	};

	return (
		<AdminLayout>
			<Container maxWidth="false">
				<ActionHeader
					title="Compromisos por Sección"
					handleclick={() => navigate("/compromisos_seccion")}
				/>
				<Grid container spacing={2}>
					<Grid xs={12} md={6}>
						<CardCommitments
							icon={"groups"}
							title={"Promovidos"}
							total={`${numberWithCommas(
								dataTotales.MetaCompromisos.TotalAvance
							)} de ${numberWithCommas(dataTotales.MetaCompromisos.Meta)}`}
							subIcon={"update"}
							subtitle={moment(
								dataTotales.MetaCompromisos.FechaActualizo
							).format("DD/MM/YYYY")}
							loading={loadingCharts}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<CardCommitments
							icon={"groups"}
							title={"Seccionales"}
							total={`${numberWithCommas(
								dataTotales.seccionales.TotalAvance
							)} de ${numberWithCommas(dataTotales.seccionales.Meta)}`}
							subIcon={"update"}
							subtitle={moment(dataTotales.seccionales.FechaActualizo).format(
								"DD/MM/YYYY"
							)}
							loading={loadingCharts}
						/>
					</Grid>
				</Grid>

				<Card className="card-primary">
					<CardContent>
						<Filter
							onChangeFilter={(e) => handleChangeFilter(e)}
							manzana={false}
							responsable={false}
							checkRespon={true}
							municipio={false}
							municipio_dl={true}
						/>
					</CardContent>
				</Card>

				<Card className="card-primary">
					<CardContent>
						{showMessage && (
							<Box marginTop={2}>
								<MessageAlert
									showMessage={showMessage}
									successDownload={successDownload}
									setShowMessage={setShowMessage}
								/>
							</Box>
						)}
						{/*      {isDownload ? (
              <Download format={"xlsx"} isDownload={isDownload} />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Box>
            )} */}

						{/*       {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
 */}
						<BasicTable
							rows={data}
							hcolumns={columns}
							pageProp={tableData.page}
							pageSize={tableData.pageSize}
							total={total}
							handleChangePagination={handleChangePagination}
							isLoading={loading}
							stickyHeader={true}
							handleManualSort={handleRequestSort}
						/>
					</CardContent>
				</Card>
			</Container>
		</AdminLayout>
	);
};
export default ProgressSections;
