import { useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
//Mui
import {
    Box,
    Card,
    CardContent,
    Container,
    Fab,
    FormControlLabel,
    Radio,
    RadioGroup,
    Skeleton,
    Tooltip,
    Icon
} from "@mui/material";
import { blue } from "@mui/material/colors";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import PrepFilter from "@components/Prep/PrepSamplingFilter";
import PrepSamplingHeaders from "@components/Prep/PrepSamplingHeaders";
import Chart from "@components/Prep/ChartColumComparison";

import prep from "@services/PrepServices";

const PrepMuestreo = () => {
    const [series, setSeries] = useState([]);
    const [vista, setVista] = useState(1);
    const [loadingChart, setLoadingChart] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);

    const [sampling, setSampling] = useState(true);
    const [summaryData, setSummaryData] = useState({
        TipoEleccion: "",
        TotalActas: 0,
        ActasRecibidas: 0,
        TotalVotos: 0,
        LNom: 0,
        FechaHora: moment().format('DD/MM/YYYY HH:mm'),
        PActas: 0,
        Participacion: 0,
        Ambito: ""
    })

    const handleChangeRadio = (event) => {
        setVista(event.target.value);
    };

    const handleChangeFilter = (filtered) => {
        let params = {
            ...filtered,
            idTipoVista: parseInt(vista),
            Muestra: sampling ? 1 : 0
        };

        getChart(params);
    }

    const getChart = (params) => {
        setLoadingChart(true);
        setLoadingFilter(true);
        prep.getSamplingChart(params).then(res => {
            const { results, response, message } = res;
            if (results) {
                setSeries(response.data.series);
                setSummaryData(response.data.summary);
            } else {
                setSeries([]);
                setSummaryData({
                    TipoEleccion: "",
                    TotalActas: 0,
                    ActasRecibidas: 0,
                    TotalVotos: 0,
                    LNom: 0,
                    FechaHora: moment().format('DD/MM/YYYY HH:mm'),
                    PActas: 0,
                    Participacion: 0,
                    Ambito: ""
                });
                Swal.fire({
                    title: message,
                    icon: "warning",
                });
            }
        }).catch(error => {
            setSeries([]);
            setSummaryData({
                TipoEleccion: "",
                TotalActas: 0,
                ActasRecibidas: 0,
                TotalVotos: 0,
                LNom: 0,
                FechaHora: moment().format('DD/MM/YYYY HH:mm'),
                PActas: 0,
                Participacion: 0,
                Ambito: ""
            });
            Swal.fire({
                title: error.message,
                icon: "warning",
            });
        }).finally(() => {
            setLoadingChart(false);
            setLoadingFilter(false);
        });
    }

    return (
        <AdminLayout>
            <Container maxWidth={false}>
                <ActionHeader title="PREP (Muestreo)" />
            </Container>

            <PrepSamplingHeaders
                sampling={sampling}
                setSampling={setSampling}
                summaryData={summaryData}
                loadingCards={loadingChart}
            />

            <Box component={Card} className="card-primary">
                <Box component={CardContent}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={vista}
                        onChange={handleChangeRadio}
                        sx={{ mb: 2, display: "flex", justifyContent: "center" }}
                    >
                        <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Vista por candidatos"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Vista por partidos"
                            labelPlacement="end"
                        />
                    </RadioGroup>

                    {
                        loadingChart ? (
                            <Skeleton animation="wave" variant="rounded" width={"100%"} height={400} />
                        ) : (
                            <Chart
                                Series={series}
                                tipoVista={vista}
                                loading={loadingChart}
                                percent={true}
                            />
                        )
                    }
                </Box>
            </Box>

            <PrepFilter onChangeFilter={handleChangeFilter} disabledButtons={loadingFilter} />

            <Box position={"fixed"} bottom={"24px"} right={"24px"} zIndex={10}>
                <Tooltip title="Actualizar">
                    <Fab
                        size={"small"}
                        aria-label={"refresh"}
                        color={"primaryDark"}
                        sx={{ "&:hover": { backgroundColor: blue[900] } }}
                    >
                        <Icon>sync</Icon>
                    </Fab>
                </Tooltip>
            </Box>
        </AdminLayout>
    )
}

export default PrepMuestreo;