import { useEffect, useState } from "react";
import {
	PERFIL_RC,
	PERFIL_RG,
	PERFIL_ABOGADO,
	PERFIL_CASA,
	PERFIL_FiL,
	PERFIL_OBSERVER,
} from "../../data/constants";
import {
	Container,
	CardContent,
	Rating,
	Stack,
	Grid,
	Box,
	Button,
	Icon,
	Typography,
} from "@mui/material";
import { numberWithCommas } from "@utils/Utilities";
import BasicTable from "@components/Tables/BasicTable";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import AttendanceCard from "@components/Attendance/AttendanceCard";
import Filter from "../../components/Electoral/Filter";
import Card from "@components/Card/Card";
import MessageAlert from "@components/Downloads/MessageAlert";
import Download from "@components/Downloads/Download";
import middleware from "@middlewares/middleware";
import { useNavigate } from "react-router-dom";
import electoral from "../../services/ElectoralServices";
import Swal from "sweetalert2";
import { isNullOrUndefined } from "@utils/validations";
import { roundTo } from "./../../components/Maps/Auxiliars/MathFunctions";

const TableElectoral = (props) => {
	const { idPerfil } = props;
	var today = new Date();
	const [data, setData] = useState([]);
	const [tableData, setTableData] = useState({
		page: 0,
		pageSize: 10,
		filtered: [],
	});
	const [dataTotales, setDataTotales] = useState({
		responsable: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		voluntarios: {
			asignados: 0,
			voluntarios: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
	});
	const [tittle, setTittle] = useState("");
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [successDownload, setSuccessDownload] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [loadingCharts, setLoadingChart] = useState(true);
	const [filtrosView, setFiltrosView] = useState({
		filtrosView: true,
		dl: true,
		df: true,
		municipio: false,
		municipioDL: true,
		poligono: true,
		seccion: true,
		casilla: false,
		propietario: false,
		tieneExperiencia: true,
		TieneAuto: true,
		AceptaOtra: true,
		filterData: true,
	});
	const [chart, setChart] = useState({
		page: 0,
		pageSize: 10,
		filtered: [],
	});

	const navigate = useNavigate();

	const [columns, setColumns] = useState([
		{ id: "Region", label: "Resp. Municipal" },
		{ id: "Municipio", label: "Distrito Federal" },
		{ id: "Seccion", label: "RG" },
		{ id: "NombreCompleto", label: "Tiene Auto" },
	]);

	useEffect(() => {
		setLoading(true);
		setLoadingChart(true);
		setData([]);
		setTableData({
			page: 0,
			pageSize: 10,
			filtered: [],
		});
		setDataTotales({
			avance_total: 0,
			meta_total: 0,
			porcentaje: 0,
			porcentaje_voluntarios: 0,
			total_voluntarios: 0,
			total_voluntarios_asignados: 0,
		});

		view();
	}, [idPerfil]);

	useEffect(() => {
		setData([]);
		setLoading(true);
		setLoadingChart(true);
		view();
	}, [tableData]);

	const view = () => {
		let api;
		let ApiStats;
		switch (idPerfil) {
			case PERFIL_RC:
				setTittle("RCS");
				setFiltrosView({
					...filtrosView,
					dl: false,
					df: false,
					region: true,
					municipio: false,
					municipioDL: true,
					poligono: false,
					seccion: true,
					casilla: true,
					propietario: true,
				});
				setColumns([
					{ id: "idDF", label: "DF" },
					{ id: "idDL", label: "DL" },
					{ id: "MunicipioCapturo", label: "Municipio" },
					{ id: "Seccion", label: "Sección" },
					{ id: "NombreCasilla", label: "Casilla" },
					{ id: "CalidadRepresentante", label: "Representante" },
					{ id: "NombreCompleto", label: "NombreCompleto" },
					{ id: "Celular", label: "Celular" },
					{ id: "TieneExperiencia", label: "¿Tiene Experiencia?" },
					{ id: "AceptaCuidarOtra", label: "¿Aceptaría cuidar otra?" },
					{ id: "TieneAuto", label: "¿Tiene Auto?" },
				]);
				api = "get-rcs";
				ApiStats = "get-stats-rcs";
				break;
			case PERFIL_RG:
				setTittle("RGS");
				setFiltrosView({
					...filtrosView,
					dl: false,
					df: false,
					region: true,
					municipio: false,
					municipioDL: true,
					poligono: false,
					seccion: false,
					AceptaOtra: false,
					casilla: false,
					propietario: false,
				});
				setColumns([
					{ id: "MunicipioCapturo", label: "Respons. Municipio" },
					{ id: "DistritoFederal", label: "DF" },
					{ id: "NombreCompleto", label: "RG" },
					{ id: "Celular", label: "Celular" },
					/*  { id: "Ruta", label: "Ruta"}, */
					{ id: "TieneExperiencia", label: "¿Tiene Experiencia?" },
					{ id: "TieneAuto", label: "¿Tiene Auto?" },
				]);
				api = "get-rgs";
				ApiStats = "get-stats-rgs";
				break;
			case PERFIL_ABOGADO:
				setTittle("ABOGADOS");
				setFiltrosView({
					...filtrosView,
					dl: false,
					df: false,
					region: true,
					municipio: false,
					municipioDL: true,
					poligono: false,
					seccion: false,
					AceptaOtra: false,
					casilla: false,
					propietario: false,
				});
				setColumns([
					{ id: "Municipio", label: "Municipio" },
					{ id: "Nombre", label: "Nombre" },
					{ id: "Celular", label: "Celular" },
					{ id: "TieneExperiencia", label: "¿Tiene Experiencia?" },
					{ id: "TieneAuto", label: "¿Tiene Auto?" },
				]);
				api = "get-abogados";
				ApiStats = "get-stats-abogados";
				break;
			case PERFIL_OBSERVER:
				setTittle("OBSERVADORES");
				setFiltrosView({
					...filtrosView,
					dl: false,
					df: false,
					region: true,
					municipio: false,
					municipioDL: true,
					poligono: false,
					seccion: false,
					AceptaOtra: false,
					casilla: false,
					propietario: false,
				});
				setColumns([
					{ id: "RespMunicipal", label: "Respons. Municipio" },
					{ id: "DF", label: "DF" },
					{ id: "Nombre", label: "Nombre" },
					{ id: "Celular", label: "Celular" },
					{ id: "Ruta", label: "Ruta" },
					{ id: "TieneExperiencia", label: "¿Tiene Experiencia?" },
					{ id: "TieneAuto", label: "¿Tiene Auto?" },
				]);
				api = "get-observadores";
				ApiStats = "get-stats-observadores";
				break;
			case PERFIL_CASA:
				setTittle("BUNKERS");
				setFiltrosView({
					...filtrosView,
					dl: false,
					df: false,
					municipio: false,
					municipioDL: true,
					poligono: false,
					seccion: false,
					casilla: false,
					propietario: false,
					tieneExperiencia: true,
					TieneAuto: true,
					AceptaOtra: true,
				});
				setColumns([
					{ id: "Municipio", label: "Municipio" },
					{ id: "Nombre", label: "Nombre" },
					{ id: "Celular", label: "Celular" },
					{ id: "TieneExperiencia", label: "¿Tiene Experiencia?" },
					{ id: "TieneAuto", label: "¿Tiene Auto?" },
				]);
				api = "get-casaazul";
				ApiStats = "get-stats-casaazul";
				break;
			case PERFIL_FiL:
				setTittle("PRIMERO EN FILA");
				setFiltrosView({
					...filtrosView,
					dl: false,
					df: false,
					region: true,
					municipio: false,
					municipioDL: true,
					poligono: false,
					seccion: true,
					casilla: true,
					propietario: true,
				});
				setColumns([
					{ id: "DF", label: "DF" },
					{ id: "DL", label: "DL" },
					{ id: "Municipio", label: "Municipio" },
					{ id: "Seccion", label: "Sección" },
					{ id: "Casilla", label: "Casilla" },
					{ id: "NombreCompleto", label: "NombreCompleto" },
					{ id: "Celular", label: "Celular" },
					{ id: "TieneExperiencia", label: "¿Tiene Experiencia?" },
					{ id: "AceptaCuidarOtra", label: "¿Aceptaría cuidar otra?" },
					{ id: "TieneAuto", label: "¿Tiene Auto?" },
				]);
				break;
			default:
				break;
		}
		if (!isNullOrUndefined(api)) {
			getList(api);
		}
		if (!isNullOrUndefined(ApiStats)) {
			getStats(ApiStats);
		}
	};

	const getList = (api) => {
		electoral
			.getList(tableData, api)
			.then((res) => {
				if (res.results) {
					setData(
						res.response.data.map((item) => {
							return {
								...item,
								NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
								TieneAuto: item.TieneAuto === 1 ? "SI" : "NO",
								TieneExperiencia: item.TieneExperiencia === 1 ? "SI" : "NO",
								AceptaCuidarOtra: item.AceptaCuidarOtra === 1 ? "SI" : "NO",
							};
						})
					);
					setTotal(res.response.total);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoading(false));
	};

	const getStats = (api) => {
		electoral
			.getList(chart, api)
			.then((res) => {
				if (res.results) {
					setDataTotales({ ...res.response.data });
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoadingChart(false));
	};

	const handleChangePagination = (pagination) => {
		setTableData({ ...tableData, ...pagination });
	};

	const handleChangeFilter = (type) => {
		const {
			DF,
			DL,
			Region,
			Municipio,
			MunicipioDL,
			Poligono,
			Seccion,
			Casillas,
			Propietario,
			Celular,
			ClaveElector,
			NombreCompleto,
			TieneAuto,
			TieneExperiencia,
			AceptaOtra,
		} = type;

		let filtered = [];
		let filtro = [];

		if (DF > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idDF", filter: "=", value: DF },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idDF", filter: "=", value: DF },
			];
		}
		if (DL > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idDL", filter: "=", value: DL },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idDL", filter: "=", value: DL },
			];
		}
		if (Region > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idRegion", filter: "=", value: Region },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idRegion", filter: "=", value: Region },
			];
		}
		if (Municipio > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
			];
		}
		if (MunicipioDL > 0) {
			filtered = [
				...filtered,
				{
					id: "cat_secciones.idMunicipioReportes",
					filter: "=",
					value: MunicipioDL,
				},
			];

			filtro = [
				...filtro,
				{
					id: "cat_secciones.idMunicipioReportes",
					filter: "=",
					value: MunicipioDL,
				},
			];
		}
		if (Poligono.length > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
			];
		}
		if (Seccion > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.Seccion", filter: "=", value: Seccion },
			];

			/*    filtro = [
        ...filtro,
        { id: "cat_secciones.Seccion", filter: "=", value: Seccion },
      ]; */
		}
		if (Casillas > 0) {
			filtered = [
				...filtered,
				{ id: "cat_casillas.id", filter: "=", value: Casillas },
			];

			/*  filtro = [
        ...filtro,
        { id: "cat_casillas.id", filter: "=", value: Casillas },
      ]; */
		}
		if (Propietario > 0) {
			filtered = [
				...filtered,
				{
					id: "usuarios_ambito_perfil.idPropietario",
					filter: "=",
					value: Propietario,
				},
			];

			/*   filtro = [
        ...filtro,
        {
          id: "usuarios_ambito_perfil.idPropietario",
          filter: "=",
          value: Propietario,
        },
      ]; */
		}
		if (Celular.length > 0) {
			filtered = [
				...filtered,
				{
					id: "usuarios.Celular",
					filter: "LIKE",
					value: Celular,
				},
			];
		}
		if (ClaveElector.length > 0) {
			filtered = [
				...filtered,
				{
					id: "usuarios.INE",
					filter: "=",
					value: ClaveElector,
				},
			];
		}
		if (NombreCompleto.length > 0) {
			filtered = [
				...filtered,
				{
					id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"],
					filter: "LIKE",
					value: NombreCompleto,
				},
			];
		}
		if (TieneAuto) {
			filtered = [
				...filtered,
				{ id: "usuarios_ambito_perfil.TieneAuto", filter: "=", value: 1 },
			];

			/*    filtro = [
        ...filtro,
        { id: "usuarios_ambito_perfil.TieneAuto", filter: "=", value: 1 },
      ]; */
		}
		if (TieneExperiencia) {
			filtered = [
				...filtered,
				{
					id: "usuarios_ambito_perfil.TieneExperiencia",
					filter: "=",
					value: 1,
				},
			];

			/*     filtro = [
        ...filtro,
        {
          id: "usuarios_ambito_perfil.TieneExperiencia",
          filter: "=",
          value: 1,
        },
      ]; */
		}
		if (AceptaOtra) {
			filtered = [
				...filtered,
				{
					id: "usuarios_ambito_perfil.AceptaCuidarOtra",
					filter: "=",
					value: 1,
				},
			];

			/*    filtro = [
        ...filtro,
        {
          id: "usuarios_ambito_perfil.AceptaCuidarOtra",
          filter: "=",
          value: 1,
        },
      ]; */
		}

		setTableData({
			...tableData,
			page: 0,
			filtered: filtered,
		});

		setChart({ ...chart, filtered: filtro });
	};

	const handleExport = () => {
		setIsDownloading(true);
		/*     SocialService.exportCompromisos({filtered:tableData.filtered})
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false)); */
	};

	return (
		<AdminLayout>
			<Container maxWidth="false">
				<ActionHeader
					title={`${tittle}`}
					handleclick={() => navigate(`${tittle}`)}
				/>
				<Grid container spacing={2}>
					<Grid item xs={6} md={6}>
						<AttendanceCard
							data={{
								contentData: String(roundTo(dataTotales.porcentaje, 2)),
								leftFooterData: String(
									numberWithCommas(dataTotales.avance_total)
								),
								rightFooterData: String(
									numberWithCommas(dataTotales.meta_total)
								),
							}}
							config={{
								leftFooterTitle: "Avance",
								rightFooterTitle: "Meta",
							}}
							loading={loadingCharts}
							titlecontent={`${tittle}`}
						/>
					</Grid>
					<Grid item xs={6} md={6}>
						<AttendanceCard
							data={{
								contentData: String(
									roundTo(dataTotales.porcentaje_voluntarios, 2)
								),
								leftFooterData: String(
									numberWithCommas(dataTotales.total_voluntarios_asignados)
								),
								rightFooterData: String(
									numberWithCommas(dataTotales.total_voluntarios)
								),
							}}
							config={{
								leftFooterTitle: "Asignados",
								rightFooterTitle: "Participantes",
							}}
							loading={loadingCharts}
							titlecontent={"Participantes"}
						/>
					</Grid>
				</Grid>
				<Card className="card-primary">
					<CardContent>
						<Filter
							onChangeFilter={(e) => handleChangeFilter(e)}
							filterData={filtrosView.filtrosView}
							dl={filtrosView.dl}
							df={filtrosView.df}
							// region={filtrosView.region}
							municipio={filtrosView.municipio}
							municipioDL={filtrosView.municipioDL}
							poligono={filtrosView.poligono}
							seccion={filtrosView.seccion}
							casilla={filtrosView.casilla}
							propietario={filtrosView.propietario}
							tieneExperiencia={filtrosView.tieneExperiencia}
							TieneAuto={filtrosView.TieneAuto}
							AceptaOtra={filtrosView.AceptaOtra}
							refreash={idPerfil}
						/>
					</CardContent>
				</Card>

				<Card className="card-primary">
					<CardContent>
						{middleware.checkMenuActionId("Exportar") && (
							<>
								{isDownloading ? (
									<Download isDownload={isDownloading} format="xlsx" />
								) : (
									<Box
										display={"flex"}
										justifyContent={"flex-end"}
										sx={{ mb: 2 }}
									>
										<Button
											variant="outlined"
											color="primaryDark"
											startIcon={<Icon>download</Icon>}
											size="small"
											onClick={handleExport}
											sx={{ borderRadius: "10px" }}
										>
											Exportar
										</Button>
									</Box>
								)}
							</>
						)}

						{showMessage && (
							<Box marginTop={2}>
								<MessageAlert
									showMessage={showMessage}
									successDownload={successDownload}
									setShowMessage={setShowMessage}
								/>
							</Box>
						)}

						<BasicTable
							rows={data}
							hcolumns={columns}
							pageProp={tableData.page}
							pageSize={tableData.pageSize}
							total={total}
							handleChangePagination={handleChangePagination}
							isLoading={loading}
							stickyHeader={true}
						/>
					</CardContent>
				</Card>
			</Container>
		</AdminLayout>
	);
};
export default TableElectoral;
