import ApiExec, { downloadFile } from "@utils/ApiExec";

class RcoService {
  getInfoRcos = (params) => {
    return ApiExec(params, "rcos/get-info-rcos", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  exportListadoMovilizacionExcel = ({ params, api }) => {
    return downloadFile({
      api,
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };
}
const rco = new RcoService();
export default rco;
