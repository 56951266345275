import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Paper,
  InputBase,
  IconButton,
  List,
  LinearProgress,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import InfiniteScroll from "react-infinite-scroll-component";

const BasicList = (props) => {
  const {
    Header = true,
    search,
    configParams,
    data,
    fetchHasMore,
    totalItems,
    itemComponent,
    hasMore,
    handleClickItem,
    finder,
    handleDeleteItem,
  } = props;
  const [showedItems, setShowedItems] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const [config, setConfig] = useState({
    title: "Listado",
    icon: "list_alt",
    height: 400,
    endMessage: "No hay registros para mostrar",
  });

  useEffect(() => {
    Object.keys(configParams).forEach(function (key) {
      setConfig((prevState) => ({ ...prevState, [key]: configParams[key] }));
    });
  }, [configParams]);

  useEffect(() => {
    setListingData(data);
    setShowedItems(data ? data.length : 0);
  }, [data]);

  const handleSelectedIndex = (index, data) => {
    setSelectedIndex(index);
    handleClickItem(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    search(searchInput);
  };

  const handleChangeInputSearch = (value) => {
    setSearchInput(value);
    if (value.length < 1) {
      search(value);
    }
  };

  return (
    <Card sx={{ width: "100%" }} className="card-secondary">
      {Header && (
        <CardHeader avatar={<Icon>{config.icon}</Icon>} title={config.title} />
      )}
      <CardContent>
        {finder && (
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
            }}
            onSubmit={(e) => handleSubmit(e)}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Buscar usuario"
              inputProps={{ "aria-label": "Buscar usuario" }}
              value={searchInput}
              onChange={(e) => handleChangeInputSearch(e.target.value)}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={(e) => handleSubmit(e)}
            >
              <Icon>search</Icon>
            </IconButton>
          </Paper>
        )}
        <InfiniteScroll
          dataLength={showedItems}
          next={fetchHasMore}
          hasMore={hasMore}
          loader={<LinearProgress color="secondary" />}
          height={config.height}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>
                {config.endMessage !== undefined
                  ? config.endMessage
                  : "¡Final de la lista de registros!"}
              </b>
            </p>
          }
        >
          <List dense={true}>
            {listingData.map((i, index) => (
              <Fragment key={index}>
                {itemComponent({
                  item: i,
                  selectedIndex: selectedIndex,
                  handleSelectedIndex: handleSelectedIndex,
                  index: index,
                  handleDeleteItem: handleDeleteItem,
                })}
              </Fragment>
            ))}
          </List>
        </InfiniteScroll>
      </CardContent>
      <CardActions>
        <small>
          Mostrando {showedItems} de {totalItems}
        </small>
      </CardActions>
    </Card>
  );
};

BasicList.propTypes = {
  data: PropTypes.array.isRequired,
  fetchHasMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  itemComponent: PropTypes.any.isRequired,
  totalItems: PropTypes.number.isRequired,
  configParams: PropTypes.object,
  handleClickItem: PropTypes.func,
  finder: PropTypes.bool,
  handleDeleteItem: PropTypes.func,
};

BasicList.defaultProps = {
  totalItems: 0,
  finder: true,
  configParams: {
    title: "Listado",
    icon: "list_alt",
    height: 400,
    endMessage: "No hay registros para mostrar",
  },
  handleClickItem: () => {},
  handleDeleteItem: () => {},
};

export default BasicList;
