import { useEffect, useState } from "react";

// Material UI
import { Card, CardContent, Box, Button, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import makeAnimated from "react-select/animated";
import Select from "react-select";

// Components
import FilterCollapse from "@components/Filters/FilterCollapse";
import InputSelect from "@components/Selects/BasicSelect";

// Services
import Swal from "sweetalert2";
import NumeraliaServices from "@services/NumeraliaServices";
import {
  CATALOGS_OPTIONS,
  VIEW_NATIONAL,
  VIEW_ESTATAL,
  GROUPBY,
  NAME_GROUP,
} from "@data/constants/Numeralia";

const Filter = ({
  entity,
  setDataFiltered,
  setNameGroup,
  onSearch,
  onClear,
}) => {
  const animatedComponents = makeAnimated();
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };

  const [filterValues, setFilterValues] = useState({
    groupBy: VIEW_ESTATAL,
    variable: "",
    municipality: "",
    section: "",
  });
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of CATALOGS_OPTIONS) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [catGroupBy] = useState([
    {
      value: VIEW_ESTATAL,
      label: "VISTA ESTATAL",
    },
    { value: GROUPBY.DF, label: "DF" },
    { value: GROUPBY.DL, label: "DL" },
  ]);
  const [catVariable, setCatVariable] = useState([]);
  const [labelVariable, setLabelVariable] = useState("");

  const getCatalogs = async ({ body }) => {
    setLoadingCatalogs(true);
    try {
      const result = await NumeraliaServices.getCatalogsNumeralia(body);
      const { results, response, message } = result;
      if (!results) {
        throw new Error(message);
      }
      setCatalogFiltered(response.catalogs);
      setCatalogs(response.catalogs);
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const handleChangeFilter = ({ name, value }) => {
    const key = name;
    setFilterValues({ ...filterValues, [key]: value });
  };

  const handleChangeGroupBy = ({ value }) => {
    const groupByCatVariableMap = {
      [GROUPBY.DF]: catalogs.dfs,
      [GROUPBY.DL]: catalogs.dls,
    };

    const groupByMunicipalityMap = {
      [GROUPBY.DF]: catalogs.municipios_df,
      [GROUPBY.DL]: catalogs.municipios_dl,
    };

    setFilterValues((prevState) => ({
      ...prevState,
      groupBy: value,
      variable: "",
      municipality: "",
      section: "",
    }));

    setCatVariable(groupByCatVariableMap[value] || []);
    setCatalogFiltered((prevState) => ({
      ...prevState,
      municipios: groupByMunicipalityMap[value] || catalogs.municipios,
    }));
    setLabelVariable(NAME_GROUP[value]);
    setNameGroup(NAME_GROUP[value]);
    setDataFiltered((prevState) => ({
      ...prevState,
      idAmbitoAgrupador: value,
      filtered: [],
    }));
  };

  const handleChangeVariable = ({ value }) => {
    setFilterValues((prevState) => ({
      ...prevState,
      variable: value,
      municipality: "",
      section: "",
    }));
    const municipios_df = catalogs.municipios_df.filter(
      ({ idDF }) => idDF === value
    );
    const municipios_dl = catalogs.municipios_dl.filter(
      ({ idDL }) => idDL === value
    );

    setCatalogFiltered((prevState) => ({
      ...prevState,
      municipios:
        (filterValues.groupBy === GROUPBY.DF ? municipios_df : municipios_dl) ||
        (filterValues.groupBy === VIEW_ESTATAL && catalogs.municipios),
    }));
  };

  const handleSearch = () => {
    const groupByIdVariableMap = {
      [GROUPBY.DF]: "idDF",
      [GROUPBY.DL]: "idDL",
    };

    const filters = [
      {
        id: groupByIdVariableMap[filterValues.groupBy],
        key: "variable",
      },
      {
        id: "idMunicipio",
        key: "municipality",
      },
    ];

    const filtered = filters
      .filter((item) => filterValues[item.key] > 0)
      .map((item) => ({
        id: item.id,
        filter: "=",
        value: filterValues[item.key],
      }));

    onSearch({ filters: filtered });
  };

  const handleResetFilter = () => {
    setFilterValues({
      groupBy: VIEW_ESTATAL,
      variable: "",
      municipality: "",
      section: "",
    });
    setCatVariable([]);
    setLabelVariable("VISTA ESTATAL");
    setCatalogFiltered(catalogs);
    onClear();
  };

  useEffect(() => {
    if (entity !== VIEW_NATIONAL) {
      getCatalogs({ body: { idEstado: entity, catalogs: CATALOGS_OPTIONS } });
      setFilterValues((prevState) => ({
        ...prevState,
        groupBy: VIEW_ESTATAL,
        variable: "",
        municipality: "",
        section: "",
      }));
      setCatVariable([]);
      setLabelVariable("");
    }
  }, [entity]);

  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={2} md={2} lg={2}>
              <InputSelect
                name="groupBy"
                label="Filtrar por"
                value={filterValues.groupBy}
                options={catGroupBy}
                onChange={(e) => handleChangeGroupBy({ value: e.target.value })}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            {filterValues.groupBy !== VIEW_ESTATAL && (
              <>
                <Grid2 xs={12} sm={3} md={3} lg={3}>
                  <InputSelect
                    name="variable"
                    label={labelVariable}
                    value={filterValues.variable}
                    options={catVariable}
                    onChange={(e) =>
                      handleChangeVariable({ value: e.target.value })
                    }
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </Grid2>
                <Grid2 xs={12} sm={3} md={3} lg={3}>
                  <InputSelect
                    label="Municipios"
                    name="municipality"
                    value={filterValues.municipality}
                    options={catalogsFiltered.municipios}
                    onChange={(e) =>
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      })
                    }
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </Grid2>
                <Grid2 xs={12} sm={2} md={2} lg={2}>
                  <Select
                    placeholder="Sección"
                    styles={selectStyles}
                    menuPortalTarget={document.body}
                    options={catalogsFiltered.secciones.map(
                      ({ value, label }) => ({
                        value,
                        label: String(label),
                      })
                    )}
                    value={filterValues.section}
                    onChange={(selectdOption) => {
                      handleChangeFilter({
                        name: "section",
                        value: selectdOption,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                  />
                </Grid2>

                <Grid2 xs={12} sm={2} md={2} lg={2}>
                  <Stack spacing={1} direction={"row"}>
                    <Button
                      variant="outlined"
                      color="primaryDark"
                      onClick={handleResetFilter}
                    >
                      Limpiar
                    </Button>
                    <Button
                      variant="contained"
                      color="primaryDark"
                      onClick={handleSearch}
                    >
                      Filtrar
                    </Button>
                  </Stack>
                </Grid2>
              </>
            )}
          </Grid2>
        </FilterCollapse>
      </CardContent>
    </Card>
  );
};

export default Filter;
