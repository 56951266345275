import PropTypes from "prop-types";
import moment from "moment";
//Mui
import {
    Box,
    Card,
    CardContent,
    Icon,
    Stack,
    Typography,
    CircularProgress,
    ButtonGroup,
    Button
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { blue, grey } from "@mui/material/colors";

import PieProgressCard from "@components/Card/PieProgressCard";

const PrepSamplingHeaders = (props) => {
    const { sampling, setSampling, summaryData, loadingCards } = props;

    return (
        <Box component={'div'}>
            <Grid2 container spacing={2} marginTop={2} justifyContent={'center'}>
                <ButtonGroup size="large" variant="outlined">
                    <Button
                        color="info"
                        key={'fastcount'}
                        onClick={e => setSampling(true)}
                        variant={sampling ? 'contained' : 'outlined'}
                        sx={{
                            borderWidth: '1px',
                            borderRadius: '30px',
                            pr: '23 px',
                            pl: '23 px'
                        }}
                    >
                        Conteo Rápido
                    </Button>
                    <Button
                        color="info"
                        key={'machinelearning'}
                        onClick={e => setSampling(false)}
                        variant={!sampling ? 'contained' : 'outlined'}
                        sx={{
                            borderWidth: '1px',
                            borderRadius: '30px',
                            pr: '23 px',
                            pl: '23 px'
                        }}
                    >
                        Machine Learning
                    </Button>
                </ButtonGroup>
            </Grid2>
            <Grid2 container spacing={2} marginTop={2}>
                <Grid2 xs={12} md={4}>
                    <Box
                        component={Card}
                        className="card-primary"
                        height={"91%"}
                        display="flex"
                        flexDirection={"column"}
                        alignContent={"center"}
                    >
                        <Stack
                            direction={"row"}
                            spacing={1}
                            justifyContent="center"
                            marginTop={2}
                        >
                            <Icon sx={{ color: grey[600] }}>newspaper</Icon>
                            <Typography marginTop={2} fontWeight={600} variant="h6">
                                Elección
                            </Typography>
                        </Stack>

                        <Box component={CardContent}>
                            <Box display={"flex"} alignItems="center" gap={2}>
                                <div>
                                    <Box
                                        width={"50px"}
                                        height={"50px"}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        margin={"10px"}
                                        sx={{ backgroundColor: blue[50], borderRadius: "50%" }}
                                    >
                                        <Icon sx={{ fontSize: "3rem", color: blue[800] }}>
                                            description
                                        </Icon>
                                    </Box>
                                </div>
                                {
                                    loadingCards ? (
                                        <CircularProgress
                                            sx={{ color: 'blue', m: 1 }}
                                            size={15}
                                        />
                                    ) : (
                                        <div>
                                            <Typography
                                                variant="Subtitle2"
                                                fontWeight={600}
                                                fontSize={18}
                                            >
                                                {summaryData.TipoEleccion}
                                            </Typography>
                                            <Typography variant="body2">{summaryData.Ambito}</Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems="center"
                                                marginTop={1}
                                            >
                                                <Icon fontSize="small" sx={{ color: grey[600] }}>
                                                    calendar_today
                                                </Icon>
                                                <Typography variant="body2">
                                                    {summaryData.FechaHora}
                                                </Typography>
                                            </Stack>
                                        </div>
                                    )
                                }

                            </Box>
                        </Box>
                    </Box>
                </Grid2>
                <Grid2 xs={12} md={4}>
                    <PieProgressCard
                        loading={loadingCards}
                        data={summaryData.PActas}
                        title={'Actas Recibidas'}
                        config={{
                            icon: 'description'
                        }}
                    />
                </Grid2>
                <Grid2 xs={12} md={4}>
                    <PieProgressCard
                        loading={loadingCards}
                        data={summaryData.Participacion}
                        title={'Participación Ciudadana'}
                        config={{
                            icon: 'group'
                        }}
                    />
                </Grid2>
            </Grid2>
        </Box>
    )
}

PrepSamplingHeaders.propTypes = {
    summaryData: PropTypes.object.isRequired
};

PrepSamplingHeaders.defaultProps = {
    summaryData: {
        TipoEleccion: "",
        TotalActas: 0,
        ActasRecibidas: 0,
        TotalVotos: 0,
        LNom: 0,
        FechaHora: moment().format('DD/MM/YYYY HH:mm'),
        PActas: 0,
        Participacion: 0,
        Ambito: ""
    },
};

export default PrepSamplingHeaders;