import { PrivilegedRoute, ProtectedRoute } from "@middlewares";
import Movilization from "@pages/Rcos/Movilization";
import Polygonal from "@pages/Rcos/Polygonal";

const SijeRoutes = [
  {
    path: "/rco-movilizacion",
    element: (
      <ProtectedRoute>
        <Movilization />
      </ProtectedRoute>
    ),
  },
  {
    path: "/rco-poligonal",
    element: (
      <ProtectedRoute>
        <Polygonal />
      </ProtectedRoute>
    ),
  },
];
export default SijeRoutes;
