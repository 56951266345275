import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Tab, Box, Paper, Table, TableBody, TableHead, TableRow, TableCell, TablePagination, Chip, Icon, Typography, TableSortLabel } from "@mui/material";
import { TabList, TabContext } from "@mui/lab";
import { green, red,grey } from "@mui/material/colors";


const UserAssistTable = (props) => {
  const {rows, columns, total, page, pageSize, handleChangeRowsPerPage, handleChangePage, setEstatus, setSorted, navigateTo} = props;
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
    setEstatus(newVal)
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setEstatus(tabValue, { orderBy: property, order: isAsc ? "desc" : "asc" });
    setSorted([{ id: property, value: isAsc ? "desc" : "asc" }]);
  };

    
  const getStripedStyle = (index, row) => {
    return row.style && row.style.background ? row.style : { background: index % 2 ? "#E2FFE4" : "white" };
  };

  const getStatusTextColor = (status) => {
    switch (status) {
      case 1:
        return red[900]; 
      case 2:
        return green[900]; 
      case 3:
        return grey[700]; 
      default:
        return 'black'; 
    }
  };
  const getBackgroundColor = (estatus) => {
    switch (estatus) {
      case 1:
        return red[300];
      case 2:
        return green[200];
      case 3:
        return grey[300];
      default:
        return "";
    }
  };
 
  const onRowClick = (id, Nombre, Celular, SolicitudSoporte, NumeroSolicitudes, FechaCreo, idEstatus, TieneUsuario, total) => {
    const chipColor = getBackgroundColor(idEstatus);
    navigate(navigateTo, {
      state: { id,  Nombre, Celular, SolicitudSoporte, NumeroSolicitudes, FechaCreo, idEstatus, TieneUsuario, total, chipColor },
    });
  };

  return (
    <Card className="card-primary">
      <TabContext value={tabValue}>
        <Box>
          <TabList
            onChange={handleChangeTab}
            aria-label="tab for user module"
            textColor="primary"
            indicatorColor="primary"
            start
            scrollButtons="auto"
            centered
          >
            <Tab iconPosition="start" label="TODOS" value={0} />
            <Tab iconPosition="start" label="Atendidas" value={2} />
            <Tab iconPosition="start" label="Pendientes" value={1} />
            <Tab iconPosition="start" label="Canceladas" value={3} />
          </TabList>
        </Box>
        <Card
          className="card-secondary"
          style={{
            width: "95%",
            margin: "auto",
            marginBottom: "1%",
            marginTop: "1%",
          }}
        >
          <Paper sx={{ overflowX: "auto" }}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "rgba(189, 189, 189, 0.3)" }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell 
                      key={column.field}
                      align="left"
                      sortDirection={orderBy === column.field ? order : false}
                      sx={{ backgroundColor: "rgba(189, 189, 189, 0.3)" }}
                    >
                      <TableSortLabel
                        active={orderBy === column.field}
                        direction={orderBy === column.field ? order : "asc"}
                        onClick={() => handleRequestSort(column.field)}
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                 <TableRow>
                 <TableCell colSpan={12} align="center">
                   <Icon fontSize={"large"}>info</Icon>
                   <Typography>Sin Registros</Typography>
                 </TableCell>
               </TableRow>
                ):(
                  rows.map((row, index) => (
                    <TableRow 
                      key={row.id} 
                      style={{
                          ...getStripedStyle(index, row),
                          backgroundColor: hoveredRowIndex === index ? "#e0e0e0" : getStripedStyle(index, row).background,
                        }}
                        onClick={() => onRowClick(row.id, row.Nombre, row.Celular, row.SolicitudSoporte, row.NumeroSolicitudes, row.FechaCreo, row.idEstatus, row.TieneUsuario)}
                        onMouseEnter={() => setHoveredRowIndex(index)}
                        onMouseLeave={() => setHoveredRowIndex(-1)}
                    >
                      {columns.map((header, index) => (
                        <TableCell key={index}>
                           {header.field === "TieneUsuario" ? (row.TieneUsuario ? "Sí" : "No") : 
                           header.field === "idEstatus" ? (
                            <Chip
                              label={row.idEstatus === 1 ? "Pendiente" : row.idEstatus === 2 ? "Atendido" : row.idEstatus === 3 ? "Cancelado" : ""}
                              style={{
                                backgroundColor: getBackgroundColor(row.idEstatus),
                                color: getStatusTextColor(row.idEstatus),
                                borderRadius: "4px",
                                height: "auto",
                              }}
                            />
                          ) :
                          row[header.field]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                )}
                
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              rowsPerPage={pageSize}
              page={page}
              count={total}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Registros por página"
            />
          </Paper>
        </Card>
      </TabContext>
    </Card>
  );
};

export default UserAssistTable;
