import { useEffect, useState } from "react";

import FilterPopper from "@components/Filters/FilterPopper";
import BasicSelect from "@components/Selects/BasicSelect";

import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import {
  CATALOGS_OPTIONS,
  CATALOGS_GROUPS,
  ENTITY,
  DF,
  DF_PRIORITY,
  PRIORITY,
  DEFAULT_OPTION,
} from "@data/constants/Reports";

const Filter = ({ filterValue, setFilterValue, setHasEntity }) => {
  const valueDefault = DEFAULT_OPTION.value;
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of CATALOGS_OPTIONS) {
      newObject[item.id] = [];
    }
    return newObject;
  });

  const getCatalogs = async () => {
    try {
      const result = await CatalogServices.getCatalogsNacional({
        catalogs: CATALOGS_OPTIONS,
      });

      const {
        results,
        response: { catalogs },
        message,
      } = result;

      if (!results) {
        throw new Error(message);
      }
      const _catalogs = {
        ...catalogs,
        dfPriority: catalogs.df.filter(
          ({ Prioritario }) => Prioritario === PRIORITY
        ),
      };

      setCatalogFiltered(_catalogs);
      setCatalogs(_catalogs);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
    }
  };

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterValue({ ...filterValue, [key]: e.target.value });
  };

  const handleChangeEntity = (e) => {
    const { value: idEntity } = e.target;

    if (idEntity === valueDefault) {
      setCatalogFiltered(catalogs);
      setFilterValue((prevState) => ({
        ...prevState,
        entity: valueDefault,
        df: valueDefault,
        dfPriority: valueDefault,
      }));
      return;
    }

    const _df = catalogs.df.filter(({ idEntidad }) => idEntidad === idEntity);

    setCatalogFiltered((prevState) => ({
      ...prevState,
      df: _df,
      dfPriority: _df.filter(({ Prioritario }) => Prioritario === PRIORITY),
    }));
    setFilterValue((prevState) => ({
      ...prevState,
      df: valueDefault,
      dfPriority: valueDefault,
    }));
  };

  const handleSearchFilter = () => {
    setHasEntity(filterValue.entity !== valueDefault);
  };

  const handleClearFilter = () => {
    setFilterValue({
      group: valueDefault,
      entity: valueDefault,
      df: valueDefault,
      dfPriority: valueDefault,
    });
    setCatalogFiltered(catalogs);
    setHasEntity(false);
  };

  useEffect(() => {
    getCatalogs();
  }, []);

  return (
    <FilterPopper
      handleClearFilter={handleClearFilter}
      handleSearchFilter={handleSearchFilter}
      zIndex={1001}
      width="20%"
    >
      <BasicSelect
        label="VER POR"
        name="group"
        value={filterValue.group}
        options={[DEFAULT_OPTION].concat(CATALOGS_GROUPS)}
        onChange={handleChangeFilter}
        sx={{ width: "100%", mb: 2 }}
      />
      {filterValue.group !== ENTITY && (
        <BasicSelect
          label="ENTIDAD"
          name="entity"
          value={filterValue.entity}
          options={[DEFAULT_OPTION].concat(catalogs.entidades) || []}
          onChange={(e) => {
            handleChangeFilter(e);
            handleChangeEntity(e);
          }}
          sx={{ width: "100%", mb: 2 }}
        />
      )}
      {filterValue.group !== ENTITY && filterValue.group !== DF_PRIORITY && (
        <BasicSelect
          label="DF"
          value={filterValue.df}
          name="df"
          options={
            [DEFAULT_OPTION].concat(
              catalogsFiltered.df?.map(({ idDF, DistritoFederal }) => ({
                value: idDF,
                label: DistritoFederal,
              }))
            ) || []
          }
          onChange={handleChangeFilter}
          sx={{ width: "100%", mb: 2 }}
        />
      )}
      {filterValue.group !== ENTITY && filterValue.group !== DF && (
        <BasicSelect
          label="DF PRIORITARIO"
          name="dfPriorty"
          value={
            catalogsFiltered.dfPriority?.length > 1
              ? filterValue.dfPriority
              : ""
          }
          options={
            catalogsFiltered.dfPriority?.length > 1
              ? [DEFAULT_OPTION].concat(
                  catalogsFiltered.dfPriority?.map(
                    ({ idDF, DistritoFederal }) => ({
                      value: idDF,
                      label: DistritoFederal,
                    })
                  )
                )
              : []
          }
          onChange={handleChangeFilter}
          sx={{ width: "100%", mb: 2 }}
        />
      )}
    </FilterPopper>
  );
};

export default Filter;
