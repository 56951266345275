import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Typography,
  Stack,
  Avatar,
  CircularProgress,
  LinearProgress,
  Grid,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineSeparator,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import UserService from "@services/UserServices";
import { getVars } from "@utils/global";
import { UserAccessInterface } from "@data/interfaces/UserInterfaces";
import useWindowDimensions from "@hooks/useWindowDimensions";
import moment from "moment/moment";
import "moment/locale/es";
import chrome from "@assets/img/browsers/google-chrome.png";
import safari from "@assets/img/browsers/apple-safari.png";
import explorer from "@assets/img/browsers/internet-explorer.png";
import firefox from "@assets/img/browsers/mozila-firefox.png";
import opera from "@assets/img/browsers/opera.png";
import postman from "@assets/img/browsers/postman.png";

const UserAccess = (props) => {
  const { /* data */ userId, message } = props;
  const { height } = useWindowDimensions();
  const [page, setPage] = useState(0);
  const [rowsPerPage, /* setRowsPerPage */] = useState(10);
  const [rows, setRows] = useState([UserAccessInterface]);
  const [total, setTotal] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreRecords, setNoMoreRecords] = useState(false);
  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (t !== {}) return t.access_token;
    return "";
  });

  const timelineContainerRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    UserService.getAccessLogs(
      {
        idUsuario: userId,
        page: page,
        pageSize: rowsPerPage,
        filtered: [],
      },
      access_token
    )
      .then((res) => {
        if (res.results) {
          setRows(res.response.data);
          setTotal(res.response.total);
        } else {
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [userId, access_token]);

  const orangeDotStyle = { backgroundColor: "orange" };

  const handleScroll = () => {
    const container = timelineContainerRef.current;

    if (
      container.scrollTop + container.clientHeight >= container.scrollHeight - 20 &&
      !isLoadingMore &&
      rows.length < total
    ) {
      setIsLoadingMore(true);
      setPage(page + 1);

      UserService.getAccessLogs(
        {
          idUsuario: userId,
          page: page + 1,
          pageSize: rowsPerPage,
          filtered: [],
        },
        access_token
      )
        .then((res) => {
          if (res.results) {
            setRows((prevRows) => [...prevRows, ...res.response.data]);
            setTotal(res.response.total);
            setNoMoreRecords(res.response.data.length === 0);
          } else {
            // Manejar el caso de error si es necesario
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoadingMore(false);
        });
    }
  };

  useEffect(() => {
    const container = timelineContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [isLoadingMore, rows.length, total, userId, page, rowsPerPage, access_token]);
  console.log(rows.length)

  return (
    <Grid sx={{ width: "100%", p: 1 }}>
      <Card className="card-secondary">
        <Grid
          id="timeline-container"
          ref={timelineContainerRef}
          sx={{
            maxHeight: height * 0.4,
            overflowY: rows.length ? "scroll" : "none",
            marginTop: 2,
          }}
        >
          {isLoading ? (
            <Grid height={100} sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
              <CircularProgress size={50} />
            </Grid>
          ) : (
            <>
              {rows.length > 0 && (
                <>
                  {rows.map((user) => (
                    <Timeline
                      key={user.id}
                      position="right"
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot style={orangeDotStyle} />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ width: "100%", marginLeft: 2 }}>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                sm: "row",
                              },
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Grid>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar
                                  src={
                                    user.Navegador === "Chrome" ? (
                                      chrome
                                    ) : user.Navegador === "Firefox" ? (
                                      firefox
                                    ) : user.Navegador === "Safari" ? (
                                      safari
                                    ) : user.Navegador === "Explorer" ? (
                                      explorer
                                    ) : user.Navegador === "Opera" ? (
                                      opera
                                    ) : user.Navegador === "PostmanRuntime" ? (
                                      postman
                                    ) : (
                                      <></>
                                    )
                                  }
                                  sx={{ width: 20, height: 20 }}
                                ></Avatar>
                                <Typography>{user.Navegador}</Typography>
                              </Stack>
                            </Grid>
                            <Grid>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "12px",
                                  marginLeft: {
                                    xs: 0,
                                    sm: 20,
                                  },
                                  marginBottom: {
                                    xs: 2,
                                    sm: 0,
                                  },
                                  color: "gray",
                                }}
                              >
                                {moment(user.FechaHora, "DD-MM-YYYY HH:m").fromNow()}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Typography
                            variant="h8"
                            sx={{
                              marginTop: 2,
                              fontSize: "15px",
                              fontWeight: 400,
                            }}
                          >
                            Plataforma: {user.Plataforma}
                          </Typography>
                          <Typography variant="h4" sx={{ marginTop: 2, fontSize: "13px" }}>
                            {user.FechaHora}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  ))}

                  {noMoreRecords && (
                    <Typography variant="h6" sx={{ textAlign: "center", fontSize: "15px", marginTop: 3 }}>
                      No hay más registros para mostrar
                    </Typography>
                  )}
                  {isLoadingMore && (
                    <Grid sx={{ display: "flex", justifyContent: "center", marginY: 2 }}>
                      <LinearProgress sx={{ width: "100%" }} color="secondary" />
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
          {rows.length === 0 && (
            <Typography variant="h6" sx={{ textAlign: "center", fontSize: "13px" }}>
              {userId ? message : ""}
            </Typography>
          )}
        </Grid>
      </Card>
      <Typography sx={{ textAlign: "center", fontSize: "14px", marginTop: "10px" }}>
        Mostrando {Math.min(rows.length, total)} de {total}
      </Typography>
    </Grid>
  );
};

export default UserAccess;
