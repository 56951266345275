import React, { useState } from "react";

import {
	Box,
	TextField,
	Chip,
	Icon,
	FormHelperText,
	IconButton,
	InputAdornment,
	Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";
import BasicSelect from "@components/Selects/BasicSelect";
import { getVars } from "@utils/global";

const UserForm = (props) => {
	const { catalogs, errors, touched, values, handleChange, loadigCatalogs } =
		props;
	const { user } = getVars("Token");
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordR, setShowPasswordR] = useState(false);
	const [afiliacion] = useState([
		{ value: 1, label: "Ciudadano" },
		{ value: 2, label: "Asociación Civil" },
		{ value: 3, label: "Partido Político" },
	]);

	const hadleUsuario = (value) => {
		handleChange({
			target: {
				name: "Celular",
				value: isTypePhone(value) ? value : values.Celular,
			},
		});

		handleChange({
			target: {
				name: "PIN",
				value: isTypePhone(value.substring(0, 5))
					? value.substring(0, 5)
					: values.PIN,
			},
		});
	};

	const handleAfiliacion = (e) => {
		let idType = e.target.value;
		if (idType === 2) {
			handleChange({
				target: {
					name: "NombreOSC",
					value: "",
				},
			});
		}
		if (idType !== 2) {
			handleChange({
				target: {
					name: "NombreOSC",
					value: null,
				},
			});
		}
		if (idType !== 3) {
			handleChange({
				target: {
					name: "idPartido",
					value: user?.idPartido ? user?.idPartido : null,
				},
			});
		}
		handleChange(e);
	};

	return (
		<Box sx={{ mt: 1, mb: 2 }}>
			<Grid2 xs={12} sm={12} md={12} lg={12}>
				{/* <Chip icon={<Icon>person</Icon>} label="Datos de la cuenta" /> */}
				<Typography variant="h6">Datos para ingresar a tu cuenta: </Typography>
			</Grid2>
			<Grid2 container spacing={2} sx={{ mt: 1 }}>
				<Grid2 xs={12} sm={12} md={4} lg={4}>
					<TextField
						error={!isEmptyOrInvalidString(errors.Celular)}
						helperText={errors.Celular && errors.Celular}
						sx={{ width: "100%" }}
						required
						label="Celular"
						//type="text"
						type="tel"
						name="Celular"
						onChange={(e) => hadleUsuario(e.target.value)}
						onBlur={(e) => hadleUsuario(e.target.value.trim())}
						value={values.Celular}
						variant="outlined"
						size="small"
						className="fixed-input"
						placeholder="Celular"
					/>
				</Grid2>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<TextField
						error={!isEmptyOrInvalidString(errors.Password)}
						helperText={errors.Password && errors.Password}
						sx={{ width: "100%" }}
						required
						label="Contraseña"
						name="Password"
						onChange={(e) =>
							handleChange({
								target: {
									name: "Password",
									value: e.target.value,
								},
							})
						}
						onBlur={(e) =>
							handleChange({
								target: {
									name: "Password",
									value: e.target.value.trim(),
								},
							})
						}
						value={values.Password}
						variant="outlined"
						size="small"
						className="fixed-input"
						type={showPasswordR ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={(e) => {
											setShowPasswordR(!showPasswordR);
										}}
									>
										{showPasswordR ? (
											<Icon>visibility_off</Icon>
										) : (
											<Icon>visibility</Icon>
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<FormHelperText sx={{ textAlign: "end" }}>
						La contraseña debe contener mínimo 8 carácteres, una letra
						mayúscula, número y símbolo especial (* _ . @)
					</FormHelperText>
				</Grid2>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<TextField
						error={!isEmptyOrInvalidString(errors.Confirm)}
						helperText={errors.Confirm && errors.Confirm}
						sx={{ width: "100%" }}
						required
						label="Repetir Contraseña"
						name="Confirm"
						onChange={(e) =>
							handleChange({
								target: {
									name: "Confirm",
									value: e.target.value,
								},
							})
						}
						onBlur={(e) =>
							handleChange({
								target: {
									name: "Confirm",
									value: e.target.value.trim(),
								},
							})
						}
						value={values.Confirm}
						variant="outlined"
						size="small"
						className="fixed-input"
						type={showPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={(e) => {
											setShowPassword(!showPassword);
										}}
									>
										{showPassword ? (
											<Icon>visibility_off</Icon>
										) : (
											<Icon>visibility</Icon>
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid2>
				<Grid2 xs={6} sm={12} md={2} lg={2}>
					<TextField
						error={!isEmptyOrInvalidString(errors.PIN)}
						helperText={errors.PIN && errors.PIN}
						sx={{ width: "100%" }}
						required
						label="PIN"
						//type="text"
						type="tel"
						name="PIN"
						onChange={(e) =>
							handleChange({
								target: {
									name: "PIN",
									value: isTypePhone(e.target.value)
										? e.target.value
										: values.PIN,
								},
							})
						}
						onBlur={(e) =>
							handleChange({
								target: {
									name: "PIN",
									value: isTypePhone(e.target.value)
										? e.target.value
										: values.PIN,
								},
							})
						}
						value={values.PIN}
						variant="outlined"
						size="small"
						inputProps={{
							maxLength: 5,
						}}
						className="fixed-input"
					/>
					<FormHelperText sx={{ textAlign: "end" }}>
						PIN único númerico
					</FormHelperText>
				</Grid2>
			{/* 	<Grid2 xs={12} sm={12} md={12} lg={6}>
					<BasicSelect
						name="idAfiliacion"
						label="Seleccione una opción *"
						options={afiliacion}
						value={values.idAfiliacion}
						onChange={(e) => handleAfiliacion(e)}
						isLoading={false}
						sx={{ width: "100%", mb: 1 }}
					/>
					{errors.idAfiliacion && touched.idAfiliacion && (
						<FormHelperText error>
							Por favor, selecciona una opción
						</FormHelperText>
					)}
				</Grid2> */}
				{values.idAfiliacion === 2 && (
					<Grid2 xs={12} sm={12} md={6} lg={6}>
						<TextField
							error={!isEmptyOrInvalidString(errors.NombreOSC)}
							helperText={errors.NombreOSC && errors.NombreOSC}
							sx={{ width: "100%" }}
							required
							label="Asociación Civil"
							type="text"
							name="NombreOSC"
							onChange={(e) =>
								handleChange({
									target: {
										name: "NombreOSC",
										value: e.target.value,
									},
								})
							}
							onBlur={(e) =>
								handleChange({
									target: {
										name: "NombreOSC",
										value: e.target.value.trim(),
									},
								})
							}
							value={values.NombreOSC}
							variant="outlined"
							size="small"
							className="fixed-input"
						/>
					</Grid2>
				)}
				{values.idAfiliacion === 3 && (
					<Grid2 xs={12} sm={12} md={12} lg={6}>
						<BasicSelect
							name="idPartido"
							label="Seleccione un partido *"
							options={catalogs.partidos}
							value={values.idPartido}
							onChange={handleChange}
							isLoading={loadigCatalogs}
							sx={{ width: "100%", mb: 1 }}
						/>
						{errors.idPartido && touched.idPartido && (
							<FormHelperText error>Seleccione un partido</FormHelperText>
						)}
					</Grid2>
				)}
			</Grid2>
		</Box>
	);
};

export default UserForm;
