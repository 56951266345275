import { useState, useEffect } from "react";

// Material UI
import { Grid, Button, Stack } from "@mui/material";

// Componentes
import AdvancedSelect from "@components/Selects/AdvancedSelect";

// Servicios y Utilidades
import { setVars, getVars } from "@utils/global";

const Filter = (props) => {
	const { catalogs, isLoadingCatalogs, handleFilter, width = "100%" } = props;

	const [municipiosFilter, setMunicipiosFilter] = useState([]);
	const [perfilFilter, setPerfilFilter] = useState([]);

	const [filter, setFilter] = useState({
		idEstado: 0,
		idMunicipio: 0,
		idPerfil: 0,
		idTipo: "",
	});

	// Llena inicialmente
	useEffect(() => {
		if (catalogs) {
			const params = getVars("filterSavedMap");
			const tieneFiltros = Object.values(params).some((value) => value !== "");

			if (tieneFiltros) {
				setFilter(params);
				getCatalogsWithStored();
			} else {
				params.idEstado =
					catalogs.entidades.length === 1 ? catalogs.entidades[0].value : 0;
				params.idMunicipio =
					catalogs.municipios.length === 1 ? catalogs.municipios[0].value : 0;
				params.idPerfil =
					catalogs.perfil.length === 1 ? catalogs.perfil[0].value : 0;
				params.idTipo = 0;

				setFilter(params);
				setPerfilFilter(catalogs.perfil);
				setMunicipiosFilter(catalogs.municipios);
			}
		}
		// eslint-disable-next-line
	}, [catalogs]);

	// Obtener el catalogo de municipios guardados
	const getCatalogsWithStored = () => {
		setPerfilFilter(getVars("filterSavedMapPerfil"));
		setMunicipiosFilter(getVars("filterSavedMapMunicipio"));
	};

	const handleChangeEstado = (e) => {
		const value = e.value;
		const idMunicipio =
			catalogs.municipios?.length === 1 ? catalogs.municipios[0].value : 0;

		const save = {
			idEstado: value,
			idMunicipio: idMunicipio,
			idPerfil: 0,
			idTipo: 0,
		};
		setFilter(save);
	};

	const handleChangeMunicipio = (e) => {
		const value = e.value;
		const save = {
			idEstado: filter.idEstado,
			idMunicipio: value,
			idTipo: 0,
			idPerfil: 0,
		};
		setFilter(save);
	};

	/*   const handleChangeTipo = (e) => {
    const value = e.value
    let one = 0;
    if (value !== 0) {
      const find = catalogs.perfil.find((item) => item.value === value);
      const filtered = catalogs.perfil.filter((option) => option.idTipo === find.idTipo);
      if (filtered.length === 1) one = filtered[0].value;
      setPerfilFilter(filtered.length > 1 ? [{ value: 0, label: "TODOS" }].concat(filtered) : filtered);
    } else setPerfilFilter(catalogs.perfil);

    const save = {
      idEstado: filter.idEstado,
      idMunicipio: filter.idMunicipio,
      idTipo: value,
      idPerfil: one,
    };
    setFilter(save);
  };

  const handleChangePerfil = (e) => {
    const value = e.value
    const save = {
      idEstado: filter.idEstado,
      idMunicipio: filter.idMunicipio,
      idTipo: filter.idTipo,
      idPerfil: value,
    };
    setFilter(save);
  }; */

	const handleClick = () => {
		const filterData = {
			idEstado: filter.idEstado,
			idMunicipio: filter.idMunicipio,
			idPerfil: filter.idPerfil,
			idTipo: filter.idTipo,
		};

		setVars("filterSavedMap", filterData);
		setVars("filterSavedMapPerfil", perfilFilter);
		setVars("filterSavedMapMunicipio", municipiosFilter);

		handleFilter(filterData, "filter");
	};

	const handleClear = () => {
		const idEstado =
			catalogs.entidades.length === 1 ? catalogs.entidades[0].value : 0;
		const idMunicipio =
			catalogs.municipios.length === 1 ? catalogs.municipios[0].value : 0;
		const idPerfil =
			catalogs.perfil.length === 1 ? catalogs.perfil[0].value : 0;

		const filterData = {
			idEstado: idEstado,
			idMunicipio: idMunicipio,
			idPerfil: idPerfil,
			idTipo: 0,
		};

		setFilter(filterData);
		setVars("filterSavedMap", filterData);
		setVars("filterSavedMapPerfil", catalogs.perfil);
		setVars("filterSavedMapMunicipio", catalogs.municipios);
		setPerfilFilter(catalogs.perfil);
		setMunicipiosFilter(catalogs.municipios);

		handleFilter(filterData, "clear");
	};

	const disableButtons =
		isLoadingCatalogs ||
		filter.idEstado === "" ||
		(filter.idMunicipio === "" && filter.idPerfil === "");

	return (
		<>
			<Grid
				container
				rowSpacing={1.5}
				sx={{ width: { xs: "100%", sm: width } }}
			>
				<Grid item xs={12}>
					<AdvancedSelect
						label="Estado"
						name="estado"
						id="estado"
						options={catalogs?.entidades}
						value={filter.idEstado}
						onChange={handleChangeEstado}
						isLoading={isLoadingCatalogs}
						isSearchable
					/>
				</Grid>

				{filter.idEstado > 0 ? (
					<Grid item xs={12}>
						<AdvancedSelect
							label="Municipio"
							name="municipio"
							id="municipio"
							options={catalogs?.municipios}
							value={filter.idMunicipio}
							onChange={handleChangeMunicipio}
							isLoading={isLoadingCatalogs}
							isSearchable
						/>
					</Grid>
				) : (
					<></>
				)}

				{/* <Grid item xs={12}>
          <BasicSelect
            label="Tipos"
            name="tipos"
            id="tipos"
            options={catalogs?.tipos}
            value={filter.idTipo}
            onChange={(e) => handleChangeTipo(e.target.value)}
            isLoading={isLoadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12}>
          <AdvancedSelect
            label="Asignación"
            name="responsabilidad"
            id="responsabilidad"
            options={perfilFilter}
            value={filter.idPerfil}
            onChange={handleChangePerfil}
            isLoading={isLoadingCatalogs}
            isSearchable
          />
        </Grid> */}
			</Grid>
			<br></br>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				<Button
					size="small"
					style={{ borderRadius: "20px", alignSelf: "center" }}
					variant="outlined"
					disabled={disableButtons}
					onClick={handleClick}
				>
					Filtrar
				</Button>
				<Button
					size="small"
					style={{ borderRadius: "20px", alignSelf: "center" }}
					variant="outlined"
					disabled={disableButtons}
					onClick={handleClear}
				>
					Limpiar
				</Button>
			</Stack>
		</>
	);
};
export default Filter;
