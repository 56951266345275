import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

//MUI
import {
	Box,
	Button,
	Grow,
	Icon,
	IconButton,
	Stack,
	TextField,
	Typography,
	CircularProgress,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { grey } from "@mui/material/colors";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import InputSelect from "@components/Selects/BasicSelect";
import { limpiarTelefono } from "@utils/global";

const Filter = ({ onChangeFilter, catalogsOptions, catalogs, load }) => {
	const animatedComponents = makeAnimated();
	const [checked, setChecked] = useState(false);

	const [catalogsFiltered, setCatalogFiltered] = useState(() => {
		let newObject = {};
		for (const item of catalogsOptions) {
			newObject[item.id] = [];
		}
		return newObject;
	});
	const [voluntarios] = useState([
		{ value: 0, label: "Todos" },
		{ value: 1, label: "Si" },
		{ value: 2, label: "No" },
	]);
	const [selectedPolygons, setSelectedPolygons] = useState([]);
	const [selectedSections, setSelectedSections] = useState([]);
	const [selectedMun, setSelectedMun] = useState([]);
	const [filterData, setFilterData] = useState({
		Region: "",
		Voluntarios: "",
		Municipio: [],
		Poligono: [],
		Seccion: [],
		Responsabilidad: "",
		Voluntario: "",
		Celular: "",
		INE: "",
		Nombre: "",
		Paterno: "",
		Materno: "",
	});

	const selectStyles = {
		menuPortal: (base) => ({ ...base, zIndex: 9999 }),
		menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
	};

	useEffect(() => {
		if (load === false) {
			setCatalogFiltered(catalogs);
		}
		// eslint-disable-next-line
	}, [load]);

	//Functions and Handlers
	const handleChecked = () => {
		setChecked((prevState) => !prevState);
	};

	const handleChangeRegion = (value) => {
		if (value > 0) {
			setCatalogFiltered((prevState) => ({
				...prevState,
				municipios_dl: catalogs.municipios_dl.filter(
					(item) => item.idRegion === parseInt(value)
				),
				poligonos: catalogs.poligonos.filter(
					(item) => item.idRegion === parseInt(value)
				),
				secciones: catalogs.secciones.filter(
					(item) => item.idRegion === parseInt(value)
				),
			}));
		} else {
			setCatalogFiltered((prevState) => ({
				...prevState,
				municipios_dl: catalogs.municipios_dl,
				poligonos: catalogs.poligonos,
				secciones: catalogs.secciones,
			}));
		}
	};

	const handleChangeMunicipality = (values) => {
		setSelectedMun(values);

		const array_mun = values.map((option) => option.value || option);
		setFilterData((prevState) => ({ ...prevState, Municipio: array_mun }));

		if (array_mun.length > 0) {
			setCatalogFiltered((prevState) => ({
				...prevState,
				poligonos: catalogs.poligonos.filter((item) =>
					array_mun.some((id) => id === item.idMunicipioReportes)
				),
				secciones: catalogs.secciones.filter((item) =>
					array_mun.some((id) => id === item.idMunicipioReportes)
				),
			}));
		} else {
			setCatalogFiltered((prevState) => ({
				...prevState,
				poligonos: catalogs.poligonos,
				secciones: catalogs.secciones,
			}));
		}
	};

	const handleChangePolygon = (values) => {
		setSelectedPolygons(values);
		const array_poligon = values.map((option) => option.value || option);
		setFilterData((prevState) => ({ ...prevState, Poligono: array_poligon }));

		if (array_poligon.length > 0) {
			setCatalogFiltered((prevState) => ({
				...prevState,
				secciones: catalogs.secciones.filter((item) =>
					array_poligon.some((id) => id === item.idPoligono)
				),
			}));
		} else {
			setCatalogFiltered((prevState) => ({
				...prevState,
				secciones: catalogs.secciones,
			}));
		}
	};

	const handleChangeSection = (values) => {
		setSelectedSections(values);
		const array_section = values.map((option) => option.value || option);
		setFilterData((prevState) => ({ ...prevState, Seccion: array_section }));
	};

	const handleChangeFilter = (e) => {
		const key = e.target.name;
		let value = "";

		if (key === "Celular") {
			value = limpiarTelefono(e.target.value);
		} else {
			value =
				typeof e.target.value === "string"
					? e.target.value.length > 0
						? e.target.value.toUpperCase()
						: ""
					: e.target.value;
		}
		setFilterData((prevState) => ({ ...prevState, [key]: value }));
	};

	const handleClearForm = () => {
		setCatalogFiltered(catalogs);
		const newFilter = {
			Region: "",
			Municipio: [],
			Poligono: [],
			Seccion: [],
			Responsabilidad: "",
			Celular: "",
			INE: "",
			Nombre: "",
			Paterno: "",
			Materno: "",
		};
		setFilterData(newFilter);
		setSelectedPolygons([]);
		setSelectedSections([]);
		setSelectedMun([]);
		onChangeFilter([]);
	};

	const handleSearch = () => {
		let filtered = [];
		if (filterData.Region > 0) {
			filtered.push({
				id: "cat_secciones.idRegion",
				filter: "=",
				value: filterData.Region,
			});
		}
		if (filterData.Municipio.length > 0) {
			filtered.push({
				id: "cat_secciones.idMunicipioReportes",
				filter: "IN",
				value: filterData.Municipio,
			});
		}
		if (filterData.Poligono.length > 0) {
			filtered.push({
				id: "cat_secciones.idPoligono",
				filter: "IN",
				value: filterData.Poligono,
			});
		}
		if (filterData.Seccion.length > 0) {
			filtered.push({
				id: "cat_secciones.Seccion",
				filter: "IN",
				value: filterData.Seccion,
			});
		}
		if (filterData.Responsabilidad > 0) {
			filtered.push({
				id: "up.idPerfil",
				filter: "=",
				value: filterData.Responsabilidad,
			});
		}
		if (filterData.Voluntario > 0) {
			filtered.push({
				id: "compromisos_unicos.idVoluntario",
				filter: "=",
				value: filterData.Voluntario,
			});
		}
		if (filterData.Celular.length > 0) {
			filtered.push({
				id: "compromisos_unicos.Celular",
				filter: "=",
				value: filterData.Celular,
			});
		}
		if (filterData.INE.length > 0) {
			filtered.push({
				id: "compromisos_unicos.INE",
				filter: "=",
				value: filterData.INE,
			});
		}
		if (filterData.Nombre.length > 0) {
			filtered.push({
				id: "compromisos_unicos.Nombre",
				filter: "LIKE",
				value: filterData.Nombre,
			});
		}
		if (filterData.Paterno.length > 0) {
			filtered.push({
				id: "compromisos_unicos.Paterno",
				filter: "LIKE",
				value: filterData.Paterno,
			});
		}
		if (filterData.Materno.length > 0) {
			filtered.push({
				id: "compromisos_unicos.Materno",
				filter: "LIKE",
				value: filterData.Materno,
			});
		}
		if (filterData.Voluntarios > 0) {
			filtered.push({
				id: "compromisos_unicos.EsVoluntario",
				filter: "=",
				value: filterData.Voluntarios === 1 ? 1 : 0,
			});
		}

		onChangeFilter(filtered);
	};

	return (
		<Fragment>
			<Stack direction="row" spacing={1} alignItems="center">
				{load ? (
					<CircularProgress sx={{ color: grey[900], m: 1 }} size={25} />
				) : (
					<IconButton
						onClick={handleChecked}
						sx={{
							width: "32px",
							height: "32px",
							backgroundColor: checked ? "none" : "rgba(0, 0, 0, 0.04)",
							border: checked ? "1px solid #0041a0" : "none",
							borderRadius: "20%",
						}}
					>
						<Icon sx={{ color: checked && "#0041a0" }}>
							{checked
								? "keyboard_double_arrow_up"
								: "keyboard_double_arrow_down"}
						</Icon>
					</IconButton>
				)}

				<Typography
					variant="subtitle2"
					fontWeight={700}
					color={checked && "#0041a0"}
				>
					FILTROS
				</Typography>
			</Stack>
			{checked && (
				<Box marginTop={"1rem"}>
					<Grow
						in={checked}
						style={{ transformOrigin: "0 0 0" }}
						{...(checked ? { timeout: 800 } : {})}
					>
						<Box>
							<Grid2 container spacing={2} marginBottom={"1rem"}>
								<Grid2 item xs={12} md={3}>
									<InputSelect
										label="Delimitación"
										options={catalogs.regiones}
										name="Region"
										value={filterData.Region}
										onChange={(e) => {
											handleChangeFilter(e);
											handleChangeRegion(e.target.value);
										}}
										sx={{ width: "100%" }}
										isLoading={load}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<Select
										label="Municipio"
										placeholder="Municipio"
										name="Municipio"
										menuPlacement="bottom"
										styles={selectStyles}
										closeMenuOnSelect={false}
										components={animatedComponents}
										value={
											catalogsFiltered.municipios_dl.length === 1
												? [catalogsFiltered.municipios_dl[0]]
												: selectedMun
										}
										onChange={handleChangeMunicipality}
										isMulti
										options={catalogsFiltered.municipios_dl}
										isLoading={load}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<Select
										placeholder="Zona"
										styles={selectStyles}
										closeMenuOnSelect={false}
										components={animatedComponents}
										value={
											catalogsFiltered.poligonos.length === 1
												? [catalogsFiltered.poligonos[0]]
												: selectedPolygons
										}
										onChange={handleChangePolygon}
										isMulti
										options={catalogsFiltered.poligonos}
										isDisabled={
											catalogsFiltered.poligonos.length === 1 ? true : false
										}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<Select
										placeholder="Sección"
										styles={selectStyles}
										closeMenuOnSelect={false}
										components={animatedComponents}
										value={
											catalogsFiltered.secciones.length === 1
												? [catalogsFiltered.secciones[0]]
												: selectedSections
										}
										onChange={handleChangeSection}
										isMulti
										options={catalogsFiltered.secciones}
										isDisabled={
											catalogsFiltered.secciones.length === 1 ? true : false
										}
									/>
								</Grid2>
							</Grid2>

							<Grid2 container spacing={2} marginBottom={"1rem"}>
								<Grid2 item xs={12} md={3}>
									<InputSelect
										label="Tipo de Participante"
										options={catalogs.voluntarios}
										name="Voluntario"
										value={filterData.Voluntario}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
										isLoading={load}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<InputSelect
										label="Responsabilidad"
										options={catalogs.perfiles_hijos}
										name="Responsabilidad"
										value={filterData.Responsabilidad}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
										isLoading={load}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<TextField
										label="Celular"
										size="small"
										name="Celular"
										value={filterData.Celular}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<TextField
										label="Clave Elector"
										size="small"
										name="INE"
										value={filterData.INE}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
									/>
								</Grid2>
							</Grid2>

							<Grid2 container spacing={2} marginBottom={"1rem"}>
								<Grid2 item xs={12} md={3}>
									<TextField
										label="Nombre"
										size="small"
										name="Nombre"
										value={filterData.Nombre}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<TextField
										label="Paterno"
										size="small"
										name="Paterno"
										value={filterData.Paterno}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<TextField
										label="Materno"
										size="small"
										name="Materno"
										value={filterData.Materno}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
									/>
								</Grid2>
								<Grid2 item xs={12} md={3}>
									<InputSelect
										label="Asignar permisos"
										options={voluntarios}
										name="Participantes"
										value={filterData.Voluntarios}
										onChange={handleChangeFilter}
										sx={{ width: "100%" }}
										isLoading={load}
									/>
								</Grid2>
							</Grid2>

							<Box display={"flex"} justifyContent={"flex-end"}>
								<Button
									variant="contained"
									color="primaryDark"
									onClick={handleSearch}
								>
									Filtrar
								</Button>
								<Button
									color="primaryDark"
									sx={{ ml: 1 }}
									onClick={handleClearForm}
								>
									Limpiar
								</Button>
							</Box>
						</Box>
					</Grow>
				</Box>
			)}
		</Fragment>
	);
};

Filter.propTypes = {
	onChangeFilter: PropTypes.func.isRequired,
	catalogsOptions: PropTypes.array.isRequired,
	catalogs: PropTypes.object.isRequired,
	load: PropTypes.bool.isRequired,
};

export default Filter;
