import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Grid, Button, Stack, Tabs, Tab } from "@mui/material";

// Componentes
import { TabPanel, a11yProps } from "@components/Maps/Varius/TabComponent";
import AdvancedSelect from "@components/Selects/AdvancedSelect";
import BasicSelect from "@components/Selects/BasicSelect";

// Servicios y Utils
import CatalogService from "@services/CatalogServices";
import { setVars, getVars } from "@utils/global";
import "@components/Maps/Styles/TabPadding.css";

const Filter = (props) => {
	const {
		catalogs,
		loadingCatalogs,
		handleFilter,
		entidad,
		type = false,
		responsability = false,
	} = props;

	const tipoOptions = [
		{ value: 10, label: "TODOS" },
		{ value: 1, label: "Se acreditan INE" },
		{ value: 0, label: "No se acreditan INE" },
	];

	const [value, setValue] = useState(getVars("valueTab", 0));

	const [catResponsabilidad, setCatResponsabilidad] = useState(
		catalogs?.estructura_electoral || []
	);
	const [catMunicipios, setCatMunicipios] = useState([
		{ value: 0, label: "Selecciona una entidad" },
	]);
	const [catDLOC, setCatDLOC] = useState([
		{ value: 0, label: "Selecciona una entidad" },
	]);
	const [catDFED, setCatDFED] = useState([
		{ value: 0, label: "Selecciona una entidad" },
	]);
	const [catSecciones, setCatSecciones] = useState([
		{
			value: 0,
			label: `Selecciona un ${value === 0 ? "municipio" : "distrito"}`,
		},
	]);
	const [isLoadingCatMunicipios, setIsLoadingMunicipios] = useState(false);
	const [isLoadingCatDL, setIsLoadingCatDL] = useState(false);
	const [isLoadingCatDF, setIsLoadingCatDF] = useState(false);
	const [isLoadingCatSecciones, setIsLoadingCatSecciones] = useState(false);

	const [filter, setFilter] = useState({
		idTipo: 10,
		idResponsabilidad: 0,
		idEntidad: 0,
		idMunicipio: 0,
		idDF: 0,
		idDL: 0,
		Seccion: 0,
	});

	const handleChange = (event, newValue) => {
		const entidad = filter.idEntidad >= 0 && filter.idEntidad !== "";
		setCatSecciones(
			entidad
				? [
						{
							value: 0,
							label: `Selecciona un ${
								newValue === 0 ? "municipio" : "distrito"
							}`,
						},
				  ]
				: []
		);

		setFilter({
			...filter,
			idMunicipio: entidad ? (newValue === 0 ? 0 : "") : "",
			idDF: entidad ? (newValue === 1 ? 0 : "") : "",
			idDL: entidad ? (newValue === 2 ? 0 : "") : "",
			Seccion: entidad ? 0 : "",
		});

		setVars("valueTab", newValue);
		setValue(newValue);
	};

	const getCatalogsWithStored = () => {
		const filterSavedMapMunicipio = getVars("filterSavedMapMunicipio", []);
		const filterSavedMapSeccion = getVars("filterSavedMapSeccion", []);
		const filterSavedMapDFED = getVars("filterSavedMapDFED", []);
		const filterSavedMapDLOC = getVars("filterSavedMapDLOC", []);
		const filterSavedMapResponsabilidad = responsability
			? getVars("filterSavedMapResponsabilidad", [])
			: null;
		setCatMunicipios(filterSavedMapMunicipio);
		setCatDFED(filterSavedMapDFED);
		setCatDLOC(filterSavedMapDLOC);
		setCatSecciones(filterSavedMapSeccion);
		if (responsability) setCatResponsabilidad(filterSavedMapResponsabilidad);
	};

	useEffect(() => {
		if (entidad) handleChangeGlobal({ value: entidad, name: "idEntidad" });

		const filter_saved = getVars("filterSavedMap");

		if (filter_saved.idTipo !== "" || filter_saved.idEntidad !== "") {
			setFilter(filter_saved);
			getCatalogsWithStored();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (responsability) setCatResponsabilidad(catalogs?.estructura_electoral);
		// eslint-disable-next-line
	}, [catalogs]);

	const emptyOptions = () => {
		const elemento_seleccionar = [
			{ value: 0, label: "Selecciona una entidad" },
		];
		setCatMunicipios(elemento_seleccionar);
		setCatDFED(elemento_seleccionar);
		setCatDLOC(elemento_seleccionar);
		setCatSecciones([
			{
				value: 0,
				label: `Selecciona un ${value === 0 ? "municipio" : "distrito"}`,
			},
		]);
	};

	const loadCatalogsByEntity = async (idEntidad = null) => {
		const elemento_todos = [{ value: 0, label: "TODOS" }];
		const catalogsParams = idEntidad
			? [{ id: "idEntidad", value: idEntidad, filter: "=" }]
			: [];

		setCatSecciones([
			{
				value: 0,
				label: `Selecciona un ${value === 0 ? "municipio" : "distrito"}`,
			},
		]);

		if (!idEntidad) {
			emptyOptions();
			return true;
		}

		try {
			setIsLoadingCatDL(true);
			setIsLoadingCatDF(true);
			setIsLoadingMunicipios(true);

			const result = await CatalogService.getCatalogsNationalMunicipalities(
				catalogsParams
			);
			const resultDF = await CatalogService.getCatalogsNationalDFED(
				catalogsParams
			);
			const resultDL = await CatalogService.getCatalogsNationalDLOC(
				catalogsParams
			);
			const { success, results, response, message } = result;
			const {
				success: successDF,
				results: resultsDF,
				response: responseDF,
				message: messageDF,
			} = resultDF;
			const {
				success: successDL,
				results: resultsDL,
				response: responseDL,
				message: messageDL,
			} = resultDL;

			if (success && results)
				setCatMunicipios(elemento_todos.concat(response.data));
			else Swal.fire({ title: message, icon: "warning" });

			if (successDF && resultsDF)
				setCatDFED(elemento_todos.concat(responseDF.data));
			else Swal.fire({ title: messageDF, icon: "warning" });

			if (successDL && resultsDL)
				setCatDLOC(elemento_todos.concat(responseDL.data));
			else Swal.fire({ title: messageDL, icon: "warning" });
		} catch (e) {
			Swal.fire({ title: e.message, icon: "warning" });
		} finally {
			setIsLoadingCatDL(false);
			setIsLoadingCatDF(false);
			setIsLoadingMunicipios(false);
		}
	};

	const loadCatalogsSections = async (value = 0, name) => {
		const catalogsParams = [];

		setVars("filterSavedMap", { ...filter, [name]: value, Seccion: "" });

		if (filter.idEntidad)
			catalogsParams.push({
				id: "idEntidad",
				value: filter.idEntidad,
				filter: "=",
			});
		if (value > 0) catalogsParams.push({ id: name, value: value, filter: "=" });
		else {
			setCatSecciones([
				{
					value: 0,
					label: `Selecciona un ${
						name === "idMunicipio" ? "municipio" : "distrito"
					}`,
				},
			]);
			setFilter({ ...filter, [name]: value, Seccion: 0 });
			return true;
		}

		try {
			setIsLoadingCatSecciones(true);

			const result = await CatalogService.getCatalogsNationalSections(
				catalogsParams
			);
			const { success, results, response, message } = result;

			if (success && results) {
				setFilter({ ...filter, [name]: value, Seccion: 0 });
				setCatSecciones([{ value: 0, label: "TODAS" }].concat(response.data));
			} else {
				Swal.fire({ title: message, icon: "warning" });
				setFilter({ ...filter, [name]: value, Seccion: 0 });
				setCatSecciones([{ value: 0, label: "Error al cargar los datos" }]);
			}
		} catch (e) {
			Swal.fire({ title: e.message, icon: "warning" });
		} finally {
			setIsLoadingCatSecciones(false);
		}
	};

	const handleChangeGlobal = (e) => {
		const localValue = e?.target ? e.target.value : e.value;
		const name = e?.target ? e.target.name : e.name;

		switch (name) {
			case "idTipo":
				emptyOptions();
				if (localValue !== 10) {
					const filterData = catalogs?.estructura_electoral.filter(
						(item) => item.Acreditar === localValue
					);
					if (filterData.length === 0) setCatResponsabilidad([]);
					else
						setCatResponsabilidad([
							catalogs?.estructura_electoral[0],
							...filterData,
						]);
				} else setCatResponsabilidad(catalogs?.estructura_electoral);
				setFilter({
					[name]: localValue,
					idResponsabilidad: 0,
					idEntidad: 0,
					idMunicipio: value === 0 ? 0 : "",
					idDF: value === 1 ? 0 : "",
					idDL: value === 2 ? 0 : "",
					Seccion: 0,
				});
				break;
			case "idResponsabilidad":
				emptyOptions();
				setFilter({
					...filter,
					[name]: localValue,
					idEntidad: 0,
					idMunicipio: value === 0 ? 0 : "",
					idDF: value === 1 ? 0 : "",
					idDL: value === 2 ? 0 : "",
					Seccion: 0,
				});
				break;
			case "idEntidad":
				setFilter({
					...filter,
					[name]: localValue,
					idMunicipio: value === 0 ? 0 : "",
					idDF: value === 1 ? 0 : "",
					idDL: value === 2 ? 0 : "",
					Seccion: 0,
				});
				if (localValue > 0) loadCatalogsByEntity(localValue);
				else loadCatalogsByEntity();
				break;
			case "Seccion":
				setFilter({ ...filter, [name]: localValue });
				break;
			default: // idMunicipio, idDF y idDL
				loadCatalogsSections(localValue, name);
				break;
		}
	};

	const handleClick = () => {
		let filter_data = [];

		if (filter.idTipo !== "" && filter.idTipo !== undefined) {
			filter_data.push({
				id: "tb.idTipo",
				filter: filter.idTipo === 10 ? "IN" : "=",
				value: filter.idTipo === 10 ? [0, 1] : filter.idTipo,
			});
		}
		if (
			filter.idResponsabilidad !== "" &&
			filter.idResponsabilidad !== undefined
		) {
			filter_data.push({
				id: "tb.idResponsabilidad",
				filter: "=",
				value: filter.idResponsabilidad,
			});
		}
		if (filter.idEntidad !== "" && filter.idEntidad !== undefined) {
			filter_data.push({
				id: "tb.idEntidad",
				filter: filter.idEntidad === 0 ? "IN" : "=",
				value: filter.idEntidad === 0 ? 0 : filter.idEntidad,
			});
		}
		if (
			filter.idMunicipio !== "" &&
			filter.idMunicipio !== undefined &&
			filter.idMunicipio !== 0
		) {
			filter_data.push({
				id: "tb.idMunicipio",
				filter: "=",
				value: filter.idMunicipio,
			});
		}
		if (filter.idDF !== "" && filter.idDF !== undefined && filter.idDF !== 0) {
			filter_data.push({
				id: "tb.idDF",
				filter: "=",
				value: filter.idDF,
			});
		}
		if (filter.idDL !== "" && filter.idDL !== undefined && filter.idDL !== 0) {
			filter_data.push({
				id: "tb.idDL",
				filter: "=",
				value: filter.idDL,
			});
		}
		if (
			filter.Seccion !== "" &&
			filter.Seccion !== undefined &&
			filter.Seccion !== 0
		) {
			filter_data.push({
				id: "tb.Seccion",
				filter: "=",
				value: filter.Seccion,
			});
		}

		handleFilter(filter_data, value);
		setVars("filterSavedMapMunicipio", catMunicipios);
		setVars("filterSavedMapSeccion", catSecciones);
		setVars("filterSavedMapDFED", catDFED);
		setVars("filterSavedMapDLOC", catDLOC);
		setVars("filterSavedMapDLOC", catDLOC);
		if (responsability)
			setVars("filterSavedMapResponsabilidad", catResponsabilidad);
		setVars("filterSavedMap", {
			idTipo: filter.idTipo,
			idResponsabilidad: filter.idResponsabilidad,
			idEntidad: filter.idEntidad,
			idMunicipio: filter.idMunicipio,
			idDF: filter.idDF,
			idDL: filter.idDL,
			Seccion: filter.Seccion,
		});
	};

	return (
		<>
			<Grid container rowSpacing={1}>
				<Grid item xs={12}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="Tabs"
						variant="scrollable"
						scrollButtons={false}
					>
						<Tab label="Municipio" {...a11yProps(0)} sx={{ flex: 1 }} />
						<Tab label="DFED" {...a11yProps(1)} sx={{ flex: 1 }} />
						<Tab label="DLOC" {...a11yProps(2)} sx={{ flex: 1 }} />
					</Tabs>
				</Grid>

				{type && (
					<Grid item xs={12}>
						<BasicSelect
							label="Tipo"
							name="idTipo"
							id="idTipo"
							options={tipoOptions}
							value={filter.idTipo}
							onChange={handleChangeGlobal}
							isLoading={loadingCatalogs}
							sx={{ width: "100%" }}
						/>
					</Grid>
				)}

				{responsability && (
					<Grid item xs={12}>
						<AdvancedSelect
							label="Asignación"
							name="idResponsabilidad"
							id="idResponsabilidad"
							options={catResponsabilidad}
							value={filter.idResponsabilidad}
							onChange={handleChangeGlobal}
							isLoading={loadingCatalogs}
							isSearchable
						/>
					</Grid>
				)}

				{!entidad ? (
					<Grid item xs={12}>
						<AdvancedSelect
							label="Entidad"
							name="idEntidad"
							id="idEntidad"
							options={catalogs?.entidades}
							value={filter.idEntidad}
							onChange={handleChangeGlobal}
							isLoading={loadingCatalogs}
							isSearchable
						/>
					</Grid>
				) : (
					<></>
				)}

				<Grid item xs={12}>
					<TabPanel value={value} index={0}>
						<AdvancedSelect
							label="Municipio"
							name="idMunicipio"
							id="idMunicipio"
							disabled={catMunicipios.length <= 1}
							options={catMunicipios}
							value={filter.idMunicipio}
							onChange={handleChangeGlobal}
							isLoading={loadingCatalogs || isLoadingCatMunicipios}
							isSearchable
						/>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<AdvancedSelect
							label="DFED"
							name="idDF"
							id="idDF"
							disabled={catDFED.length <= 1}
							options={catDFED}
							value={filter.idDF}
							onChange={handleChangeGlobal}
							isLoading={loadingCatalogs || isLoadingCatDF}
							isSearchable
						/>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<AdvancedSelect
							label="DFED"
							name="idDL"
							id="idDL"
							disabled={catDLOC.length <= 1}
							options={catDLOC}
							value={filter.idDL}
							onChange={handleChangeGlobal}
							isLoading={loadingCatalogs || isLoadingCatDL}
							isSearchable
						/>
					</TabPanel>
				</Grid>
				<Grid item xs={12}>
					<AdvancedSelect
						label="Sección"
						name="Seccion"
						id="Seccion"
						disabled={catSecciones.length <= 1}
						options={catSecciones}
						value={filter.Seccion}
						onChange={handleChangeGlobal}
						isLoading={loadingCatalogs || isLoadingCatSecciones}
						isSearchable
					/>
				</Grid>
			</Grid>
			<br></br>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				<Button
					size="small"
					style={{ borderRadius: "20px", alignSelf: "center" }}
					variant="outlined"
					disabled={
						filter.idEntidad === "" ||
						isLoadingCatMunicipios ||
						isLoadingCatDL ||
						isLoadingCatDF ||
						isLoadingCatSecciones
					}
					onClick={handleClick}
				>
					Filtrar
				</Button>
			</Stack>
		</>
	);
};

export default Filter;
