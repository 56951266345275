import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

//Components
import InputSelect from "@components/Selects/BasicSelect";

//Mui
import {
    Box,
    Button,
    Card,
    CardContent,
    Icon,
    Stack,
    Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import sije from "@services/SijeService";
import { ELECCIONES_GLOBALES } from "@data/constants/Prep";

const Filter = props => {
    const { onChangeFilter, disabledButtons } = props;
    const catalogsOptions = [
        {
            id: 'tipos_eleccion', filtered: [{
                id: 'id',
                filter: 'IN',
                value: ELECCIONES_GLOBALES
            }]
        },
    ];

    const [loadingCatalogs, setLoadingCatalogs] = useState(true);
    const [catalogs, setCatalogs] = useState(() => {
        let newObject = { ambito_agrupador: [] };
        for (const item of catalogsOptions) {
            newObject[item.id] = [];
        }
        return newObject;
    });

    const [filterData, setFilterData] = useState({
        idTipoEleccion: ''
    });
    const [initFilter, setInitFilter] = useState({});
    useEffect(() => {
        getCatalogs();
        //eslint-disable-next-line
    }, []);

    const getCatalogs = async (e) => {
        try {
            setLoadingCatalogs(true);
            const result = await sije.getCatalogs({ catalogs: catalogsOptions });
            const { results, response, message } = await result;

            if (results) {
                setCatalogs((prevState) => ({ ...prevState, ...response.catalogs }));
                if (response.catalogs.tipos_eleccion.length > 0) {
                    handleSetInitFilter(response.catalogs.tipos_eleccion[0].value);
                }
            } else {
                Swal.fire({
                    title: message,
                    icon: "warning",
                });
            }
        } catch (error) {
            Swal.fire({
                title: error,
                icon: "warning",
            });
        } finally {
            setLoadingCatalogs(false);
        }
    }

    const handleSetInitFilter = (idTipoEleccion) => {
        setFilterData({
            idTipoEleccion: idTipoEleccion
        });

        onChangeFilter({
            idTipoEleccion: idTipoEleccion
        });

        setInitFilter({
            idTipoEleccion: idTipoEleccion
        });
    }

    const handleApplyFilter = (e) => {
        e.preventDefault();
        onChangeFilter(filterData);
    }

    const handleClearFilter = (e) => {
        e.preventDefault();
        setFilterData(initFilter);
        onChangeFilter(initFilter);
    }

    const handleChangeElectionType = (value) => {
        setFilterData({
            idTipoEleccion: value
        });
    }

    return (
        <Box component={Card} className="card-primary">
            <Box component={CardContent}>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    marginBottom={2}
                >
                    <Icon>filter_list</Icon>
                    <Typography variant="body2" fontWeight={600}>
                        Filtros
                    </Typography>
                </Stack>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={4}>
                        <InputSelect
                            label="Tipo de elección"
                            options={catalogs.tipos_eleccion}
                            name="idTipoEleccion"
                            value={catalogs.tipos_eleccion.length > 0 ? filterData.idTipoEleccion : ''}
                            onChange={e => handleChangeElectionType(e.target.value)}
                            disabled={catalogs.tipos_eleccion.length < 2}
                            sx={{ width: "100%" }}
                            isLoading={loadingCatalogs}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={3}>
                        <Button
                            variant="outlined"
                            color="primaryDark"
                            onClick={handleClearFilter}
                            sx={{ ml: 1 }}
                            fullWidth
                        >
                            Limpiar
                        </Button>
                    </Grid2>
                    <Grid2 xs={12} md={3}>
                        <Button
                            variant="contained"
                            color="primaryDark"
                            onClick={handleApplyFilter}
                            fullWidth
                        >
                            Filtrar
                        </Button>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    )
}

Filter.propTypes = {
    onChangeFilter: PropTypes.func.isRequired
};

export default Filter;