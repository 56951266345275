import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  Icon,
} from "@mui/material";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/Sije/Reports/Filter";
import Download from "@components/Downloads/Download";
import DataTableReport from "@components/Sije/Reports/DataTableReport";
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";

//Services
import { COLUMNS_REPORTS, PROGRESS_KEYS } from "@data/constants/Sije";
import sije from "@services/SijeService";
import Swal from "sweetalert2";

const Reports = () => {
  //States
  const [total, setTotal] = useState(10);
  const [isDownload, setIsDownloading] = useState(false);
  const [dataReports, setDataReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState(false);
  const [columnsReports, setColumnsReports] = useState([]);
  const [summary, setSummary] = useState([]);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
    columnas: [],
    idAmbitoAgrupador: 5,
  });
  const { handleRequestSort } = useSorted({ total: total, setData });

  const getListSijeAttendance = async (data) => {
    try {
      setLoadingReports((prevState) => !prevState);
      const result = await sije.getListSijeAsistencia(data);
      const { response, results, message } = await result;

      if (results) {
        const columns = setDiynamicColumns({ columns: response.data[0] });
        setColumnsReports(columns);

        const newDataReports = response.data.map((item) => {
          const updatedItem = { ...item };

          PROGRESS_KEYS.forEach((key) => {
            updatedItem[key] = (
              <CircularProgressPorcent
                size={42}
                fontSize={10}
                value={item[key]}
              />
            );
          });

          return updatedItem;
        });
        setDataReports(newDataReports);
        setSummary(Object.values(response.totales));
        setTotal(response.total);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingReports((prevState) => !prevState);
    }
  };

  const exportListAttendance = async () => {
    try {
      setIsDownloading((prevState) => !prevState);

      const result = await sije.exportSije({
        params: {
          columnas: data.columnas,
          idAmbitoAgrupador: data.idAmbitoAgrupador,
        },
        api: "sije/export-list-sije-asistencia",
      });
      const { success } = await result;
      if (success) {
        Swal.fire({
          title: "Descargado con éxito.",
          icon: "success",
        });
      } else {
        throw new Error("Ocurrió un error, contacte al administrador.");
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const onChangeFilter = (filters) => {
    setData((prevState) => ({
      ...prevState,
      page: 0,
      columnas: filters.columnas,
      idAmbitoAgrupador: filters.idAmbitoAgrupador,
    }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const setDiynamicColumns = ({ columns }) => {
    let dynamicColumns = [];
    for (const prop in columns) {
      const columnId = prop;
      const column = COLUMNS_REPORTS.find((item) => item.id === columnId);
      if (column) dynamicColumns = [...dynamicColumns, column];
    }

    return dynamicColumns;
  };

  useEffect(() => {
    getListSijeAttendance(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Reportes" />
        <Filter onChangeFilter={onChangeFilter} />
        <Card className="card-primary">
          <CardContent>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={2}
            >
              <Typography variant="body2" fontWeight={700}>
                REPORTES
              </Typography>
              <Button
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                size="small"
                disabled={isDownload}
                onClick={exportListAttendance}
              >
                Exportar
              </Button>
            </Stack>
            {isDownload && (
              <Box marginBottom={2}>
                <Download format={"xlsx"} isDownload={isDownload} />
              </Box>
            )}
            <DataTableReport
              rows={dataReports}
              hcolumns={columnsReports}
              total={total}
              summary={summary}
              pageProp={data.page}
              pageSize={data.pageSize}
              minHeight="0px"
              stickyHeader={true}
              isLoading={loadingReports}
              handleChangePagination={handleChangePagination}
              handleManualSort={handleRequestSort}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Reports;
