import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";

const SolidGaugeChart = (props) => {
  const { series, options, loading, height = "100%" } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    // Escalamos el valor proporcionado para que se ajuste al rango de 0 a 100
    const scaledValue = Math.min(100, Math.max(0, series[0].data[0]));

    setData({
      chart: {
        type: "solidgauge",
        height: height,
        marginTop: 8, // Reducir el espacio superior
        marginBottom: 8, // Reducir el espacio inferior
      },
      title: {
        text: options.title,
      },
      subtitle: {
        text: options.subtitle,
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      yAxis: {
        stops: [
          [0.1, "#DF5353"],//red
          [0.5, "#DDDF0D"], // yellow
          [0.9, "#55BF3B"], // green
        ],
        min: 0,
        max: 100, // Establecemos el máximo en 100
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70,
        },
        labels: {
          y: 16,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            yAxis: {
              labels: {
                style: {
                  fontSize: '8px'
                }
              }
            }
          }
        }]
      },
      pane: {
        center: ["50%", "85%"],
        size: "140%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      credits: false,
      series: [{
        name: series.name,
        data: [scaledValue],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            `<span style="font-size:25px">${scaledValue}</span><br/>` +
            '<span style="font-size:12px;opacity:0.4">%</span>' +
            '</div>'
        },
        tooltip: {
          valueSuffix: series.valueSuffix
        }
      }],
    });

    // eslint-disable-next-line
  }, [series, options, loading, height]);

  return data !== null || loading ? (
    <div>
      <HighchartsReact highcharts={Highcharts} options={data} />
    </div>
  ) : (
    <CircularProgress className="color-componentes"/>
  );
};

export default SolidGaugeChart;
