import { useEffect, useState } from "react";
import BasicTable from "@components/Tables/BasicTable";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import moment from "moment";
import PlacementColumns from "@components/Charts/DashChart";
import middleware from "@middlewares/middleware";
import Card from "@components/Card/Card";
import Filter from "../../components/Electoral/Filter";
import MessageAlert from "@components/Downloads/MessageAlert";
import Download from "@components/Downloads/Download";
import { useCatalogs } from "@hooks/useCatalogs";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CardContent,
  Box,
  Button,
  Icon,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import ListCalidadRepresentante from "../../components/Electoral/ListCalidadRepresentante";
import { 
  TERRITORIAL_REGIONAL,
  TERRITORIAL_MPAL,
  TERRITORIAL_DISTRITAL,
  TERRITORIAL_POLIGONAL,
  TERRITORIAL_SECCIONAL,
  TERRITORIAL_MANZANERA
} from "@data/constants";

const StructElectoral = (props) => {
  const navigate = useNavigate();
  let today = new Date();
  // catalogo
  const catalogsParams = [
   { id: "calidad_representante" }
  ,{id:"perfiles_hijos"}];
  const [value, setValue] = useState(0);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [total, setTotal] = useState(0);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [dataChart, setDataChart] = useState({title:"Estructura",subtitle:today,dato:[],categories:[]});
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const [columns, setColumns] = useState([]);
  const [state, setState] = useState(false);
  const [responsibilities, setResponsibilities] = useState([]);

  useEffect(() => {
    if (!load) {
      console.log(catalogs.perfiles_hijos.filter(item => [10,11,12,13,14].includes(parseInt(item.value))));
      setResponsibilities(catalogs.perfiles_hijos.filter(item => [10,11,12,13,14].includes(parseInt(item.value))));
    }
  }, [load]);

  
  useEffect(() => {
    if (responsibilities.length > 0) {
      handleChangeTab({},value); 
    }
  }, [load]);

  const handleChangeFilter = (type) => {
    const {
      DF,
      DL,
      Region,
      Municipio,
      Poligono,
      Seccion,
      Casillas,
      Propietario,
      Celular,
      ClaveElector,
      NombreCompleto,
      TieneAuto,
      TieneExperiencia,
      AceptaOtra,
    } = type;

    let filtered = [];
    let filtro = [];

    if (DF > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idDF", filter: "=", value: DF },
      ];

      filtro = [
        ...filtro,
        { id: "cat_secciones.idDF", filter: "=", value: DF },
      ];
    }
    if (DL > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idDL", filter: "=", value: DL },
      ];

      filtro = [
        ...filtro,
        { id: "cat_secciones.idDL", filter: "=", value: DL },
      ];
    }
    if (Region > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idRegion", filter: "=", value: Region },
      ];

      filtro = [
        ...filtro,
        { id: "cat_secciones.idRegion", filter: "=", value: Region },
      ];
    }
    if (Municipio > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
      ];

      filtro = [
        ...filtro,
        { id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
      ];
    }
    if (Poligono.length > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
      ];

      filtro = [
        ...filtro,
        { id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
      ];
    }
    if (Seccion > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.Seccion", filter: "=", value: Seccion },
      ];

      filtro = [
        ...filtro,
        { id: "cat_secciones.Seccion", filter: "=", value: Seccion },
      ];
    }
    if (Casillas > 0) {
      filtered = [
        ...filtered,
        { id: "cat_casillas.id", filter: "=", value: Casillas },
      ];

      filtro = [
        ...filtro,
        { id: "cat_casillas.id", filter: "=", value: Casillas },
      ];
    }
    if (Propietario > 0) {
      filtered = [
        ...filtered,
        {
          id: "usuarios_ambito_perfil.idPropietario",
          filter: "=",
          value: Propietario,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: "usuarios_ambito_perfil.idPropietario",
          filter: "=",
          value: Propietario,
        },
      ];
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "usuarios.Celular",
          filter: "LIKE",
          value: Celular,
        },
      ];
    }
    if (ClaveElector.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "usuarios.INE",
          filter: "=",
          value: ClaveElector,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"],
          filter: "LIKE",
          value: NombreCompleto,
        },
      ];
    }
    if (TieneAuto) {
      filtered = [
        ...filtered,
        { id: "usuarios_ambito_perfil.TieneAuto", filter: "=", value: 1 },
      ];

      filtro = [
        ...filtro,
        { id: "usuarios_ambito_perfil.TieneAuto", filter: "=", value: 1 },
      ];
    }
    if (TieneExperiencia) {
      filtered = [
        ...filtered,
        {
          id: "usuarios_ambito_perfil.TieneExperiencia",
          filter: "=",
          value: 1,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: "usuarios_ambito_perfil.TieneExperiencia",
          filter: "=",
          value: 1,
        },
      ];
    }
    if (AceptaOtra) {
      filtered = [
        ...filtered,
        {
          id: "usuarios_ambito_perfil.AceptaCuidarOtra",
          filter: "=",
          value: 1,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: "usuarios_ambito_perfil.AceptaCuidarOtra",
          filter: "=",
          value: 1,
        },
      ];
    }

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    // setChartCompromisos({filtered: filtro})
  };

  const handleExport = () => {
    setIsDownloading(true);
    /*     SocialService.exportCompromisos({filtered:tableData.filtered})
          .then((res) => {
            if (res.success) {
              setShowMessage(true);
              setSuccessDownload(true);
            } else {
              setShowMessage(true);
              setSuccessDownload(false);
            }
          })
          .catch((error) => {
            setShowMessage(true);
            setSuccessDownload(false);
          })
          .finally(() => setIsDownloading(false)); */
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [data, setData] = useState([
    {
      Municipio: "Abasolo",
      MetaGeneral: 500,
      AvanceEsperadoGeneral: 100,
      AvanceGeneral: 50,
      porcentajeAvanceGeneral: 10,
      Meta: 50,
      AvanceEsperado: 20,
      Pro1: (
        <Button variant="outlined" onClick={toggleDrawer("right", true)}>
          20
        </Button>
      ),
      Pro2: (
        <Button variant="outlined" onClick={toggleDrawer("right", true)}>
          210
        </Button>
      ),
      Sup1: (
        <Button variant="outlined" onClick={toggleDrawer("right", true)}>
          20
        </Button>
      ),
      Sup2: (
        <Button variant="outlined" onClick={toggleDrawer("right", true)}>
          10
        </Button>
      ),
      "Pro1%": 20,
      "Pro2%": 20,
      "Sup1%": 20,
      "Sup2%": 20,
    },
  ]);

  const handleChangeTab = (event,newValue) => {
    setLoading(true);
    setColumns([]);
    setValue(newValue);
    let tab = responsibilities[newValue];

   switch(tab.value){
      case TERRITORIAL_REGIONAL:
        DataRegional();
      break;
      case TERRITORIAL_MPAL:
        DataMunicipio();
      break;
      case TERRITORIAL_DISTRITAL:
    /*     DataAbogados(); */
      break;
      case TERRITORIAL_POLIGONAL:
        DataPoligono();
      break;
      case TERRITORIAL_SECCIONAL:
        DataSeccion();
      break;
      case TERRITORIAL_MANZANERA:
        DataManzana();
      break;
      default:
      break;
    }
  };

  const DataRegional = () => {
    setColumns([
      { id: "Region", label: "Delimitación", width: 20, align: "center" },
      {
        id: "Responsable",
        label: "Responsable",
        width: 200,
        align: "center",
      },
      {
        id: "Celular",
        label: "Celular",
        width: 100,
        align: "center",
      },
      { id: "MetaEstructura", label: "Meta Estructura",width: 20, align: "center" },
      { id: "AvanceEstructura", label: "Avan. Estructura",width: 20, align: "center" },
      {
        id: "porcentajeAvanceEstructura",
        label: "% Avan. Estructura",
        align: "center",
      },
      { id: "MetaCompromisos", label: "Meta Compromisos", align: "center" },
      { id: "AvanceCompromisos", label: "Avan. Compromisos", align: "center" },
      { id: "PorcentajeCompromisos", label: "% Avan. Compromisos", align: "center" },
    ]);


    let datosChart = [
      {
        name: "Meta",
        color: "rgba(98, 168, 239,0.4)",
        data: [150, 100, 200, 80],
        pointPadding: 0.3,
        pointPlacement: -0.2,
      },
      {
        name: "Representantes",
        color: "rgba(3, 15, 152,.9)",
        data: [140, 50, 200, 60],
        pointPadding: 0.4,
        pointPlacement: -0.2,
      },
    ];

    setLoading(false);
  };

  const DataMunicipio = () => {
    setColumns([
      { id: "Region", label: "Delimitación", width: 20, align: "center" },
      { id: "Municipio", label: "Municipio", width: 30, align: "center" },
      {
        id: "Responsable",
        label: "Responsable",
        width: 200,
        align: "center",
      },
      {
        id: "Celular",
        label: "Celular",
        width: 100,
        align: "center",
      },
      { id: "MetaEstructura", label: "Meta Estructura",width: 20, align: "center" },
      { id: "AvanceEstructura", label: "Avan. Estructura",width: 20, align: "center" },
      {
        id: "porcentajeAvanceEstructura",
        label: "% Avan. Estructura",
        align: "center",
      },
      { id: "MetaCompromisos", label: "Meta Compromisos", align: "center" },
      { id: "AvanceCompromisos", label: "Avan. Compromisos", align: "center" },
      { id: "PorcentajeCompromisos", label: "% Avan. Compromisos", align: "center" },
    ]);
    setLoading(false);
  };

  const DataPoligono = () => {
    setColumns([
      { id: "Region", label: "Delimitación", width: 20, align: "center" },
      { id: "Municipio", label: "Municipio", width: 30, align: "center" },
      { id: "Poligono", label: "Poligono", width: 30, align: "center" },
      {
        id: "Responsable",
        label: "Responsable",
        width: 200,
        align: "center",
      },
      {
        id: "Celular",
        label: "Celular",
        width: 100,
        align: "center",
      },
      { id: "MetaEstructura", label: "Meta Estructura",width: 20, align: "center" },
      { id: "AvanceEstructura", label: "Avan. Estructura",width: 20, align: "center" },
      {
        id: "porcentajeAvanceEstructura",
        label: "% Avan. Estructura",
        align: "center",
      },
      { id: "MetaCompromisos", label: "Meta Compromisos", align: "center" },
      { id: "AvanceCompromisos", label: "Avan. Compromisos", align: "center" },
      { id: "PorcentajeCompromisos", label: "% Avan. Compromisos", align: "center" },
    ]);
    setLoading(false);
  };

  const DataSeccion = () => {
    setColumns([
      { id: "Municipio", label: "Municipio", width: 30, align: "center" },
      { id: "Poligono", label: "Poligono", width: 30, align: "center" },
      { id: "Seccion", label: "Sección", width: 30, align: "center" },
      {
        id: "Responsable",
        label: "Responsable",
        width: 200,
        align: "center",
      },
      {
        id: "Celular",
        label: "Celular",
        width: 100,
        align: "center",
      },
      { id: "MetaEstructura", label: "Meta Estructura",width: 20, align: "center" },
      { id: "AvanceEstructura", label: "Avan. Estructura",width: 20, align: "center" },
      {
        id: "porcentajeAvanceEstructura",
        label: "% Avan. Estructura",
        align: "center",
      },
      { id: "MetaCompromisos", label: "Meta Compromisos", align: "center" },
      { id: "AvanceCompromisos", label: "Avan. Compromisos", align: "center" },
      { id: "PorcentajeCompromisos", label: "% Avan. Compromisos", align: "center" },
    ]);
    setLoading(false);
  };

  const DataManzana = () => {
    setColumns([
      { id: "Municipio", label: "Municipio", width: 30, align: "center" },
      { id: "Poligono", label: "Poligono", width: 30, align: "center" },
      { id: "Seccion", label: "Sección", width: 30, align: "center" },
      { id: "Manzana", label: "Manzana", width: 30, align: "center" },
      {
        id: "Responsable",
        label: "Responsable",
        width: 200,
        align: "center",
      },
      {
        id: "Celular",
        label: "Celular",
        width: 100,
        align: "center",
      },
      { id: "MetaEstructura", label: "Meta Estructura",width: 20, align: "center" },
      { id: "AvanceEstructura", label: "Avan. Estructura",width: 20, align: "center" },
      {
        id: "porcentajeAvanceEstructura",
        label: "% Avan. Estructura",
        align: "center",
      },
      { id: "MetaCompromisos", label: "Meta Compromisos", align: "center" },
      { id: "AvanceCompromisos", label: "Avan. Compromisos", align: "center" },
      { id: "PorcentajeCompromisos", label: "% Avan. Compromisos", align: "center" },
    ]);
    setLoading(false);
  };

  const DataCasa = () => {
    setColumns([ 
      { id: "Municipio", label: "Municipio", width: 20, align: "center" },
      { id: "Meta", label: "Meta", width: 20, align: "center" },
      { id: "AvanceEsperado", label: "Avance Esperado", width: 20, align: "center" },
      { id: "Avance", label: "Avance C.AZUL", width: 20, align: "center" },
      { id: "PorAvance", label: "%Avance C.AZUL", width: 20, align: "center" }
    ]);
    setLoading(false);
  };

  return (
    <>
      <ListCalidadRepresentante
        state={state}
        toggleDrawer={(e) => toggleDrawer(e)}
      />
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader
            title={`Estructura`}
            handleclick={() => navigate(`/estructura-electoral`)}
          />
          {isLoadingChart ? (
            <CircularProgress />
          ) : (
            <PlacementColumns
            titulo={{ title: dataChart.title }}
            subtitle={{
              subtitle: moment(dataChart.date).format("DD/MM/YYYY H:mm"),
            }} 
            datos={{
              categories:dataChart.categories,
              series: dataChart.dato,
            }}
            loading={isLoadingChart}
          />
          )}
          <Card className="card-primary">
            <CardContent>
              <Filter
                onChangeFilter={(e) => handleChangeFilter(e)}
                filterData={false}
                dl={false}
                df={false}
                region={true}
                municipio={false}
                municipioDL={true}
                poligono={false}
                seccion={false}
                tieneExperiencia={false}
                TieneAuto={false}
                AceptaOtra={false}
                filtroRepresentante={false}
              />
            </CardContent>
          </Card>

          <Card className="card-primary">
            <CardContent>
              {middleware.checkMenuActionId("Exportar") && (
                <>
                  {isDownloading ? (
                    <Download isDownload={isDownloading} format="xlsx" />
                  ) : (
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      sx={{ mb: 2 }}
                    >
                      <Button
                        variant="outlined"
                        color="primaryDark"
                        startIcon={<Icon>download</Icon>}
                        size="small"
                        onClick={handleExport}
                        sx={{ borderRadius: "10px" }}
                      >
                        Exportar
                      </Button>
                    </Box>
                  )}
                </>
              )}

              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )}
              <Box sx={{overflow:"auto"}}>
              <Tabs
                centered
                value={value}
                onChange={handleChangeTab}
                aria-label="responsabilitis"
              >
                {
                  responsibilities.map(item =>{return <Tab label={item.label} />})
                }
              </Tabs>
              </Box>
              <BasicTable
                rows={data}
                hcolumns={columns}
                pageProp={tableData.page}
                pageSize={tableData.pageSize}
                total={total}
                handleChangePagination={handleChangePagination}
                isLoading={loading}
                stickyHeader={true}
              />
            </CardContent>
          </Card>
        </Container>
      </AdminLayout>
    </>
  );
};
export default StructElectoral;
