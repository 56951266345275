import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Icon,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicLine from "@components/Charts/BasicLine";
import BasicBarColumn from "@components/Charts/BasiBarColumn";
import BasicTable from "@components/Tables/BasicTable";
import Download from "@components/Downloads/Download";
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";
import Filter from "@components/Activation/DashboardMobilization/Filter";
import RefreshButton from "@components/Button/RefreshButton";
import BasicSelect from "@components/Selects/BasicSelect";

import {
  COLUMNS_DASH_MOBILIZATION,
  DATA_BINGO_CHART_2,
  DATA_DASH,
  DATA_MOVILIZACION_ACUMULATIVA,
  PROGRESS_KEYS,
} from "../../data/constants/Mobilization";

const DashboardMobilization = () => {
  const [total, setTotal] = useState(10);
  const [isDownload, setIsDownloading] = useState(false);
  const [dataMovilization, setDataMovilization] = useState([]);
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const getMovilization = () => {
    const newDataMovilization = DATA_DASH.data.map((item) => {
      const updatedItem = { ...item };

      updatedItem.Efectividad = (
        <Chip
          label={item.Efectividad}
          icon={<Icon>{validateRangeIcon({ number: item.Efectividad })}</Icon>}
          color={validateRangeColor({ number: item.Efectividad })}
          size="small"
        />
      );
      PROGRESS_KEYS.forEach((key) => {
        updatedItem[key] = (
          <CircularProgressPorcent size={42} fontSize={10} value={item[key]} />
        );
      });

      return updatedItem;
    });

    setDataMovilization(newDataMovilization);
  };

  function validateRangeColor({ number }) {
    const rango = parseInt(number);
    if (rango === 100) return "success";
    if (rango >= 50) return "primary";
    if (rango >= 0) return "warning";
    return "error";
  }

  function validateRangeIcon({ number }) {
    const rango = parseInt(number);
    if (rango === 100) return "done";
    if (rango > 0) return "keyboard_double_arrow_up";
    return "keyboard_double_arrow_down";
  }

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSearchFilter = ({ filtereds }) => {
    console.log(filtereds);
  };

  const handleClearFilter = () => {
    console.log("clear filter");
  };

  useEffect(() => {
    getMovilization();
  }, []);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Dashboard Movilización" />
        <Paper
          sx={{
            margin: "0 auto",
            width: "27%",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            padding: 1,
          }}
        >
          <div>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Icon sx={{ color: blue[900] }}>update</Icon>
              <Typography
                variant="subtitle2"
                fontWeight={600}
                textAlign={"center"}
              >
                ÚLTIMA ACTUALIZACIÓN
              </Typography>
            </Stack>
            <Typography variant="body2" textAlign={"center"}>
              13:13:36
            </Typography>
          </div>
        </Paper>
        <Filter onSearch={handleSearchFilter} onClear={handleClearFilter} />
        <Card className="card-primary">
          <CardContent>
            <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
              BINGO
            </Typography>
            <BasicBarColumn
              series={DATA_BINGO_CHART_2.data.series}
              categories={DATA_BINGO_CHART_2.data.categories}
            />
          </CardContent>
        </Card>
        <Card className="card-primary">
          <CardContent>
            <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
              LÍNEA DE TIEMPO MOVILIZACIÓN ACUMULATIVA
            </Typography>
            <BasicLine
              title={{
                primary: "",
              }}
              legend={{
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              }}
              series={DATA_MOVILIZACION_ACUMULATIVA.data.Series}
              categories={DATA_MOVILIZACION_ACUMULATIVA.data.Categories}
            />
          </CardContent>
        </Card>
        <Card className="card-primary">
          <CardContent>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={2}
            >
              <Typography variant="body2" fontWeight={700}>
                MOVILIZACIÓN
              </Typography>
              <Button
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                size="small"
                disabled={isDownload}
                //onClick={handleExport}
              >
                Exportar
              </Button>
            </Stack>
            {isDownload && (
              <Box marginBottom={2}>
                <Download format={"xlsx"} isDownload={isDownload} />
              </Box>
            )}
            <Stack direction={"row"} spacing={2}>
              <BasicSelect
                name="tipoVista"
                label="Agrupar por"
                options={[
                  { value: 0, label: "Region" },
                  { value: 1, label: "Municipio" },
                  { value: 2, label: "Zona" },
                  { value: 3, label: "Sección" },
                ]}
                //value={filterValue.tipoVista}
                onChange={(e) => {
                  //handleChangeTypeView({ event: e });
                }}
                // isLoading={loadingCatalogs}
                sx={{ width: "30%", mb: 2 }}
              />
              <BasicSelect
                name="tipoVista"
                label="Agrupar por"
                options={[
                  { value: 0, label: "Region" },
                  { value: 1, label: "Municipio" },
                  { value: 2, label: "Zona" },
                  { value: 3, label: "Sección" },
                ]}
                //value={filterValue.tipoVista}
                onChange={(e) => {
                  //handleChangeTypeView({ event: e });
                }}
                // isLoading={loadingCatalogs}
                sx={{ width: "30%", mb: 2 }}
              />
            </Stack>
            <BasicTable
              rows={dataMovilization}
              hcolumns={COLUMNS_DASH_MOBILIZATION}
              total={total}
              pageProp={data.page}
              pageSize={data.pageSize}
              minHeight="0px"
              stickyHeader={true}
              //isLoading={loadingData}
              handleChangePagination={handleChangePagination}
              handleManualSort={handleRequestSort}
            />
          </CardContent>
        </Card>

        <RefreshButton
          handleRefresh={() => {
            console.log("refresh");
          }}
        />
      </Container>
    </AdminLayout>
  );
};

export default DashboardMobilization;
