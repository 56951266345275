import { useState } from "react";

//Material UI
import { Card, CardContent, CardHeader, Grid, Paper, styled, Button, Stack, IconButton } from "@mui/material";
import { RemoveCircleOutline, InfoOutlined } from "@mui/icons-material";

// Utils
import { generateRandomString } from "@utils/global";

// Map Things
import { roundTo } from "@components/Maps/Auxiliars/MathFunctions";

const style_b = {
  color: "#000000",
};
const style_square = {
  padding: "6px 8px",
  font: "14px/16px Arial, Helvetica, sans-serif",
  background: "rgba(255,255,255,0.8)",
  boxShadow: "0 0 15px rgba(0,0,0,0.2)",
  borderRadius: "5px",
  marginLeft: "55px",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CardGeneralInfoWButton = ({ initialValues, values, handleShowOldShape }) => {
  const [showCard, setShowCard] = useState(true);
  /* 
    let exampleInitialValues = {
          title: "INFORMACIÓN",
          elements: [
            {
              title: "Seccion:",
              name: "seccion",
              color: "#000",
              type: "text",
            },
            {
              title: "Avance:",
              name: "avance",
              color: "#000",
              type: "int",
            },
            {
              title: "Porcentaje de Avance:",
              name: "avance_porcentual",
              color: "#000",
              type: "percent",
            },
          ],
        }; */

  return values !== null && values !== undefined && showCard ? (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-left">
        <div className="leaflet-control" style={style_square}>
          <Card className="card-secondary">
            <CardHeader
              title={initialValues.title}
              style={{ textAlign: "center" }}
              subheader={""}
              titleTypographyProps={{ fontSize: "14px" }}
              action={
                <IconButton
                  onClick={() => {
                    setShowCard(false);
                  }}
                  aria-label="Minimizar"
                >
                  <RemoveCircleOutline />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {initialValues.elements.map((itemOfArray) => {
                    return (
                      <div key={"key_" + values[itemOfArray.name] + generateRandomString(5)}>
                        <Item style={{ fontSize: "12px", padding: "0px" }}>
                          {" "}
                          <b style={style_b}>
                            <small>{itemOfArray.title}</small>
                          </b>
                          <b style={{ color: values.color }}>
                            <small>
                              {itemOfArray.type === "text"
                                ? values[itemOfArray.name]
                                  ? values[itemOfArray.name]
                                  : "--"
                                : itemOfArray.type === "int"
                                ? new Intl.NumberFormat("en-EN").format(values[itemOfArray.name])
                                : itemOfArray.type === "percent"
                                ? values[itemOfArray.name] === null
                                  ? "--"
                                  : roundTo(values[itemOfArray.name], 2) + "%"
                                : values[itemOfArray.name]}
                            </small>
                          </b>
                        </Item>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
              <br></br>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Button size="small" style={{ borderRadius: "20px", fontSize: "10px" }} variant="outlined" onClick={() => handleShowOldShape()}>
                  Mirar Capa Anterior
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-left">
        <div className="leaflet-bar leaflet-control" style={{ marginLeft: "55px" }}>
          <IconButton
            aria-label="Abrir info"
            onClick={() => setShowCard(true)}
            disabled={!values}
            style={{ backgroundColor: "white" }}
            sx={{
              height: "30px",
              width: "30px",
              borderRadius: "3px",
              "&:hover": { backgroundColor: values ? "#eeeeee!important" : "white!important" },
            }}
          >
            <InfoOutlined sx={{ fontSize: 25, fontWeight: 200, color: "#000" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CardGeneralInfoWButton;
