import { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import { Container, CardContent, Chip, Box, Tooltip } from "@mui/material";
import FiltroDL from "@components/Territorial/FiltroDL";
import Filter from "@components/Territorial/Filter";
import { useNavigate } from "react-router-dom";
import BasicTable from "@components/Tables/BasicTable";
import Card from "@components/Card/Card";
import CardCommitments from "@components/Card/CardStats";
import Grid from "@mui/material/Unstable_Grid2";
import TerritorialService from "../../services/TerritorialServices";
import Swal from "sweetalert2";
import { EJERCICIO } from "@data/constants";
import MessageAlert from "@components/Downloads/MessageAlert";
import { numberWithCommas } from "@utils/Utilities";
import moment from "moment";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import middleware from "@middlewares/middleware";
import { getVars } from "@utils/global";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import MovingIcon from "@mui/icons-material/Moving";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const ProgressPolygon = (props) => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [loadingCharts, setLoadingChart] = useState(false);
	const [chartCompromisos, setChartCompromisos] = useState({
		type: 12,
		filtered: [],
	});
	let filterFijo = { id: "Ejercicio", value: EJERCICIO, filter: "=" };
	const [tableData, setTableData] = useState({
		page: 0,
		pageSize: 10,
		filtered: [filterFijo],
	});
	const [buttonLoading, setButtonLoading] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [successDownload, setSuccessDownload] = useState(false);
	const [user] = useState(() => {
		let t = getVars("Token");
		if (t !== {}) return t.user.ambito_perfil;
		return [];
	});

	const [dataTotales, setDataTotales] = useState({
		MetaCompromisos: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		regional: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		municipales: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		poligonal: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		seccionales: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
	});

	const columns = [
		{ id: "MunicipioReportes", label: "Municipio" },
		{ id: "Poligono", label: "Zona" },
		{ id: "MetaCompromisos", label: "Meta Promovidos" },
		{ id: "AvEsperado", label: "Av.Esperado" },
		/*   { id: "AvanceCompromisos", label: "Av.Compromisos" },
    { id: "PorcentajeCompromisos", label: "(%) Av.Compromisos" }, */
		{ id: "AvanceCompromisosUnicos", label: "Av.Promovidos" },
		{ id: "PorcentajeCompromisosUnico", label: "(%) Av.Promovidos" },
		{ id: "MetaEstructura", label: "Meta Estructura" },
		{ id: "AvanceEstructura", label: "Av.Estructura" },
		{ id: "PorcentajeEstructura", label: "(%) Avance Estructura" },
	];

	const getList = () => {
		TerritorialService.getProgressPolygon(tableData)
			.then((res) => {
				if (res.results) {
					setData(
						res.response.data.map((item) => {
							return {
								...item,
								AvEsperado: item.AvEsperado ? item.AvEsperado : "0",
								AvanceCompromisos: item.AvanceCompromisos
									? item.AvanceCompromisos
									: "0",
								AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
									? item.AvanceCompromisosUnicos
									: "0",
								AvanceEstructura: item.AvanceEstructura
									? item.AvanceEstructura
									: "0",
								PorcentajeEstructura: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeEstructura) >= 100
												? "success"
												: parseInt(item.PorcentajeEstructura) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeEstructura) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeEstructura > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeEstructura}
									/>
								),
								PorcentajeCompromisos: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeCompromisos) >= 100
												? "success"
												: parseInt(item.PorcentajeCompromisos) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeCompromisos) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeCompromisos > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeCompromisos}
									/>
								),
								PorcentajeCompromisosUnico: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeCompromisosUnico) >= 100
												? "success"
												: parseInt(item.PorcentajeCompromisosUnico) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeCompromisosUnico > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeCompromisosUnico}
									/>
								),
							};
						})
					);
					setTotal(res.response.total);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoading(false));
	};

	const getTotalCompromiso = () => {
		TerritorialService.getStatsStruct(chartCompromisos)
			.then((res) => {
				if (res.results) {
					setDataTotales(res.response.data);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoadingChart(false));
	};

	useEffect(() => {
		setLoading(true);
		setLoadingChart(true);
		getList();
		getTotalCompromiso();
		// eslint-disable-next-line
	}, [tableData]);

	const handleChangePagination = (pagination) => {
		setTableData({ ...tableData, ...pagination });
	};

	const handleChangeFilter = (type) => {
		const {
			Region,
			Municipio,
			Poligono,
			responsable = 0,
			Celular = "",
			NombreCompleto = "",
		} = type;

		let filtered = [filterFijo];
		let filtro = [];

		if (Region > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idRegion", filter: "=", value: Region },
			];

			filtro = [
				...filtro,
				{ id: "cat_poligonos.idRegion", filter: "=", value: Region },
			];
		}
		if (Municipio > 0) {
			filtered = [
				...filtered,
				{
					id: "cat_secciones.idMunicipioReportes",
					filter: "=",
					value: Municipio,
				},
			];

			filtro = [
				...filtro,
				{
					id: "cat_poligonos.idMunicipioReportes",
					filter: "=",
					value: Municipio,
				},
			];
		}
		if (Poligono.length > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
			];

			filtro = [
				...filtro,
				{ id: "cat_poligonos.id", filter: "IN", value: Poligono },
			];
		}
		if (Celular.length > 0) {
			filtered = [
				...filtered,
				{
					id: "usuarios.Celular",
					filter: "LIKE",
					value: Celular,
				},
			];
		}
		if (responsable > 0) {
			filtered = [
				...filtered,
				responsable === 1
					? { id: "estad_poligonos.AvanceEstructura", filter: "=", value: "0" }
					: { id: "estad_poligonos.AvanceEstructura", filter: ">", value: "0" },
			];
		}
		if (NombreCompleto.length > 0) {
			filtered = [
				...filtered,
				{
					id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"],
					filter: "LIKE",
					value: NombreCompleto,
				},
			];
		}

		setTableData({
			...tableData,
			page: 0,
			filtered: filtered,
		});

		setChartCompromisos({ type: 12, filtered: filtro });
	};

	return (
		<AdminLayout>
			<Container maxWidth="false">
				<ActionHeader
					title="Promovidos por Zonas"
					handleclick={() => navigate("/promovidos_por_zonass")}
				/>
				<Grid container spacing={2}>
					<Grid xs={12} md={4}>
						<CardCommitments
							icon={"groups"}
							title={"Promovidos"}
							total={`${numberWithCommas(
								dataTotales.MetaCompromisos.TotalAvance
							)} de ${numberWithCommas(dataTotales.MetaCompromisos.Meta)}`}
							subIcon={"update"}
							subtitle={moment(
								dataTotales.MetaCompromisos.FechaActualizo
							).format("DD/MM/YYYY")}
							loading={loadingCharts}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<CardCommitments
							icon={"groups"}
							title={"Zonales"}
							total={`${numberWithCommas(
								dataTotales.poligonal.TotalAvance
							)} de ${numberWithCommas(dataTotales.poligonal.Meta)}`}
							subIcon={"update"}
							subtitle={moment(dataTotales.poligonal.FechaActualizo).format(
								"DD/MM/YYYY"
							)}
							loading={loadingCharts}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<CardCommitments
							icon={"groups"}
							title={"Seccionales"}
							total={`${numberWithCommas(
								dataTotales.seccionales.TotalAvance
							)} de ${numberWithCommas(dataTotales.seccionales.Meta)}`}
							subIcon={"update"}
							subtitle={moment(dataTotales.seccionales.FechaActualizo).format(
								"DD/MM/YYYY"
							)}
							loading={loadingCharts}
						/>
					</Grid>
				</Grid>

				<Card className="card-primary">
					<CardContent>
						{user.find((e) => e.idPerfil === 113) ? (
							<FiltroDL
								onChangeFilter={(e) => handleChangeFilter(e)}
								poligono={true}
								seccion={false}
							/>
						) : (
							<Filter
								onChangeFilter={(e) => handleChangeFilter(e)}
								seccion={false}
								manzana={false}
								responsable={false}
								checkRespon={true}
								municipio={false}
								municipio_dl={true}
							/>
						)}
					</CardContent>
				</Card>

				<Card className="card-primary">
					<CardContent>
						{showMessage && (
							<Box marginTop={2}>
								<MessageAlert
									showMessage={showMessage}
									successDownload={successDownload}
									setShowMessage={setShowMessage}
								/>
							</Box>
						)}
						{/*      {isDownload ? (
              <Download format={"xlsx"} isDownload={isDownload} />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Box>
            )} */}

						{/*       {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
 */}
						<BasicTable
							rows={data}
							hcolumns={columns}
							pageProp={tableData.page}
							pageSize={tableData.pageSize}
							total={total}
							handleChangePagination={handleChangePagination}
							isLoading={loading}
							stickyHeader={true}
						/>
					</CardContent>
				</Card>
			</Container>
		</AdminLayout>
	);
};
export default ProgressPolygon;
