import { PrivilegedRoute, ProtectedRoute } from "@middlewares";

import DashboardNational from "@pages/Reports/DashboardNational";

const ReportesRoutes = [
  {
    path: "/reporte-nacional",
    element: (
      <ProtectedRoute>
        <DashboardNational />
      </ProtectedRoute>
    ),
  },
];

export default ReportesRoutes;
