import { PrivilegedRoute, ProtectedRoute } from "@middlewares";
import RollCall from "@pages/Activation/RollCall";
import Bingo from "@pages/Activation/Bingo";
import MovilizationCapture from "@pages/Activation/MovilizationCapture";
import DashboardMobilization from "@pages/Activation/DashboardMobilization";

const ActivationRoute = [
  {
    path: "/activacion-pase-lista",
    element: (
      <PrivilegedRoute>
        <RollCall />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/captura-bingo",
    element: (
      <PrivilegedRoute>
        <Bingo />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/captura-movilizacion",
    element: (
      <PrivilegedRoute>
        <MovilizationCapture />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/dashboard-movilizacion",
    element: (
      <ProtectedRoute>
        <DashboardMobilization />
      </ProtectedRoute>
    ),
  },
];

export default ActivationRoute;
