import { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Container,
    Box,
    Stack,
    Typography,
    Button,
    Icon
} from "@mui/material";
import Swal from "sweetalert2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import DataTableReport from "@components/Sije/Reports/DataTableReport";
import Filter from "@components/Prep/ReportFilter";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";
import Download from "@components/Downloads/Download";

import prep from "@services/PrepServices";

const Reporte = () => {
    const PROGRESS_KEYS = [
        "PorcentajePendientes",
        "PorcentajePendientesMuestreo",
        "PorcentajeRecibidas",
        "PorcentajeRecibidasMuestreo"
    ];

    const [params, setParams] = useState(null);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);
    const [summary, setSummary] = useState([]);
    const [paramsTable, setParamsTable] = useState({
        page: 0,
        pageSize: 25,
        filtered: []
    });
    const [loading, setLoading] = useState(false);
    const [isDownload, setIsDownloading] = useState(false);
    const [isLoadigForm, setIsLoadingForm] = useState(false);
    const [openLinearProgress, setOpenLinearProgress] = useState(false);
    const [isSuccessFormSave] = useState(false);

    useEffect(() => {
        const getData = async () => {
            if (params) {
                getReport(params);
            }
        }

        getData();
        //eslint-disable-next-line
    }, [params])

    const handleChangePagination = (pagination) => {
        setParamsTable({ ...paramsTable, ...pagination });
        setParams((prevState) => ({ ...prevState, ...pagination }));
    };

    const handleRequestSort = (dataSorted) => {
        let sorted = [];
        let id = dataSorted.orderBy;

        switch (id) {
            case 'TotalActas':
                id = `COUNT(vw_prep_reporte_actas.idCasilla)`;
                break;
            case 'ActasRecibidas':
                id = `COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL))`;
                break;
            case 'PorcentajeRecibidas':
                id = `(ROUND((100 * (COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(vw_prep_reporte_actas.idCasilla) )), 2))`;
                break;
            case 'ActasPendientes':
                id = `(COUNT(vw_prep_reporte_actas.idCasilla) - COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)))`;
                break;
            case 'PorcentajePendientes':
                id = `ROUND((100 * (1 - (COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(vw_prep_reporte_actas.idCasilla)))), 2)`;
                break;
            case 'TotalActasMuestreo':
                id = `COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL))`;
                break;
            case 'ActasRecibidasMuestreo':
                id = `COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL))`;
                break;
            case 'PorcentajeRecibidasMuestreo':
                id = `IFNULL((ROUND((100 * (COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL)) )), 2)), 0)`;
                break;
            case 'ActasPendientesMuestreo':
                id = `(COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL)) - COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)))`;
                break;
            case 'PorcentajePendientesMuestreo':
                id = `IFNULL(ROUND((100 * (1 - (COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL))))), 2), 0)`;
                break;
            default:
                break;
        }

        setParams((prevState) => ({ ...prevState, sorted: [...sorted, { id: id, value: dataSorted.order }] }));
    }

    const handleChangeFilter = async (values) => {
        const params = {
            ...values,
            ...paramsTable
        };
        setParams(params);
    }

    const getReport = async (params) => {
        setLoading(true);
        setIsLoadingForm(true);
        setOpenLinearProgress(true);

        try {
            const result = await prep.getProgressReport(params);
            const { results, message, response } = await result;

            if (results) {
                setColumns(response.columns);
                const newDataReports = response.data.map((item) => {
                    const updatedItem = { ...item };

                    PROGRESS_KEYS.forEach((key) => {
                        updatedItem[key] = (
                            <CircularProgressPorcent
                                size={42}
                                fontSize={10}
                                value={item[key]}
                            />
                        );
                    });

                    return updatedItem;
                });
                setRows(newDataReports);
                setTotal(response.total);
                setSummary(Object.values(response.totals));
            } else {
                Swal.fire({
                    title: message,
                    icon: 'warning'
                });
                setColumns([]);
                setRows([]);
                setTotal(0);
                setSummary([]);
            }
        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: "warning",
            });
            setColumns([]);
            setRows([]);
            setTotal(0);
            setSummary([]);
        } finally {
            setLoading(false);
            setIsLoadingForm(false);
            setOpenLinearProgress(false);
        }
    }

    const handleExport = async () => {
        try {
            setIsDownloading(true);
            const result = await prep.exportProgressReport({
                idTipoEleccion: params.idTipoEleccion,
                idAmbitoAgrupador: params.idAmbitoAgrupador
            });
            const { success } = await result;
            if (success) {
                Swal.fire({
                    title: "Descargado con éxito.",
                    icon: "success",
                });
            } else {
                throw new Error("Ocurrió un error, contacte al administrador.");
            }
        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: "warning",
            });
        } finally {
            setIsDownloading(false);
        }
    }

    return (
        <AdminLayout>
            <Container maxWidth={false}>
                <ActionHeader title={"Reporte de Actas"} />

                <LoadingForm
                    loadinMessage={'Consultando...'}
                    successMessage="¡Consultado con éxito!"
                    isLoading={isLoadigForm}
                    success={isSuccessFormSave}
                    isOpen={openLinearProgress}
                    setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
                />

                <Card
                    sx={{
                        variant: "elevation",
                        borderRadius: "12px",
                        padding: 0,
                        width: "100%",
                    }}
                    className="card-primary">
                    <CardContent>
                        <Filter onChangeFilter={handleChangeFilter} />

                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            marginBottom={2}
                        >
                            <Typography variant="body2" fontWeight={700}>
                                REPORTES
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primaryDark"
                                startIcon={<Icon>download</Icon>}
                                size="small"
                                disabled={isDownload}
                                onClick={handleExport}
                            >
                                Exportar
                            </Button>
                        </Stack>
                        {isDownload && (
                            <Box marginBottom={2}>
                                <Download format={"xlsx"} isDownload={isDownload} />
                            </Box>
                        )}

                        <DataTableReport
                            rows={rows}
                            hcolumns={columns}
                            total={total}
                            summary={summary}
                            pageProp={paramsTable.page}
                            pageSize={paramsTable.pageSize}
                            minHeight="0px"
                            stickyHeader={true}
                            isLoading={loading}
                            handleChangePagination={handleChangePagination}
                            handleManualSort={handleRequestSort}
                        />
                    </CardContent>
                </Card>
            </Container>
        </AdminLayout>
    )
}

export default Reporte;