import React, { useEffect, useState } from "react";

// Material UI
import { Container, Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserAssistFilter from "@components/Support/UserAssistFilter";
import UserAssistTable from "@components/Support/UserAssistTable";
import SupportService from "@services/SupportServices"

import Swal from "sweetalert2"

const UserAssist = () => {
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm, /* setIsSuccessForm */] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [listSupportData, setListSupportData] = useState([]);
  const [total, setTotal] = useState(0)
  const [tabValue, setTabValue] = useState(0)
  const [filter, setFilter] = useState([])
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: []
  })

  const columns = [
    { title: "Nombre", field: "Nombre" },
    { title: "Celular", field: "Celular" },
    { title: "Fecha", field: "FechaCreo" },
    { title: "Tipo Solicitud", field: "SolicitudSoporte" },
    { title: "Tiene Usuario", field: "TieneUsuario" },
    { title: "Solicitudes Hechas", field: "NumeroSolicitudes" },
    { title: "Estatus", field: "idEstatus" },
  ];

  useEffect(()=>{
    ApiResponse()
    // eslint-disable-next-line
  },[params])

  useEffect(()=>{
    const newFilter = tabValue ? [{ id: "idEstatus", filter: "=", value: tabValue }] : [];

  if (filter && filter.length > 0) {
    newFilter.push(...filter);
  }

  setParams({
    ...params,
    filtered: newFilter,
  });
    // eslint-disable-next-line
  },[tabValue, filter])

  const ApiResponse = async()=>{
    try {
      setIsLoadingForm(true)
      setOpenLinearProgress(true)
      const result = await SupportService.getListSupport(params)
      const {results, message, response} = result;
      if(results){
        setListSupportData(response.data)
        setTotal(response.total)
      }else{
        /* Swal.fire({
          title: message,
          icon: "warning"
        }) */
        setListSupportData([])
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon:"warning"
      })
      setListSupportData([])
    }finally{
      setIsLoadingForm(false)
      setOpenLinearProgress(false)
    }
  }

  const handleChangePage = (event, newPage) => {
    setParams({
      ...params,
      page: newPage
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({
      ...params,
      page: 0,
      pageSize: event.target.value
    })
  }; 
  
  const handleChangeParams = (value) => {
    setFilter(value)
  };

  const handleChangeEstatus = (estatus) =>{
    setTabValue(estatus)
  }
  return (
    <>
      <AdminLayout>
        <Container maxWidth={false} >
          <ActionHeader title="Soporte a usuarios" />
          <LoadingForm
            isLoading={isLoadigForm}
            success={isSuccessForm}
            isOpen={openLinearProgress}
            setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
            loadinMessage={"Cargando..."}
          />
          <Box>
            <Grid2 container>
              <Grid2 xs={12}  lg={12}>
                <Box >
                  <UserAssistFilter 
                    setFiltered={handleChangeParams} 
                  />
                  <UserAssistTable 
                    columns={columns}
                    total={total}
                    rows= {listSupportData}
                    page={params.page}
                    pageSize={params.pageSize}
                    setEstatus={handleChangeEstatus}
                    setSorted={(sorted) => setParams({ ...params, sorted })}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} 
                    handleChangePage={handleChangePage}
                    navigateTo={"/soporte/user-assist-description"}
                  />
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      </AdminLayout>
    </>
  );
};

export default UserAssist;
