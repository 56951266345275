import { useEffect, useState } from "react";
import { useFormik } from "formik";

import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Icon,
    Typography,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import mov from '@services/MovilizationServices';
import Swal from "sweetalert2";

import { MovilizationByHourInterface } from '@data/interfaces/MovilizationInterfaces'
import { HourMovilizationSchema } from '@data/schemas/MovilizationSchemas';
import { isEmptyOrInvalidString } from "@utils/validations";

const Filter = ({ onChange, onClear }) => {
    //CONSTANTS
    const catalogsOptions = [
        { id: "municipios_reportes" },
        { id: "poligonos" },
        { id: "secciones" },
    ];

    const formik = useFormik({
        initialValues: MovilizationByHourInterface,
        validationSchema: HourMovilizationSchema,
        enableReinitialize: false,
        onSubmit: (values, actions) => {
            console.log(values)
            onChange(values);
        },
    });
    //States
    const [loadingCatalogs, setLoadingCatalogs] = useState(false);
    const [catalogs, setCatalogs] = useState([]);
    const [catSchedules, setCatSchedules] = useState([]);
    const [catalogsFiltered, setCatalogFiltered] = useState(() => {
        let newObject = {};
        for (const item of catalogsOptions) {
            newObject[item.id] = [];
        }
        return newObject;
    });
    const [filterData, setFilterData] = useState({
        municipio: "",
        poligono: "",
        seccion: "",
        Hora: ""
    });

    const getCatalogs = async () => {
        try {
            setLoadingCatalogs(true);
            const result = await sije.getCatalogs({ catalogs: catalogsOptions });
            const { results, response, message } = result;
            if (results) {
                const { catalogs } = response;
                setCatalogFiltered(catalogs);
                setCatalogs(catalogs);
            } else {
                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({
                title: error,
                icon: "warning",
            });
        } finally {
            setLoadingCatalogs(false);
        }


        try {
            setLoadingCatalogs(true);
            const result = await mov.getSchedules({});
            const { results, response, message } = result;
            if (results) {
                setCatSchedules(response.data);
            } else {
                setCatSchedules([]);
                throw new Error(message);
            }
        } catch (error) {
            setCatSchedules([]);
            Swal.fire({
                title: error,
                icon: "warning",
            });
        } finally {
            setLoadingCatalogs(false);
        }

    };

    const handleChangeFilter = (e) => {
        const key = e.target.name;
        setFilterData({ ...filterData, [key]: e.target.value });
    };

    const handleChangeMunicipality = (e) => {
        const _idMunicipality = e.target.value;
        setCatalogFiltered((prevState) => ({
            ...prevState,
            poligonos: catalogs.poligonos.filter(
                ({ idMunicipioReportes }) => idMunicipioReportes === _idMunicipality
            ),
            secciones: catalogs.secciones.filter(
                ({ idMunicipio }) => idMunicipio === _idMunicipality
            ),
        }));
    };

    const handleChangePolygon = (e) => {
        const _idPolygon = e.target.value;
        setCatalogFiltered((prevState) => ({
            ...prevState,
            secciones: catalogs.secciones.filter(
                ({ idPoligono }) => idPoligono === _idPolygon
            ),
        }));
    };

    const handleClear = () => {
        setCatalogFiltered(catalogs);
        setFilterData((prevState) => ({
            ...prevState,
            municipio: "",
            poligono: "",
            seccion: "",
            Hora: ""
        }));
        onClear();
    };

    useEffect(() => {
        getCatalogs();
        // eslint-disable-next-line
    }, []);

    return (
        <Card className="card-primary">
            <CardContent>
                <Box component={"div"} marginBottom={2}>
                    <Typography variant="body2" fontWeight={600}>
                        FILTRO
                    </Typography>
                    <Divider />
                </Box>
                <Box component={"div"} marginBottom={2}>

                    <BasicSelect
                        name="municipio"
                        label="Municipio"
                        options={catalogsFiltered.municipios_reportes}
                        value={filterData.municipio}
                        onChange={(e) => {
                            handleChangeFilter(e);
                            handleChangeMunicipality(e);
                        }}
                        isLoading={loadingCatalogs}
                        sx={{ width: "100%", mb: 3 }}
                    />

                    <BasicSelect
                        name="poligono"
                        label="Zona"
                        options={catalogsFiltered.poligonos}
                        value={filterData.poligono}
                        onChange={(e) => {
                            handleChangeFilter(e);
                            handleChangePolygon(e);
                        }}
                        isLoading={loadingCatalogs}
                        sx={{ width: "100%", mb: 3 }}
                    />

                    <BasicSelect
                        error={formik.touched.Hora && !isEmptyOrInvalidString(formik.errors.Hora)}
                        errorMessage={formik.errors.Hora}
                        name="Hora"
                        label="Hora de Reporte"
                        options={catSchedules}
                        value={formik.values.Hora}
                        onChange={formik.handleChange}
                        isLoading={loadingCatalogs}
                        sx={{ width: "100%", mb: 3 }}
                    />

                    <BasicSelect
                        error={formik.touched.Seccion && !isEmptyOrInvalidString(formik.errors.Seccion)}
                        errorMessage={formik.errors.Seccion}
                        name="Seccion"
                        label="Sección"
                        options={catalogsFiltered.secciones.map((item) => ({
                            value: item.value,
                            label: String(item.label),
                        }))}
                        value={formik.values.Seccion}
                        onChange={formik.handleChange}
                        isLoading={loadingCatalogs}
                        sx={{ width: "100%", mb: 3 }}
                    />
                </Box>
                <Box marginBottom={2}>
                    <Button
                        variant="contained"
                        color="primaryDark"
                        size="small"
                        startIcon={<Icon>search</Icon>}
                        onClick={formik.handleSubmit}
                        sx={{ width: "100%", mb: 1 }}
                    >
                        buscar
                    </Button>
                    <Button
                        variant="outlined"
                        color="primaryDark"
                        size="small"
                        startIcon={<Icon>search_off</Icon>}
                        onClick={handleClear}
                        sx={{ width: "100%" }}
                    >
                        Limpiar
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Filter;
