import React, { useState, useEffect } from "react";
import {
	Grid,
	Button,
	Icon,
	Card,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
} from "@mui/material";
import swal from "sweetalert2";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";

import BasicSelect from "@components/Selects/BasicSelect";
import { isEmptyOrInvalidString } from "@utils/validations";
import { AmbitSchema } from "@data/schemas/CompromisoSchemas";
import { AmbitAddInterface } from "@data/interfaces/CompromisoInterfaces";
import CatalogService from "@services/CatalogServices";
import TargetForm from "@components/Compromisos/TargetForm";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
	padding: 0,
}));

const AmbitForm = (props) => {
	const defaultValues = {
		idTipoAmbito: null,
		idAmbito: null,
		idPerfil: null,
		TipoAmbito: "-",
		Ambito: "-",
		Perfil: "-",
		ResponsabilidadEtiqueta: "-",
		uq: null,
	};

	const [listValues, setListValues] = useState([]);
	const [selectValues, setSelectValues] = useState(defaultValues);
	const [catAmbit, setCatAmbit] = useState([]);
	const [catProfile, setCatProfile] = useState([]);
	const [catProfileFilter, setCatProfileFilter] = useState([]);
	const [catProfileType, setCatProfileType] = useState([]);
	const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);

	const formik = useFormik({
		initialValues: AmbitAddInterface,
		validationSchema: AmbitSchema,
		onSubmit: (values) => {
			handleSave(values);
		},
	});

	//Catalogs
	useEffect(() => {
		let catalogsParams = [
			{ id: "ambito", getAll: false },
			{ id: "tipo_perfil", getAll: false },
			{ id: "perfil", getAll: false },
		];
		CatalogService.getCatalogs(catalogsParams)
			.then((res) => {
				if (res.results) {
					if (res.response.errors.length > 0) {
						swal.fire({
							title:
								"Algunos catálogos pudieron ser cargados. Contacte al administrador",
							icon: "warning",
						});
					}
					catalogsParams.forEach((item) => {
						switch (item.id) {
							case "ambito":
								setCatAmbit(res.response.catalogs[item.id]);
								break;
							case "perfil":
								setCatProfile(res.response.catalogs[item.id]);
								setCatProfileFilter(res.response.catalogs[item.id]);
								break;
							case "tipo_perfil":
								setCatProfileType(res.response.catalogs[item.id]);
								break;
							default:
								break;
						}
					});
					setIsLoadingCatalogs(false);
				} else {
					swal.fire({ title: res.message, icon: "warning" });
					setIsLoadingCatalogs(false);
				}
			})
			.catch((e) => {
				swal.fire({ title: e.message, icon: "warning" });
				setIsLoadingCatalogs(false);
			});
		// eslint-disable-next-line
	}, []);

	const handleSave = (values) => {
		let uqAux = null;
		if (
			values.idTipoAmbito !== "" &&
			values.idPerfil !== "" &&
			values.idAmbito !== ""
		) {
			uqAux = `${values.idTipoAmbito}-${values.idPerfil}-${values.idAmbito}`;
		} else if (values.idTipoAmbito !== "" && values.idPerfil !== "") {
			uqAux = `${values.idTipoAmbito}-${values.idPerfil}`;
		} else if (values.idTipoAmbito !== "" && values.idAmbito !== "") {
			uqAux = `${values.idTipoAmbito}-${values.idAmbito}`;
		} else if (values.idPerfil !== "" && values.idAmbito !== "") {
			uqAux = `${values.idPerfil}-${values.idAmbito}`;
		} else if (values.idTipoAmbito !== "") {
			uqAux = `${values.idTipoAmbito}`;
		} else if (values.idPerfil !== "") {
			uqAux = `${values.idPerfil}`;
		} else if (values.idAmbito !== "") {
			uqAux = `${values.idAmbito}`;
		}
		setSelectValues({
			...selectValues,
			uq: uqAux,
		});
	};

	useEffect(() => {
		if (selectValues.uq !== null) {
			AddIfNotExists(selectValues);
		}
		// eslint-disable-next-line
	}, [selectValues]);

	useEffect(() => {
		if (listValues.length < 0) {
			formik.setValues(AmbitAddInterface);
		}
		// eslint-disable-next-line
	}, [listValues]);

	const AddIfNotExists = (item) => {
		if (listValues.length < 1) {
			setListValues([item]);
		} else {
			let found;
			if (item.uq) {
				found = listValues.find((row) => row.uq === item.uq);
			} else if (item.id) {
				found = listValues.find((row) => row.id === item.id);
			}
			if (!found) {
				setListValues([...listValues, item]);
			}
		}
		setSelectValues(defaultValues);
		formik.setValues(AmbitAddInterface);
	};

	const handleDeleteRow = (item, index) => {
		swal
			.fire({
				title: `¿Esta seguro de Eliminar el registro ?`,
				text: `Al confirmar, el registro desaparecera del listado, ¿Desea continuar?`,
				icon: "question",
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonText: "Sí",
			})
			.then((isResponse) => {
				if (isResponse) {
					let valuesArray = [...listValues];
					valuesArray.splice(index, 1);
					swal
						.fire({
							title: `Eliminado con éxito`,
							icon: "success",
						})
						.then((isResponse) => {
							if (isResponse) {
								setListValues(valuesArray);
							}
						});
				}
			});
	};

	const handleChangeType = (e) => {
		let idTipoAmbito = e.target.value;
		let TipoAmbito = catProfileType.find((item) => item.value === idTipoAmbito);

		formik.setValues({
			idTipoAmbito: idTipoAmbito,
			idPerfil: "",
			idAmbito: "",
		});
		setSelectValues({
			...selectValues,
			idTipoAmbito: TipoAmbito.value,
			TipoAmbito: TipoAmbito.label,
		});
		setCatProfileFilter(
			catProfile.filter((item) => item.idTipo === idTipoAmbito)
		);
	};

	const handleChangePerfil = (e) => {
		let idPerfil = e.target.value;
		let Perfil = catProfile.find((item) => item.value === idPerfil);

		formik.setFieldValue("idPerfil", idPerfil);

		setSelectValues({
			...selectValues,
			idPerfil: Perfil.value,
			Perfil: Perfil.label,
			ResponsabilidadEtiqueta: Perfil.EtiquetaCargo,
		});
	};

	const handleChangeAmbito = (e) => {
		let idAmbito = e.target.value;
		let Ambito = catAmbit.find((item) => item.value === idAmbito);

		formik.setFieldValue("idAmbito", idAmbito);

		setSelectValues({
			...selectValues,
			idAmbito: Ambito.value,
			Ambito: Ambito.label,
		});
	};

	return (
		<>
			<Grid item xs={12} sm={12} md={3} lg={3}>
				<BasicSelect
					error={
						formik.touched.idTipoAmbito &&
						!isEmptyOrInvalidString(formik.errors.idTipoAmbito)
					}
					label="Tipo *"
					size="small"
					errorMessage={formik.errors.idTipoAmbito}
					name="idTipoAmbito"
					onChange={(e) => handleChangeType(e)}
					onBlur={formik.handleBlur}
					value={formik.values.idTipoAmbito}
					options={catProfileType}
					sx={{ width: "100%" }}
					isLoading={isLoadingCatalogs}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={3} lg={3}>
				<BasicSelect
					error={
						formik.touched.idPerfil &&
						!isEmptyOrInvalidString(formik.errors.idPerfil)
					}
					label="Perfil *"
					size="small"
					errorMessage={formik.errors.idPerfil}
					name="idPerfil"
					onChange={(e) => handleChangePerfil(e)}
					onBlur={formik.handleBlur}
					value={formik.values.idPerfil}
					options={catProfileFilter}
					isLoading={isLoadingCatalogs}
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={3} lg={3}>
				<BasicSelect
					error={
						formik.touched.idAmbito &&
						!isEmptyOrInvalidString(formik.errors.idAmbito)
					}
					label="Ámbito *"
					size="small"
					errorMessage={formik.errors.idAmbito}
					name="idAmbito"
					onChange={(e) => handleChangeAmbito(e)}
					onBlur={formik.handleBlur}
					value={formik.values.idAmbito}
					options={catAmbit}
					isLoading={isLoadingCatalogs}
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={1} lg={1}>
				<Button
					sx={{ mt: 1 }}
					type="submit"
					color="secondary"
					variant="contained"
					size="small"
					//disabled={true}
					onClick={(e) => {
						e.preventDefault();
						formik.submitForm(e);
					}}
					startIcon={<Icon>add</Icon>}
				>
					Agregar
				</Button>
			</Grid>
			{formik.values.idAmbito !== "" && (
				<Grid container spacing={3} sx={{ mt: 1, ml: 0 }}>
					<TargetForm
						idAmbito={formik.values.idAmbito}
						errors={formik.errors}
						touched={formik.touched}
						values={formik.values}
						setValues={formik.setValues}
						handleChange={formik.handleChange}
					/>
				</Grid>
			)}
			{listValues.length > 0 && (
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Card className="card-secondary">
						<TableContainer sx={{ maxHeight: 400, p: 2 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<CustomTableCell>No</CustomTableCell>
										<CustomTableCell>Tipo</CustomTableCell>
										<CustomTableCell>Perfil</CustomTableCell>
										<CustomTableCell>Ámbito</CustomTableCell>
										<CustomTableCell>Asignación</CustomTableCell>
										<CustomTableCell></CustomTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{listValues.map((row, index) => (
										<TableRow key={index}>
											<CustomTableCell>{index + 1}</CustomTableCell>
											<CustomTableCell>{row.TipoAmbito}</CustomTableCell>
											<CustomTableCell>{row.Ambito}</CustomTableCell>
											<CustomTableCell>{row.Perfil}</CustomTableCell>
											<CustomTableCell>
												{row.ResponsabilidadEtiqueta}
											</CustomTableCell>
											<CustomTableCell align="center">
												<IconButton
													edge="end"
													aria-label="delete"
													color="error"
													size="small"
													onClick={(e) => handleDeleteRow(row, index)}
													// sx={{ p: 0, m: 0 }}
												>
													<Icon size="small">delete</Icon>
												</IconButton>
											</CustomTableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Card>
				</Grid>
			)}
		</>
	);
};

export default AmbitForm;
