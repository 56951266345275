import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  Icon,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import BasicList from "../Lists/BasicList";
import CloseIcon from "@mui/icons-material/Close";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import electoral from "../../services/ElectoralServices";
import Swal from "sweetalert2";
import RGListItem from "./RGsListItem";
import NotPermission from "@assets/img/NotPermission.jpg";
import middleware from "@middlewares/middleware";
import Yup from "@utils/Yupi18n";

const AssignRG = ({ open, setOpen, title = { tipo: "RG",idTipo:0, ruta: "Sin ruta"} }) => {
  const [volunteer, setVolunteer] = useState({ NombreCompleto: "" });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
 // const [loading, setLoading] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [statusValidated, setStatusValidated] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [resultAssing, setResultAssing] = useState({
    success: false,
    message: "",
  });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const pageSize = 10;

  const formik = useFormik({
    initialValues: {
      idTipo:title.idTipo,
      idUsuarioRG: null,
      idUsuarioAbogado: null,
      idRuta: null,
    },
    validationSchema: Yup.object().shape({
      idTipo:Yup.number().required(),
      idUsuarioRG: Yup.number().when('idTipo', {
        is: idTipo => idTipo === 0,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable()
      }),
      idUsuarioAbogado: Yup.number().when('idTipo', {
        is: idTipo => idTipo === 1,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable()
      }),
      idRuta: Yup.number().required()
    }),
    onSubmit: values => {
      // Aquí manejas la lógica de envío del formulario
      onSubmit(values);
    },
  });
  
  useEffect(()=>{
    formik.handleChange({
      target: {
        name:  "idTipo" ,
        value: title.idTipo,
      },
    });
  },[title.idTipo])

  useEffect(() => {
    if (open) {
      let newValues = {
        ...formik.values,
        idRuta:title.idRuta,
      };

      formik.setValues({
        ...newValues,
      });
    }
  }, [title]);

  useEffect(() => {
    if (open) {
      getList({ page: page, pageSize: pageSize, filtered: filtered });
    }
  }, []);

  const getList = useCallback((data) => {
    
  if(title.idTipo === 0){
    electoral.getListRG({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
    .then((res) => {
      if (res.results) {
        if (res.response.total > 0) {
          const volunteers = res.response.data.map((volunteer) => {
            return {
              ...volunteer,
              NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`
            };
          });

          setData(volunteers);
          setTotal(res.response.total);
        }

        if (pageSize >= parseInt(res.response.total)) {
          setHasMore(false);
        }
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
        setHasMore(false);
      }
    })
    .catch((e) => {
      setHasMore(false);
      Swal.fire({ title: e.message, icon: "warning" });
    })
    .finally(() => {
    //  setLoading(false);
    });
  }else{
    electoral.getListAbogado({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
    .then((res) => {
      if (res.results) {
        if (res.response.total > 0) {
          const volunteers = res.response.data.map((volunteer) => {
            return {
              ...volunteer,
              NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`
            };
          });

          setData(volunteers);
          setTotal(res.response.total);
        }

        if (pageSize >= parseInt(res.response.total)) {
          setHasMore(false);
        }
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
        setHasMore(false);
      }
    })
    .catch((e) => {
      setHasMore(false);
      Swal.fire({ title: e.message, icon: "warning" });
    })
    .finally(() => {
     // setLoading(false);
    });
  }


    // eslint-disable-next-line
  }, []);

  /*   useEffect(()=>{},[sectionValid]) */

  const fetchHasMore = () => {
    electoral.getListRG({
      page: page + 1,
      pageSize: pageSize,
      filtered: filtered,
    })
      .then((res) => {
        if (res.results) {
          const volunteers = res.response.data.map((volunteer) => {
            return {
              ...volunteer,
              NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`
            };
          });
          setData(data.concat(volunteers));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
    setPage(page + 1);
  };

  const handleClickData = (data) => {
    setStatusValidated(false);
    if(formik.values.idTipo === 0){
      formik.handleChange({
        target: {
          name:  "idUsuarioAbogado" ,
          value: null
        },
      });
      formik.handleChange({
        target: {
          name:  "idUsuarioRG" ,
          value: data.id,
        },
      });
    }else{
      formik.handleChange({
        target: {
          name:  "idUsuarioRG" ,
          value: null
        },
      });
      formik.handleChange({
        target: {
          name:  "idUsuarioAbogado" ,
          value: data.id,
        },
      });
    }

    setVolunteer(data);
  };

  const onSubmit = async (values) => {
    console.log(values);
    setStatusValidated(false);
    const res = await Swal.fire({
      title: "Asignar!",
      html: `<p>Esta Seguro de asignar la Ruta ${title.ruta} al ${title.tipo} ${volunteer.NombreCompleto}</p>`,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Continuar`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: "modal-alert",
      },
    });

    if (res.isConfirmed) {
      setIsLoadingForm(true);
      let newData = {
        idUsuarioAbogado:values.idUsuarioAbogado,
        idUsuarioRG: values.idUsuarioRG,
        idRuta: values.idRuta
      };

      if(values.idTipo === 0){
        delete newData.idUsuarioAbogado;
      }else{
        delete newData.idUsuarioRG;
      }
      

      try {
        if(values.idTipo === 0){
          const result = await electoral.asignarRGRuta(newData);
          const { results, message } = result;
          if (results) {
            setResultAssing({
              success: true,
              message: message,
            });
            setOpen(false);
          } else {
            setResultAssing({
              success: false,
              message: message,
            });
          }
        }else{
          const result = await electoral.asignarAbogadoRuta(newData);
          const { results, message } = result;
          if (results) {
            setResultAssing({
              success: true,
              message: message,
            });
            setOpen(false);
          } else {
            setResultAssing({
              success: false,
              message: message,
            });
          }
        }
      
      } catch (error) {
        setResultAssing({
          success: false,
          message: error.message,
        });
      } finally {
        setStatusValidated(true);
        setIsLoadingForm(false);
      }
    }
  };


  const evalInputSearch = (text) => {
    let out_text = text.trim();
    let type = "text";
    // const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const phoneRegex =
      /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

    if (phoneRegex.test(out_text)) {
      out_text = out_text.replace(/\D/g, "").slice(-10);
      type = "phone";
    } else {
      out_text = text.replace(/\s+/g, "%");
    }

    return {
      type,
      text: out_text,
    };
  };

  const handleSearch = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
    } else {
      const { type, text } = evalInputSearch(searchInput);
      if (type === "phone") {
        filtro = [
          {
            id: `compromisos_unicos.Celular`,
            filter: "=",
            value: `${text}`,
            inheritFilterType: null,
          },
        ];
      } else {
        filtro = [
          {
            id: `CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)`,
            filter: "LIKE",
            value: `%${text}%`,
            inheritFilterType: null,
          },
        ];
      }
    }
    setFiltered(filtro);
    setPage(0);
    getList({ page: 0, pageSize: pageSize, filtered: filtro });
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
            width: "40%", 
            "@media (max-width: 600px)": {
              overflowY: "auto",
              width: "100%",
              height: "90%",
            },
          }}
        >
          <IconButton
            aria-label="cancel"
            onClick={() => setOpen(false)}
            sx={{ position: "fixed", left: "3%", top: "2%", color: "#FFFFFF" }}
          >
            <Icon>cancel</Icon>
          </IconButton>
          {middleware.checkMenuAction("Autorizar") ? (
            <Card elevation={0}>
              <CardHeader
                title={`Asignar ${title.tipo}`}
                subheader={`Ruta ${title.ruta}`}
                titleTypographyProps={{
                  align: "center",
                  variant: "h5",
                  color: "white",
                }}
                subheaderTypographyProps={{ align: "center", color: "white" }}
                sx={{ backgroundColor: "#152A9E", pt: "25px" }}
              />
              <CardContent>
                <Grid2
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid2 xs={12} sm={12} md={12} lg={12}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={0.5}
                    >
                      <BasicList
                        Header={false}
                        data={data}
                        fetchHasMore={fetchHasMore}
                        totalItems={total}
                        itemComponent={RGListItem}
                        hasMore={hasMore}
                        finder={true}
                        search={handleSearch}
                        handleClickItem={(e) => handleClickData(e)}
                      />
                    </Stack>
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          ) : (
            <Card elevation={0}>
              <CardHeader
                title={`Asignar ${title.tipo}`}
                subheader={`Ruta ${title.ruta}`}
                titleTypographyProps={{
                  align: "center",
                  variant: "h5",
                  color: "white",
                }}
                subheaderTypographyProps={{ align: "center", color: "white" }}
                sx={{ backgroundColor: "#152A9E", pt: "25px" }}
              />
              <CardContent>
                <Grid2 container justifyContent="center" rowSpacing={1}>
                  <Grid2 item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      style={{
                        fontFamily: "Open Sans, sans-serif",
                        fontSize: "25px",
                        color: "red",
                        textAlign: "center", // Centra el texto
                      }}
                    >
                      No tiene los permisos necesarios. Contacte al
                      administrador
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={12} sm={12} md={12} lg={12}>
                    <img
                      src={NotPermission}
                      alt="permission"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          )}
          <div>
          <Grid2 container spacing={1} justifyContent={"flex-end"}>
          <Grid2 item xs={12}>
           {!resultAssing.success && <FormHelperText>{resultAssing.message}</FormHelperText>}
          </Grid2>
            <Grid2 item >
            <LoadingButton
              variant="contained"
              size="small"
              loading={isLoadigForm}
              endIcon={<Icon>save</Icon>}
              loadingPosition="end"
              onClick={formik.submitForm}
            >
              Asignar
            </LoadingButton>
            </Grid2>
            <Grid2 item >
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => setOpen(false)}
              sx={{ borderRadius: "10px" }}
              endIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
            </Grid2>
          </Grid2>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AssignRG;
