import React, { useEffect } from "react";
import Highcharts from "highcharts";
import solidGauge from "highcharts/modules/solid-gauge";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useWindowDimensions from "@hooks/useWindowDimensions";
import HC_more from "highcharts/highcharts-more";
import moment from "moment";
HC_more(Highcharts);
solidGauge(Highcharts);

const SolidGauge = (props) => {
  const { loading, max, data } = props;
  const { height, width } = useWindowDimensions();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const gaugeOptions = {
    chart: {
      type: "solidgauge",
      height: width > 430 ? height * 0.22 : 350,
      //height: height * 0.2
    },
    title: null,
    pane: {
      center: ["50%", `${isMobile ? "60%" : "85%"}`], // 85 md - 60 sm
      size: isMobile ? "100%" : "130%", // 130 md - 100 sm
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      stops: [
        [0.1, "#DF5353"], // green
        [0.5, "#81c784"], // yellow
        [0.9, "#55BF3B"], // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70,
      },
      labels: {
        y: 16,
      },
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
  };

  let chartSpeed;

  useEffect(() => {
    chartSpeed = Highcharts.chart(
      "container-speed",
      Highcharts.merge(gaugeOptions, {
        yAxis: {
          min: 0,
          max: max,
          title: {
            text: "Avance",
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Avance",
            data: [data],
            dataLabels: {
              format:
                '<div style="text-align:center">' +
                `<span style="font-size:25px">{y}</span><br/>` +
                '<span style="font-size:12px;opacity:0.4">Avance</span>' +
                "</div>",
            },
            tooltip: {
              valueSuffix: " Avance",
            },
          },
        ],
      })
    );
  }, [max, data]);

  return (
    <Card
      sx={{
        boxShadow: "0px 0px 15px 0px rgb(16 168 255 / 12%)",
        borderRadius: 2,
        backgroundColor: { xs: loading ? "white" : "transparent", sm: "white" },
      }}
    >
      <CardContent
        sx={{
          padding: { xs: 0, sm: 2 },
          paddingBottom: { xs: "0 !important", sm: "16px !important" },
        }}
      >
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.25 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <Grid2>
            <Grid2 container direction="column" alignContent="center">
              <Grid2 item>
                <Typography variant="h6" align="center">
                  Porcentaje de avance nacional
                </Typography>
              </Grid2>
              <Grid2 item>
                <Typography variant="subtitle1" align="center">
                  Última actualización - {moment().format("DD/MM/YYYY HH:mm")}
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2
              id="container-speed" /* sx={{ width: '400px', height: '400px' }} */
            ></Grid2>
          </Grid2>
        )}
      </CardContent>
    </Card>
  );
};

export default SolidGauge;
