import React, { useState, useEffect } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts-drilldown";
import { CircularProgress } from "@mui/material";
drilldown(Highcharts);

export default function BarClick(props) {
	const { drilldown, series, title, loading = false } = props;
	const [data, setData] = useState(null);

	useEffect(() => {
		setData({
			chart: {
				type: "column",
			},
			title: {
				text: title.primary,
			},
			xAxis: {
				type: "category",
			},
			yAxis: {
				title: {
					text: "Promovidos",
				},
			},
			legend: {
				enabled: false,
			},
			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: "{point.y}",
					},
				},
			},
			series: series,
			drilldown: drilldown,
		});
	}, [drilldown, series, title]);

	return data !== null || loading ? (
		<HighchartsReact highcharts={Highcharts} options={data} />
	) : (
		<CircularProgress className="color-componentes" />
	);
}
