import { useState, useEffect, useRef } from "react";
import bbox from "geojson-bbox";

// Mapas
import { MapContainer, TileLayer, GeoJSON, ZoomControl } from "react-leaflet";
import L from "leaflet";
import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";

// Componentes
import CardInfo from "@components/Maps/InternalComponents/Cards/CardInfo";
import MapViewType from "@components/Maps/InternalComponents/Cards/MapViewType";
import ControlFilter from "@components/Maps/InternalComponents/Cards/ControlFilter";
import ControlCenterView from "@components/Maps/InternalComponents/Cards/ControlCenterView";
import CardComponentContainer from "@components/Maps/InternalComponents/Cards/CardComponent";
import CardGeneralInfoWButton from "@components/Maps/InternalComponents/Cards/CardGeneralInfoWButton";

// Estilos
import { MagicSpinner } from "react-spinners-kit";
import { style_spinner } from "@components/Maps/Styles/Spinners";
import { style_rutas, style_original_rutas } from "@components/Maps/Auxiliars/ColorFeature";

// Utils
import { setVars, getVars } from "@utils/global";

const Map = ({
  zoom,
  position,
  data,
  CardInfoFlag,
  CardInfoInitialValues,
  CardComponentFlag,
  CardComponent,
  TitleCardComponent,
  handleClickViewDetailEvent,
  FilterComponentFlag,
  FilterComponent,
  handleChangeSections,
  valuesInfoGeneral,
  polygonsOLD
}) => {
  const geoJsonRef = useRef();
  const _position = position ? position : [20.816157, -101.021312];
  const _cardInfo = CardInfoFlag ? CardInfoFlag : false;
  const _cardInfoInitialValues = CardInfoInitialValues
    ? CardInfoInitialValues
    : {
        title: "INFORMACIÓN",
        elements: [
          {
            title: "Seccion:",
            name: "seccion",
            color: "#000",
            type: "text",
          },
        ],
      };
  const _cardComponentFlag = CardComponentFlag ? CardComponentFlag : false;
  const _cardComponent = CardComponent ? CardComponent : <></>;
  const _filterComponentFlag = FilterComponentFlag
    ? FilterComponentFlag
    : false;
  const _filterComponent = FilterComponent ? FilterComponent : <></>;
  const [_zoom] = useState(zoom ? zoom : 6);
  const [flagShowOldShape, setFlagShowOldShape] = useState(false);
  
  const [_data, setData] = useState(null);
  const [feature, setFeature] = useState({ properties: null });
  const mapRef = useRef();
  const [viewMap, setViewMap] = useState({
    //attribution: "",
    //url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    url: "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga",
    attribution: "",
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  });
  var selectedFeature = null;
  useEffect(() => {
    setFlagShowOldShape(false);
    setFeature({ properties: null });
    setData(data);
    if (geoJsonRef.current) {
      geoJsonRef.current.clearLayers(); // remove old data
      geoJsonRef.current.addData(data); // might need to be geojson.features
    }
  }, [data]);
  const onEachFeature = (feature, layer) => {
    //Organizamos los eventos del GeoJson.
    if (feature.properties[feature.properties.shp] !== undefined) {
      layer.on({
        //mouseout: resetHighlight,
        //mouseover: clickFeature
        click: clickFeature,
      });
    }
  };
  var geojson =
    _data !== null ? (
      <>
        <GeoJSON
          ref={geoJsonRef}
          onEachFeature={onEachFeature}
          style={style_original_rutas}
          key={"geojson"}
          data={_data}
        ></GeoJSON>
      </>
    ) : null;
    var geojson_ex_pol =
    polygonsOLD !== null ? (
      <>
        <GeoJSON
          onEachFeature={()=>{}}
          style={style_original_rutas}
          key={"geojson_ex_pol"}
          data={polygonsOLD}
        ></GeoJSON>
      </>
    ) : null;
  const highlightFeature = (e) => {
    var layer = e.target;

    if (selectedFeature && !e.target.feature.properties.seccion) {
      resetHighlight(selectedFeature);
    }
    setFeature(layer.feature);
    selectedFeature = e;
    const flagCreatePolygon = getVars("flagCreatePolygon", null);
    if (!flagCreatePolygon) {
      return true;
    }
    const DFED = getVars("selectedDFED", null);
    if (
      e.target.feature.properties.Selected === 1 &&
      e.target.feature.properties.Editable === 0
    ) {
      return true;
    }
    if (DFED !== null) {
      if (e.target.feature.properties.DFED !== DFED) {
        //layer.bindTooltip("La sección tiene diferente DFED", { permanent: false, direction: "center" });
        return true;
      }
    }
    if (e.target.feature.properties.seccion) {
      //Vamos a recuperar el array selectedSections del storage para revisar si el feature se encuentra dentro
      let selectedSections = getVars("selectedSections", []);
      let selectedSectionsProperties = getVars(
        "selectedSectionsProperties",
        []
      );

      if (selectedSections.length === 0) {
        setVars("selectedDFED", e.target.feature.properties.DFED);
      }
      const found = selectedSections.find(
        (element) => element === e.target.feature.properties.seccion
      );
      if (found === undefined) {
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        layer.setStyle(style_rutas(layer.feature, true));
        selectedSections.push(e.target.feature.properties.seccion);
        setVars("selectedSections", selectedSections);
        selectedSectionsProperties.push(e.target.feature.properties);
        setVars("selectedSectionsProperties", selectedSectionsProperties);
      } else {
        setVars(
          "selectedSections",
          selectedSections.filter(
            (element) => element !== e.target.feature.properties.seccion
          )
        );
        const arr_selectedSectionsPropertiesTemp =
          selectedSectionsProperties.filter(
            (element) => element.seccion !== e.target.feature.properties.seccion
          );
        setVars(
          "selectedSectionsProperties",
          arr_selectedSectionsPropertiesTemp
        );
        resetHighlight(e);
      }
      const array = getVars("selectedSections", []);
      const array_properties = getVars("selectedSectionsProperties", []);
      handleChangeSections(array, array_properties);
    } else {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
      layer.setStyle(style_rutas(layer.feature, true));
    }
  };
  const clickFeature = (e) => {
    highlightFeature(e);
    //Aqui configuramos lo que queremos que haga un feature cuando le den click.
  };
  const resetHighlight = (e) => {
    //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
    var layer = e.target;

    layer.setStyle(style_rutas(e.target.feature));
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };
  const centerFeature = (map, extent) => {
    map.flyToBounds([
      [extent[1], extent[0]],
      [extent[3], extent[2]],
    ]);
  };
  const whenCreatedExecute = (mapInstance) => {
    let map = mapInstance.target;
    if (!data || data.features.length === 0) return;
    let extent = bbox(data);
    centerFeature(map, extent);
  };
  const handleCenter = () => {
    let extent = bbox(data);
    centerFeature(mapRef.current, extent);
  };

  const handleViewMapType = () => {
    if (viewMap.url === "https://tile.openstreetmap.org/{z}/{x}/{y}.png") {
      setViewMap({
        attribution: "",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      });
    } else if (
      viewMap.url ===
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    ) {
      setViewMap({
        url: "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga",
        attribution: "",
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      });
    } else {
      setViewMap({
        attribution: "",
        url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      });
    }
  };
  return (
    <>
      {data !== null ? (
        <MapContainer
          ref={mapRef}
          center={_position}
          zoom={_zoom}
          zoomControl={false}
          style={{ width: "100%", height: "85vh" }}
          whenReady={whenCreatedExecute}
          fullscreenControl={true}
          fullscreenControlOptions={{
            position: "topright", // cambiar de posicion en el mapa
            title: "¡Mirar en pantalla completa!", // texto al acercar el mouse
            titleCancel: "¡Salir de pantalla completa!", // texto al acercar el mouse en pantalla completa
            content: null, // contenido del button, puede ser html, por defecto es null
            forceSeparateButton: true, // separar del boton de zoom
            forcePseudoFullscreen: false, // pantalla completa del ordenador o del navegador (si está en false toma pantalla del ordenador)
            fullscreenElement: false, // Elemento Dom para renderizar en pantalla completa,  (si está en false recurre el map container)
          }}
        >
          {/* <TileLayer attribution={viewMap.Attribution} url={viewMap.Url}/> */}
          <TileLayer {...viewMap} />
          <ZoomControl position="topright" />
          {geojson}
          
          {flagShowOldShape?geojson_ex_pol:null}
          {_cardInfo ? (
            <CardInfo
              values={feature.properties}
              initialValues={_cardInfoInitialValues}
              handleClickViewDetail={handleClickViewDetailEvent}
            />
          ) : (
            <></>
          )}
          {_cardInfo ? (
            <CardGeneralInfoWButton
              handleShowOldShape={()=>{setFlagShowOldShape(!flagShowOldShape)}}
              values={valuesInfoGeneral}
              initialValues={{
                title: "RESUMEN MUNICIPAL",
                elements: [
                  {
                    title: "Municipio:",
                    name: "MunicipioReportes",
                    color: "#000",
                    type: "text",
                  },
                  {
                    title: "Total Zona:",
                    name: "TotalPoligonos",
                    color: "#000",
                    type: "text",
                  },
                  {
                    title: "Total Secciones:",
                    name: "TotalSecciones",
                    color: "#000",
                    type: "text",
                  },
                  {
                    title: "Con Zona:",
                    name: "TotalSeccionesOcupadas",
                    color: "#000",
                    type: "text",
                  },
                  {
                    title: "Sin Zona:",
                    name: "TotalSeccionesLibres",
                    color: "#000",
                    type: "text",
                  },
                ],
              }}
            />
          ) : (
            <></>
          )}

          {_cardComponentFlag ? (
            <CardComponentContainer
              component={_cardComponent}
              title={TitleCardComponent}
            ></CardComponentContainer>
          ) : (
            <></>
          )}
          {_filterComponentFlag ? (
            <ControlFilter
              component={_filterComponent}
              title={"Filtro"}
            ></ControlFilter>
          ) : (
            <></>
          )}

          <ControlCenterView handleClickEvent={handleCenter} />
          <MapViewType handleClickEvent={handleViewMapType} />
        </MapContainer>
      ) : (
        <div style={style_spinner}>
          <MagicSpinner
            size={70}
            color={"#008000"}
            loading={true}
          ></MagicSpinner>
        </div>
      )}
    </>
  );
};
export default Map;
