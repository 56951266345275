import { ApiExec, ApiExecPublic } from "@utils/ApiExec";
/**
 * Clase para invocar las apis del módulo catálogos
 */

class CatalogService {
	/**
	 * @property {Function} getCatalogs - Función para obtener uno o más catálogos
	 * @param {Array} catalogs - Definiciones de los catálogos a devolver con o sin filtro
	 * @returns {Object}
	 */
	getCatalogs = (catalogs) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExec({ catalogs: catalogs }, "catalogs/get", "POST")
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};

	getCatalogsNacional = (catalogs) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExec(catalogs, "catalogs/nacional/get", "POST")
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};

	getCatalogsNationalMunicipalities = (filtered) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExec(
			{ filtered: filtered },
			"map/national/get-catalogs-national-municipalities",
			"POST"
		)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};

	getCatalogsNationalDFED = (filtered) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExec(
			{ filtered: filtered },
			"map/national/get-catalogs-national-dfed",
			"POST"
		)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};

	getCatalogsNationalDLOC = (filtered) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExec(
			{ filtered: filtered },
			"map/national/get-catalogs-national-dloc",
			"POST"
		)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};

	getCatalogsNationalSections = (filtered) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExec(
			{ filtered: filtered },
			"map/national/get-catalogs-national-sections",
			"POST"
		)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};
	getCatalogsPublic = (catalogs) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExecPublic(catalogs, "app/catalogs/get-public", "POST")
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};

	getCatalogsPublicEvents = (catalogs) => {
		/**
		 * @typedef {Array} catalogs - Arreglo con la definición de cada catálogo
		 * @property {number} id - clave para invocar el catálogo desde la api [obligatorio]
		 * @property {Array} filtered - Arreglo con los valores a filtrar [opcional]
		 * @property {Array} sorted - Arreglo con los valores a ordenar [opcional]
		 * Ejemplo: {"catalogs" : [ {"id": "tipos_movimientos"}, {"id": "tipos_movimientos", filtered: [{id: "tipos_movimientos.id", value: "2"}]} ]}
		 */
		return ApiExecPublic(catalogs, "app/catalogs/get-public-catalogs", "POST")
			.then(function (res) {
				return res;
			})
			.then((res) => {
				/**
				 * @typedef {Object} res
				 * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
				 * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
				 * @property {number} status - Código de respuesta http
				 * @property {string} message - Mensaje para el usuario
				 * @property {Object} response - {catalogs: []} Arreglo con cada uno de los resultados de los catálogos definidos en el envío
				 */
				return res;
			})
			.catch((e) => {
				return e;
			});
	};
}

const catalog = new CatalogService();
export default catalog;
