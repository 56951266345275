import { useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
//Mui
import {
  Box,
  Card,
  CardContent,
  Container,
  Fab,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  Skeleton,
  CircularProgress
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { blue, grey } from "@mui/material/colors";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import AttendanceCard from "@components/Attendance/AttendanceCard";
import Chart from "@components/Prep/ChartColumComparison";
import PrepFilter from "@components/Prep/PrepFilter";
import PrepTable from "@components/Prep/PrepTable";

import prep from "@services/PrepServices";
import { AMBITO_CASILLA, AMBITO_DF, AMBITO_DL, AMBITO_MPAL } from "@data/constants";
import { ELECCION_DIPUTADO_FEDERAL, ELECCION_DIPUTADO_LOCAL } from "@data/constants/Prep";
import { numberWithCommas } from "@utils/Utilities"

const Prep = () => {
  const [series, setSeries] = useState([]);
  const [vista, setVista] = useState(1);
  const [loadingChart, setLoadingChart] = useState(true);
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [summaryData, setSummaryData] = useState(
    {
      TipoEleccion: "",
      TotalActas: 0,
      ActasRecibidas: 0,
      TotalVotos: 0,
      LNom: 0,
      FechaHora: moment().format('DD/MM/YYYY HH:mm'),
      PActas: 0,
      Participacion: 0,
      Ambito: ""
    });
  const [tableTitle, setTableTitle] = useState('');
  const [params, setParams] = useState();


  const handleChangeRadio = (event) => {
    setVista(event.target.value);
  };

  const handleChangeFilter = (filtered) => {

    if (!filtered.idTipoEleccion || !filtered.idAmbitoAgrupador) {
      return Swal.fire({
        icon: 'info',
        title: 'Parámetros insuficiente'
      });
    }

    let params = {
      ...filtered,
      ValorAmbito: filtered.ValorAmbito ? filtered.ValorAmbito : 0,
      idTipoVista: parseInt(vista)
    };

    setParams(params);

    const { idTipoVista, ...paramsSummary } = params;
    getSummaryTotals(paramsSummary);
    handleTableTitle(filtered.idAmbitoAgrupador);

    if (filtered.idAmbitoAgrupador === AMBITO_CASILLA) {
      if (filtered.ValorAmbito === 0) {
        switch (filtered.idTipoEleccion) {
          case ELECCION_DIPUTADO_FEDERAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_DF
            });
            break;
          case ELECCION_DIPUTADO_LOCAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_DL
            });
            break;
          default:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_MPAL
            });
            break;
        }
      } else {
        getChart(params);
      }
    } else {
      getChart(params);
    }
  }

  const handleTableTitle = (idAmbitoAgrupador) => {
    switch (idAmbitoAgrupador) {
      case AMBITO_DF:
        setTableTitle('Mostrando detalle por distrito federal');
        break;
      case AMBITO_DL:
        setTableTitle('Mostrando detalle por distrito local');
        break;
      case AMBITO_MPAL:
        setTableTitle('Mostrando detalle por municipio');
        break;
      case AMBITO_CASILLA:
        setTableTitle('Mostrando detalle por casilla');
        break;
      default:
        setTableTitle('');
        break;
    }
  }

  const getChart = (params) => {
    setLoadingChart(true);
    setLoadingFilter(true);
    prep.getChart(params).then(res => {
      const { results, response, message } = res;
      if (results) {
        setSeries(response.series);
      } else {
        setSeries([]);
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    }).catch(error => {
      setSeries([]);
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    }).finally(() => {
      setLoadingChart(false);
      setLoadingFilter(false);
    });
  }

  const getSummaryTotals = (params) => {
    setLoadingCards(true);
    setLoadingFilter(true);
    prep.getSummary(params).then(res => {
      const { results, response, message } = res;
      if (results) {
        setSummaryData(response);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    }).catch(error => {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    }).finally(() => {
      setLoadingCards(false);
      setLoadingFilter(false);
    });
  }

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Programa de Resultados Electorales Preliminares (PREP)" />
        <Grid2 container spacing={2} marginTop={2}>
          <Grid2 xs={12} md={4}>
            <Box
              component={Card}
              className="card-primary"
              height={"91%"}
              display="flex"
              flexDirection={"column"}
              alignContent={"center"}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="center"
                marginTop={2}
              >
                <Icon sx={{ color: grey[600] }}>newspaper</Icon>
                <Typography marginTop={2} fontWeight={600} variant="h6">
                  Elección
                </Typography>
              </Stack>

              <Box component={CardContent}>
                <Box display={"flex"} alignItems="center" gap={2}>
                  <div>
                    <Box
                      width={"90px"}
                      height={"90px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      margin={"10px"}
                      sx={{ backgroundColor: blue[50], borderRadius: "50%" }}
                    >
                      <Icon sx={{ fontSize: "3rem", color: blue[800] }}>
                        description
                      </Icon>
                    </Box>
                  </div>
                  {
                    loadingCards ? (
                      <CircularProgress
                        sx={{ color: 'blue', m: 1 }}
                        size={15}
                      />
                    ) : (
                      <div>
                        <Typography
                          variant="Subtitle2"
                          fontWeight={600}
                          fontSize={18}
                        >
                          {summaryData.TipoEleccion}
                        </Typography>
                        <Typography variant="body2">{summaryData.Ambito}</Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          marginTop={1}
                        >
                          <Icon fontSize="small" sx={{ color: grey[600] }}>
                            calendar_today
                          </Icon>
                          <Typography variant="body2">
                            {summaryData.FechaHora}
                          </Typography>
                        </Stack>
                      </div>
                    )
                  }

                </Box>
              </Box>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={4}>
            <AttendanceCard
              titlecontent={""}
              data={{
                contentData: summaryData.PActas,
                leftFooterData: numberWithCommas(summaryData.ActasRecibidas),
                rightFooterData: numberWithCommas(summaryData.TotalActas),
              }}
              config={{
                titleHeader: "Actas Recibidas",
                leftFooterTitle: "Recibidas",
                rightFooterTitle: "Total de Actas",
                icon: "description",
              }}
              loading={loadingCards}
            />
          </Grid2>
          <Grid2 xs={12} md={4}>
            <AttendanceCard
              titlecontent={""}
              data={{
                contentData: summaryData.Participacion,
                leftFooterData: numberWithCommas(summaryData.TotalVotos),
                rightFooterData: numberWithCommas(summaryData.LNom),
              }}
              config={{
                titleHeader: "Participación Ciudadana",
                leftFooterTitle: "Votantes",
                rightFooterTitle: "Listado Nominal",
                icon: "group",
              }}
              loading={loadingCards}
            />
          </Grid2>
        </Grid2>

        <Box component={Card} className="card-primary">
          <Box component={CardContent}>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={vista}
              onChange={handleChangeRadio}
              sx={{ mb: 2, display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Vista por candidatos"
                labelPlacement="end"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Vista por partidos"
                labelPlacement="end"
              />
            </RadioGroup>

            {
              loadingChart ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"} height={400} />
              ) : (
                <Chart
                  Title={summaryData.TipoEleccion}
                  Subtitle={summaryData.Ambito}
                  Series={series}
                  tipoVista={vista}
                  loading={loadingChart}
                  percent={true}
                />
              )
            }
          </Box>
        </Box>

        <PrepFilter onChangeFilter={handleChangeFilter} disabledButtons={loadingFilter} />

        <PrepTable title={tableTitle} params={params} setLoadingFilter={setLoadingFilter} />

        <Box position={"fixed"} bottom={"24px"} right={"24px"} zIndex={10}>
          <Tooltip title="Actualizar">
            <Fab
              size={"small"}
              aria-label={"refresh"}
              color={"primaryDark"}
              sx={{ "&:hover": { backgroundColor: blue[900] } }}
            >
              <Icon>sync</Icon>
            </Fab>
          </Tooltip>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Prep;
