import ApiExec from "@utils/ApiExec";


class ScheduleServices {

    getSchedules = (params) => {
        return ApiExec(params, "system/get-schedules", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    }

    setSchedules = (params) => {
        return ApiExec(params, "system/set-schedules", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    }

    deleteScheduleException = (params) => {
        return ApiExec(params, "system/delete-schedule-exception", "DELETE")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    }

}

const schedules = new ScheduleServices();
export default schedules;