import {
  Paper,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  CardActions,
} from "@mui/material";
import Logo_privacy from "@assets/img/Logo_privacy.png";

const SimplePrivacyPolicy = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      padding={4}
    >
      <Card
        className="card-primary"
        sx={{
          maxWidth: 550,
        }}
      >
        <Box className="color-principal" width={"100%"}>
          <Stack
            direction={"row"}
            spacing={2}
			justifyContent={"center"}
            alignItems={"center"}
          >
            <Box component={"img"} src={Logo_privacy} width={80} height={80} />
            <Box>
              <Typography color={"white"} fontWeight={600} variant="h5">
                Aviso de Privacidad Integral
              </Typography>
            </Box>
          </Stack>
        </Box>
        <CardContent sx={{ padding: "40px 20px" }}>
          <List>
            <ListItem>
              <ListItemText
                primary="I. Nombre del responsable:"
                secondary="El Partido Verde Ecologista de México hace de su conocimiento sus derechos y obligaciones previstos en la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, la responsabilidad de su uso y tratamiento."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="II. Domicilio del responsable:"
                secondary="La oficina ubicada en la calle a José Iriarte #34 interior 7, colonia Reforma, C. P. 22183, Tijuana, Baja California."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="II. Domicilio del responsable:"
                secondary="La oficina ubicada en la calle a José Iriarte #34 interior 7, colonia Reforma, C. P. 22183, Tijuana, Baja California."
              />
            </ListItem>
            <ListItem>
              <Grid conteiner>
                <Grid item xs={12}>
                  <ListItemText
                    primary="III. Datos personales que se recaban:"
                    secondary="Los datos personales que se recabarán son los siguientes:"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 500 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow className="color-principal">
                          <TableCell align="center">Tipo</TableCell>
                          <TableCell align="center">Descripción</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={"Tipo"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          align="center"
                        >
                          <TableCell component="th" scope="row">
                            De identificación:
                          </TableCell>
                          <TableCell align="right">
                            <List dense>
                              <ListItem>
                                <ListItemText primary="Clave de elector" />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Vigencia de la credencial para votar" />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Sección en que vota" />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Nombre" />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Apellidos" />
                              </ListItem>
                            </List>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          key={"descripcion"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          align="center"
                        >
                          <TableCell component="th" scope="row">
                            De contacto:
                          </TableCell>
                          <TableCell>
                            <List dense>
                              <ListItem>
                                <ListItemText primary="Domicilio" />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Teléfono celular" />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Teléfono particular" />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Correo electrónico" />
                              </ListItem>
                            </List>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="IV. Finalidad"
                secondary="Los Datos Personales solicitados, son recabados con la finalidad desconocer su interés en sumarse a las campañas del partido, como promotor de sus propuestas, o bien defensor de las candidaturas ante las mesas directivas de casilla que se instalarán el próximo 2 de junio de 2024."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="V. Fundamento legal"
                secondary="Los datos personales se recaban de conformidad con la Ley General de Instituciones y Procedimientos Electorales, la Ley General de Partidos Políticos, la Ley General de Protección de Datos Personales en Posesión de los Sujetos Obligados, la Ley Federal de Transparencia y Acceso a la Información Pública, así como la Ley General de Transparencia y Acceso a la Información Pública."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VI. Transferencia de la información"
                secondary="Se informa que no se realizarán transferencias de datos personales, salvo que se actualice alguna de las excepciones previstas en el artículo 70 de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados."
              />
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListItemText primary="VII. Derecho al acceso, rectificación, cancelación y oposición de sus datos personales (derechos ARCO)" />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography variant="body2"  fontWeight="bold">
                      Acceso.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Podrá acceder a sus datos personales que obren en posesión
                      del responsable, así como conocer la información
                      relacionada con las condiciones y generalidades de su
                      tratamiento.
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
				<Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography variant="body2"  fontWeight="bold">
					Rectificación.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
					Es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta.
                    </Typography>
                  </Stack>
				</Grid>
                <Grid item xs={12}>
				<Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography variant="body2"  fontWeight="bold">
					Cancelación.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
					También podrá requerir que sus datos se eliminen de nuestros registros o bases de datos cuando considere que la misma no esté siendo utilizada adecuadamente.
					 </Typography>
                  </Stack>
				</Grid>
                <Grid item xs={12}>
				<Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography variant="body2"  fontWeight="bold">
					Oposición. 
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
					Además, podrá oponerse al uso de sus datos personales para fines específicos.
					 </Typography>
                  </Stack>
				</Grid>
                <Grid item xs={12}>
				<Typography variant="body2" color="textSecondary">
				Si desea acceder, rectificar, cancelar u oponerse al uso de sus Datos Personales podrá ejercer de manera gratuita y en cualquier momento su derecho de acceso, rectificación, cancelación u oposición de sus Datos Personales (Derechos ARCO) previstos en la Ley General de Protección de Datos Personales en Posesión de los Sujetos Obligados, podrá presentar un escrito libre en la oficina del partido, señalado en el punto II anterior.
				</Typography>
				</Grid>
                <Grid item xs={12}>
				<Typography variant="body2" color="textSecondary">
				El escrito deberá ir acompañado de una copia simple de un documento oficial que acredite su identidad e incluir una descripción clara y precisa de los Datos Personales respecto de los cuales ejercitará cualquiera de los derechos que le confiere la Ley, así mismo deberá proporcionar un correo electrónico, número telefónico y dirección para oír y recibir notificaciones a fin de comunicarle la respuesta a su petición y cualquier otra información que facilite la búsqueda y localización de sus datos para los fines requeridos.
				</Typography>
				</Grid>
              </Grid>
            </ListItem>
            <ListItem>
			<Grid container spacing={2}>
				<Grid item xs={12}>
				<ListItemText primary="VIII. Modificaciones al aviso de privacidad" />
				</Grid>
				<Grid item xs={12}>
				<Typography variant="body2" color="textSecondary">
				El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de las propias necesidades; o bien de las prácticas de privacidad, o por otras causas.
				</Typography>
				</Grid>
				<Grid item xs={12}>
				<Typography variant="body2" color="textSecondary">
				De efectuarse cambios al presente Aviso de Privacidad Integral, se darán a conocer a través de esta página de internet: https://www.partidoverde.org.mx.
				</Typography>
				</Grid>
			</Grid>
            </ListItem>
          </List>
        </CardContent>
		<CardActions><Typography>Fecha de actualización:</Typography> <Typography>20/03/2024</Typography></CardActions>
      </Card>
    </Box>
  );
};

export default SimplePrivacyPolicy;
