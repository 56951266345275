import React from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Icon,
  Chip,
} from "@mui/material";
import middleware from "@middlewares/middleware";
import { yellow } from "@mui/material/colors";

const RoleListItem = ({
  item,
  selectedIndex,
  handleSelectedIndex,
  handleDeleteItem,
}) => {
  //  const canDelete = middleware.checkMenuAction('Eliminar');
  const handleListItemClick = (event, index) => {
    let data = item;
    handleSelectedIndex(index, data);
  };

  return (
    <>
      <ListItem>
        <ListItemButton
          selected={selectedIndex === item.id}
          onClick={(event) => handleListItemClick(item, item.id)}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: yellow[900] }}>
              <Icon>assignment_ind</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={item.NombreCompleto}
            secondary={item.Responsabilidad ? <Chip label={item.Responsabilidad} color="primary" variant="outlined" /> : ""}
          />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default RoleListItem;
