import { Box, Card, CardContent, Icon, Stack, Typography,CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
const CardsPorcent = ({ icon, title, total, subtitle, subIcon, loading,component }) => {
  return (
    <Card className="card-primary">
      <CardContent>
        <Stack direction="row" spacing={3} alignItems="center">
          {
             component ? component:
             <Box
             width={"90px"}
             height={"90px"}
             display={"flex"}
             justifyContent={"center"}
             alignItems={"center"}
             margin={"10px"}
             sx={{ backgroundColor: blue[50], borderRadius: "50%" }}
           >
               <Icon sx={{ fontSize: "3rem", color: blue[800] }}>{icon}</Icon>
   
           </Box>
          }
          <Box margin={"10px"}>
            <Typography variant="body2" fontWeight={500}>
              {title}
            </Typography>
            {
              loading ? <CircularProgress className="color-componentes"/>
              : <Typography variant="h3" fontWeight={800}>
              {total}
            </Typography>
            }
           
            <Typography
              variant="h5"
              component="subtitle2"
              fontWeight={300}
              display={"flex"}
              alignItems={"center"}
            >
              {subtitle}
              <Icon sx={{ fontSize: "1rem", mr: 1, color: blue[800] }}>
                {subIcon}
              </Icon>
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default CardsPorcent;
