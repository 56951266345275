import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Stack,
  ToggleButtonGroup,
  Icon,
  ToggleButton,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import "moment/locale/es";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import SolidGauge from "@components/Reports/SolidGauge";
import TabsOptions from "@components/Reports/TabsOptions";
import Chart from "@components/Reports/Chart";
import Map from "@components/Reports/Map";
import ReportsContext from "@components/Reports/ReportsContext";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import report from "@services/ReportsServices";
import Swal from "sweetalert2";
import HistoricalCard from "@components/Reports/CardContenet";
import FilterMap from "@components/Reports/FilterMap";
import BasicTable from "@components/Tables/BasicTable";
import { numberWithCommas } from "@utils/Utilities/";
import { blue } from "@mui/material/colors";
import { DEFAULT_OPTION, COLUMNS_TABLE } from "@data/constants/Reports";

const DashboardNational = () => {
  const [messages] = useState([
    { mensaje: "Top Mejores Estados - Compromisos", type: "" },
    { mensaje: "Top Peores Estados - Compromisos", type: "" },
  ]);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(true);
  const [isSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(true);
  const [totals, setTotals] = useState({
    Ejercicio: "",
    avance: 0,
    Meta: 0,
  });
  const [bestTopList, setBestTopList] = useState([]);
  const [worstTopList, setWorstTopList] = useState([]);
  const [data, setData] = useState({
    historico: 0,
    esperado: 0,
    capturadoHoy: 0,
    capturadoAyer: 0,
  });
  const [viewOption, setViewOption] = useState("map");
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 25,
  });
  const [hasEntity, setHasEntity] = useState(false);
  /* const [body, setBody] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
    type: 1,
  }); */
  const [filterValue, setFilterValue] = useState({
    group: DEFAULT_OPTION.value,
    entity: DEFAULT_OPTION.value,
    df: DEFAULT_OPTION.value,
    dfPriority: DEFAULT_OPTION.value,
  });

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const type = tabValue + 1;

      await getTotals(type);
      await getBestTopTen(type);
      await getWorstTopTen(type);
      await getStatsTable(type);

      setLoading(false);
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    };

    init();
  }, [tabValue]);

  const getTotals = async (type) => {
    try {
      const result = await report.getTotals({ type });
      const {
        results,
        response: { data },
        message,
      } = result;

      if (results) {
        setTotals(data);
      } else {
        setTotals({
          Ejercicio: "",
          avance: 0,
          Meta: 0,
        });

        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
    }
  };

  const getBestTopTen = async (type) => {
    try {
      const result = await report.getBestTopTen({ type });
      const {
        results,
        response: { data },
        message,
      } = result;

      if (results) {
        setBestTopList(data);
      } else {
        setBestTopList([]);

        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
    }
  };

  const getWorstTopTen = async (type) => {
    try {
      const result = await report.getWorstTopTen({ type });
      const {
        results,
        response: { data },
        message,
      } = result;

      if (results) {
        setWorstTopList(data);
      } else {
        setWorstTopList([]);

        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
    }
  };

  const getStatsTable = async (type) => {
    try {
      const result = await report.getStatsTable({ type });
      const {
        results,
        response: { data, total },
        message,
      } = result;

      if (results) {
        setRows(
          data.map((item) => ({
            ...item,
            estado: item.estado,
            meta: numberWithCommas(item.meta),
            value: numberWithCommas(item.value),
          }))
        );
        setTotal(total);
      } else {
        setRows([]);
        setTotal(0);
        throw new Error(message);
      }
    } catch (error) {
      setRows([]);
      setTotal(0);
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    }
  };

  const handleLoading = (loadingValue) => {
    setIsLoadingForm(loadingValue);
    setOpenLinearProgress(loadingValue);
  };

  const handleViewChange = (event, newValue) => {
    if (newValue !== null) {
      setViewOption(newValue);
    }
  };

  const handleChangePagination = ({ page, pageSize }) => {};

  const handleChangeSort = (e) => {};

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Reporte Nacional" />
        <LoadingForm
          loadinMessage={"Consultando..."}
          successMessage="¡Consultado con éxito!"
          isLoading={isLoadigForm}
          success={isSuccessFormSave}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        />

        <ReportsContext.Provider
          value={{ setIsLoadingForm, setOpenLinearProgress, handleLoading }}
        >
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={3} lg={4}>
                <HistoricalCard
                  data={data}
                  title={"Al momento"}
                  firstTitle={"Capturados hoy"}
                  secondTitle={"Capturados ayer"}
                  loading={isLoadigForm}
                />
              </Grid2>
              <Grid2 xs={12} md={3} lg={4}>
                <HistoricalCard
                  data={data}
                  title={"Días transcurridos: 22"}
                  firstTitle={"Acumulados"}
                  secondTitle={"Esperados"}
                  lastTitle={"Días transcurridos"}
                  showLast={true}
                  loading={isLoadigForm}
                />
              </Grid2>
              <Grid2 xs={12} md={3} lg={4}>
                <SolidGauge
                  max={parseInt(totals.Meta)}
                  data={parseInt(totals.avance)}
                  title={"Porcentaje de avance nacional"}
                  //loading={isLoadigForm}
                />
              </Grid2>
            </Grid2>
          </Box>
          <TabsOptions
            tabValue={tabValue}
            setTabValue={setTabValue}
            setLoading={setLoading}
          />
          <Box>
            <Grid2 container spacing={2}>
              {!hasEntity && (
                <Grid2 xs={12} md={4} lg={4}>
                  <Stack direction={"column"}>
                    <Chart
                      vertical={bestTopList.map((item) => item.estado)}
                      horizontal={bestTopList.map((item) => item.value)}
                      message={messages[0]}
                      loading={loading}
                      graphType={1}
                    />
                    <Chart
                      vertical={worstTopList.map((item) => item.estado)}
                      horizontal={worstTopList.map((item) => item.value)}
                      message={messages[1]}
                      loading={loading}
                      graphType={3}
                    />
                  </Stack>
                </Grid2>
              )}
              <Grid2 xs={12} md={!hasEntity ? 8 : 12} lg={!hasEntity ? 8 : 12}>
                <Card className="card-primary">
                  <CardContent>
                    <Grid2 container>
                      <Grid2 item xs={12} lg={2}>
                        <FilterMap
                          filterValue={filterValue}
                          setFilterValue={setFilterValue}
                          setHasEntity={setHasEntity}
                        />
                      </Grid2>
                      <Grid2 item xs={12} lg={8}>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          marginBottom={2}
                        >
                          <ToggleButtonGroup
                            value={viewOption}
                            onChange={handleViewChange}
                            exclusive
                            size="small"
                            aria-label="type-view"
                          >
                            <ToggleButton value="map" aria-label="map">
                              <Icon sx={{ color: blue[800] }}>map</Icon>{" "}
                              <Typography variant="body2" fontWeight={600}>
                                Mapa
                              </Typography>
                            </ToggleButton>
                            <ToggleButton value="table" aria-label="table">
                              <Icon sx={{ color: blue[800] }}>table_view</Icon>{" "}
                              <Typography variant="body2" fontWeight={600}>
                                Tabla
                              </Typography>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Grid2>
                    </Grid2>
                    <Grid2 container spacing={1}>
                      <Grid2 item xs={12}>
                        {viewOption === "map" ? (
                          <Map best={bestTopList} worst={worstTopList} />
                        ) : (
                          <BasicTable
                            rows={rows}
                            hcolumns={COLUMNS_TABLE}
                            isLoading={loading}
                            stickyHeader={true}
                            showPagination={true}
                            total={total}
                            pageProp={pagination.page}
                            pageSize={pagination.pageSize}
                            handleChangePagination={handleChangePagination}
                            pagination={[5, 10, 25]}
                            manual={true}
                            handleManualSort={handleChangeSort}
                          />
                        )}
                      </Grid2>
                    </Grid2>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </Box>
        </ReportsContext.Provider>
      </Container>
    </AdminLayout>
  );
};

export default DashboardNational;
