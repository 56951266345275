import { useState, Fragment } from "react";
//MUI
import {
  Popper,
  Typography,
  Card,
  Box,
  Button,
  Tooltip,
  IconButton,
  Icon,
  CircularProgress,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const FilterPopper = ({
  children,
  handleSearchFilter,
  handleClearFilter,
  isLoading,
  width,
  zIndex,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const index = zIndex ? zIndex : 1;
  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <Fragment>
      {!isLoading ? (
        <Tooltip title={open ? "Cerrar" : "Filtros"}>
          <IconButton
            size="small"
            aria-describedby={id}
            onClick={handleToggle}
            sx={{ backgroundColor: open && grey[200] }}
            disabled={isLoading}
          >
            <Icon sx={{ color: grey[900] }}>
              {open ? "close" : "filter_list"}
            </Icon>
          </IconButton>
        </Tooltip>
      ) : (
        <CircularProgress sx={{ color: grey[900], m: 1 }} size={25} />
      )}

      <Popper
        style={{
          zIndex: index,
          position: "absolute",
          width: width ? width : "auto",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        disablePortal
        placement="bottom-end"
      >
        <Box component={Card} padding={2} boxShadow={4} borderRadius={3}>
          <Typography variant="subtitle1" fontWeight={500} marginBottom={2}>
            Filtros
          </Typography>

          {children}

          <Box display={"flex"} justifyContent={"flex-end"} marginTop={2} gap={1}>
            <Button
              color="primaryDark"
              size="small"
              onClick={handleClearFilter}
            >
              Limpiar
            </Button>

            <Button
              variant="contained"
              color="primaryDark"
              size="small"
              onClick={(e) => {
                handleSearchFilter(e);
                handleToggle(e);
              }}
            >
              Filtrar
            </Button>
          </Box>
        </Box>
      </Popper>
    </Fragment>
  );
};

export default FilterPopper;
