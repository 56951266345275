import { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Filter from "@components/MapsModules/AdvanceElectoral/Filter";
import CardInfoMini from "@components/Maps/InternalComponents/Cards/CardInfoMini";
import Map from "@components/Maps/CustomComponents/GenericMap";

// Servicios y utilidades
import MapServices from "@services/MapServices/NationalServices";
import CatalogService from "@services/CatalogServices";
import { setVars } from "@utils/global";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

const commonElements = [
  { title: "LNOM: ", name: "Lnom", type: "int" },
  { title: "LNOM M:", name: "LnomM", type: "int" },
  { title: "LNOM H:", name: "LnomH", type: "int" },
  { title: "Meta:", name: "meta", type: "int" },
  { title: "Avance:", name: "avance", type: "int" },
  { title: "% Avance:", name: "porcentaje", type: "text" },
];

const elements = {
  municipio: [{ title: "Municipio: ", name: "municipio", type: "text" }, ...commonElements],
  entidad: [{ title: "Entidad: ", name: "entidad", type: "text" }, ...commonElements],
};

const Maps = () => {
  const [polygons, setPolygons] = useState(null);

  const [polygonsEstados, setPolygonsEstados] = useState(null);
  // const [polygonsMunicipios, setPolygonsMunicipios] = useState(null);

  const { height } = useWindowDimensions();

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [catalogs, setCatalogs] = useState(null);

  const [currentFeature, setCurrentFeature] = useState(null);

  const loadCatalogs = useCallback(async () => {
    const params = [
      { id: "entidades", getAll: false },
      { id: "municipios", getAll: false },
      { id: "perfil", getAll: false },
    ];

    setIsLoadingCatalogs(true);
    try {
      const result = await CatalogService.getCatalogs(params);
      const { results, response, message } = result;
      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
            icon: "warning",
          });
        }

        const catalogsRes = response.catalogs;
        const TODOS = [{ value: 0, label: "TODOS" }];

        // Obtiene los tipos unicos de Perfil
        const uniqueTypesSet = new Set();
        const tipos = catalogsRes.perfil.reduce((result, item) => {
          if (!uniqueTypesSet.has(item.idTipo)) {
            uniqueTypesSet.add(item.idTipo);
            result.push({
              value: item.value,
              label: `Tipo ${item.idTipo}`,
              idTipo: item.idTipo,
            });
          }
          return result;
        }, []);

        setCatalogs({
          entidades: catalogsRes?.entidades ? TODOS.concat(catalogsRes.entidades.filter((item) => item.value <= 40)) : [],
          municipios: catalogsRes.municipios ? TODOS.concat(catalogsRes.municipios) : [],
          perfil: catalogsRes.perfil ? TODOS.concat(catalogsRes.perfil) : [],
          tipos: TODOS.concat(tipos.sort((a, b) => a.idTipo - b.idTipo)),
        });
      } else Swal.fire({ title: message, icon: "warning" });
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsLoadingCatalogs(false);
    }
  }, []);

  useEffect(() => {
    setVars("filterSavedMap", {
      idEstado: "",
      idMunicipio: "",
      idPerfil: "",
      idTipo: "",
    });
    setVars("filterSavedMapPerfil", []);
    setVars("filterSavedMapMunicipio", []);
    loadCatalogs();

    const params = {
      color: 0,
      page: 0,
      pageSize: 10,
      filtered: [],
      shp: "entidad",
      idEntidad: 0,
    };

    fetchMapData(params, MapServices.getShapes, setPolygonsEstados);
    // eslint-disable-next-line
  }, []);

  // Pone el mapa que carga inicialmente
  useEffect(() => {
    if (polygonsEstados) setPolygons(polygonsEstados);
    // eslint-disable-next-line
  }, [polygonsEstados]);

  const handleFilter = (filterData, action) => {
    console.log(action, ": ", filterData);

    if (filterData.idEstado > 0) {
      const filterPoli = polygonsEstados.features.filter((item) => item.id === filterData.idEstado);
      setCurrentFeature(filterPoli[0].properties);

      setPolygons(() => {
        return {
          ...polygonsEstados,
          features: filterPoli,
        };
      });
    } else {
      setPolygons(polygonsEstados);
      setCurrentFeature(null);
    }

    if (action === "clear") setCurrentFeature(null);
  };

  const handleClickFeature = (e, params) => {
    if (params.sameFeature) setCurrentFeature(null); // Se resetea al clickear el mismo feature
    else setCurrentFeature(e.target.feature);
  };

  const FilterComponent = (
    <>
      <Filter catalogs={catalogs} isLoadingCatalogs={isLoadingCatalogs} handleFilter={handleFilter} />
      {currentFeature && (
        <CardInfoMini properties={currentFeature.properties} elements={elements[polygons?.shp ?? "entidad"]} title={"INFORMACIÓN"} />
      )}
    </>
  );

  const handleViewDetail = (e) => console.log("ViewDetail");

  const fetchMapData = async (params, API, setState) => {
    setState(null);
    try {
      const result = await API(params);
      const { results, response, message } = result;
      if (results) {
        setState(response.data);
      } else Swal.fire({ title: message, icon: "warning" });
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  const drawerLeft = {
    open: true,
    title: "Filtros",
    component: FilterComponent,
    width: { sm: 300, md: 350, lg: 500, xl: 600 }, // Solo valores variables o vacio (se ajusta al contenido)
  };

  const cardInfo = {
    initialValues: {
      title: "INFORMACIÓN",
      elements: elements[polygons ? polygons.shp : "region"],
    },
    handleClick: handleViewDetail,
  };

  return (
    <AdminLayout delPadding={true}>
      <Map
        height={height - 65}
        data={polygons}
        drawerLeft={drawerLeft}
        cardInfo={cardInfo}
        handleClickFeature={handleClickFeature}
        centerByFeatures
      />
    </AdminLayout>
  );
};

export default Maps;
