import {ApiExec, ApiExecDecrypted} from "../../utils/ApiExec";

class MapService {
  getSections = (params) => {
    return ApiExecDecrypted(params, "map/promovidos/get-sections", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getBlocks = (params) => {
    return ApiExecDecrypted(params, "map/promovidos/get-blocks", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getInformationGeneral = (params) => {
    return ApiExec(params, "map/promovidos/get-information-general", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }
}
var map = new MapService();
export default map;
