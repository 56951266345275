import { useEffect, useState } from "react";
import { useFormik } from "formik";

// Material UI
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Componentes
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Form from "@components/Roles/Form";
import Seleccion from "@components/Roles/Seleccion";

// Servicios
import RoleService from "@services/RoleServices";
import { AgregarPerfilInterface } from "@data/interfaces/RoleInterfaces";
import { AgregarPerfilSchema } from "@data/schemas/RoleSchemas";

const RoleEdit = (props) => {
  const { data, change, setChange } = props;

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [loadigMessage, setLoadigMessage] = useState("Cargando...");
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave] = useState(false);
  const [formPrev, setFormPrev] = useState(false);

  // Al cargar mas datos deshabilitar el seleccionar todos
  const [isLoadingMore1, setIsLoadingMore1] = useState(false);
  const [isLoadingMore2, setIsLoadingMore2] = useState(false);

  // Total de elementos
  const [total, setTotal] = useState(0);

  // Todos los datos sin filtrar
  const [allHijos, setAllHijos] = useState("");
  const [allPadres, setAllPadres] = useState("");

  // Se usa para desabilitar los campos del componente contrario
  const [disable1, setDisable1] = useState(false);
  const [disable2, setDisable2] = useState(false);

  // Se definen los datos que tiene cada componente de selección
  const [filteredData1, setFilteredData1] = useState("");
  const [filteredData2, setFilteredData2] = useState("");

  // Los datos que se guardan en la columna derecha
  const [newHijos, setNewHijos] = useState([]);
  const [newPadres, setNewPadres] = useState([]);

  // Paginación
  const [page1, setPage1] = useState(0);
  const [hasMore1, setHasMore1] = useState(true);

  const [page2, setPage2] = useState(0);
  const [hasMore2, setHasMore2] = useState(true);

  const pageSize = 10;

  const fetch1 = {
    setPage: setPage1,
    setHasMore: setHasMore1,
    setFilteredData: setFilteredData1,
    setIsLoadingMore: setIsLoadingMore1,
    setAllData: setAllHijos,
    allData: allHijos,
    checkData: newPadres,
  };

  const fetch2 = {
    setPage: setPage2,
    setHasMore: setHasMore2,
    setFilteredData: setFilteredData2,
    setIsLoadingMore: setIsLoadingMore2,
    setAllData: setAllPadres,
    allData: allPadres,
    checkData: newHijos,
  };

  // Datos del formulario
  const formik = useFormik({
    initialValues: AgregarPerfilInterface,
    validationSchema: AgregarPerfilSchema,
    onSubmit: (values, actions) => {
      handleVerification(values);
    },
  });

  // Formato de datos para el componente de seleccion
  const formatData = (data) => {
    const dataEdited = data.map((item) => ({
      id: item.value,
      Perfil: item.label,
      idTipo: item.idTipo,
      Tipo: item.Tipo,
      check: 1,
    }));
    return dataEdited;
  };

  // Llamada a la API buscando los datos que ya tiene el usuario
  const fetchPrevData = async (params) => {
    setIsLoadingForm(true);
    setLoadigMessage("Cargando...");
    setOpenLinearProgress(true);
    try {
      const result = await RoleService.getParentRoles(params);
      const result2 = await RoleService.getChildrenRoles(params);
      const result3 = await RoleService.getRole(params.idPerfil);

      const { results, message, response } = result;
      if (results && result2.results && result3.results) {
        const formD = result3.response;

        // Llenado de datos del Formik
        formik.setFieldValue("TipoPerfil", formD.idTipoPerfil || "");
        formik.setFieldValue("AmbitoCargo", formD.AmbitoCargo || []);
        formik.setFieldValue("NombrePerfil", formD.NombrePerfil || "");
        formik.setFieldValue("EtiquetaCargo", formD.EtiquetaCargo || "");
        formik.setFieldValue("Descripcion", formD.Descripcion || "");
        formik.setFieldValue("PerfilHijo", result2.response.data || []);
        formik.setFieldValue("PerfilPadre", response.data || []);

        // Agrega los roles que ya se le asignaron
        setNewHijos(formatData(result2.response.data || [])); // Childrens
        setNewPadres(formatData(response.data || [])); // Parents

        // Define que si hay datos previos
        setFormPrev(true);
      } else {
        Swal.fire({
          title: !results
            ? "(padre) " + message
            : !result2
            ? "(hijo) " + result2.message
            : "(formulario) " + result3.message,
          icon: "warning",
        });
        setNewHijos([]);
        setNewPadres([]);
      }
    } catch (errors) {
      Swal.fire({ title: errors.message, icon: "warning" });
      setNewHijos([]);
      setNewPadres([]);
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  // Verifica si cambia el ID para traer los datos nuevos
  useEffect(() => {
    if (data.id) {
      const params = {
        page: 0,
        pageSize: 1000,
        idPerfil: data.id,
      };

      fetchPrevData(params);
    }
  }, [data.id]);

  const fetchData = async ({
    type,
    params,
    setPage,
    setHasMore,
    setFilteredData,
    setAllData,
    allData,
    checkData,
    setIsLoadingMore,
  }) => {
    // Ve que tipo de peticion es
    const Normal = type === "fetchNormal";
    const Search = type === "fetchSearch";
    const HasMore = type === "fetchHasMore";

    const localParams = {
      ...params,
      filtered: [
        {
          id: "perfiles.id",
          filter: "<>",
          value: data.id,
        },
      ],
    };

    setIsLoadingMore(true);
    try {
      const result = await RoleService.getRoles(localParams);
      const { results, message, response } = result;

      if (results) {
        // Une los datos nuevos con los anteriores (usa set para evitar duplicados)
        const allNewData = Search
          ? response.data // Solo guarda los datos filtrados
          : [...new Set([...allData, ...response.data])];
        // Filtra los datos para evitar duplicidad
        if (allNewData) {
          const filtered = allNewData.filter(
            (item) => !checkData.find((newItem) => newItem.id === item.id)
          );
          setFilteredData(filtered);
        }

        // Almacena los datos totales agregados hasta el momento
        if (HasMore || Normal) setAllData(allNewData);

        // Almacena el total de datos
        if (Normal) setTotal(response.total);

        // Verifica si hay mas datos que mostrar
        const end = params.page + 1 * params.pageSize;
        if (params.page === total) setHasMore(true); // (entra si search es "")
        else if (end >= response.total) setHasMore(false);
        else setHasMore(true);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
        setHasMore(false);
      }
    } catch (errors) {
      Swal.fire({ title: errors.message, icon: "warning" });
      setHasMore(false);
    } finally {
      // Desabilita los checkAll si esta cargando datos
      setTimeout(() => setIsLoadingMore(false), 1000);
      // Agrega la siguiente pagina
      if (HasMore) setPage(params.page);
    }
  };

  // Primera llamada a la API
  useEffect(() => {
    const params = {
      page: 0,
      pageSize,
    };
    fetchData({
      type: "fetchNormal",
      params,
      ...fetch1,
    });
    fetchData({
      type: "fetchNormal",
      params,
      ...fetch2,
    });
  }, []);

  const fetchHasMore1 = () => {
    const params = {
      page: page1 + 1,
      pageSize,
    };
    fetchData({
      type: "fetchHasMore",
      params,
      ...fetch1,
    });
  };

  const fetchHasMore2 = () => {
    const params = {
      page: page2 + 1,
      pageSize,
    };
    fetchData({
      type: "fetchHasMore",
      params,
      ...fetch2,
    });
  };

  // Buscador del componente 1
  const handleSearch1 = (searchInput) => {
    if (searchInput === "") {
      const params = {
        page: total,
        pageSize,
      };

      fetchData({
        type: "fetchNormal",
        params,
        ...fetch1,
      });
    } else {
      // Llenado de los datos del filtro
      const params = {
        page: 0,
        pageSize: total,
        filtered: [
          {
            id: "perfiles.Perfil",
            filter: "LIKE",
            value: searchInput,
            inheritFilterType: null,
          },
        ],
      };

      fetchData({
        type: "fetchSearch",
        params,
        ...fetch1,
      });
    }
  };

  // Buscador del componente 2
  const handleSearch2 = (searchInput) => {
    if (searchInput === "") {
      const params = {
        page: total,
        pageSize,
      };

      fetchData({
        type: "fetchNormal",
        params,
        ...fetch2,
      });
    } else {
      // Llenado de los datos del filtro
      const params = {
        page: 0,
        pageSize: total,
        filtered: [
          {
            id: "perfiles.Perfil",
            filter: "LIKE",
            value: searchInput,
            inheritFilterType: null,
          },
        ],
      };

      fetchData({
        type: "fetchSearch",
        params,
        ...fetch2,
      });
    }
  };

  // Verifica que cambio el dato en el Componente de Seleccion
  useEffect(() => {
    if (allPadres) {
      const filtered = allPadres.filter(
        (item) => !newHijos.find((newItem) => newItem.id === item.id)
      );
      setFilteredData2(filtered);
    }
    formik.values.PerfilHijo = newHijos;
    setTimeout(() => {
      setDisable2(false);
    }, 50);
  }, [newHijos, setNewHijos]);

  useEffect(() => {
    if (allHijos) {
      const filtered = allHijos.filter(
        (item) => !newPadres.find((newItem) => newItem.id === item.id)
      );
      setFilteredData1(filtered);
    }
    formik.values.PerfilPadre = newPadres;
    setTimeout(() => {
      setDisable1(false);
    }, 50);
  }, [newPadres, setNewPadres]);

  // Al tener el Formik completo
  const handleVerification = async (values, actions) => {
    // Sacar el ID de cada objeto para subir solo el ID
    const idsAC = values.AmbitoCargo.map((option) => option.value || option);
    const idsPerfilHijo = values.PerfilHijo.map(
      (option) => option.id || option
    );
    const idsPerfilPadre = values.PerfilPadre.map(
      (option) => option.id || option
    );

    // Estructurar los datos a enviar
    const params = {
      id: data.id,
      idTipo: values.TipoPerfil,
      idAmbitoCargo: idsAC,
      Perfil: values.NombrePerfil,
      EtiquetaCargo: values.EtiquetaCargo,
      Descripcion: values.Descripcion,
      PerfilesHijos: idsPerfilHijo,
      PerfilesPadres: idsPerfilPadre,
    };

    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setLoadigMessage("Subiendo...");

    try {
      const result = await RoleService.updRole(params);
      const { success, response, results, message } = result;

      if (success && results) {
        Swal.fire({
          title: message,
          icon: "success",
          customClass: {
            container: "modal-alert",
          },
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
          customClass: {
            container: "modal-alert",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setChange(!change);
    }
  };

  return (
    <>
      <LoadingForm
        loadinMessage={loadigMessage}
        successMessage="¡Consultado con éxito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => {}}
      />
      <Box>
        <Grid2 container spacing={2}>
          <Form
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            handleChange={formik.handleChange}
            formPrev={formPrev}
          />
          {filteredData1 ? (
            <Seleccion
              total={total}
              usedData={newPadres.length}
              fetchHasMore={fetchHasMore1}
              hasMore={hasMore1}
              initialData={filteredData1}
              prevData={newHijos}
              setNewData={setNewHijos}
              setDisable={setDisable2}
              handleSearch={handleSearch1}
              disable={disable1}
              error={
                formik.touched.PerfilHijo &&
                formik.values.PerfilHijo.length === 0 &&
                formik.errors.PerfilHijo
              }
              title="Seleccione los perfiles que podrá asignar"
              loadingMore={isLoadingMore1}
            />
          ) : (
            <Grid2 xs={12} justifyContent="center">
              <CircularProgress size={50} />
            </Grid2>
          )}
          {/* En caso de que se requiera mensajes de error en los Seleccion */}
          {formik.touched.PerfilHijo &&
            formik.values.PerfilHijo.length === 0 &&
            formik.errors.PerfilHijo && (
              <Grid2 xs={12} paddingLeft="22px">
                <Typography
                  variant="body4"
                  color="#d32f2f"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: "1.66",
                    fontFamily:
                      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                    textAlign: "left",
                    marginTop: "4px",
                    marginRight: "14px",
                    marginBottom: 0,
                  }}
                >
                  {formik.errors.PerfilHijo || formik.touched.PerfilHijo}
                </Typography>
              </Grid2>
            )}
          {filteredData2 ? (
            <Seleccion
              total={total}
              usedData={newHijos.length}
              fetchHasMore={fetchHasMore2}
              hasMore={hasMore2}
              initialData={filteredData2}
              prevData={newPadres}
              setNewData={setNewPadres}
              setDisable={setDisable1}
              handleSearch={handleSearch2}
              disable={disable2}
              error={
                formik.touched.PerfilPadre &&
                formik.values.PerfilPadre.length === 0 &&
                formik.errors.PerfilPadre
              }
              title="Seleccione los perfiles que lo podrán asignar"
              loadingMore={isLoadingMore2}
            />
          ) : (
            <Grid2 xs={12}>
              <CircularProgress size={50} />
            </Grid2>
          )}
          {/* En caso de que se requiera mensajes de error en los Seleccion */}
          {formik.touched.PerfilPadre &&
            formik.values.PerfilPadre.length === 0 &&
            formik.errors.PerfilPadre && (
              <Grid2 xs={12} paddingLeft="22px">
                <Typography
                  variant="body4"
                  color="#d32f2f"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: "1.66",
                    fontFamily:
                      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                    textAlign: "left",
                    marginTop: "4px",
                    marginRight: "14px",
                    marginBottom: 0,
                  }}
                >
                  {formik.errors.PerfilPadre || formik.touched.PerfilPadre}
                </Typography>
              </Grid2>
            )}
          <Grid2 xs={12} sm={5} md={4} lg={3} marginX="auto">
            <Button
              fullWidth
              variant="contained"
              color="primaryDark"
              onClick={formik.handleSubmit}
            >
              Actualizar
            </Button>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default RoleEdit;
