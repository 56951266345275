import React, { useState } from "react";
import Swal from "sweetalert2";
import secciones from "@data/json/SeccionesSimulacro";
import { getVars } from "@utils/global";
import { isTypePhone } from "@utils/validations";
//Mui
import {
  Icon,
  IconButton,
  Stack,
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
//Components
import Modal from "@components/Modal/Dataview";
import AutoComplete from "@components/Selects/Autocomplete";
//Services
import CompromisoServices from "@services/CompromisoServices";

const PopupForm = ({
  open,
  item,
  setOpen,
  handleRefresh,
  handleClearFilter,
}) => {
  //AmbitoValor
  const otherOption = { value: 0, label: "OTRA" };
  const {
    user: { ambito_perfil },
  } = getVars("Token");
  const [perfil_municipio] = ambito_perfil.map((item) => item.AmbitoValor);
  const cat_secciones = secciones.RECORDS.filter(
    (item) => item.idMunicipio === perfil_municipio
  ).concat(otherOption);

  //States
  const [showInput, setShowInput] = useState(false);
  const [colonies, setColonies] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otherValue, setOtherValue] = useState("");
  const [section, setSection] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  //Functions
  const handleChangeSection = (e) => {
    const { value, Colonia } = e;

    if (value === 0) {
      setShowInput(true);
      setColonies("");
      setErrorMessage(otherValue.length < 1 ? "Ingrese una sección": "Seleccione una sección");
    } else {
      setShowInput(false);
      setColonies(Colonia);
      setOtherValue("");
      setSection(value);
      setErrorMessage("");
    }
  };

  const handleChangeOtherValue = (e) => setOtherValue(e.target.value);

  const handleTakeAttendance = () => {
    if(!section){
      setErrorMessage("Seleccione una sección");
      return
    }

    if(otherValue && otherValue.length > 0 && parseInt(otherValue)>3211){
      return
    }

    const params = {
      id: item.id,
      EsNuevo: 0,
      Seccion: otherValue ? `OTRA-${otherValue}` : section,
    };

    setIsLoading(true);
    CompromisoServices.createAttendance(params)
      .then((res) => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
            customClass: {
              container: "modal-alert",
            },
          });
          setShowInput(false);
          setColonies("");
          setOtherValue("");
          handleRefresh();
          handleClearFilter();
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setShowInput(false);
    setOtherValue("");
    setColonies("");
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hasButtonClose={false}
      maxWidth={"sm"}
      borderRadius={12}
    >
      <Box sx={{ textAlign: "center" }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Stack>
        <Typography variant="h6" fontWeight={700}>
          Confirmar pase de lista de
          <Typography variant="h6" fontWeight={700} sx={{ color: "#0041a0" }}>
            {item.NombreCompleto}
          </Typography>
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant="body2" fontWeight={700} marginBottom={1}>
          Selecciona una sección
        </Typography>
        <AutoComplete
          label="Sección Asignadas"
          data={cat_secciones}
          size="small"
          setSelect={handleChangeSection}
          sx={{ width: "100%" }}
        />
        <FormHelperText error>{errorMessage}</FormHelperText>
        {showInput && (
          <>
          <TextField
            label="Otra"
            name="otra"
            size="small"
            type={"text"}
            sx={{ width: "100%", mt: 2 }}
            value={otherValue}
            onChange={(e) =>
              handleChangeOtherValue({
                target: {
                  name: "otra",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : otherValue,
                },
              })
            }
            onBlur={(e) =>
              handleChangeOtherValue({
                target: {
                  name: "otra",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : otherValue,
                },
              })
            }
            inputProps={{
              maxLength: 4,
            }}
          />
          <FormHelperText error>{otherValue && otherValue.length > 0 && parseInt(otherValue)> 3211 ? 'La sección no es válida' : ''}</FormHelperText>
          </>
        )}
        {colonies && (
          <>
            <Typography
              variant="body2"
              fontWeight={700}
              marginTop={2}
              marginBottom={1}
            >
              Colonias
            </Typography>
            <Box>{colonies}</Box>
          </>
        )}
      </Box>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress className="color-componentes" />
        </Box>
      ) : (
        <Stack direction="row" alignItems="center" marginTop={3} spacing={2}>
          <Button
            variant="outlined"
            color="primaryDark"
            sx={{ borderRadius: "20px", width: "50%", mt: 1 }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primaryDark"
            sx={{ borderRadius: "20px", width: "50%" }}
            disabled={showInput && otherValue === ""}
            onClick={handleTakeAttendance}
          >
            Sí, Confirmar
          </Button>
        </Stack>
      )}
    </Modal>
  );
};

export default PopupForm;
