import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Box,
  Stack,
  CircularProgress,
  circularProgressClasses,
  Icon,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const AttendanceCard = (props) => {
  const {
    loading = false,
    data = {
      contentData: "0",
      leftFooterData: "Sin información",
      rightFooterData: "Sin información",
    },
    config = {},
    styleSettings = {},
    titlecontent = "Cumplimiento"
  } = props;

  const [stateConfig, setStateConfig] = useState({
    titleHeader: "Title",
    titlecontent: titlecontent,
    ColorCircularProgress: "#0d47a1",
    leftFooterTitle: "Meta",
    rightFooterTitle: "Avance",
    icon: "",
  });

  const [stateStyleSettings, setStateStyleSettings] = useState({
    variant: "elevation",
    borderRadius: "12px",
    padding: 0,
    width: "100%",
  });

  useEffect(() => {
    setStateConfig({ ...stateConfig, titlecontent: titlecontent });
  }, [titlecontent]);

  useEffect(() => {
    Object.keys(config).forEach(function (key) {
      setStateConfig((prevState) => ({ ...prevState, [key]: config[key] }));
    });
  }, [config]);

  useEffect(() => {
    Object.keys(styleSettings).forEach(function (key) {
      setStateStyleSettings((prevState) => ({
        ...prevState,
        [key]: styleSettings[key],
      }));
    });
  }, [styleSettings]);

  return (
    <Card
      className="card-primary"
      variant={stateStyleSettings.variant}
      sx={{
        borderRadius: stateStyleSettings.borderRadius,
        width: stateStyleSettings.width,
        p: stateStyleSettings.padding,
      }}
    >
      {stateConfig.titleHeader !== "Title" ? (
        <Stack direction={"row"} spacing={1} justifyContent="center" marginTop={2}>
          <Icon sx={{ color: grey[600] }}>{stateConfig.icon}</Icon>
          <Typography fontWeight={600} variant="h6">
            {stateConfig.titleHeader}
          </Typography>
        </Stack>
      ) : (
        <></>
      )}
      <CardContent sx={{ height: "auto", alignContent: "center" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Box sx={{ position: "relative", display: "inline-flex", mt: 0.3 }}>
            <CircularProgress
            className="color-componentes"
              variant={loading ? "indeterminate" : 'determinate'}
            /*   sx={{
                color: (theme) =>
                  theme.palette.grey[
                  theme.palette.mode === "light" ? 200 : 800
                  ],
              }} */
              size={80}
              thickness={4}
              {...props}
              value={100}
            />
            <CircularProgress
             className="color-componentes"
              variant={loading ? "indeterminate" : 'determinate'}
              value={
                parseInt(data.contentData) >= 100
                  ? 100
                  : parseInt(data.contentData)
              }
              // disableShrink={true}
              sx={{
              //  color: stateConfig.ColorCircularProgress,
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={80}
              thickness={4}
              {...props}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <CircularProgress
                  className="color-componentes"
                  size={15}
                />
              ) : (
                <Typography
                className="color-componentes"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Helvetica Neue",
                    fontSize: 14,
                  }}
                >{`${data.contentData} %`}</Typography>
              )}
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: "light",
              fontFamily: "Helvetica Neue",
            }}
          >
            {stateConfig.titlecontent}
          </Typography>
        </Stack>
      </CardContent>
      <Divider></Divider>
      <CardActions sx={{ textAlign: "center" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: "light",
                fontFamily: "Helvetica Neue",
              }}
            >
              {stateConfig.leftFooterTitle}
            </Typography>
            {loading ? (
              <CircularProgress
              className="color-componentes"
                size={15}
              />
            ) : (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Helvetica Neue",
                  fontSize: data.leftFooterData === "Sin información" ? 13 : 18,
                }}
              >
                {data.leftFooterData}
              </Typography>
            )}
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: "light",
                fontFamily: "Helvetica Neue",
              }}
            >
              {stateConfig.rightFooterTitle}
            </Typography>
            {loading ? (
              <CircularProgress
              className="color-componentes"
                size={15}
              />
            ) : (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Helvetica Neue",
                  fontSize:
                    data.rightFooterData === "Sin información" ? 13 : 18,
                }}
              >
                {data.rightFooterData}
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default AttendanceCard;
