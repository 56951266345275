export const AddBingoInterface = {
    Seccion: '',
    idCasilla: '',
    FolioBingo: '',
    OrigenCaptura: 'web'
}

export const MovilizationByHourInterface = {
    Seccion: '',
    Hora: ''
}