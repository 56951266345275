import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

//MUI
import {
	Container,
	Grid,
	Card,
	CardContent,
	Box,
	Stack,
	Divider,
	Icon,
	Typography,
	Tab,
	TextField,
	Button,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { Block, ChevronLeft, ChevronRight } from "@mui/icons-material";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import TreeViewIcon from "@components/TreeView/TreeViewIcon";
import CardStats from "@components/Card/CardStats";
import BasicTable from "@components/Tables/BasicTable";
import MyUsersFilter from "@components/Users/MyUsersFilter";
import DrawerRelative from "@components/Containers/DrawerRelative";

//Services
import UserServices from "@services/UserServices";
import CompromisoServices from "@services/CompromisoServices";

//Utils
import { isTypePhone } from "@utils/validations";
import { numberWithCommas, searchTree } from "@utils/Utilities";

//Middleware
import middleware from "@middlewares/middleware";

const MyUsers = () => {
	//variables
	const columns = [
		{ id: "Municipio", label: "Municipio", align: "center" },
		{ id: "NombreCompleto", label: "Nombre", align: "center" },
		{ id: "SeccionVota", label: "Sección Vota", align: "center" },
		{ id: "EsVoluntario", label: "Participante", align: "center" },
		{ id: "CapturadoPor", label: "Capturado Por", align: "center" },
	];
	const navigate = useNavigate();

	//States
	// const [total] = useState(0);
	const [currentUserID, setCurrentUserID] = useState(true);
	const [openDrawer, setOpenDrawer] = useState(true);
	const [loadingTree, setLoadingTree] = useState(false);
	const [showButtonCancel, setShowButtonCancel] = useState(false);
	const [valueTab, setValueTab] = useState("2");
	const [nameUser, setNameUser] = useState("");
	const [phone, setPhone] = useState("");
	const [commitments, setCommitments] = useState([]);
	const [treeUsersFound, setTreeUsersFound] = useState({});
	const [treeUsers, setTreeUsers] = useState([]);
	const [loadingCommitments, setLoadingCommitments] = useState(false);
	const [totalCommitments, setTotalCommitments] = useState(0);
	const [data, setData] = useState({
		idUsuario: 0,
		page: 0,
		pageSize: 10,
		unique: false,
		nested: false,
		filtered: [],
		//sorted: [{ id: "compromisos_unicos.FechaCreo", value: "DESC" }],
	});
	const [totalUser, setTotalUser] = useState({
		totalUsuarios: 0,
		totalCompromisos: 0,
		totalCompromisosUnicos: 0,
	});

	//API's
	const getMyUsersTree = () => {
		setLoadingTree(true);
		UserServices.getMyUsers({})
			.then((res) => {
				if (res.results) {
					setTreeUsers([res.response]);
					setTreeUsersFound(res.response);
					handleItemTree(res.response);
				} else Swal.fire({ title: res.message, icon: "warning" });
			})
			.catch((err) => Swal.fire({ title: err, icon: "warning" }))
			.finally(() => setLoadingTree(false));
	};

	const getUserCommitments = (params) => {
		setLoadingCommitments(true);
		CompromisoServices.getUserCommitments(params)
			.then((res) => {
				if (res.results) {
					setCommitments(
						res.response.data.map((item) => {
							return {
								...item,
								EsVoluntario: item.EsVoluntario === 1 ? "SI" : "NO",
							};
						})
					);
					setTotalCommitments(res.response.total);
				} else Swal.fire({ title: res.message, icon: "warning" });
			})
			.catch((err) => Swal.fire({ title: err, icon: "warning" }))
			.finally(() => setLoadingCommitments(false));
	};

	useEffect(() => {
		getMyUsersTree();
		//eslint-disable-next-line
	}, []);

	//Functions and Handlers
	const handleChangePagination = (pagination) =>
		setData({ ...data, ...pagination });

	const handleItemTree = (e) => {
		if (e !== null) {
			const {
				id,
				Nombre,
				Paterno,
				Materno,
				TotalUsuarios,
				TotalCompromisos,
				CompromisosUnicos,
			} = e;
			const NameUser = `${Nombre} ${Paterno} ${Materno}`;
			setNameUser(NameUser);
			setTotalUser({
				...totalUser,
				totalUsuarios: numberWithCommas(TotalUsuarios),
				totalCompromisos: numberWithCommas(TotalCompromisos),
				totalCompromisosUnicos: numberWithCommas(CompromisosUnicos),
			});
			setData({ ...data, idUsuario: id });
		} else {
			setNameUser("");
			setTotalUser({
				...totalUser,
				totalUsuarios: 0,
				totalCompromisos: 0,
				totalCompromisosUnicos: 0,
			});
		}
	};

	const handleChangeTab = (event, newValue) => setValueTab(newValue);

	const handleChangePhone = (e) => setPhone(e.target.value);

	const handleSearch = () => {
		if (phone === "") return;
		if (treeUsers.length === 0) return;

		const founds = searchTree(treeUsersFound, "Username", phone);
		setTreeUsers(founds !== null ? [founds] : []);
		setShowButtonCancel(true);
		handleItemTree(founds);
	};

	const handleClearSearch = () => {
		setPhone("");
		setShowButtonCancel(false);
		getMyUsersTree();
	};

	const handleOnKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleSearch();
		}
	};

	useEffect(() => {
		const id = data?.idUsuario;
		if (id && id !== 0 && id !== currentUserID) {
			setCurrentUserID(id);
			getUserCommitments(data);
		}
		// eslint-disable-next-line
	}, [data]);

	const handleChangeFilter = (options) => {
		setData({
			...data,
			page: 0,
			unique: options.unique,
			nested: options.nested,
		});
	};

	return (
		<AdminLayout>
			<Container maxWidth={false}>
				<ActionHeader title={"Red de Amigos"} isCustom={true}>
					{middleware.checkMenuAction("Editar") && (
						<Button
							variant="contained"
							sx={{ width: { xs: "100%", sm: "auto" } }}
							size="small"
							color="primaryDark"
							startIcon={<Icon>edit</Icon>}
							onClick={() => navigate("/mis-usuarios-editar")}
						>
							Editar
						</Button>
					)}
					<IconButton
						onClick={() => setOpenDrawer(!openDrawer)}
						aria-label="open Drawer"
						color={openDrawer ? "default" : "primary"}
					>
						{openDrawer ? <ChevronLeft /> : <ChevronRight />}
					</IconButton>
				</ActionHeader>
				<Grid container spacing={openDrawer ? 2 : 0}>
					<Grid item xs={12} md={4}>
						<DrawerRelative
							open={openDrawer}
							setOpen={setOpenDrawer}
							title={treeUsers?.length > 0 ? "Buscar por celular" : ""}
							icon="person_search"
							titleComponentPlace="under"
							titleComponent={
								treeUsers?.length > 0 && (
									<Stack
										direction="row"
										spacing={2}
										sx={{ paddingX: 2, paddingBottom: 2 }}
									>
										<TextField
											label="Celular"
											size="small"
											name="phone"
											value={phone}
											onChange={(e) =>
												handleChangePhone({
													target: {
														name: "phone",
														value: isTypePhone(e.target.value)
															? e.target.value
															: phone,
													},
												})
											}
											onKeyUp={handleOnKeyPress}
											InputProps={{
												endAdornment: showButtonCancel && (
													<InputAdornment position="end">
														<IconButton onClick={handleClearSearch}>
															<Icon>clear</Icon>
														</IconButton>
													</InputAdornment>
												),
											}}
											fullWidth
										/>

										<Button
											variant="contained"
											color="primaryDark"
											sx={{ width: "25%" }}
											onClick={handleSearch}
										>
											Buscar
										</Button>
									</Stack>
								)
							}
							className="card-primary"
							sxPaper={{ borderRadius: 3 }}
						>
							<Card
								sx={{
									height: "100%",
									overflow: "auto",
									marginBottom: { xs: "0px!important" },
								}}
							>
								<CardContent>
									<TreeViewIcon
										data={treeUsers}
										typeData={{
											value: "id",
											label: "Nombre",
											S_label: "Paterno",
											T_label: "Username",
											customItem: [
												{
													id: "idEstatus",
													value: 4,
													icon: Block,
													color: "#F67B7B",
												},
											],
										}}
										handleClick={handleItemTree}
										loading={loadingTree}
									/>
								</CardContent>
							</Card>
						</DrawerRelative>
					</Grid>

					<Grid item xs={12} md={openDrawer ? 8 : 12}>
						<Grid container columnSpacing={2}>
							<Grid item xs={12} sm={6} md={4}>
								<CardStats
									icon={"people"}
									title={"Total de Usuarios"}
									total={totalUser.totalUsuarios}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<CardStats
									icon={"feed"}
									title={"Promovidos (Gral)"}
									total={totalUser.totalCompromisos}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<CardStats
									icon={"fact_check"}
									title={"Promovidos Únicos"}
									total={totalUser.totalCompromisosUnicos}
								/>
							</Grid>
						</Grid>

						<Card
							className="card-primary"
							sx={{ marginBottom: { xs: "0px!important" } }}
						>
							<CardContent>
								{nameUser !== "" && (
									<>
										<Stack direction={"row"} spacing={1} alignItems="center">
											<Icon sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
												account_circle
											</Icon>
											<Typography variant="subtitle1" fontWeight={600}>
												{nameUser}
											</Typography>
										</Stack>
										<Divider />
									</>
								)}
								<Box
									marginTop={"1rem"}
									sx={{ width: "100%", typography: "body1" }}
								>
									<TabContext value={valueTab} >
										<Box>
											<TabList
												onChange={handleChangeTab}
												aria-label="tabs para graficas y tablas"
												className="color-letras"
											>
										{/* 		<Tab
													icon={<Icon>show_chart</Icon>}
													iconPosition="start"
													label="Estadística"
													value="1"
												/> */}
												<Tab
													className="color-letras"
													icon={<Icon>feed</Icon>}
													iconPosition="start"
													label="Promovidos"
													value="2"
												/>
											</TabList>
										</Box>
										<TabPanel value="1"></TabPanel>
										<TabPanel value="2" >
											<MyUsersFilter
												onChangeFilter={(e) => handleChangeFilter(e)}
											/>
											<BasicTable
												rows={commitments}
												hcolumns={columns}
												pageProp={data.page}
												pageSize={data.pageSize}
												total={totalCommitments}
												handleChangePagination={handleChangePagination}
												isLoading={loadingCommitments}
												stickyHeader={true}
											/>
										</TabPanel>
									</TabContext>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</AdminLayout>
	);
};

export default MyUsers;
