import React, { useState /*useEffect*/ } from "react";
import { Grid, Card, CardContent, Box, CardMedia, Typography, Avatar } from "@mui/material";

//import Logo from "@assets/img/banner.jpg";
import { getVars /* setVars */ } from "@utils/global";
import AdminLayout from "@components/MainPage/AdminLayout";
import UserSecurity from "@components/Users/UserSecurity";
import { UserProfileInterface } from "@data/interfaces/UserInterfaces";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BackImage from "@assets/img/back02.png";
import UserAccessTimeLine from "@components/Users/UserAccessTimeLine";
import useWindowDimensions from "@hooks/useWindowDimensions";

const UserProfile = () => {
  const { width } = useWindowDimensions();
  const [userInfo /* setUserInfo */] = useState(() => {
    let token = getVars("Token").user;
    let user = UserProfileInterface;
    Object.keys(token).forEach((key) => {
      user[key] = token[key] !== undefined ? token[key] : UserProfileInterface[key];
    });
    return user;
  });

  const InfoItem = ({ icon, text }) => (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2} ml={width < 1787 ? 7 : 2}>
      {icon}
      <Typography variant="body1" color="textSecondary" ml={1}>
        {text}
      </Typography>
    </Box>
  );
  return (
    <AdminLayout>
      <Grid
        sx={{
          minHeight: "100vh",
          backgroundSize: "cover",
          backgroundImage: `url(${BackImage})`,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Box sx={{ position: "relative", width: "100%", height: width < 1410 ? "auto" : "430px", overflow: "hidden" }}>
              <CardMedia component="img" height="280" image={/* Logo */""} alt="" sx={{ objectFit: "fill", width: "100%", margin: "auto" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ position: "relative" }}>
                  <Avatar
                    sx={{
                      position: "absolute",
                      top: "10%",
                      left: width < 1784 ? (width > 899 ? "65%" : "50%") : "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: width < 896 ? 200 : 250,
                      width: "100%",
                      height: "auto",
                      margin: "auto", // Añadir margen alrededor del avatar
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Opcional: Agregar una sombra al avatar para mayor separación
                    }}
                  >
                    <PersonIcon sx={{ width: "90%", height: "90%" }} />
                  </Avatar>
                </Grid>
                <Grid item xs={12} sm={5} md={5} sx={{ position: "relative" }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "Left",
                      marginBottom: 2,
                      marginTop: width < 900 ? 12 : 0,
                      marginLeft: width < 1787 ? 5 : 0
                    }}
                  >
                    {userInfo.Nombre} {userInfo.Paterno} {userInfo.Materno}
                  </Typography>
                  <Grid container spacing={2} justifyContent="left">
                    <InfoItem icon={<EmailIcon />} text={userInfo.Correo || "Correp@XX.com"} />
                    <InfoItem icon={<PhoneIcon />} text={userInfo.Celular || "+123456789"} />
                    <InfoItem icon={<LocationOnIcon />} text={userInfo.Ubicacion || "Ciudad, País"} />
                    <InfoItem icon={<WorkIcon />} text={userInfo.ambito_perfil.map((item) => item.Perfil).join(", ") || ""} />
                    <InfoItem icon={<AccessTimeIcon />} text={userInfo.UltimoAcceso || ""} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card className="card-primary">
              <CardContent>
                <UserSecurity data={userInfo} access="profile" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card className="card-primary">
              <CardContent>
                <UserAccessTimeLine userId={userInfo.id} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default UserProfile;
