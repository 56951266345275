import React, { useState, useCallback } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Icon,
  Card,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { theme } from "@src/theme/index";
import Yup from "@utils/Yupi18n";

import { setVars, RECAPTCHA_SITE_KEY, Version } from "@utils/global";
import ApiExec from "@utils/ApiExec";
import { isEmptyOrInvalidString } from "@utils/validations";
import "@pages/auth/LoginStyle.css";
// import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import avatar from "@assets/img/logo.png";

const CardHeader = () => {
  return (
    <>
      <div className="card-header-multimedia">
        <img alt="Logo" src={avatar} width={120} height={120} />
        <Typography
          sx={{ textAlign: "center", mt: 2 }}
          variant="h5"
          fontWeight={400}
        >
          Somos Puerta
        </Typography>
      </div>
    </>
  );
};

const CardBody = ({
  showPassword,
  setShowPassword,
  formik,
  handleOnKeyPress,
  // captchaRef,
  // onChangeCaptcha,
}) => {
  return (
    <>
      <div className="card-content">
        <br />
        <TextField
          //label="Usuario"
          placeholder="Celular"
          className="input"
          size="small"
          sx={{ mb: 1 }}
          error={
            formik.touched.username &&
            !isEmptyOrInvalidString(formik.errors.username)
          }
          helperText={
            formik.touched.username &&
            formik.errors.username &&
            formik.errors.username
          }
          type="text"
          name="username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          autoFocus
          onKeyPress={handleOnKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton sx={{ cursor: "inherit" }}>
                  <Icon>account_circle</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          placeholder="Contraseña"
          className="input"
          size="small"
          error={
            formik.touched.password &&
            !isEmptyOrInvalidString(formik.errors.password)
          }
          helperText={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
          type={showPassword ? "text" : "password"}
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onKeyPress={handleOnKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "#9a9a9a", mt: 1 }}
        >
          Acceso a usuarios registrado
        </Typography>
      </div>
    </>
  );
};

const CardAction = ({ signIn, loading, formik }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="card-action">
        {loading ? (
          <CircularProgress
            sx={{
              display: "flex",
              margin: "0 auto",
              width: "30px !important",
              height: "30px !important",
            }}
          />
        ) : (
          <Button className="button-login" onClick={signIn}>
            Entrar
          </Button>
        )}
        <div style={{ textAlign: "end", mt: 1 }}>
          <Typography variant="body1" sx={{ textDecoration: "none", p: 0 }}>
            {
              <Button
                onClick={() =>
                  navigate(
                    `/cambio-validation/${btoa(
                      JSON.stringify({
                        dato: formik.values.username,
                        value: formik.values.idEstado,
                      })
                    )}`
                  )
                }
                className="button-passwd"
              >
                Cambiar contraseña
              </Button>
            }
          </Typography>
        </div>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#9a9a9a",
            marginBottom: "5px",
          }}
        >
          {Version ? Version : "1.0.0"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        ></Typography>
        {/* <Typography
					variant="body2"
					sx={{
						textAlign: "center",
						color: "#9a9a9a",
						marginTop: "2px",
						marginBottom: "5px",
					}}
				>
					<Link
						underline="hover"
						style={{ color: "#9a9a9a", fontWeight: 500 }}
						target="_blank"
						rel="noreferrer"
						href={`${URL_PUBLICA_FRONT}soporte`}
					>
						¿Necesitas Ayuda?
					</Link>
				</Typography> */}
      </div>
    </>
  );
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  // const captchaRef = useRef(null);
  const navigate = useNavigate();

  const initialValues = {
    username: "",
    password: "",
    idEstado: 0,
    type_login: "web",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required().label("Celular"),
    password: Yup.string().required().min(6).label("Contraseña"),
    // idEstado: Yup.number().required().min(1).label("Entidad"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      //signIn(values);
      onChangeCaptcha(values);
    },
  });

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha("login")
        .then((token) => {
          const data = {
            ...values,
            idEstado: 2,
            token: token,
          };
          signIn(data);
        })
        .catch((error) => Swal.fire({ title: error, icon: "warning" }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const signIn = (values) => {
    // const token = captchaRef.current.getValue()
    setLoading(true);
    ApiExec({ ...values }, "auth/login")
      .then(function (res) {
        return res;
      })
      .then((token) => {
        if (token.results) {
          if (token.response.forcePasswordChange) {
            return forcePasswdChange();
          }
          setVars("Token", token.response);
          navigate(token.response.user.DefaultPage);
        } else {
          Swal.fire({ title: token.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally((end) => {
        setLoading(false);
        // captchaRef.current.reset();
      });

    const forcePasswdChange = () => {
      Swal.fire({
        icon: "info",
        title: "Cambiar Contraseña",
        text: "Antes de iniciar sesión, por favor, cambie tu contraseña.",
        showCancelButton: false,
        confirmButtonText: "Cambiar",
        confirmButtonColor: "#0041a0",
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate(
            `/cambio-validation/${btoa(
              JSON.stringify({
                dato: values.username,
                value: values.idEstado,
              })
            )}`
          );
        }
      });
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="section-image"
        sx={{
          backgroundImage: `url(${require("@assets/img/back02.png")})`,
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Card>
                <CardHeader />
                <CardBody
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  formik={formik}
                  handleOnKeyPress={handleOnKeyPress}
                />
                <CardAction
                  signIn={formik.submitForm}
                  loading={loading}
                  formik={formik}
                />
              </Card>
            </Stack>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const Inicio = () => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <Login />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default Inicio;
