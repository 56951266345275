import { /* useEffect,  */ useState } from "react";
import { useSorted } from "@hooks/useSorted";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import FilterPolygonal from "@components/Rcos/FilterPolygonal";
import DetailPolygonal from "@components/Rcos/DetailPolygonal";

import { COLUMNS_RCO_POLYGONAL } from "@data/constants/Rco";

const Polygonal = () => {
  const [total /* setTotal */] = useState(10);
  const [dataPolygonal /* setDataPolygonal */] = useState([]);
  const [loadingPolygonal /* setLoadingPolygonal */] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
  });
  const { handleRequestSort } = useSorted({ total: total, setData });

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="RCO Polígonal" />
        <FilterPolygonal />
        <Box marginTop={2}>
          <Card className="card-primary">
            <CardContent>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={2}
              >
                <Typography fontWeight={600} variant="body2">
                  SECCIONALES
                </Typography>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  //disabled={isDownload}
                  //onClick={handleExport}
                >
                  Exportar
                </Button>
              </Stack>
              <DetailPolygonal />
              <BasicTable
                rows={dataPolygonal}
                hcolumns={COLUMNS_RCO_POLYGONAL}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                minHeight="0px"
                stickyHeader={true}
                isLoading={loadingPolygonal}
                handleChangePagination={handleChangePagination}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Polygonal;
