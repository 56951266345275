// Material UI
import { Grid, Chip } from "@mui/material";

const elements = [
  { title: "Sin asignar", color: "rgba(0, 102, 255, 0.4)" },
  { title: "Faltantes", color: "rgba(255, 118, 52, 0.4)" },
  { title: "Completadas", color: "rgba(75, 181, 67, 0.5)" },
];

const Create = (props) => {
  return (
    <Grid container rowSpacing={1} sx={{ width: "115px", paddingBottom: 0.5 }}>
      {elements.map((elem, index) => (
        <Grid item key={index} xs={12}>
          <Chip label={elem.title} sx={{ width: "100%", backgroundColor: elem.color, fontWeight: 600, fontStretch: 1.2 }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Create;
