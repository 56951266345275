import { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Filter from "@components/MapsModules/Compromisos/FilterTabs";
import TableInfo from "@components/Maps/InternalComponents/Cards/TableInfo";
import CardInfoMini from "@components/Maps/InternalComponents/Cards/CardInfoMini";

// Servicios y Utils
import CatalogService from "@services/CatalogServices";
import CompromisosService from "@services/MapServices/CompromisoServices";
import { setVars, getVars } from "@utils/global";

// Estilos de mapas
import { style_original_av_struct } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
  manzana: [
    { title: "Manzana:", name: "Manzana", type: "text" },
    { title: "Prioridad:", name: "PrioridadLabel", type: "text" },
  ],
  seccion: [
    { title: "Seccion:", name: "seccion", type: "text" },
    { title: "DLOC:", name: "DLOC", type: "text" },
    { title: "DFED:", name: "DFED", type: "text" },
    { title: "Meta:", name: "MetaEstructura", type: "text" },
    { title: "Av Estructura:", name: "AvanceEstructura", type: "text" },
    { title: "% Av Estructura:", name: "PorcentajeAvanceEstructura", type: "text" },
    { title: "LNOM:", name: "Lnom", type: "text" },
  ],
  dfed: [{ title: "DFED:", name: "dfed", type: "text" }],
};

const elementoInfoGeneral = [
  { title: "Meta:", name: "MetaEstructura", color: "#000", type: "text" },
  { title: "Av Estructura:", name: "AvanceEstructura", color: "#000", type: "text" },
  { title: "% Av Estructura:", name: "PorcentajeAvanceEstructura", color: "#000", type: "percent" },
  /* { title: "Total Man. P1:", name: "TotalP1", color: "#000", type: "text" },
  { title: "Total Man. P2:", name: "TotalP2", color: "#000", type: "text" },
  { title: "Total Man. P3:", name: "TotalP3", color: "#000", type: "text" }, */
];

const Maps = () => {
  const { user } = getVars("Token");

  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });
  const [polygonsBlocks, setPolygonsBlocks] = useState(null);

  const [table, setTable] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);
  const [catalogs, setCatalogs] = useState({ regiones: [], municipios: [] });
  const [shpInfoGeneral, setShpInfoGeneral] = useState("Entidad");

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  const handleFilterExect = (_filter, polygon) => {
    let _filterModified = _filter.map((item) => {
      const regex = /tb/gi;
      let itemModified = item;
      itemModified.id = item.id.replace(regex, "cat_secciones");
      return itemModified;
    });
    let data_parameters = {};
    if (!polygon)
      data_parameters = {
        page: 0,
        pageSize: 10,
        filtered: _filterModified,
      };
    else
      data_parameters = {
        polygon,
        page: 0,
        pageSize: 10,
        filtered: _filterModified,
      };
    setPolygons(null);
    setPolygonsBlocks(null);

    CompromisosService.getSections(data_parameters)
      .then((res) => {
        if (res.results) {
          const features = res.response.data.features.map((item) => item.properties);
          setTable(features);
          setPolygons(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });

    let lastElement = _filterModified.slice(-1);
    _filterModified = lastElement;
    let shp = "entidad";
    setShpInfoGeneral("Entidad");
    const regex = /cat_secciones/gi;

    switch (_filterModified[0].id) {
      case "cat_secciones.idMunicipio":
        shp = "municipio";
        setShpInfoGeneral("Municipio");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_municipios");
        break;
      case "cat_secciones.idPoligono":
        shp = "poligono";
        setShpInfoGeneral("Poligono");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_poligonos");
        break;
      case "cat_secciones.Seccion":
        shp = "seccion";
        setShpInfoGeneral("Seccion");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_secciones");
        break;
      case "cat_secciones.idDF":
        shp = "dfed";
        setShpInfoGeneral("DFED");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_df");
        break;
      case "cat_secciones.idDL":
        shp = "dloc";
        setShpInfoGeneral("DLOC");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_dl");
        break;
      default:
        shp = "entidad";
        setShpInfoGeneral("Entidad");
    }

    CompromisosService.getInformationGeneral({ page: 0, pageSize: 10, filtered: _filterModified, shp })
      .then((res) => {
        if (res.results) {
          if (res.response.data.length !== 0) {
            setValuesInfoGeneral(res.response.data[0]);
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios_dl", getAll: false },
    ];

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
              entidades: catalogsResponse.entidades ? [{ value: 0, label: "TODAS" }].concat(catalogsResponse.entidades) : [],
            };
          });
        } else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);

  useEffect(() => {
    setVars("filterSavedMap", {
      idEntidad: "",
      idMunicipio: "",
      idPoligono: "",
      Seccion: "",
    });
    setTable(null);
    setCurrentFeature(null);
    getEmptyMap();
    loadCatalogs();
    const filter_predeterminado = [{"id":"cat_secciones.idEntidad","filter":"=","value":2}];
    const polygon_predeterminado = 0;
    handleFilterExect(filter_predeterminado,polygon_predeterminado);
    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <>
      <Filter catalogs={catalogs} loadingCatalogs={isLoadingCatalogs} entidad={user.idEstado} handleFilter={handleFilterExect} />
      {currentFeature && <CardInfoMini properties={currentFeature} elements={elements[polygons?.shp ?? "dfed"]} title={"INFORMACIÓN"} toolTip />}
      {table && <TableInfo properties={table} elements={elements[polygons?.shp ?? "dfed"]} />}
    </>
  );

  const drawerLeft = {
    open: true,
    title: "Filtros",
    component: FilterComponent,
    width: { sm: 300, md: 350, lg: 500, xl: 600 }, // Solo valores variables o vacio (se ajusta al contenido)
  };

  // Se utilizan nuevas funciones evitando usar las defaults
  const onEachFeature = (feature, layer, click) => {
    const properties = feature.properties;

    if (properties[properties.shp] !== undefined) {
      if (properties.shp === "seccion" && polygons.features.length === 1) return true;

      layer.setStyle(style_original_av_struct);
      layer.on({ click: click });
    }
  };

  const highlightFeature = (e, params) => {
    const {
      geoJsonBlocksRef,
      resetHighlight,
      L,
      setFeature,
      setSubFeature,
      selectedFeature,
      setSelectedFeature,
      selectedSubFeature,
      setSelectedSubFeature,
    } = params;
    const layer = e.target;
    const properties = layer.feature.properties;

    setCurrentFeature(properties);

    if (selectedFeature) resetHighlight(selectedFeature);

    if (geoJsonBlocksRef.current) {
      if (selectedSubFeature) resetHighlight(selectedSubFeature);

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original_av_struct(layer.feature, true));

      setSubFeature(layer.feature);
      setSelectedSubFeature(e);
      return true;
    }

    if (!properties.seccion) resetHighlight(e);

    setFeature(layer.feature);
    setSelectedFeature(e);

    if (properties.seccion) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original_av_struct(layer.feature, true));
    }
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        subData={polygonsBlocks}
        cardInfo={{
          initialValues: {
            title: "INFORMACIÓN",
            elements: elements[polygons?.shp ?? "dfed"],
          },
        }}
        drawerLeft={drawerLeft}
        highlightFeature={highlightFeature}
        onEachFeature={onEachFeature}
        centerByFeatures={true}
        styleProp={style_original_av_struct}
        infoGeneral={{
          values: valuesInfoGeneral,
          initialValues: {
            title: "RESUMEN",
            elements: [{ title: "center", name: shpInfoGeneral, color: "#000", type: "text" }, ...elementoInfoGeneral],
          },
          compromisos: 0,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;
