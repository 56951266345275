import React, { useEffect, useState, useCallback } from "react";
import moment from "moment/moment";
import {
  Typography,
  Box,
  Divider,
  IconButton,
  Grid,
  TextField,
  Button,
  Icon,
  CircularProgress,
} from "@mui/material";
import { LinearProgress } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineSeparator,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import useWindowDimensions from "@hooks/useWindowDimensions";

function ActionsHistory({ userId, userName, total, data, handleFilter, handleSort, setPage, message, isLoading, messageUserNotFound }) {
  // State
  const [startError, setStartError] = useState(null);
  const [endError, setEndError] = useState(null);
  const [errorStartMessage, setErrorStartMessage] = useState("");
  const [errorEndMessage, setErrorEndMessage] = useState("");

  const [currentOrder, setCurrentOrder] = useState("desc");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [noMoreRecords, setNoMoreRecords] = useState(false);

  // Effects
  useEffect(() => {
    if (startError === null) {
      setErrorStartMessage("");
    } else {
      setErrorStartMessage("Fecha inicial incorrecta");
    }

    if (endError === null) {
      setErrorEndMessage("");
    } else {
      setErrorEndMessage("Fecha final incorrecta");
    }
  }, [startError, endError]);

  useEffect(() => {
    setIsLoadingMore(false);

    // Verificar si no hay más registros
    if (data.length === total) {
      setNoMoreRecords(true);
    } else {
      setNoMoreRecords(false);
    }
  }, [data, total]);

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
  }, [userId]);

  // Custom Hooks
  const { height } = useWindowDimensions();

  const isSearchButtonDisabled = () => {
    if (startDate && endDate) {
      return moment(startDate).isAfter(moment(endDate));
    }
    return false;
  };

  // Callbacks
  const fetchHasMore = useCallback(() => {
    if (!userId) return;

    if (!isLoadingMore && data.length < total) {
      setIsLoadingMore(true);
      setPage((prevPageSize) => prevPageSize + 1);
    }
  }, [userId, setPage, data, total, isLoadingMore]);

  useEffect(() => {
    const timelineContainer = document.getElementById("timeline-container");
    if (!timelineContainer) return;

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = timelineContainer;

      if (scrollTop + clientHeight >= scrollHeight - 10) {
        fetchHasMore();
      }
    };

    timelineContainer.addEventListener("scroll", handleScroll);

    return () => timelineContainer.removeEventListener("scroll", handleScroll);
  }, [fetchHasMore]);

  // Styles
  const orangeDotStyle = { backgroundColor: "orange" };

  // Event Handlers
  const handleSearchClick = () => {
    if (startError !== null || endError !== null || isSearchButtonDisabled()) {
      return;
    }

    if (startDate || endDate) {
      setErrorStartMessage("");
      setErrorEndMessage("");
      const params = {
        FechaInicio: startDate,
        FechaFinal: endDate,
      };

      handleFilter("filter", params);
    }
  };

  const handleClearInputStart = (value) => {
    setStartDate(value);
    if (value === null) {
      const params = {
        FechaInicio: value,
        FechaFinal: endDate,
      };
      handleFilter("filter", params);
    } else {
      const isValidDate = moment(value).isValid();
      if (!isValidDate) {
        setErrorStartMessage("Fecha inicial incorrecta");
      } else {
        setErrorStartMessage("");
      }
    }
  };
  
  const handleClearInputEnd = (value) => {
    setEndDate(value);
    if (value === null) {
      const params = {
        FechaInicio: startDate,
        FechaFinal: value,
      };
      handleFilter("filter", params);
    } else {
      const isValidDate = moment(value).isValid();
      if (!isValidDate) {
        setErrorEndMessage("Fecha final incorrecta");
      } else {
        setErrorEndMessage("");
      }
    }
  };

  const handleClearClick = () => {
    setStartDate(null);
    setEndDate(null);
    setCurrentOrder("desc");
    handleFilter("clear");
  };

  const reverseUserList = () => {
    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    setCurrentOrder(newOrder);
    handleSort(newOrder);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
      <Box>
        <Grid container alignItems="center" my={1}>
          {userId && (
            <>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <DatePicker
                  label="Fecha inicial"
                  format="dd/MM/YYYY"
                  minDate={new Date(2023, 0, 1)} // 1 de enero de 2023
                  maxDate={new Date()}
                  onError={(newError) => setStartError(newError)}
                  TextFieldProps={{
                    helperText: errorStartMessage,
                  }}
                  inputVariant="outlined"
                  disabled={data.length === 0 && !startDate && !endDate} // Deshabilitar si es vacío
                  value={startDate}
                  onChange={(newValue) => {
                    handleClearInputStart(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true, // Esto asegura que el label siempre esté en la parte superior
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "90%",
                          md: "90%"
                        },
                        marginY: {
                          xs: 1, // Aplicar un margen vertical de 2 en pantallas extra pequeñas (teléfonos móviles)
                          sm: 0, // Aplicar un margen vertical de 0 en pantallas pequeñas y mayores
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.875rem",
                          padding: "10px 14px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <DatePicker
                  label="Fecha final"
                  format="dd/MM/yyyy"
                  minDate={new Date(2023, 0, 1)} // 1 de enero de 2023
                  maxDate={new Date()}
                  onError={(newError) => setEndError(newError)}
                  TextFieldProps={{
                    helperText: errorEndMessage,
                  }}
                  inputVariant="outlined"
                  disabled={data.length === 0 && !startDate && !endDate} // Deshabilitar si es vacío
                  value={endDate} // Vincula el estado
                  onChange={(newValue) => {
                    handleClearInputEnd(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true, // Esto asegura que el label siempre esté en la parte superior
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "90%",
                          md: "90%"
                        },
                        marginY: {
                          xs: 1, // Aplicar un margen vertical de 2 en pantallas extra pequeñas (teléfonos móviles)
                          sm: 1, // Aplicar un margen vertical de 0 en pantallas pequeñas y mayores
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.875rem",
                          padding: "10px 14px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Button
                  onClick={handleSearchClick}
                  startIcon={<Icon>search</Icon>}
                  fullWidth
                  variant="contained"
                  color="primaryDark"
                  disabled={
                    (data.length === 0 && !startDate && !endDate) ||
                    (startError !== null || endError !== null) ||
                    isSearchButtonDisabled()
                  }
                  sx={{
                    color: "rgb(255,255,255)",
                    borderColor: "rgb(0, 0, 126)",
                    marginY: {
                      xs: 1,
                      sm: 1,
                      md: 0
                    },
                    width: {
                      xs: "100%",
                      sm: "90%",
                      md: "90%"
                    },
                  }}
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  disabled={data.length === 0 && !startDate && !endDate}
                  onClick={handleClearClick}
                  sx={{
                    color: "rgb(0, 0, 126)",
                    borderColor: "rgb(0, 0, 126)",
                    marginY: {
                      xs: 1,
                      sm: 1,
                      md: 0
                    },
                    width: {
                      xs: "100%",
                      sm: "90%",
                      md: "90%"
                    },
                  }}
                >
                  Limpiar
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        <Divider />

        {userId && data.length > 0 && (
          <IconButton
            onClick={reverseUserList}
            sx={{ position: "absolute", right: 70, mt: 1 }}
            disabled={data.length === 0}
          >
            {currentOrder === "asc" ? (
              <ImportExportIcon sx={{ fontSize: "26px", transform: "rotate(180deg)" }} />
            ) : (
              <CompareArrowsIcon sx={{ transform: "rotate(90deg)", fontSize: "26px" }} />
            )}
          </IconButton>
        )}

        <Typography variant="h6" sx={{ marginTop: 2, textAlign: "center", color: "rgb(0, 0, 129)" }}>
          {(messageUserNotFound) || (userId ? userName : "Selecciona un usuario") }
        </Typography>


        <Box
          id="timeline-container"
          sx={{
            maxHeight: height * 0.58,
            overflowY: data.length ? "scroll" : "none",
            marginTop: 2,
          }}
        >
          {data.length > 0 && (
            <>
              {data.map((user) => (
                <Timeline
                  key={user.id}
                  position="right"
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={orangeDotStyle} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ width: "100%", marginLeft: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                          },
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          <Typography
                            variant="h2"
                            sx={{
                              fontSize: {
                                xs: "13px",
                                sm: "15px",
                              },
                            }}
                          >
                            Acción: {user.Accion}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "12px",
                              marginLeft: {
                                xs: 0,
                                sm: 20,
                              },
                              marginBottom: {
                                xs: 2,
                                sm: 0,
                              },
                              color: "gray",
                            }}
                          >
                            {moment(user.Fecha, "YYYY-MM-DD HH:m").fromNow()}
                          </Typography>
                        </div>
                      </Box>

                      <Typography
                        variant="h8"
                        sx={{
                          marginTop: 2,
                          fontSize: "15px",
                          fontWeight: 400,
                        }}
                      >
                        {user.Comentario}
                      </Typography>
                      <Typography variant="h4" sx={{ marginTop: 2, fontSize: "13px" }}>
                        {user.Fecha}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              ))}

              {noMoreRecords && (
                <Typography variant="h6" sx={{ textAlign: "center", fontSize: "15px", marginTop: 3 }}>
                  No hay más registros para mostrar
                </Typography>
              )}
              {isLoadingMore && (
                <Box sx={{ display: "flex", justifyContent: "center", marginY: 2 }}>
                  <LinearProgress sx={{ width: '100%' }} color="secondary" />
                </Box>
              )}
            </>
          )}
          {data.length === 0 && (
            <Typography variant="h6" sx={{ textAlign: "center", fontSize: "13px" }}>
              {userId ? message : ""}
            </Typography>
          )}
          {isLoading && <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <CircularProgress />
          </Box>}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
export default ActionsHistory;
