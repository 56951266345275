import React, { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import {
	Container,
	Card,
	CardContent,
	Tabs,
	Tab,
	Paper,
	BottomNavigation,
	BottomNavigationAction,
	Icon,
	Box,
	LinearProgress,
} from "@mui/material";
import electoral from "@services/ElectoralServices";
import Swal from "sweetalert2";
import BasicTable from "@components/Tables/BasicTable";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import PieChart from "@components/Charts/PieChart";
import SolidGaugeChart from "../../components/Charts/SolidGaugeChart";
import { numberWithCommas } from "@utils/Utilities";
import SelectSearch from "@components/Selects/SelectSearch";
import { getVars } from "@utils/global";

//catalogos
import { useCatalogs } from "@hooks/useCatalogs";

const AvanceMeta = () => {
	const { user } = getVars("Token");
	const defaultOption = [{ value: 0, label: "TODOS" }];
	const catalogsParams = [
		{ id: "regiones", getAll: false },
		{ id: "municipios", getAll: false },
		{ id: "municipios_dl", getAll: false },
	];
	const [type, setType] = useState(0);
	const [tab, setTab] = useState(0);
	const [tabType, setTabType] = useState("");
	const [data, setData] = useState([]);
	const [dataPerfiles, setDataPerfiles] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState({
		page: 0,
		pageSize: 10,
		tipo: "",
		dataid: null,
		filtered: [],
	});
	const [filtros, setFiltros] = useState({
		Region: { value: 0, label: "TODOS" },
		Municipio: { value: 0, label: "TODOS" },
	});
	const [dataPie, setDataPie] = useState({
		height: 300,
		distance: -10,
		loading: true,
		options: {
			title: "Avance Estatal",
			subtitle: `${100} de ${200}`,
		},

		pieColors: ["#9fc5e8", "#36A2EB"],
		series: [
			{
				name: "Avances",
				colorByPoint: true,
				data: [
					{
						name: "Avance",
						y: 30483,
						sliced: true,
						selected: true,
					},
					{
						name: "Meta",
						y: 500000 - 30483,
						sliced: true,
						selected: true,
					},
				],
			},
		],
	});
	const [tabs, setTabs] = useState(["Estatal", "Municipal"]);
	const [solidGauge, setSolidGauge] = useState([]);
	const [columns, setColumns] = useState([
		{ id: "Data", label: "", columnAction: false, width: 20 },
		{ id: "Meta", label: "Meta", columnAction: false, width: 50 },
		{ id: "Avance", label: "Avance", columnAction: false, width: 50 },
		{ id: "PAvance", label: "%Avance", columnAction: false, width: 50 },
	]);
	const [catRegionsFilter, setCatRegionsFilter] = useState([]);
	const [catMunicipalityDL, setCatMunicipalityDL] = useState([]);

	const { catalogs, load } = useCatalogs({
		catalogsOptions: catalogsParams,
		putDefaultOption: false,
	});

	/*   const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsParams) {
      newObject[item.id] = [];
    }
    return newObject;
  });
 */

	useEffect(() => {
		if (load === false) {
			//  setCatalogFiltered(catalogs);
			setCatRegionsFilter(defaultOption.concat(catalogs.regiones));
			setCatMunicipalityDL(defaultOption.concat(catalogs.municipios_dl));
		}
		// eslint-disable-next-line
	}, [load]);

	useEffect(() => {
		if (
			user.ambito_perfil.find((item) =>
				[99, 98, 114, 115, 16].includes(item.idPerfil)
			)
		) {
			setTabType("Estatal");
			setTableData({
				...tableData,
				tipo: "Estatal",
			});
			setTabs(["Estatal", "Municipal"]);
		} else if (
		/*     else if(user.ambito_perfil.find(item => [17].includes(item.idPerfil) )){
      setTabType("Regional");
      setTabs(["Regional", "Municipal"]);
      setTableData({
        ...tableData,
        tipo: "Regional"
      });
    } */
			user.ambito_perfil.find((item) => [18, 127, 128].includes(item.idPerfil))
		) {
			setTabType("Municipal");
			setTabs(["Municipal"]);
			setTableData({
				...tableData,
				tipo: "Municipal",
			});
		}
	}, []);

	useEffect(() => {
		if (tabType !== "") {
			getData();
		}
	}, [tableData, type]);

	const getData = () => {
		if (loading) {
			return true;
		}
		setLoading(true);

		if (type === 0) {
			electoral
				.getAvanceMetas(tableData)
				.then((res) => {
					if (res.results) {
						switch (tabType) {
							case "Estatal":
								setData(res.response.regiones);
								setDataPerfiles(res.response.detalle);
								break;
							/*  case "Regional":
              setData(res.response.regiones);
              setDataPerfiles(res.response.detalle);
              break; */
							case "Municipal":
								setData(res.response.municipios ? res.response.municipios : []);
								setDataPerfiles(res.response.detalle);
								break;
							default:
								break;
						}

						getEstatal(res.response);
						// setTotal(res.response.total);
					} else {
						Swal.fire({ title: res.message, icon: "warning" });
					}
				})
				.catch((e) => {
					Swal.fire({ title: e.message, icon: "warning" });
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			electoral
				.getAvanceMetasResponsabilidad(tableData)
				.then((res) => {
					if (res.results) {
						switch (tabType) {
							case "Estatal":
								setData(res.response.regiones);
								setDataPerfiles(res.response.detalle);
								break;
							/*         case "Regional":
              setData(res.response.regiones);
              setDataPerfiles(res.response.detalle);
              break; */
							case "Municipal":
								setData(res.response.municipios ? res.response.municipios : []);
								setDataPerfiles(res.response.detalle);
								break;
							default:
								break;
						}

						getEstatal(res.response);
						// setTotal(res.response.total);
					} else {
						Swal.fire({ title: res.message, icon: "warning" });
					}
				})
				.catch((e) => {
					Swal.fire({ title: e.message, icon: "warning" });
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const getEstatal = (data) => {
		setDataPie({
			...dataPie,
			loading: false,
			options: {
				title: "Avance Estatal",
				subtitle: `${data.resumen.Avance} de ${data.resumen.Meta}`,
			},
			series: [
				{
					name: "Avances",
					colorByPoint: true,
					data: [
						{
							name: "Avance",
							y: parseInt(data.resumen.Avance),
							sliced: true,
							selected: true,
						},
						{
							name: "Meta",
							y: parseInt(data.resumen.Meta) - parseInt(data.resumen.Avance),
							sliced: true,
							selected: true,
						},
					],
				},
			],
		});

		let ArrayGrafica = [];
		data.detalle.forEach((element) => {
			ArrayGrafica.push({
				height: 300,
				distance: -10,
				options: {
					title: element.Data,
					subtitle: `${numberWithCommas(
						parseInt(element.Avance)
					)} de ${numberWithCommas(parseInt(element.Meta))}`,
				},
				loading: false,
				series: [
					{
						name: "Avance",
						data: [element.PAvance],
						valueSuffix: "",
					},
				],
			});
		});

		setSolidGauge(ArrayGrafica);
	};

	const handleChangePagination = (pagination) => {
		setTableData({ ...tableData, ...pagination });
	};

	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
		setTabType(tabs[newValue]);
		setFiltros({
			Region: { value: 0, label: "TODOS" },
			Municipio: { value: 0, label: "TODOS" },
		});
		setTableData({
			...tableData,
			page: 0,
			dataid: null,
			tipo: tabs[newValue],
			filtered: [],
		});
	};

	const handleRegion = (e) => {
		setFiltros({
			...filtros,
			Region: e,
		});
		setTableData({
			...tableData,
			dataid: e.value,
		});
	};

	const handleMunicipio = (e) => {
		setFiltros({
			...filtros,
			Municipio: e,
		});
		setTableData({
			...tableData,
			dataid: e.value,
		});
	};

	return (
		<AdminLayout>
			<Container maxWidth="false">
				<ActionHeader title={"Avance Meta"} />
				<Grid2 container spacing={1}>
					<Grid2 item xs={12} sm={12} md={6} lg={6}>
						<Card>
							<Tabs
								sx={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
									margin: "auto",
								}}
								value={tab}
								onChange={handleChangeTab}
							>
								{tabs.map((item) => {
									return <Tab label={item} />;
								})}
							</Tabs>
							{/*        {tab === 1 && (
                <Box sx={{ m: 2, mb: 0 }}>
                  <SelectSearch
                    multiple={false}
                    placeholder="Región"
                    name="region"
                    closeMenuOnSelect={true}
                    value={filtros.Region}
                    onChange={(e) => handleRegion(e)}
                    options={catRegionsFilter}
                    //disabled={catalogs.municipios.length === 2 ? true : false}
                    isLoading={load}
                  />
                </Box>
              )} */}
							{tab === 2 && (
								<Box sx={{ m: 2, mb: 0 }}>
									<SelectSearch
										multiple={false}
										placeholder="Municipio"
										name="municipio"
										closeMenuOnSelect={true}
										value={filtros.Municipio}
										onChange={(e) => handleMunicipio(e)}
										options={catMunicipalityDL}
										//disabled={catalogs.municipios.length === 2 ? true : false}
										isLoading={load}
									/>
								</Box>
							)}
							{data.length > 1 && (
								<CardContent sx={{ height: 500, overflow: "auto" }}>
									<br />
									<BasicTable
										rows={data.length > 0 ? data : []}
										hcolumns={columns}
										pageProp={tableData.page}
										pageSize={tableData.pageSize}
										total={total}
										handleChangePagination={handleChangePagination}
										isLoading={loading}
										stickyHeader={true}
										showPagination={false}
									/>
								</CardContent>
							)}
							<br />
							<CardContent sx={{ height: 500, overflow: "auto" }}>
								<BasicTable
									rows={dataPerfiles.length > 0 ? dataPerfiles : []}
									hcolumns={columns}
									pageProp={tableData.page}
									pageSize={tableData.pageSize}
									total={total}
									handleChangePagination={handleChangePagination}
									isLoading={loading}
									stickyHeader={true}
									showPagination={false}
								/>
							</CardContent>
						</Card>
					</Grid2>
					<Grid2 item xs={12} sm={12} md={6} lg={6}>
						<Card>
							<CardContent sx={{ height: 1200, overflow: "auto" }}>
								{loading ? <></> : <PieChart {...dataPie} />}
								{loading ? (
									<LinearProgress />
								) : (
									solidGauge.map((item) => {
										return (
											<>
												<br />
												<SolidGaugeChart {...item} />
											</>
										);
									})
								)}
							</CardContent>
						</Card>
					</Grid2>
				</Grid2>
				<Paper
					sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
					elevation={3}
				>
					<BottomNavigation
						showLabels
						sx={{ backgroundColor: "#172764" }}
						value={type}
						onChange={(event, newValue) => {
							setType(newValue);
						}}
					>
						<BottomNavigationAction
							sx={{ color: "white" }}
							label="Por Acreditar"
							icon={<Icon>query_stats</Icon>}
						/>
						<BottomNavigationAction
							sx={{ color: "white" }}
							label="Por Asignación"
							icon={<Icon>bar_chart</Icon>}
						/>
					</BottomNavigation>
				</Paper>
			</Container>
		</AdminLayout>
	);
};
export default AvanceMeta;
