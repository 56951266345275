import React, { useState, useEffect } from "react";

//MUI
import { Box, Button, Grid, TextField } from "@mui/material";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import FilterCollapse from "@components/Filters/FilterCollapse";
import { isTypePhone } from "@utils/validations";

//catalogos
import { useCatalogs } from "@hooks/useCatalogs";

const FilterResponsable = ({
	onChangeFilter = () => {},
	filterData = true,
	region = false,
	df = true,
	municipioDL = true,
	Responsabilidad = true,
	refreash,
	typeResponsables = 2,
	notPhone = false,
}) => {
	// catalogo
	const catalogsParams = [
		{ id: "regiones", getAll: false },
		{ id: "df", getAll: false },
		{ id: "municipios_dl", getAll: false },
		{
			id: "perfiles_hijos",
			filtered: [
				{ id: "perfiles.idTipo", filter: "=", value: typeResponsables },
			],
			getAll: false,
		},
	];

	//variables
	const defaultOption = [{ value: 0, label: "TODOS" }];
	//States
	const [loading, setLoading] = useState([]);
	const [catResponsable, setCatResponsables] = useState([]);
	const [catRegionsFilter, setCatRegionsFilter] = useState([]);
	const [catDFFilter, setCatDFFilter] = useState([]);
	const [catMunicipalityDL, setCatMunicipalityDL] = useState([]);
	const [clean, setClean] = useState(false);
	const [dataCatalog, setDataCatalog] = useState({
		Region: 0,
		DF: 0,
		MunicipioDL: 0,
		Responsabilidad: 0,
	});
	const [filter, setFilter] = useState({
		Celular: "",
		NombreCompleto: "",
	});

	const { catalogs, load } = useCatalogs({
		catalogsOptions: catalogsParams,
		putDefaultOption: false,
	});

	const [catalogsFiltered, setCatalogFiltered] = useState(() => {
		let newObject = {};
		for (const item of catalogsParams) {
			newObject[item.id] = [];
		}
		return newObject;
	});

	useEffect(() => {
		clearFilter();
		if (load === false) {
			console.log(catalogs.perfiles_hijos);
			setCatalogFiltered(catalogs);
			setCatDFFilter(defaultOption.concat(catalogs.df));
			setCatRegionsFilter(defaultOption.concat(catalogs.regiones));
			setCatMunicipalityDL(defaultOption.concat(catalogs.municipios_dl));
			setCatResponsables(defaultOption.concat(catalogs.perfiles_hijos));
		}
		// eslint-disable-next-line
	}, [load]);

	const handleChangeRegion = (event) => {
		const idRegion = event.target.value;
		setDataCatalog({
			...dataCatalog,
			Region: idRegion,
			MunicipioDL: 0,
		});
		// selectedPolygons([]);

		if (idRegion > 0) {
			console.log(
				defaultOption.concat(
					catalogsFiltered.municipios_dl.filter(
						(item) => item.idRegion === idRegion
					)
				)
			);
			setCatMunicipalityDL(
				defaultOption.concat(
					catalogsFiltered.municipios_dl.filter(
						(item) => item.idRegion === idRegion
					)
				)
			);
			setCatMunicipalityDL(
				defaultOption.concat(
					catalogsFiltered.municipios_dl.filter(
						(item) => item.idRegion === idRegion
					)
				)
			);
		} else {
			setCatMunicipalityDL(defaultOption.concat(catalogs.municipios_dl));
		}
	};

	const handleChangeDF = (event) => {
		const idDF = event.target.value;
		setDataCatalog({
			...dataCatalog,
			DF: idDF,
			MunicipioDL: 0,
		});
		// selectedPolygons([]);

		if (idDF > 0) {
			setCatMunicipalityDL(
				defaultOption.concat(
					catalogsFiltered.municipios_dl.filter((item) => {
						if (typeof item.idDF === "string") {
							let idDFArray = item.idDF.split(",");
							return idDFArray.includes(idDF.toString());
						}
					})
				)
			);
			/*  setCatMunicipalityDL(
        defaultOption.concat(
          catalogsFiltered.municipios_dl.filter((item) => item.idDF === idDF)
        )
      ); */
		} else {
			setCatMunicipalityDL(defaultOption.concat(catalogs.municipios_dl));
		}
	};

	const handleChangeMunicipalityDL = (e) => {
		const idMunicipality = e.target.value;
		setDataCatalog({
			...dataCatalog,
			MunicipioDL: idMunicipality,
		});
	};

	const hadleChangeTextField = (e) => {
		setFilter({
			...filter,
			[e.target.name]: e.target.value,
		});
	};

	const searchFilter = () => {
		const valueFilter = {
			Region: dataCatalog.Region,
			DF: dataCatalog.DF,
			MunicipioDL: dataCatalog.MunicipioDL,
			Responsabilidad: dataCatalog.Responsabilidad,
			Celular: filter.Celular,
			ClaveElector: filter.ClaveElector,
			NombreCompleto: filter.NombreCompleto,
		};

		onChangeFilter(valueFilter);
	};

	const clearFilter = () => {
		setClean(!clean);
		const newFilter = {
			Region: 0,
			DF: 0,
			MunicipioDL: 0,
			Responsabilidad: 0,
			Celular: "",
			NombreCompleto: "",
		};
		// setCatRegionsFilter(defaultOption.concat(catalogsFiltered.regiones));
		setCatMunicipalityDL(defaultOption.concat(catalogsFiltered.municipios_dl));
		setCatResponsables(defaultOption.concat(catalogsFiltered.perfiles_hijos));

		setDataCatalog({
			...dataCatalog,
			Region: 0,
			DF: 0,
			MunicipioDL: 0,
			Responsabilidad: 0,
		});

		setFilter(newFilter);
		onChangeFilter(newFilter);
	};

	return (
		<>
			<FilterCollapse expand={false} effect={refreash}>
				<Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
					{region && (
						<Grid item xs={12} md={4} sm={2}>
							<InputSelect
								label="Delimitación"
								options={catRegionsFilter}
								name="region"
								value={
									catRegionsFilter.length === 2
										? catRegionsFilter[1].value
										: dataCatalog.Region
								}
								onChange={handleChangeRegion}
								disabled={catRegionsFilter.length === 2 ? true : false}
								sx={{ width: "100%" }}
							/>
						</Grid>
					)}
					{df && (
						<Grid item xs={12} md={4} sm={2}>
							<InputSelect
								label="Distrito Federal"
								options={catDFFilter}
								name="df"
								value={
									catDFFilter.length === 2
										? catDFFilter[1].value
										: dataCatalog.DF
								}
								onChange={handleChangeDF}
								disabled={
									catalogsFiltered.municipios_dl.length === 1
										? true
										: catDFFilter.length === 2
										? true
										: false
								}
								sx={{ width: "100%" }}
							/>
						</Grid>
					)}
					{municipioDL && (
						<Grid item xs={12} md={4}>
							<InputSelect
								label="Municipio"
								options={catMunicipalityDL}
								name="municipio"
								value={
									catalogsFiltered.municipios_dl.length === 1
										? catalogsFiltered.municipios_dl[0]?.value
										: dataCatalog.MunicipioDL
								}
								onChange={handleChangeMunicipalityDL}
								disabled={
									catalogsFiltered.municipios_dl.length === 1 ? true : false
								}
								sx={{ width: "100%" }}
							/>
						</Grid>
					)}
					{Responsabilidad && (
						<Grid item xs={12} md={4} sm={2}>
							<InputSelect
								label="Asignación"
								options={catResponsable}
								name="Responsabilidad"
								value={
									catResponsable.length === 2
										? catResponsable[1].value
										: dataCatalog.Responsabilidad
								}
								onChange={(e) =>
									setDataCatalog({
										...dataCatalog,
										Responsabilidad: e.target.value,
									})
								}
								disabled={catResponsable.length === 2 ? true : false}
								sx={{ width: "100%" }}
							/>
						</Grid>
					)}
				</Grid>
				{filterData && (
					<Grid
						container
						spacing={2}
						marginBottom={"1rem"}
						marginTop={"0.5rem"}
					>
						{!notPhone && (
							<Grid item xs={12} md={4}>
								<TextField
									label="Celular"
									size="small"
									name="Celular"
									value={filter.Celular}
									//onChange={hadleChangeTextField}
									onChange={(e) =>
										hadleChangeTextField({
											target: {
												name: "Celular",
												value: isTypePhone(e.target.value)
													? e.target.value
													: filter.Celular,
											},
										})
									}
									sx={{ width: "100%" }}
								/>
							</Grid>
						)}

						<Grid item xs={12} md={4}>
							<TextField
								label="Nombre"
								size="small"
								name="NombreCompleto"
								value={filter.NombreCompleto.toUpperCase()}
								onChange={hadleChangeTextField}
								sx={{ width: "100%" }}
							/>
						</Grid>
					</Grid>
				)}

				<Box display={"flex"} justifyContent={"flex-end"}>
					<Button
						variant="contained"
						color="primaryDark"
						onClick={searchFilter}
					>
						Filtrar
					</Button>
					<Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
						Limpiar
					</Button>
				</Box>
			</FilterCollapse>
		</>
	);
};

export default FilterResponsable;
