import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

import { ACTIONS_SPEED, COLUMNS_CIERRE } from "@data/constants/Sije";
import Swal from "sweetalert2";
import sije from "@services/SijeService";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { blue } from "@mui/material/colors";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import CardCloseCasilla from "@components/Sije/OperationCasilla/CardCloseCasilla";
import Download from "@components/Downloads/Download";
import Casilla from "@components/Sije/CloseCapture/Casilla";
import Vote from "@components/Sije/CloseCapture/Vote";
import ModalPreviewImages from "@components/Sije/OperationCasilla/ModalPreviewImages";

const CloseCapture = () => {
  const [valueTab, setValueTab] = useState("1");
  const [nameTab, setNameTab] = useState("CIERRE DE CASILLAS");
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [total, setTotal] = useState(10);
  const [dataListCierre, setDataListCierre] = useState([]);
  const [isDownload, setIsDownloading] = useState(false);
  const [dataImage, setDataImage] = useState([]);
  const [loadingDataImage, setLoadingDataImage] = useState(false);
  const [preview, setOpenPreview] = useState(false);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const handleChangeTab = (event, newValue) => {
    setNameTab(event.target.innerText);
    setValueTab(newValue);
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const getListSijeCierre = async (data) => {
    try {
      setLoadingDetail((prevState) => !prevState);
      const result = await sije.getListSijeCierre(data);
      const { response, results } = await result;
      if (results) {
        const _data = response.data.map((item) => {
          return {
            ...item,
            Conectividad: (
              <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                <Icon
                  fontSize="small"
                  color={item.ConectividadInternet ? "primary" : "disabled"}
                >
                  wifi
                </Icon>
                <Icon
                  fontSize="small"
                  color={item.ConectividadTelefono ? "primary" : "disabled"}
                >
                  phone_iphone
                </Icon>
              </Stack>
            ),
            TipoTransmision: (
              <Box>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={item.TipoTransmision.total}
                  direction="right"
                  sx={{
                    "& .MuiFab-primary": { width: 32, height: 32 },
                  }}
                >
                  {ACTIONS_SPEED.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      sx={{
                        width: 32,
                        height: 32,
                        color: item.TipoTransmision[action.type] && blue[700],
                      }}
                    />
                  ))}
                </SpeedDial>
              </Box>
            ),
            tieneFoto: item.tieneFoto && (
              <IconButton
                color="primaryDark"
                onClick={() => getListImage({ idCasilla: item.idCasilla })}
              >
                <Icon>photo_camera</Icon>
              </IconButton>
            ),
          };
        });
        setDataListCierre(_data);
        setTotal(response.total);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingDetail((prevState) => !prevState);
    }
  };

  const getListImage = ({ idCasilla }) => {
    setOpenPreview(true);
    getPicturesListSije({ idCasilla });
  };

  const getPicturesListSije = async ({ idCasilla }) => {
    try {
      setLoadingDataImage(true);
      const result = await sije.getPicturesListCierreSije({
        filtered: [
          {
            id: "sije_casillas_fotos.idCasilla",
            filter: "=",
            value: idCasilla,
          },
        ],
      });
      const { results, response, message } = result;
      if (results) {
        setDataImage(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    } finally {
      setLoadingDataImage(false);
    }
  };

  const handleExport = async () => {
    try {
      setIsDownloading((prevState) => !prevState);
      const result = await sije.exportSije({
        params: {},
        api: "sije/export-list-sije-cierre",
      });
      const { success } = await result;

      if (success) {
        Swal.fire({
          title: "Descargado con éxito.",
          icon: "success",
        });
      } else {
        throw new Error("Ocurrió un error, contacte al administrador.");
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const reload = () => {
    getListSijeCierre(data);
  };

  useEffect(() => {
    getListSijeCierre(data);
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={nameTab} />
        <LoadingForm
          loadinMessage={"Guardando datos..."}
          isLoading={isLoadigForm}
          success={isSuccessFormSave}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        />
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab label="CIERRE DE CASILLAS" value="1" />
              <Tab label="CIERRE DE VOTACIÓN" value="2" />
              <Tab label="DETALLE" value="3" />
            </TabList>
          </Box>

          {/* <Container maxWidth={false}>
            <Grid2 container marginTop={2}>
              <Grid2 xs={12} md={6} lg={6}>
                <CardCloseCasilla loading={isLoadigForm} />
              </Grid2>
            </Grid2>
          </Container> */}

          <TabPanel value="1">
            <Casilla
              nameTab={nameTab}
              isLoadigForm={isLoadigForm}
              setIsLoadingForm={setIsLoadingForm}
              setOpenLinearProgress={setOpenLinearProgress}
              setIsSuccessFormSave={setIsSuccessFormSave}
              reload={reload}
            />
          </TabPanel>
          <TabPanel value="2">
            <Vote
              nameTab={nameTab}
              isLoadigForm={isLoadigForm}
              setIsLoadingForm={setIsLoadingForm}
              setOpenLinearProgress={setOpenLinearProgress}
              setIsSuccessFormSave={setIsSuccessFormSave}
              reload={reload}
            />
          </TabPanel>

          <TabPanel value="3">
            <Grid2 container marginTop={2}>
              <Grid2 xs={12} md={4} lg={4}>
                <CardCloseCasilla loading={isLoadigForm} />
              </Grid2>
            </Grid2>

            <Card className="card-primary">
              <CardContent>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  marginBottom={2}
                >
                  <Typography variant="body2" fontWeight={700}>
                    DETALLE
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    disabled={isDownload}
                    onClick={handleExport}
                  >
                    Exportar
                  </Button>
                </Stack>
                {isDownload && (
                  <Box marginBottom={2}>
                    <Download format={"xlsx"} isDownload={isDownload} />
                  </Box>
                )}
                <BasicTable
                  rows={dataListCierre}
                  hcolumns={COLUMNS_CIERRE}
                  total={total}
                  pageProp={data.page}
                  pageSize={data.pageSize}
                  isLoading={loadingDetail}
                  minHeight="0px"
                  stickyHeader={true}
                  handleChangePagination={handleChangePagination}
                  handleManualSort={handleRequestSort}
                />
              </CardContent>
            </Card>
          </TabPanel>
        </TabContext>
        <ModalPreviewImages
          images={dataImage}
          openModal={preview}
          loading={loadingDataImage}
          setOpenModal={setOpenPreview}
        />
      </Container>
    </AdminLayout>
  );
};

export default CloseCapture;
