import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Card, CardContent, CircularProgress } from "@mui/material";
import useWindowDimensions from "@hooks/useWindowDimensions";
import Grid2 from "@mui/material/Unstable_Grid2";
import moment from "moment";

const Chart = ({ horizontal, vertical, message, graphType, loading }) => {
  const { height, width } = useWindowDimensions();
  let barColor = "#53D0EC";

  if (graphType === 1) {
    barColor = "#42a5f5";
  } else if (graphType === 2) {
    barColor = "#FFFF00";
  } else if (graphType === 3) {
    barColor = "#ef9a9a";
  }

  const horizontalData = horizontal || [];
  const verticalData = vertical || [];

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "bar",
      height: width > 430 ? height * 0.4 : 400,
    },
    title: {
      text: `${message.mensaje} ${message.type}`,
      style: {
        fontSize: width > 1400 ? width * 0.012 : "18px",
      },
    },
    subtitle: {
      text: `Última actualización - ${moment().format('DD/MM/YYYY HH:mm')}`,
      style: {
        fontSize: width > 1400 ? width * 0.0095 : "16px",
      },
    },
    xAxis: {
      categories: verticalData,
      style: {
        fontSize: width > 1400 ? width * 0.01 : "14px",
      },
    },
    yAxis: {
      title: {
        text: message.mensaje,
      },
      style: {
        fontSize: width > 1400 ? width * 0.01 : "16px",
      },
    },
    series: [
      {
        name: message.mensaje,
        data: horizontalData,
        color: barColor,
      },
    ],
  };

  return (
    <Card className="card-primary">
      <CardContent>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </CardContent>
    </Card>
  );
};

export default Chart;
