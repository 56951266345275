import React, { useEffect, useState } from "react";
// MUI
import { CircularProgress, Card, CardContent, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

//PLUGINS
import Highcharts from "highcharts";
//import HighchartsReact from "highcharts-react-official";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import useWindowDimensions from "@hooks/useWindowDimensions";

//ASSETS IMAGES
import USImage from "../../assets/img/us.svg";
import CanadaImage from "../../assets/img/canada.svg";

HighchartsDrilldown(Highcharts);

const GraficaDrillDown = (props) => {
  const { loading, title, subTitle, showChart, data } = props;

  //STATES
  const { height, width } = useWindowDimensions();
  const [chartInitialized, setChartInitialized] = useState(false);
  const [drilldown, setDrilldown] = useState(false);

  useEffect(() => {
    if (!chartInitialized && !loading) {
      const colors = Highcharts.getOptions().colors.map((c, i) =>
        Highcharts.color(Highcharts.getOptions().colors[0])
          .brighten((i - 3) / 7)
          .get()
      );
      const chartData = {
        chart: {
          type: "column",
          height: width > 1440 ? height * 0.4 : 400,
          //EVENT TITLE CHANGE
          events: {
            drilldown: function (e) {
              this.setTitle({
                text: "AVANCE POR ESTADO",
              });
              setDrilldown(true);
            },
            drillup: function () {
              this.setTitle({
                text: "AVANCE POR PAÍS",
              });
              setDrilldown(false);
            },
          },
        },
        colors: colors,
        title: {
          align: "center",
          text: `${title}`,
          style: {
            color: "#999",
            fontSize: width > 1440 ? width * 0.012 : "18px",
          },
        },
        credits: {
          enabled: false,
        },
        subtitle: {
          align: "center",
          text: `${subTitle}`,
          style: {
            fontSize: width > 1440 ? width * 0.0085 : "12px",
          },
        },
        xAxis: {
          type: "category",
          labels: {
            useHTML: true,
            //SET IMAGE OR NAME OF THE STATE
            formatter: function () {
              if (drilldown) {
                const point = this.point;
                return point.name;
              } else {
                const countryName = this.value;
                if (countryName === "Estados Unidos") {
                  return `<span><img src="${USImage}" alt="Estados Unidos" style="width: 50px; height: 50px;"/></span>`;
                } else if (countryName === "Canada") {
                  return `<span><img src="${CanadaImage}" alt="Canadá" style="width: 50px; height: 50px;"/></span>`;
                }
                return countryName;
              }
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: "{point.y}",
            },
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{point.key}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: ' +
            "<b>{point.y}</b><br/>",
        },
        series: [
          {
            name: "Paises",
            colorByPoint: true,
            data: data.country_series,
          },
        ],
        drilldown: {
          drillUpButton: {
            enabled: false,
            theme: {
              style: {
                display: "none",
              },
            },
          },
          series: data.state_series,
        },
      };

      const container = document.getElementById("container");
      if (container) {
        Highcharts.chart("container", chartData);
        setChartInitialized(true);
      }
    } else {
      //CHANGE OF USE BETWEEN IMAGE AND NAME
      const chart = Highcharts.charts[0];
      if (chart) {
        chart.update({
          xAxis: {
            labels: {
              formatter: function () {
                if (drilldown) {
                  const point = this.point;
                  return point.name;
                } else {
                  const countryName = this.value;
                  if (countryName === "Estados Unidos") {
                    return `<span><img src="${USImage}" alt="Estados Unidos" style="width: 50px; height: 50px;"/></span>`;
                  } else if (countryName === "Canada") {
                    return `<span><img src="${CanadaImage}" alt="Canadá" style="width: 50px; height: 50px;"/></span>`;
                  }
                  return countryName;
                }
              },
            },
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [chartInitialized, drilldown, subTitle, width, loading]);
  const handleBackToPaises = () => {
    if (drilldown) {
      setDrilldown(false);
      setChartInitialized(false);
    }
  };

  return (
    <Card
      // sx={{
      //   boxShadow: { xs: 0, sm: 1 },
      //   borderRadius: 2,
      //   backgroundColor: { xs: loading ? "white" : "transparent", sm: "white" },
      //   height: "96%",
      // }}
      className="card-primary"
    >
      <CardContent>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} className="color-componentes"/>
          </Grid2>
        ) : showChart ? (
          <Grid2 style={{ position: "relative" }}>
            {drilldown && (
              <Button
                variant="contained"
                color="primaryDark"
                size="small"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 1, // PUTS THE BUTTON OVER THE GRAPH
                }}
                onClick={handleBackToPaises}
              >
                <ArrowBackIcon />
                Volver
              </Button>
            )}
            <Grid2 id="container" style={{ width: "100%", height: "100%" }} />
          </Grid2>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default GraficaDrillDown;
