import { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import { CATALOG_OPTIONS } from "@data/constants/Mobilization";
import Swal from "sweetalert2";

const Filter = ({ onSearch, onClear }) => {
  const [typeView, setTypeView] = useState([1]);
  const [catalogs, setCatalogs] = useState([]);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of CATALOG_OPTIONS) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [catView] = useState([
    { label: "Regional", value: 1 },
    { label: "Distrito Federal", value: 2 },
    { label: "Distrito Local", value: 3 },
  ]);
  const [filterValue, setFilterValue] = useState({
    tipoVista: 1,
    region: "",
    ditritoFederal: "",
    distritoLocal: "",
    municipio: "",
    poligono: "",
    seccion: "",
    color: "",
  });

  const isIncluded = (value) => typeView.includes(value);

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs(true);
      const result = await sije.getCatalogs({ catalogs: CATALOG_OPTIONS });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(response.catalogs);
        setCatalogs(response.catalogs);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const handleChangeTypeView = ({ event }) => {
    const view = event.target.value;
    setFilterValue((prevState) => ({
      ...prevState,
      tipoVista: view,
      region: "",
      ditritoFederal: "",
      distritoLocal: "",
      municipio: "",
      poligono: "",
      seccion: "",
      color: "",
    }));
    setTypeView([view]);
    setCatalogFiltered(catalogs);
  };

  const handleChangeFilter = ({ name, value }) => {
    setFilterValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCatalogFilterChange = ({
    filterKey,
    filterValue,
    filterType,
  }) => {
    setCatalogFiltered((prevState) => ({
      ...prevState,
      [filterKey]: catalogs[filterKey].filter(
        (item) => item[filterType] === filterValue
      ),
    }));
  };

  const handleChangeRegion = ({ event }) => {
    const _idRegion = event.target.value;
    handleCatalogFilterChange({
      filterKey: "municipios_reportes",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
    handleCatalogFilterChange({
      filterKey: "poligonos",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
  };

  const handleChangeDfs = ({ event }) => {
    const _idDfs = event.target.value;
    /* handleCatalogFilterChange({
      filterKey: "municipios_reportes",
      filterType: "idDL",
      filterValue: _idDfs,
    }); */
    handleCatalogFilterChange({
      filterKey: "poligonos",
      filterType: "idDL",
      filterValue: _idDfs,
    });
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idDL",
      filterValue: _idDfs,
    });
  };

  const handleChangeDls = ({ event }) => {
    const _idDls = event.target.value;
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idDL",
      filterValue: _idDls,
    });
  };

  const handleChangeMunicipality = ({ event }) => {
    const _idMunicipality = event.target.value;
    handleCatalogFilterChange({
      filterKey: "poligonos",
      filterType: "idMunicipioReportes",
      filterValue: _idMunicipality,
    });
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idMunicipioReportes",
      filterValue: _idMunicipality,
    });
  };

  const handleChangePolygon = ({ event }) => {
    const _idPolygon = event.target.value;
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idPoligono",
      filterValue: _idPolygon,
    });
  };

  const handleSearch = () => {
    onSearch({ filtereds: [] });
  };

  const handleClear = () => {
    onClear();
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary" sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="subtitle2" fontWeight={600} marginBottom={2}>
          FILTROS
        </Typography>

        <Grid2 container>
          <Grid2 xs={12} md={4} lg={4}>
            <BasicSelect
              name="tipoVista"
              label="Agrupar por"
              options={catView}
              value={filterValue.tipoVista}
              onChange={(e) => {
                handleChangeTypeView({ event: e });
              }}
              // isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
            />
          </Grid2>
        </Grid2>
        <Divider sx={{ mt: 1 }} />

        <Grid2 container marginTop={1} spacing={2}>
      {/*     {typeView.includes(1) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="region"
                label="Región"
                options={catalogsFiltered.regiones}
                value={filterValue.region}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeRegion({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )} */}
          {isIncluded(2) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="ditritoFederal"
                label="Distrito Federal"
                options={catalogsFiltered.dfs}
                value={filterValue.ditritoFederal}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeDfs({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}
          {isIncluded(3) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="distritoLocal"
                label="Distrito Local"
                options={catalogsFiltered.dls}
                value={filterValue.distritoLocal}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeDls({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}
          {(isIncluded(1) || isIncluded(2)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="municipio"
                label="Municipio"
                options={catalogsFiltered.municipios_reportes}
                value={filterValue.municipio}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeMunicipality({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}

          {(isIncluded(1) || isIncluded(2)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="poligono"
                label="Zona"
                options={catalogsFiltered.poligonos}
                value={filterValue.poligono}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangePolygon({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}

          {(isIncluded(1) || isIncluded(2) || isIncluded(3)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="seccion"
                label="Sección"
                options={catalogsFiltered.secciones.map((item) => ({
                  ...item,
                  label: String(item.label),
                }))}
                value={filterValue.seccion}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}
          {(isIncluded(1) || isIncluded(3)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="color"
                label="Color"
                options={catView}
                value={filterValue.color}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}
        </Grid2>
        <Stack
          direction={"row"}
          spacing={1}
          marginTop={2}
          justifyContent={"flex-end"}
        >
          <Button
            variant="outlined"
            color="primaryDark"
            size="small"
            onClick={handleClear}
          >
            Limpiar
          </Button>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            onClick={handleSearch}
          >
            Filtrar
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Filter;
