import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    numericSymbols: null,
  },
});

const BasicBarColumn = (props) => {
  const { categories, series, title, subtitle, textX, loading = false } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "column",
      },
      title: {
        text: title,
        align: "center",
        style: {
          fontWeight: "bold",
          fontSize: "16px",
        },
      },
      subtitle: {
        text: subtitle,
        align: "left",
      },
      xAxis: {
        categories: categories,
        title: {
          text: null,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: textX,
          align: "high",
        },
        labels: {
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        valueSuffix: " millions",
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "11px",
              fontFamily: "Verdana, sans-serif",
            },
          },
          borderWidth: 0,
        },
      },
      legend: {},
      credits: {
        enabled: false,
      },
      series: series,
    };

    setData(chartOptions);
    // eslint-disable-next-line
  }, [series, title, categories]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress className="color-componentes"/>
  );
};

export default BasicBarColumn;
