import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Icon,
  TextField,
  Typography,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import Swal from "sweetalert2";

const Filter = ({ onChange, onClear }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: "regiones" },
    { id: "municipios_reportes" },
    { id: "poligonos" },
    { id: "secciones" },
  ];

  //States
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    region: "",
    municipio: "",
    poligono: "",
    seccion: "",
    nombreCelular: "",
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs(true);
      const result = await sije.getCatalogs({ catalogs: catalogsOptions });
      const { results, response, message } = result;
      if (results) {
        const { catalogs } = response;
        setCatalogFiltered(catalogs);
        setCatalogs(catalogs);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterData({ ...filterData, [key]: e.target.value });
  };

  const handleCatalogFilterChange = ({
    filterKey,
    filterValue,
    filterType,
  }) => {
    setCatalogFiltered((prevState) => ({
      ...prevState,
      [filterKey]: catalogs[filterKey].filter(
        (item) => item[filterType] === filterValue
      ),
    }));
  };

  const handleChangeRegion = (e) => {
    const _idRegion = e.target.value;
    handleCatalogFilterChange({
      filterKey: "municipios_reportes",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
    handleCatalogFilterChange({
      filterKey: "poligonos",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
  };

  const handleChangeMunicipality = (e) => {
    const _idMunicipality = e.target.value;
    handleCatalogFilterChange({
      filterKey: "poligonos",
      filterType: "idMunicipioReportes",
      filterValue: _idMunicipality,
    });
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idMunicipioReportes",
      filterValue: _idMunicipality,
    });
  };

  const handleChangePolygon = (e) => {
    const _idPolygon = e.target.value;
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idPoligono",
      filterValue: _idPolygon,
    });
  };

  const handleSearch = () => {
    const filters = [
      {
        id: "cat_secciones.Seccion",
        key: "seccion",
      },
      {
        id: "cat_municipios_reportes.Municipio",
        key: "municipio",
      },
      {
        id: "cat_poligonos.Poligono",
        key: "poligono",
      },
      {
        id: "cat_region.Region",
        key: "region",
      },
      {
        id: [
          "usuarios.Username",
          "usuarios.Nombre",
          "usuarios.Paterno",
          "usuarios.Materno",
        ],
        key: "nombreCelular",
      },
    ];

    const filtered = filters
      .filter((filter) => {
        const value = filterData[filter.key];
        return (
          value !== undefined &&
          value !== null &&
          (typeof value === "string" ? value.length > 0 : value > 0)
        );
      })
      .map((filter) => ({
        id: filter.id,
        filter: filter.key === "nombreCelular" ? "LIKE" : "=",
        value: filterData[filter.key],
      }));

    onChange({ filtereds: filtered });
  };

  const handleClear = () => {
    setCatalogFiltered(catalogs);
    setFilterData((prevState) => ({
      ...prevState,
      region: "",
      municipio: "",
      poligono: "",
      seccion: "",
      nombreCelular: "",
    }));
    onClear();
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary">
      <CardContent>
        <Box component={"div"} marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            FILTRO
          </Typography>
          <Divider />
        </Box>
        <Box component={"div"} marginBottom={2}>
          <BasicSelect
            name="region"
            label="Delimitación"
            options={catalogsFiltered.regiones}
            value={filterData.region}
            onChange={(e) => {
              handleChangeFilter(e);
              handleChangeRegion(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%", mb: 3 }}
          />

          <BasicSelect
            name="municipio"
            label="Municipio"
            options={catalogsFiltered.municipios_reportes}
            value={filterData.municipio}
            onChange={(e) => {
              handleChangeFilter(e);
              handleChangeMunicipality(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%", mb: 3 }}
          />

          <BasicSelect
            name="poligono"
            label="Zona"
            options={catalogsFiltered.poligonos}
            value={filterData.poligono}
            onChange={(e) => {
              handleChangeFilter(e);
              handleChangePolygon(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%", mb: 3 }}
          />

          <BasicSelect
            name="seccion"
            label="Sección"
            options={catalogsFiltered.secciones.map((item) => ({
              value: item.value,
              label: String(item.label),
            }))}
            value={filterData.seccion}
            onChange={(e) => {
              handleChangeFilter(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%", mb: 3 }}
          />

          <TextField
            name="nombreCelular"
            label="Nombre o Celular"
            size="small"
            value={filterData.nombreCelular.toUpperCase()}
            onChange={handleChangeFilter}
            sx={{ width: "100%", mb: 1 }}
          />
        </Box>
        <Box marginBottom={2}>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            startIcon={<Icon>search</Icon>}
            onClick={handleSearch}
            sx={{ width: "100%", mb: 1 }}
          >
            buscar
          </Button>
          <Button
            variant="outlined"
            color="primaryDark"
            size="small"
            startIcon={<Icon>search_off</Icon>}
            onClick={handleClear}
            sx={{ width: "100%" }}
          >
            Limpiar
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Filter;
