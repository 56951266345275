import { useState } from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Card,
	Typography,
	Icon,
	Box,
	Button,
	CardContent,
	CardActions,
	TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const ListMobilization = ({ data, setMovilization }) => {
	return (
		<Card className="card-primary">
			<CardContent>
				<TableContainer sx={{ marginTop: 2, borderRadius: 3 }}>
					<Table>
						<TableHead sx={{ backgroundColor: grey[100] }}>
							<TableRow>
								<TableCell align="right">
									<Typography variant="subtitle2" fontWeight={600}>
										SECCIÓN
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2" fontWeight={600}>
										RESPONSABLE
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2" fontWeight={600}>
										Asignación
									</Typography>
								</TableCell>
								<TableCell align="center">PROMOVIDOS MOVILIZADOS</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.length > 0 ? (
								data.map((row) => (
									<TableRow key={row.idUsuario}>
										<TableCell align="right">{row.Seccion}</TableCell>
										<TableCell>{row.NombreCompleto}</TableCell>
										<TableCell>{row.Responsabilidad}</TableCell>
										<TableCell align="center">
											<TextField
												size="small"
												sx={{ width: "50%" }}
												inputProps={{
													inputMode: "numeric",
													pattern: "[0-9]*",
												}}
											/>
										</TableCell>
										<TableCell></TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={4}>
										<Box
											display={"flex"}
											alignContent={"center"}
											alignItems={"center"}
											flexDirection={"column"}
										>
											<Icon sx={{ fontSize: "5rem", color: grey[500] }}>
												person_search
											</Icon>
											<Typography fontWeight={600} variant="h6" marginTop={1}>
												Datos No Disponible
											</Typography>
											<Typography variant="caption" marginTop={1}>
												Utiliza el filtro a tu derecha para realizar una
												búsqueda y obtener los resultados deseados.
											</Typography>
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
			{data.length > 0 && (
				<CardActions sx={{ justifyContent: "center" }}>
					<Button
						variant="contained"
						color="primaryDark"
						// onClick={() => setMovilization({ body: users })}
					>
						Guardar
					</Button>
				</CardActions>
			)}
		</Card>
	);
};

export default ListMobilization;
