import React, { useState, useEffect } from "react";

//MUI
import { Box, Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

//components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import CardStats from "@components/Card/CardStats";
import AgeGraphBar from "@components/Numeralia/AgeGraphBar";
import CompromisosServices from "@services/CompromisoServices";
import GraficaDrillDown from "@components/Compromisos/GraficaDrillDown";

//Plugins
import Swal from "sweetalert2";
import { numberWithCommas } from "@utils/Utilities";

const CompromisosExtranjero = () => {
	//States
	const [loadingGraph, setLoadingGraph] = useState(false);
	const [loadingDrillDown, setLoadingDrillDown] = useState(false);
	const [loadingCards, setLoadingCards] = useState(false);
	const [loadingTable, setLoadingTable] = useState(false);
	const [actualDate, setActualDate] = useState(new Date().toLocaleString());
	const [showChart, setShowChart] = useState(false);
	const [total, setTotal] = useState({
		capturadosHoy: 0,
		totalCapturados: 0,
	});
	const [tableAgeData, setTableAgeData] = useState({
		label: [],
		masculino: [],
		femenino: [],
		noBinario: [],
	});
	const [graphAgeData, setGraphAgeData] = useState({
		label: [],
		hombre: [],
		mujer: [],
		noBinario: [],
	});
	const [drillDownData, setDrillDownData] = useState({
		country_series: [],
		state_series: [],
	});

	//CODIGO DE GRAFICA AGE Y TABLA USO TEMPORAL
	useEffect(() => {
		const ApiResponseAge = async () => {
			setLoadingGraph(true);
			try {
				const result = await CompromisosServices.getChartByAge();
				const { results, message, response } = result;
				if (results) {
					const newData = response.data;
					setGraphAgeData({
						label: newData.categorias,
						hombre: newData.series.hombres.map((item) => parseInt(item)),
						mujer: newData.series.mujeres.map((item) => parseInt(item)),
						noBinario: newData.series.noBinarios.map((item) => parseInt(item)),
					});
				} else {
					Swal.fire({
						title: message,
						icon: "warning",
					});
					setGraphAgeData({
						label: [],
						hombre: [],
						mujer: [],
						noBinario: [],
					});
				}
			} catch (error) {
				Swal.fire({
					title: error.message,
					icon: "warning",
				});
				setGraphAgeData({
					label: [],
					hombre: [],
					mujer: [],
					noBinario: [],
				});
			} finally {
				setLoadingGraph(false);
			}
		};

		const ApiResponseCompromisos = async () => {
			setLoadingCards(true);
			try {
				const result = await CompromisosServices.getSummaryByScope();
				const { message, results, response } = result;
				if (results) {
					setTotal({
						...total,
						capturadosHoy: response.CapturadosHoy,
						totalCapturados: response.TotalCapturados,
					});
				} else {
					Swal.fire({
						title: message,
						icon: "warning",
					});
				}
			} catch (error) {
				Swal.fire({
					title: error.message,
					icon: "warning",
				});
			} finally {
				setActualDate(new Date().toLocaleString());
				setLoadingCards(false);
			}
		};

		const ApiResponseDillDown = async () => {
			setLoadingDrillDown(true);
			setShowChart(false);
			try {
				const result = await CompromisosServices.getChartByScope();
				const { message, results, response } = result;
				if (results) {
					setDrillDownData({
						...drillDownData,
						country_series: response.series.country_series,
						state_series: response.series.state_series,
					});
				} else {
					Swal.fire({
						title: message,
						icon: "warning",
					});
					setDrillDownData({
						country_series: [],
						state_series: [],
					});
				}
			} catch (error) {
				Swal.fire({
					title: error.message,
					icon: "warning",
				});
				setDrillDownData({
					country_series: [],
					state_series: [],
				});
			} finally {
				setLoadingDrillDown(false);
				setShowChart(true);
			}
		};

		const ApiResponseTableAge = async () => {
			setLoadingTable(true);
			try {
				const result = await CompromisosServices.getTableByAge();
				const { message, results, response } = result;
				const data = response.data;
				if (results) {
					setTableAgeData({
						label: data.map((item) => item.label),
						masculino: data.map((item) => parseInt(item.Masculino)),
						femenino: data.map((item) => parseInt(item.Femenino)),
						noBinario: data.map((item) => parseInt(item.NoBinario)),
					});
				} else {
					Swal.fire({
						title: message,
						icon: "warning",
					});
					setTableAgeData({
						label: [],
						masculino: [],
						femenino: [],
						noBinario: [],
					});
				}
			} catch (error) {
				Swal.fire({
					title: error.message,
					icon: "warning",
				});
				setTableAgeData({
					label: [],
					masculino: [],
					femenino: [],
					noBinario: [],
				});
			} finally {
				setLoadingTable(false);
			}
		};

		ApiResponseAge();
		ApiResponseCompromisos();
		ApiResponseDillDown();
		ApiResponseTableAge();
		// eslint-disable-next-line
	}, []);

	return (
		<AdminLayout>
			<Container maxWidth={false}>
				<ActionHeader title={"Compromiso en el extranjero"} />
				<Grid2 container spacing={2}>
					<Grid2 item xs={12} lg={4}>
						<CardStats
							icon={"fact_check"}
							title={"Capturados Hoy"}
							total={total.capturadosHoy}
							subIcon={"calendar_today"}
							subtitle={actualDate}
							loading={loadingCards}
						/>
					</Grid2>
					<Grid2 item xs={12} lg={4}>
						<CardStats
							icon={"groups"}
							title={"Promovidos"}
							total={total.totalCapturados}
							subIcon={"sync"}
							subtitle={"Actualización"}
							loading={loadingCards}
						/>
					</Grid2>
					<Grid2 item xs={12}>
						<GraficaDrillDown
							title={"AVANCE POR PAÍS"}
							subTitle={actualDate}
							loading={loadingDrillDown}
							data={drillDownData}
							showChart={showChart}
						/>
					</Grid2>
					<Grid2 item xs={12} lg={12}>
						<Box>
							<Grid2 container spacing={2}>
								<Grid2 item xs={12} lg={8.5}>
									<AgeGraphBar
										title={"RANGO POR EDAD"}
										data={graphAgeData}
										actualDate={actualDate}
										loading={loadingGraph}
									/>
								</Grid2>
								<Grid2 item xs={12} lg={3.5}>
									<BasicTable
										rows={tableAgeData.label.map((item, index) => ({
											label: item,
											masculino: numberWithCommas(
												tableAgeData.masculino[index] || "-"
											),
											femenino: numberWithCommas(
												tableAgeData.femenino[index] || "-"
											),
											noBinario: numberWithCommas(
												tableAgeData.noBinario[index] || "-"
											),
										}))}
										hcolumns={[
											{ id: "label", label: "Edad" },
											{ id: "masculino", label: "Masculino", align: "right" },
											{ id: "femenino", label: "Femenino", align: "right" },
											{ id: "noBinario", label: "No Binario", align: "right" },
										]}
										isLoading={loadingTable}
										stickyHeader={true}
										showPagination={false}
									/>
								</Grid2>
							</Grid2>
						</Box>
					</Grid2>
				</Grid2>
			</Container>
		</AdminLayout>
	);
};

export default CompromisosExtranjero;
