import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Material UI
import {
	Container,
	Typography,
	Card,
	CardContent,
	CardMedia,
} from "@mui/material";
import QRCode from "react-qr-code";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";

// Utils
import { getVars } from "@utils/global";
import { URL_PUBLICA_FRONT, ClipboardCopy } from "@utils/global";

// catalogos
import { useCatalogs } from "@hooks/useCatalogs";

const Welcome = () => {
	const { user } = getVars("Token");
	const catalogsOptions = [
		{
			id: "entidades",
			filtered: [{ id: "id", value: user.idEstado, filter: "=" }],
		},
	];
	const username = `${user.Nombre} ${user.Paterno} ${user.Materno}`;
	let delayNumber = 0;
	const [shortUrl, setShortUrl] = useState("");
	const { catalogs, load } = useCatalogs({
		catalogsOptions: catalogsOptions,
		putDefaultOption: false,
	});

	const [message, setMessage] = useState(``);
	const [messageLink, setMessageLink] = useState(``);

	useEffect(() => {
		if (user.ambito_perfil.filter((item) => item.idTipo === 5).length) {
			setMessage(
				`Comparte tu enlace a los que te ayudarán a defender Baja California`
			);
			setMessageLink(
				`Soy ${username} vengo de parte de Juan Carlos Hank, para darle continuidad a este gran cambio, te invito a que te únas aquí: ${shortUrl}`
			);
		} else {
			setMessage(
				`Comparte tu enlace a los que te ayudarán a defender Baja California.`
			);
			setMessageLink(
				`Soy ${username} vengo de parte de Juan Carlos Hank, para darle continuidad a este gran cambio, te invito a que te únas aquí: ${shortUrl}`
			);
		}
	}, [load]);

	useEffect(() => {
		if (Object.keys(user).length > 0) {
			setShortUrl(
				`${URL_PUBLICA_FRONT}convencidos/${user.idEstado}/${user.UUID}`
			);
		}

		/* 	if (fechaFin) {
			// Calcula el tiempo restante
			const targetDate = new Date(fechaFin); // Cambia a tu fecha objetivo
			const now = new Date();

			const timeDiff = targetDate - now;

			if (timeDiff > 0) {
				const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
				const hours = Math.floor(
					(timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

				setTimeRemaining({ days, hours, minutes, seconds });

				// Actualiza la cuenta regresiva cada segundo
				const countdownInterval = setInterval(() => {
					if (timeDiff > 0) {
						const updatedDiff = targetDate - new Date();
						const updatedDays = Math.floor(updatedDiff / (1000 * 60 * 60 * 24));
						const updatedHours = Math.floor(
							(updatedDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
						);
						const updatedMinutes = Math.floor(
							(updatedDiff % (1000 * 60 * 60)) / (1000 * 60)
						);
						const updatedSeconds = Math.floor(
							(updatedDiff % (1000 * 60)) / 1000
						);

						setTimeRemaining({
							days: updatedDays,
							hours: updatedHours,
							minutes: updatedMinutes,
							seconds: updatedSeconds,
						});
					} else {
						clearInterval(countdownInterval);
					}
				}, 1000);
			}
		} */
		//eslint-disable-next-line
	}, [user]);

	return (
		<AdminLayout>
			{/* 		<div>
				<Snackbar
					open={open}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					sx={{ mt: "50px" }}
					onClose={() => setOpen(false)}
				>
					<Alert
						severity="info"
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setOpen(false);
								}}
							>
								<Icon fontSize="inherit">close</Icon>
							</IconButton>
						}
						sx={{
							mb: 2,
							fontSize: "20px", // Tamaño de la fuente personalizado
							"& .MuiAlert-icon": {
								display: "none", // Oculta el icono de info
							},
						}}
					>
						{!load ? catalogs.entidades[0]?.label : ""}
					</Alert>
				</Snackbar>
			</div> */}
			<Container
				component="main"
				sx={{
					"&:hover .span-word": { animation: "bounce .6s" },
				}} /* maxWidth="lg" */
			>
				<Grid2 container rowSpacing={1} columnSpacing={1}>
					{true ? (
						<Grid2 item lg={12} xs={12} md={12}>
							<Card
								className="card-primary"
								sx={{ textAlign: "center", minHeight: "530px" }}
							>
								<Grid2 container spacing={2}>
									<Grid2 item xs={12}>
										<CardMedia
											component={() => (
												<QRCode
													value={shortUrl}
													sx={{
														height: "65%",
														width: "65%",
													}}
												/>
											)}
											alt="QR BAJA CALIFORNIA VERDE"
											title="QR BAJA CALIFORNIA VERDE"
											sx={{ marginBottom: "20px" }}
										/>
									</Grid2>
									<Grid2 item xs={12}>
										<CardContent
											sx={{
												flexGrow: 1,
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												border: "10px",
											}}
										>
											<Typography
												className="color-letras"
												variant="h5"
												component="div"
												align="center"
												gutterBottom
											>
												¡Hola! {username}
											</Typography>
											<Typography
												variant="body1"
												align="center"
												sx={{ marginBottom: "20px", whiteSpace: "pre-wrap" }}
											>
												{message}
											</Typography>
											<Typography
												className="color-letras"
												variant="caption"
												align="center"
												component={Link}
												href={shortUrl}
												target="_blank"
												sx={{
													fontSize: "15px",
													marginBottom: "20px",
													whiteSpace: "pre-wrap",
												}}
											>
												{shortUrl}
											</Typography>
											<ClipboardCopy copyText={messageLink} />
										</CardContent>
									</Grid2>
								</Grid2>
							</Card>
						</Grid2>
					) : (
						<Grid2 item lg={12} xs={12} md={12}>
							<Typography variant="h5" fontWeight={700}>
								¡Hola!{" "}
								{username.map((item, index) => (
									<span
										className="color-letras"
										key={index}
										style={{ display: "inline-block", marginRight: 8 }}
									>
										{item.split("").map((word, i) => (
											<span
												className="span-word"
												key={i}
												style={{
													display: "inline-block",
													animationDelay: `${delayNumber++ * 0.05}s`,
												}}
											>
												{word}
											</span>
										))}
									</span>
								))}
							</Typography>
						</Grid2>
					)}
					{/* 			<Grid2
						container
						xs={12}
						md={user.linkToShare !== 0 ? 7 : 12}
						lg={user.linkToShare !== 0 ? 7 : 12}
						spacing={1}
					>
						<Grid2 item lg={4} xs={12} md={12}>
							<CardIconColor
								title="Invitados"
								total={totalesAvance.TotalGuest}
								color="info"
								icon={"schema"}
								sx={{ height: "215px" }}
								loading={loading}
							/>
						</Grid2>
						<Grid2 item lg={4} xs={12} md={12}>
							<CardIconColor
								title="Promovidos"
								total={totalesAvance.TotalWeb}
								color="success"
								icon={"schema"}
								sx={{ height: "215px" }}
								loading={loading}
							/>
						</Grid2>
						<Grid2 item lg={4} xs={12} md={12}>
							<CardIconColor
								title={`${timeRemaining.hours} horas, ${timeRemaining.minutes} minutos, ${timeRemaining.seconds} segundos`}
								total={`${timeRemaining.days} días`}
								color="warning"
								icon={"schema"}
								sx={{ height: "215px" }}
							/>
						</Grid2>
						{middleware.checkMenuActionId("Ver", 256) && (
							<Grid2 item lg={6} xs={12} md={12}>
								<Card
									className="card-primary"
									onClick={() => navigate("/promovidos-registro")}
									onMouseEnter={() => setIsHovered(1)}
									onMouseLeave={() => setIsHovered(-1)}
									sx={{
										minHeight: 400,
										borderRadius: 10,
										boxShadow:
											isHovered === 1
												? "0px 8px 16px rgba(0, 0, 0, 0.3)"
												: "0px 2px 4px rgba(0, 0, 0, 0.2)",
										transition:
											"box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease",
										transform: isHovered === 1 ? "scale(1.05)" : "scale(1)",
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: isHovered === 1 ? "#E3F2FD" : "#ffffff",
										cursor: isHovered === 1 ? "pointer" : "default",
										zIndex: isHovered === 1 ? 9999999999 : "auto",
									}}
								>
									<CardContent>
										<Icon fontSize="large" style={{ color: "#007bff" }}>
											edit_note
										</Icon>{" "}
										<Typography
											variant="h5"
											sx={{
												textAlign: "center",
												marginTop: 2,
												color: "#007bff",
											}}
										>
											Captura
										</Typography>
										<Typography
											variant="body1"
											sx={{
												textAlign: "center",
												marginTop: 2,
												color: "#495057",
											}}
										>
											Comienza a registrar tus promovidos.
										</Typography>
										<br />
										<Divider color={"#00000"} />
										<Box
											sx={{
												display: "flex",
												alignItems: "end",
												justifyContent: "end",
											}}
										>
											<Typography variant="body2" sx={{ color: "#FF4081" }}>
												Ir a Captura de Compromisos{" "}
											</Typography>{" "}
											<Icon style={{ color: "#FF4081" }}>
												arrow_forward
											</Icon>{" "}
										</Box>
									</CardContent>
								</Card>
							</Grid2>
						)}
						<Grid2
							item
							lg={middleware.checkMenuActionId("Ver", 256) ? 6 : 12}
							xs={12}
							md={12}
						>
							<Card
								className="card-primary"
								onClick={() => navigate("/mi-perfil")}
								onMouseEnter={() => setIsHovered(2)}
								onMouseLeave={() => setIsHovered(-1)}
								sx={{
									minHeight: 400,
									borderRadius: 10,
									boxShadow:
										isHovered === 2
											? "0px 8px 16px rgba(0, 0, 0, 0.3)"
											: "0px 2px 4px rgba(0, 0, 0, 0.2)",
									transition:
										"box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease",
									transform: isHovered === 2 ? "scale(1.05)" : "scale(1)",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: isHovered === 2 ? "#FCE4EC" : "#ffffff", // Cambia el color de fondo al acercar el cursor
									cursor: isHovered === 2 ? "pointer" : "default",
								}}
							>
								<CardContent sx={{ textAlign: "center" }}>
									<Grid2 container spacing={2}>
										<Grid2 item lg={12} xs={12} md={12}>
											<Stack
												direction={{ xs: "column", sm: "row" }}
												spacing={1}
											>
												<Icon sx={{ color: "darkblue" }}>manage_accounts</Icon>
												<Typography sx={{ fontSize: 20 }} color="darkblue">
													Mi Perfil
												</Typography>
											</Stack>
										</Grid2>
										<Grid2 item lg={12} xs={12} md={12}>
											<ul
												style={{
													paddingLeft: "20px",
													listStyleType: "none",
												}}
											>
												<li
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: "8px",
													}}
												>
													<Icon sx={{ marginRight: "8px" }}>phonelink_off</Icon>
													<Typography
														variant="overline"
														display="block"
														gutterBottom
													>
														Desvinculación
													</Typography>
												</li>
												<li
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: "8px",
													}}
												>
													<Icon sx={{ marginRight: "8px" }}>password</Icon>
													<Typography
														variant="overline"
														display="block"
														gutterBottom
													>
														Cambio Contraseña
													</Typography>
												</li>
												<li
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: "8px",
													}}
												>
													<Icon sx={{ marginRight: "8px" }}>dialpad</Icon>
													<Typography
														variant="overline"
														display="block"
														gutterBottom
													>
														Cambio PIN
													</Typography>
												</li>
											</ul>
										</Grid2>
									</Grid2>
								</CardContent>
							</Card>
						</Grid2>
					</Grid2> */}
				</Grid2>
			</Container>
		</AdminLayout>
	);
};

export default React.memo(Welcome);
