//MUI
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

const ListRepresentatives = ({ representative }) => {
  return (
    <List>
      {representative?.NombreRCP1.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCP1}
              </Typography>
            }
            secondary={
              <Typography variant="body2" noWrap fontWeight={400} fontSize={12}>
                RCP1- {representative?.CelularRCP1}
              </Typography>
            }
          />
        </ListItem>
      )}

      {representative?.NombreRCP2.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCP2}
              </Typography>
            }
            secondary={
              <Typography variant="body2" noWrap fontWeight={400} fontSize={12}>
                RCP2- {representative?.CelularRCP2}
              </Typography>
            }
          />
        </ListItem>
      )}

      {representative?.NombreRCS1.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCS1}
              </Typography>
            }
            secondary={
              <Typography variant="body2" noWrap fontWeight={400} fontSize={12}>
                RCS1- {representative?.CelularRCS1}
              </Typography>
            }
          />
        </ListItem>
      )}

      {representative?.NombreRCS2.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCS2}
              </Typography>
            }
            secondary={
              <Typography variant="body2" noWrap fontWeight={400} fontSize={12}>
                RCS2 {representative?.CelularRCS2}
              </Typography>
            }
          />
        </ListItem>
      )}
    </List>
  );
};

export default ListRepresentatives;
