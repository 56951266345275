import { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import { Container, CardContent, Chip, Box, Tooltip } from "@mui/material";
import Filter from "@components/Territorial/Filter";
import { useNavigate } from "react-router-dom";
import BasicTable from "@components/Tables/BasicTable";
import Card from "@components/Card/Card";
import CardCommitments from "@components/Card/CardStats";
import Grid from "@mui/material/Unstable_Grid2";
import TerritorialService from "../../services/TerritorialServices";
import Swal from "sweetalert2";
import { EJERCICIO } from "@data/constants";
import { isEmptyOrNullObject } from "@utils/validations";
import MessageAlert from "@components/Downloads/MessageAlert";
import moment from "moment";
import { numberWithCommas } from "@utils/Utilities";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import MovingIcon from "@mui/icons-material/Moving";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const ProgressMunicipality = (props) => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [loadingCharts, setLoadingChart] = useState(false);
	const [sortedData, setSortedData] = useState([]);
	let filterFijo = { id: "Ejercicio", value: EJERCICIO, filter: "=" };
	const [chartCompromisos, setChartCompromisos] = useState({
		type: 11,
		filtered: [],
	});
	const [tableData, setTableData] = useState({
		page: 0,
		pageSize: 10,
		filtered: [filterFijo],
		sorted: [],
	});
	const [buttonLoading, setButtonLoading] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [successDownload, setSuccessDownload] = useState(false);
	const [dataTotales, setDataTotales] = useState({
		MetaCompromisos: {
			FechaActualizo: "2023-01-11 16:00:19",
			Meta: 0,
			TotalAvance: 0,
		},
		regional: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		distritales: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		municipales: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		poligonal: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
		seccionales: {
			Meta: 0,
			TotalAvance: 0,
			FechaActualizo: "2023-01-11 16:00:19",
		},
	});

	const columns = [
		{
			id: "Municipio",
			label: "Municipio",
			orderBy: "Municipio",
			columnAction: false,
		},
		{
			id: "MetaCompromisos",
			label: "Meta Promovidos",
			orderBy: "MetaCompromisos",
			columnAction: false,
		},
		{
			id: "AvEsperado",
			label: "Av.Esperado",
			orderBy: false,
			columnAction: false,
		},
		{
			id: "AvanceCompromisos",
			label: "Av.Promovidos",
			orderBy: "AvanceCompromisos",
			columnAction: false,
		},
		{
			id: "PorcentajeCompromisos",
			label: "(%) Av.Promovidos",
			orderBy: "PorcentajeCompromisos",
			columnAction: false,
		},
		{
			id: "AvanceCompromisosUnicos",
			label: "Av.Promovidos Únicos",
			orderBy: "AvanceCompromisosUnicos",
			columnAction: false,
		},
		{
			id: "PorcentajeCompromisosUnico",
			label: "(%) Av.Promovidos Únicos",
			orderBy: "PorcentajeCompromisosUnico",
			columnAction: false,
		},
		{
			id: "MetaEstructura",
			label: "Meta Estructura",
			orderBy: "MetaEstructura",
			columnAction: false,
		},
		{
			id: "AvanceEstructura",
			label: "Av.Estructura",
			orderBy: "AvanceEstructura",
			columnAction: false,
		},
		{
			id: "PorcentajeEstructura",
			label: "(%) Avance Estructura",
			orderBy: false,
			columnAction: false,
		},
	];

	useEffect(() => {
		if (sortedData.length > 0) {
			let params = {
				...tableData,
				sorted: sortedData,
			};

			setTableData(params);
		}
		// eslint-disable-next-line
	}, [sortedData]);

	useEffect(() => {
		setLoading(true);
		setLoadingChart(true);
		getList();
		getTotalCompromiso();
		// eslint-disable-next-line
	}, [tableData]);

	const getList = () => {
		TerritorialService.getProgressDistritales(tableData)
			.then((res) => {
				if (res.results) {
					setData(
						res.response.data.map((item) => {
							return {
								...item,
								MetaCompromisos: item.MetaCompromisos
									? numberWithCommas(item.MetaCompromisos)
									: "0",
								AvEsperado: item.AvEsperado
									? numberWithCommas(item.AvEsperado)
									: "0",
								AvanceCompromisos: item.AvanceCompromisos
									? numberWithCommas(item.AvanceCompromisos)
									: "0",
								AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
									? numberWithCommas(item.AvanceCompromisosUnicos)
									: "0",
								AvanceEstructura: item.AvanceEstructura
									? numberWithCommas(item.AvanceEstructura)
									: "0",
								PorcentajeEstructura: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeEstructura) >= 100
												? "success"
												: parseInt(item.PorcentajeEstructura) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeEstructura) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeEstructura > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeEstructura}
									/>
								),
								PorcentajeCompromisos: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeCompromisos) >= 100
												? "success"
												: parseInt(item.PorcentajeCompromisos) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeCompromisos) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeCompromisos > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeCompromisos}
									/>
								),
								PorcentajeCompromisosUnico: (
									<Chip
										variant="outlined"
										color={
											parseInt(item.PorcentajeCompromisosUnico) >= 100
												? "success"
												: parseInt(item.PorcentajeCompromisosUnico) > 50
												? "warning"
												: "error"
										}
										icon={
											parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
												<TaskAltIcon />
											) : item.PorcentajeCompromisosUnico > 50 ? (
												<MovingIcon />
											) : (
												<TrendingDownIcon />
											)
										}
										label={item.PorcentajeCompromisosUnico}
									/>
								),
							};
						})
					);
					setTotal(res.response.total);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoading(false));
	};

	const getTotalCompromiso = () => {
		TerritorialService.getStatsStruct(chartCompromisos)
			.then((res) => {
				if (res.results) {
					setDataTotales(res.response.data);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => setLoadingChart(false));
	};

	const handleChangePagination = (pagination) => {
		setTableData({ ...tableData, ...pagination });
	};

	const handleChangeFilter = (type) => {
		const { Region, Municipio, responsable, Celular, NombreCompleto } = type;

		let filtered = [filterFijo];
		let filtro = [];

		if (Region > 0) {
			filtered = [
				...filtered,
				{ id: "cat_secciones.idDF", filter: "=", value: Region },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idDF", filter: "=", value: Region },
			];
		}
		if (Municipio > 0) {
			filtered = [
				...filtered,
				{ id: "cat_municipios_reportes.id", filter: "=", value: Municipio },
			];

			filtro = [
				...filtro,
				{ id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
			];
		}
		if (responsable > 0) {
			filtered = [
				...filtered,
				responsable === 1
					? {
							id: "estad_municipios_reportes.AvanceEstructura",
							filter: "=",
							value: "0",
					  }
					: {
							id: "estad_municipios_reportes.AvanceEstructura",
							filter: ">",
							value: "0",
					  },
			];
		}
		if (Celular.length > 0) {
			filtered = [
				...filtered,
				{
					id: "usuarios.Celular",
					filter: "LIKE",
					value: Celular,
				},
			];
		}
		if (NombreCompleto.length > 0) {
			filtered = [
				...filtered,
				{
					id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"],
					filter: "LIKE",
					value: NombreCompleto,
				},
			];
		}

		setTableData({
			...tableData,
			page: 0,
			filtered: filtered,
		});

		setChartCompromisos({ ...chartCompromisos, filtered: filtro });
	};

	const handleRequestSort = (sortData) => {
		if (total > 0) setSortedData(handleSorted(sortData));
	};

	const handleSorted = (dataSorted) => {
		const fields = {
			Municipio: "cat_municipios_reportes.id",
			MetaCompromisos: "estad_municipios_reportes.MetaCompromisos",
			AvanceCompromisos: "estad_municipios_reportes.AvanceCompromisos",
			PorcentajeCompromisos:
				"IF(TRUNCATE(((estad_municipios_reportes.AvanceCompromisos * 100)/estad_municipios_reportes.MetaCompromisos),2)>=100,100,TRUNCATE(((estad_municipios_reportes.AvanceCompromisos * 100)/estad_municipios_reportes.MetaCompromisos),2))",
			AvanceCompromisosUnicos:
				"estad_municipios_reportes.AvanceCompromisosUnicos",
			PorcentajeCompromisosUnico:
				"IF(TRUNCATE(((estad_municipios_reportes.AvanceCompromisosUnicos * 100)/estad_municipios_reportes.MetaCompromisos),2)>=100,100,TRUNCATE(((estad_municipios_reportes.AvanceCompromisosUnicos * 100)/estad_municipios_reportes.MetaCompromisos),2))",
			MetaEstructura: "estad_municipios_reportes.MetaEstructura",
			AvanceEstructura: "estad_municipios_reportes.AvanceEstructura",
		};

		let sorted = [];

		if (!isEmptyOrNullObject(dataSorted)) {
			sorted.push({
				id: fields[dataSorted.orderBy],
				value: dataSorted.order,
			});
		}

		return sorted;
	};

	return (
		<AdminLayout>
			<Container maxWidth="false">
				<ActionHeader
					title="Promovidos por Municipios"
					handleclick={() => navigate("/promovidos_por_municipio")}
				/>
				<Grid container spacing={2}>
					{true && (
						<>
							<Grid xs={12} md={4}>
								<CardCommitments
									icon={"groups"}
									title={"Municipales"}
									total={`${numberWithCommas(
										dataTotales.municipales.TotalAvance
									)} de ${numberWithCommas(dataTotales.municipales.Meta)} `}
									subIcon={"update"}
									subtitle={moment(
										dataTotales.municipales.FechaActualizo
									).format("DD/MM/YYYY")}
									loading={loadingCharts}
								/>
							</Grid>
							{/*       <Grid xs={12} md={4}>
                <CardCommitments
                  icon={"groups"}
                  title={"Polígonales"}
                  total={`${numberWithCommas(
                    dataTotales.poligonal.TotalAvance
                  )} de ${numberWithCommas(dataTotales.poligonal.Meta)} `}
                  subIcon={"update"}
                  subtitle={moment(dataTotales.poligonal.FechaActualizo).format(
                    "DD/MM/YYYY"
                  )}
                  loading={loadingCharts}
                />
              </Grid> */}
							<Grid xs={12} md={4}>
								<CardCommitments
									icon={"groups"}
									title={"Seccionales"}
									total={`${numberWithCommas(
										dataTotales.seccionales.TotalAvance
									)} de ${numberWithCommas(dataTotales.seccionales.Meta)} `}
									subIcon={"update"}
									subtitle={moment(
										dataTotales.seccionales.FechaActualizo
									).format("DD/MM/YYYY")}
									loading={loadingCharts}
								/>
							</Grid>{" "}
						</>
					)}
					<Grid xs={12} md={4}>
						<CardCommitments
							icon={"groups"}
							title={"Promovidos"}
							total={`${numberWithCommas(
								dataTotales.MetaCompromisos.TotalAvance
							)} de ${numberWithCommas(dataTotales.MetaCompromisos.Meta)}`}
							subIcon={"update"}
							subtitle={moment(
								dataTotales.MetaCompromisos.FechaActualizo
							).format("DD/MM/YYYY")}
							loading={loadingCharts}
						/>
					</Grid>
				</Grid>

				<Card className="card-primary">
					<CardContent>
						<Filter
							onChangeFilter={(e) => handleChangeFilter(e)}
							poligono={false}
							seccion={false}
							manzana={false}
							checkRespon={true}
							municipio={false}
							municipio_dl={true}
						/>
					</CardContent>
				</Card>

				<Card className="card-primary">
					<CardContent>
						{showMessage && (
							<Box marginTop={2}>
								<MessageAlert
									showMessage={showMessage}
									successDownload={successDownload}
									setShowMessage={setShowMessage}
								/>
							</Box>
						)}
						{/*      {isDownload ? (
              <Download format={"xlsx"} isDownload={isDownload} />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Box>
            )} */}

						{/*       {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
 */}
						<BasicTable
							rows={data}
							hcolumns={columns}
							pageProp={tableData.page}
							pageSize={tableData.pageSize}
							total={total}
							handleChangePagination={handleChangePagination}
							isLoading={loading}
							stickyHeader={true}
							handleManualSort={handleRequestSort}
						/>
					</CardContent>
				</Card>
			</Container>
		</AdminLayout>
	);
};
export default ProgressMunicipality;
