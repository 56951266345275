import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { CircularProgress } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Servicios
import Auditoria from "@services/AuditServices";

const LineChart = (props) => {
	const { change, filtered } = props;

	const [categories, setCategories] = useState([]);
	const [seriesData, setSeriesData] = useState([]);
	const [loading, setLoading] = useState(false);
	const chartRef = useRef(null);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Estado para el ancho de la ventana

	// Llamada a la API
	const fetchData = async () => {
		setLoading(true);
		try {
			const result = await Auditoria.getTotalsCopy(filtered);
			const { results, message, response } = result;
			if (results) {
				const tempCategories = response.data.map((item) => item.Dia);
				const tempSeriesData = response.data.map((item) => item.Total);
				setCategories(tempCategories);
				setSeriesData(tempSeriesData);

				if (chartRef.current) {
					const chart = chartRef.current.chart;

					chart.update({
						subtitle: {
							text: `Última actualización: ${new Date().toLocaleString()}`,
						},
					});
				}
			} else {
				setCategories([]);
				setSeriesData([]);
			}
		} catch (errors) {
			Swal.fire({
				title: "",
				text: errors.message,
				icon: "warning",
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();

		const handleResize = () => setWindowWidth(window.innerWidth); // Listener de cambio de tamaño de la ventana
		window.addEventListener("resize", handleResize); // Se agrega el listener
		return () => window.removeEventListener("resize", handleResize); // Se elimina el listener al desmontar el componente
	}, [change]);

	// Volvemos a renderizar el LineChart
	useEffect(() => {
		if (!loading) {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
			}, 0); // Se puede agregar mas tiempo para evitar los flash
		}
	}, [windowWidth]);

	// Evita que se quite la linea del chart
	const handleLegendItemClick = (e) => {
		if (e.target.name === "Captura por Día") {
			e.preventDefault();
		}
	};

	// Opciones del chart
	const options = {
		credits: {
			enabled: false,
		},
		title: {
			text: "Avance Día/Hora",
		},
		subtitle: {
			text: `Última actualización: ${new Date().toLocaleString()}`,
		},
		xAxis: {
			categories: categories,
			min: 0,
			max: categories.length - 1,
			labels: {
				rotation: -80,
			},
		},
		yAxis: {
			title: {
				text: "Promovidos",
			},
		},
		series: [
			{
				name: "Promovidos de hoy",
				data: seriesData,
				events: {
					legendItemClick: handleLegendItemClick, // Maneja el evento de clic en la leyenda
				},
			},
		],
		chart: {
			scrollablePlotArea: {
				minWidth: categories.length * 25, // Ancho mínimo para activar el scroll
			},
		},
	};

	return (
		<>
			{loading ? (
				<Grid2
					container
					xs={12}
					height={400}
					alignItems="center"
					justifyContent="center"
				>
					<CircularProgress size={50} className="color-componentes"/>
				</Grid2>
			) : (
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					ref={chartRef}
				/>
			)}
		</>
	);
};

export default LineChart;
