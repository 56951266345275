import { useState, useEffect } from "react";

// Material UI
import { FilterList, ChevronLeft } from "@mui/icons-material";
import { IconButton, Divider, Drawer, Typography, Toolbar, Box } from "@mui/material";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

/* Ejemplo de  properties
{
    open: true, // Si se desea que este inicialmente abierto.
    title: "Titulo del Drawer",
    component: ReactComponent,
    width: { sm: "100%", md: 350, lg: 500, xl: 700 }, // Solo valores variables o vacio (se ajusta al contenido)
}*/

const DrawerLeft = (props) => {
  const { properties, setWidth, navigationAction } = props;
  const { width } = useWindowDimensions();

  const [drawerOpen, setDrawerOpen] = useState(properties.open || false);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const pW = properties.width;
  const widthD = pW && Object.keys(pW).length > 0 ? pW : { sm: "fit-content" };
  const titleFlag = properties.title ? true : false;

  useEffect(() => {
    if (drawerOpen) {
      const elemento = document.getElementById("MapDrawerLeft");
      const ancho = elemento ? elemento.offsetWidth : 0;
      setWidth(ancho);
    } else setWidth(0);
    // eslint-disable-next-line
  }, [drawerOpen, width]);

  return (
    <>
      <DrawerButton handleDrawerToggle={handleDrawerToggle} />

      <Drawer
        sx={{
          flexShrink: 0,
          height: "100%",
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: widthD.sm, md: widthD.md, lg: widthD.lg, xl: widthD.xl },
            position: "relative",
            zIndex: 1020,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <Box
          id="MapDrawerLeft"
          px={2}
          onMouseEnter={() => navigationAction("disable")}
          onMouseLeave={() => navigationAction("enable")}
          onTouchStart={() => navigationAction("disable")}
          onTouchEnd={() => navigationAction("enable")}
          height="100%"
        >
          {titleFlag && (
            <>
              <Toolbar style={{ paddingLeft: 0, paddingRight: 0, justifyContent: "space-between" }}>
                <Typography variant="h6" noWrap component="div">
                  {properties.title}
                </Typography>
                <IconButton
                  onClick={() => {
                    navigationAction("enable");
                    handleDrawerToggle();
                  }}
                >
                  <ChevronLeft />
                </IconButton>
              </Toolbar>
              <Divider sx={{ marginBottom: 2 }} />
            </>
          )}
          {properties.component}
        </Box>
      </Drawer>
    </>
  );
};

const DrawerButton = ({ handleDrawerToggle }) => (
  <div className="leaflet-control-container">
    <div className="leaflet-top leaflet-left">
      <div className="leaflet-bar leaflet-control" style={{ marginTop: "55px" }}>
        <IconButton
          aria-label="Abrir menu lateral"
          onClick={handleDrawerToggle}
          style={{ backgroundColor: "white" }}
          sx={{
            height: "30px",
            width: "30px",
            borderRadius: "3px",
            "&:hover": { backgroundColor: "#eeeeee!important" },
          }}
        >
          <FilterList style={{ fontSize: 25, color: "#000" }} />
        </IconButton>
      </div>
    </div>
  </div>
);

export default DrawerLeft;
