import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { CircularProgress } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Servicios
import Auditoria from "@services/AuditServices";

// Utilidades
import { numberWithCommas } from "@utils/Utilities/";

const PieChart = (props) => {
  const { change, filtered } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const chartRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await Auditoria.getTotals(filtered);
      const { results, message, response } = result;

      if (results) {
        const responseData = response.data;

        setData(responseData);
        setLoading(false);

        if (chartRef.current) {
          const chart = chartRef.current.chart;

          chart.update({
            subtitle: {
              text: `Última actualización: ${new Date().toLocaleString()}`,
            },
          });
        }
      } else {
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [change]);

  // Opciones del chart
  const options = {
    credits: {
      enabled: false,
    },
    title: {
      text: "Porcentaje Exitoso",
    },
    subtitle: {
      text: `Última actualización: ${new Date().toLocaleString()}`,
    },
    xAxis: {
      categories: data.map((item) => item.Estatus),
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}%", // Muestra el nombre y el porcentaje en el gráfico
        },
        showInLegend: true,
      },
    },
    tooltip: {
      formatter: function () {
        // Formato personalizado del tooltip
        return `<b>${this.point.name}</b><br/>Total: ${numberWithCommas(
          this.point.x
        )}`; // Muestra el nombre y el valor total en el tooltip
      },
    },
    series: [
      {
        type: "pie",
        name: "Total",
        data: data.map((item) => ({
          name: item.Estatus,
          y: parseFloat(item.Porcentaje),
          x: item.TotalEstatus,
        })),
      },
    ],
    legend: {
      align: "center",
      verticalAlign: "bottom",
    },
    colors: ["#FFD700", "#98FB98", "#FF6347"],
  };

  return loading ? (
    <Grid2
      container
      xs={12}
      height={400}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={50} className="color-componentes"/>
    </Grid2>
  ) : (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
};

export default PieChart;
