import flag_mexico from "@assets/img/flag-mex.svg";
import flag_ext from "@assets/img/flag-world.svg";
import canada from "@assets/img/canada.svg";
import us from "@assets/img/us.svg";

export const OPT_REGISTERS = [
	{
		id: 1,
		icon: flag_mexico,
		title: "Promovidos Nacionales",
		paragraph: "Aquí podrás registrar los promovidos nacionales.",
	},
	{
		id: 2,
		icon: flag_ext,
		title: "Promovidos en el Extranjero",
		paragraph: "Aquí podrás registrar los promovidos del extranjero.",
	},
];

export const COLUMNS_FOREIGNERS = [
	{ label: "Fecha", id: "FechaCreo", columnAction: false, width: 200 },
	{ label: "País", id: "idPais", orderBy: false },
	{ label: "Estado", id: "Estado", columnAction: false, width: 200 },
	{
		label: "Nombre Completo",
		id: "NombreCompleto",
		columnAction: false,
		width: 800,
		orderBy: "NombreCompleto",
	},

	{ label: "Municipio", id: "Municipio", columnAction: false, width: 350 },
	{ label: "Colonia", id: "ColoniaVive", columnAction: false, width: 350 },
];

export const TYPE_COUNTRY = {
	2: us,
	3: canada,
};
