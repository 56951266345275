import { Public } from "@middlewares";

import Login from "@pages/auth/Login";
import Confirmation from "@pages/auth/Confirmation";
import Register from "../../pages/auth/Register";
import SimplePrivacyPolicy from "../../pages/auth/SimplePrivacyPolicy";
import UnsubscribedRequest from "@pages/auth/UnsubscribedRequest";
import RecoveryPasswd from "@pages/auth/RecoveryPasswd";
//import RegisterPublic from "../../pages/auth/RegisterPublic";
import ChangePassword from "../../pages/auth/ChangePassword";
import HelpCenter from "@pages/auth/HelpCenter";

const AuthRoutes = [
	{
		path: "/login",
		element: (
			<Public>
				<Login />
			</Public>
		),
	},
	{
		path: "/confirmar-celular/:id/:uuid",
		element: (
			<Public>
				<Confirmation />
			</Public>
		),
	},
	{
		path: "/convencidos/:id/:uuid",
		element: (
			<Public>
				<Register />
			</Public>
		),
	},
	{
		path: "cambiar-contrasena/:id",
		element: (
			<Public>
				<RecoveryPasswd />
			</Public>
		),
	},
	{
		path: "privacidad",
		element: (
			<Public>
				<SimplePrivacyPolicy />
			</Public>
		),
	},
	{
		path: "solicitud-baja",
		element: (
			<Public>
				<UnsubscribedRequest />
			</Public>
		),
	},
	/*   {
    path: "/registro",
    element: (
      <Public>
        <RegisterPublic />
      </Public>
    ),
  }, */
	{
		path: "/cambio-validation/:id",
		element: (
			<Public>
				<ChangePassword />
			</Public>
		),
	},
	{
		path: "/soporte",
		element: (
			<Public>
				<HelpCenter />
			</Public>
		),
	},
];

export default AuthRoutes;
