import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Card, CardContent, Box, Grid } from "@mui/material";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import UsersSearch from "@components/Users/UsersSearch";
import UserUpdate from "@components/Users/UsersUpdate";
import SupportService from "@services/SupportServices";

const UserPassword = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [nombreCompleto, setNombreCompleto] = useState("");

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Contraseñas y Dispositivos"
          isAction={false}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />
        <Card className="card-primary">
          <CardContent>
            <Box sx={{ mt: 1, mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item sx={12} lg={6}>
                  <UsersSearch
                    idUsuario={setId}
                    nombreCompleto={setNombreCompleto}
                    API={SupportService.getUser}
                  />
                </Grid>
                <Grid item sx={{ width: "100%" }} lg={5.5}>
                  <UserUpdate
                    idUsuario={id}
                    nombreCompleto={nombreCompleto}
                    perfil={false}
                    defaultOpen={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserPassword;
