import PropTypes from "prop-types";

// Material UI
import { Drawer, Stack, Icon, Typography, IconButton, Divider } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const DrawerRelative = (props) => {
  const { title, icon, titleComponent, titleComponentPlace, titleSpace, variant, anchor, open, setOpen, className, sx, sxPaper, sxTitle } = props;

  return (
    <Drawer
      variant={variant}
      anchor={anchor}
      open={open}
      className={className}
      sx={{
        height: "100%",
        display: open ? "block" : "none",
        ...sx,
        "& .MuiDrawer-paper": {
          zIndex: 20,
          overflowY: "auto",
          position: "relative",
          border: 0,
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-thumb": { backgroundColor: "rgba(0, 0, 0, 0.2)", borderRadius: "6px" },
          "&::-webkit-scrollbar-track": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
          ...sxPaper,
        },
      }}
    >
      {(title || titleComponent) && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} padding={2} sx={{ ...sxTitle }}>
            {title && (
              <Stack direction="row" alignItems="center" spacing={1} marginBottom="0.5rem">
                {icon && <Icon>{icon}</Icon>}
                <Typography fontWeight={600} variant="body2">
                  {title}
                </Typography>
              </Stack>
            )}
            {titleSpace && <span style={{ flex: 1 }} />}
            {titleComponent && titleComponentPlace === "between" && titleComponent}
            <IconButton onClick={() => setOpen(false)}>{anchor === "left" ? <ChevronLeft /> : <ChevronRight />}</IconButton>
          </Stack>
          {titleComponent && titleComponentPlace === "under" && titleComponent}
          <Divider sx={{ marginBottom: "1rem" }} />
        </>
      )}
      {props.children}
    </Drawer>
  );
};

DrawerRelative.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
  titleSpace: PropTypes.bool,
  titleComponent: PropTypes.node,
  titleComponentPlace: PropTypes.oneOf(["under", "between"]),
  variant: PropTypes.oneOf(["persistent", "permanent", "temporary"]),
  anchor: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
  sx: PropTypes.object,
  sxPaper: PropTypes.object,
  sxTitle: PropTypes.object,
};

DrawerRelative.defaultProps = {
  open: true,
  setOpen: () => {},
  title: null,
  icon: null,
  titleSpace: true,
  titleComponent: null,
  titleComponentPlace: "between",
  variant: "persistent",
  anchor: "left",
  className: "",
  sx: {},
  sxPaper: {},
  sxTitle: {},
};

export default DrawerRelative;
