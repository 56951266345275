import { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";

// Componentes
import Map from "@components/Maps/CustomComponents/GenericMap";
import ReportsContext from "@components/Reports/ReportsContext";

// Servicios
import national from "@services/MapServices/NationalServices";

// Estilos de mapas
import { style_original } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
	manzana: [
		{ title: "Manzana:", name: "Manzana", type: "text" },
		{ title: "Prioridad:", name: "PrioridadLabel", type: "text" },
	],
	seccion: [
		{ title: "Seccion:", name: "seccion", type: "text" },
		{ title: "DL:", name: "DLOC", type: "text" },
		{ title: "DF:", name: "DFED", type: "text" },
		{ title: "Meta:", name: "MetaCompromisos", type: "text" },
		{ title: "Av Esp:", name: "AvanceEsperado", type: "text" },
		{ title: "Av Prom:", name: "AvanceCompromisos", type: "text" },
		{ title: "Av Prom Únicos:", name: "AvanceCompromisosUnicos", type: "text" },
		{
			title: "% Prom Únicos:",
			name: "PorcentajeAvanceCompromisosUnicos",
			type: "text",
		},
		{ title: "Lnom", name: "Lnom", type: "text" },
	],
	dfed: [{ title: "DF", name: "dfed", type: "text" }],
};

const Maps = (props) => {
	const { best, worst } = props;
	const { handleLoading } = useContext(ReportsContext);

	const [polygons, setPolygons] = useState(null);
	const [loadedLayer, setLoadedLayer] = useState(false);

	useEffect(() => {
		const init = async () => {
			getEmptyMap();
			await loadMainLayer();
		};

		init();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (loadedLayer) {
			const _best = best.map((item) => parseInt(item.idEntidad));
			const _worst = worst.map((item) => parseInt(item.idEntidad));
			const _features = polygons.features?.map((row) => {
				const id = parseInt(row.id);
				return {
					...row,
					properties: {
						...row.properties,
						line_color: setLineColor(id, _best, _worst),
						color: setColor(id, _best, _worst),
					},
				};
			});

			setPolygons(() => {
				return { ...polygons, features: _features };
			});
		}
		// eslint-disable-next-line
	}, [loadedLayer, best, worst]);

	const getEmptyMap = () =>
		setPolygons({ type: "FeatureCollection", features: [] });

	const loadMainLayer = async () => {
		setPolygons(null);
		handleLoading(true);
		try {
			const params = {
				color: 0,
				page: 0,
				pageSize: 10,
				filtered: [{ filter: "IN", id: "tb.idEntidad", value: 0 }],
				shp: "entidad",
				idEntidad: 0,
			};

			const result = await national.getShapes(params);
			const { results, response, message } = result;

			if (results) setPolygons(response.data);
			else Swal.fire({ title: message, icon: "warning" });
		} catch (error) {
			Swal.fire({ title: error.message, icon: "warning" });
		} finally {
			handleLoading(false);
			setLoadedLayer(true);
		}
	};

	const setColor = (id, best, worst) => {
		if (best.includes(id)) return "#1a237e";
		else if (worst.includes(id)) return "#c62828";
		return "#fff8e1";
	};

	const setLineColor = (id, best, worst) => {
		if (best.includes(id)) return "#2196f3";
		else if (worst.includes(id)) return "#c62828";
		return "#666";
	};

	const highlightFeature = (e, params) => {
		const {
			resetHighlight,
			setFeature,
			L,
			selectedFeature,
			setSelectedFeature,
		} = params;
		const layer = e.target;
		const properties = layer.feature.properties;

		if (selectedFeature) resetHighlight(selectedFeature);
		if (!properties.seccion) resetHighlight(e);

		setSelectedFeature(e);
		setFeature(layer.feature);

		if (properties.seccion) {
			if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge)
				layer.bringToFront();
			layer.setStyle(style_original(layer.feature, true));
		}
	};

	return (
		<Map
			height="85vh"
			data={polygons}
			highlightFeature={highlightFeature}
			centerByFeatures
			cardInfo={{
				initialValues: {
					title: "INFORMACIÓN",
					elements: elements[polygons?.shp ?? "dfed"],
				},
			}}
		/>
	);
};

export default Maps;
