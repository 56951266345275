export const COLUMNS_RCO_MOVILIZATION = [
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Poligono", label: "Zona" },
  { id: "Seccion", label: "SECCIÓN" },
  { id: "MetaCompromisos", label: "META" },
  { id: "AvanceCompromisosUnicos", label: "AVANCE" },
  { id: "PorcentajeAvance", label: "% AVANCE" },
  { id: "NoBingo", label: "No BINGO" },
  { id: "MetaBingo", label: "BINGO" },
  { id: "AvanceBingo", label: "AVANCE BINGO" },
  { id: "DiferenciaBingo", label: "POR MOVILIZAR" },
];

export const COLUMNS_RCO_POLYGONAL = [
  { id: "Seccion", label: "SECCIÓN" },
  { id: "MetaEstructuraPartido", label: "META" },
  { id: "AvanceEstructura", label: "AVANCE" },
  { id: "Seccional", label: "NOMBRE" },
  { id: "ManzanaP1", label: "META MZANA P1" },
  { id: "AvanceManzanaP1", label: "AVANCE MZANA P1" },
  { id: "ManzanaP2", label: "META MZANA P2" },
  { id: "AvanceManzanaP2", label: "AVANCE MZANA P2" },
  { id: "ManzanaP3", label: "META MZANA P3" },
  { id: "AvanceManzanaP3", label: "AVANCE MZANA P3" },
  { id: "MetaCompromisosPartido", label: "META PROMOVIDOS" },
  { id: "AvanceCompromisos", label: "AVANCE PROMOVIDOS" },
  { id: "Observaciones", label: "OBSERVACIONES" },
];
