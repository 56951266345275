import { useEffect, useState } from "react";
import { CAT_SOLVED, CAT_STATUS } from "@data/constants/Sije";
import sije from "@services/SijeService";
import Swal from "sweetalert2";
import { isTypePhone } from "@utils/validations";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Stack, TextField } from "@mui/material";

import FilterCollapse from "@components/Filters/FilterCollapse";
import BasicSelect from "@components/Selects/BasicSelect";

const Filter = ({ onChangeFilter }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: "dfs" },
    { id: "secciones" },
    { id: "dls" },
    { id: "municipios_df" },
    { id: "municipios_dl" },
    { id: "municipios_reportes" },
    { id: "incidencias" },
  ];
  //States
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    DttoLocal: "",
    DttoFederal: "",
    Municipio: "",
    Seccion: "",
    TelRG: "",
    Casilla: "",
    RG: "",
    Estatus: "",
    isResuelto: "",
    Incidencia: "",
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await sije.getCatalogs({ catalogs: catalogsOptions });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(response.catalogs);
        setCatalogs(response.catalogs);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterData({ ...filterData, [key]: e.target.value });
  };

  const handleChangeDttoFederal = (e) => {
    const idDttoFederal = e.target.value;
    const municipios_df = catalogs.municipios_df.filter(
      (item) => item.idDF === idDttoFederal
    );

    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter(
        (item) => item.idDF === idDttoFederal
      ),
      municipios_reportes: municipios_df.map((item) => ({
        value: item.idMunicipioReportes,
        label: item.Municipio,
      })),
    }));
  };

  const handleChangeDttoLocal = (e) => {
    const idDttoLocal = e.target.value;
    const municipios_dl = catalogs.municipios_dl.filter(
      (item) => item.idDL === idDttoLocal
    );

    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter((item) => item.idDL === idDttoLocal),
      municipios_reportes: municipios_dl.map((item) => ({
        value: item.idMunicipioReportes,
        label: item.Municipio,
      })),
    }));
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter(
        (item) => item.idMunicipioReportes === idMunicipality
      ),
    }));
  };

  const handleSearch = () => {
    let filtered = [];
    if (filterData.DttoFederal > 0) {
      filtered = [
        ...filtered,
        {
          id: "DFED",
          filter: "=",
          value: filterData.DttoFederal,
        },
      ];
    }

    if (filterData.DttoLocal > 0) {
      filtered = [
        ...filtered,
        {
          id: "DLOC",
          filter: "=",
          value: filterData.DttoLocal,
        },
      ];
    }
    if (filterData.Municipio > 0) {
      filtered = [
        ...filtered,
        {
          id: "idMunicipioReportes",
          filter: "=",
          value: filterData.Municipio,
        },
      ];
    }
    if (filterData.Seccion > 0) {
      filtered = [
        ...filtered,
        {
          id: "Seccion",
          filter: "=",
          value: filterData.Seccion,
        },
      ];
    }
    if (filterData.TelRG.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "TelefonoRG",
          filter: "=",
          value: filterData.TelRG,
        },
      ];
    }
    if (filterData.Casilla.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "NombreCasilla",
          filter: "=",
          value: filterData.Casilla.toUpperCase(),
        },
      ];
    }
    if (filterData.RG.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "RG",
          filter: "=",
          value: filterData.RG.toUpperCase(),
        },
      ];
    }
    if (filterData.Estatus > 0) {
      filtered = [
        ...filtered,
        {
          id: "idEstatus",
          filter: "=",
          value: filterData.Estatus,
        },
      ];
    }
    if (filterData.isResuelto !== 9999) {
      if (filterData.isResuelto === 0 || filterData.isResuelto === 1) {
        filtered = [
          ...filtered,
          {
            id: "esResuelto",
            filter: "=",
            value: filterData.isResuelto,
          },
        ];
      }
    }
    if (filterData.Incidencia > 0) {
      filtered = [
        ...filtered,
        {
          id: "idIncidencia",
          filter: "=",
          value: filterData.Incidencia,
        },
      ];
    }

    onChangeFilter(filtered);
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      DttoLocal: "",
      DttoFederal: "",
      Municipio: "",
      Seccion: "",
      TelRG: "",
      Casilla: "",
      RG: "",
      Estatus: "",
      isResuelto: "",
      Incidencia: "",
    };
    setCatalogFiltered(catalogs);
    setFilterData(cleanFileds);
    onChangeFilter([]);
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <FilterCollapse expand>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="DttoFederal"
            label="Distrito Federal"
            options={catalogsFiltered.dfs}
            value={filterData.DttoFederal}
            onChange={(e) => {
              handleChangeFilter(e);
              handleChangeDttoFederal(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="DttoLocal"
            label="Distrito Local"
            options={catalogsFiltered.dls}
            value={filterData.DttoLocal}
            onChange={(e) => {
              handleChangeFilter(e);
              handleChangeDttoLocal(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="Municipio"
            label="Municipio"
            options={catalogsFiltered.municipios_reportes}
            value={filterData.Municipio}
            onChange={(e) => {
              handleChangeFilter(e);
              handleChangeMunicipality(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="Seccion"
            label="Sección"
            options={catalogsFiltered.secciones.map((item) => {
              return { value: item.value, label: String(item.label) };
            })}
            value={filterData.Seccion}
            onChange={(e) => {
              handleChangeFilter(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <TextField
            label="Tel RG"
            name="TelRG"
            size="small"
            sx={{ width: "100%" }}
            value={filterData.TelRG}
            onChange={(e) =>
              handleChangeFilter({
                target: {
                  name: "TelRG",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : filterData.TelRG,
                },
              })
            }
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <TextField
            label="Casilla"
            name="Casilla"
            size="small"
            sx={{ width: "100%" }}
            value={filterData.Casilla.toUpperCase()}
            onChange={(e) => {
              handleChangeFilter(e);
            }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <TextField
            label="RG"
            name="RG"
            size="small"
            sx={{ width: "100%" }}
            value={filterData.RG.toUpperCase()}
            onChange={(e) => {
              handleChangeFilter(e);
            }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="Estatus"
            label="Estatus"
            options={CAT_STATUS}
            value={filterData.Estatus}
            onChange={(e) => {
              handleChangeFilter(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="isResuelto"
            label="Solucionado"
            options={CAT_SOLVED}
            value={filterData.isResuelto}
            onChange={(e) => {
              handleChangeFilter(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={9} lg={9}>
          <BasicSelect
            name="Incidencia"
            label="Incidencia"
            options={catalogsFiltered.incidencias}
            value={filterData.Incidencia}
            onChange={(e) => {
              handleChangeFilter(e);
            }}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid2>
      </Grid2>
      <Stack
        direction={"row"}
        marginTop={2}
        justifyContent={"flex-end"}
        spacing={2}
      >
        <Button onClick={handleClearFilter} color="primaryDark">
          Limpiar
        </Button>
        <Button variant="contained" color="primaryDark" onClick={handleSearch}>
          Filtrar
        </Button>
      </Stack>
    </FilterCollapse>
  );
};

export default Filter;
