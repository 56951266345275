import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  IconButton,
  Stack,
  Typography,
  Icon,
  Divider,
  Box,
  Skeleton,
} from "@mui/material";

import Modal from "@components/Modal/Dataview";
import { grey } from "@mui/material/colors";
import { HideImage } from "@mui/icons-material";

import moment from "moment";
import { LINK_API } from "@data/constants/Sije";

const ModalPreviewImages = ({
  images = {},
  openModal,
  loading,
  setOpenModal,
}) => {
  const AllPropertiesEmpty = ({ data }) => {
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key].length > 0) {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal
      open={openModal}
      setOpen={setOpenModal}
      hasButtonClose={false}
      maxWidth={"sm"}
      borderRadius={12}
    >
      <Box marginBottom={1}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={1}
        >
          <Typography variant="h6">Fotografías</Typography>
          <IconButton size="small" onClick={() => setOpenModal(false)}>
            <Icon>close</Icon>
          </IconButton>
        </Stack>
        <Divider />
      </Box>
      {loading ? (
        <Skeleton variant="rounded" height={400} />
      ) : (
        <>
          {AllPropertiesEmpty({ data: images }) ? (
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
              width={"100%"}
              marginTop={2}
              height={400}
            >
              <HideImage sx={{ width: 80, height: 80, color: grey[500] }} />
              <Typography variant="body2" marginTop={1} fontWeight={600}>
                No tiene fotografías.
              </Typography>
            </Box>
          ) : (
            <ImageList>
              {Object.entries(images).map(([etapa, data]) => {
                if (data.length === 0) return null;
                return (
                  <>
                    <ImageListItem key="Subheader" cols={2}>
                      <ListSubheader
                        component="div"
                        sx={{
                          backgroundColor: grey[300],
                          color: grey[900],
                          fontWeight: 600,
                          borderRadius: 1,
                          mt: 1,
                        }}
                      >
                        {etapa.toUpperCase()}
                      </ListSubheader>
                    </ImageListItem>

                    {data.map((item) => (
                      <ImageListItem key={item.RutaFoto}>
                        <img
                          src={item.RutaFoto}
                          srcSet={item.RutaFoto}
                          alt={item.Etapa}
                          loading="lazy"
                        />
                        <ImageListItemBar
                          title={item.Etapa}
                          subtitle={`${item.RegistradoPor} - ${moment(
                            item.HoraRegistro
                          ).format("DD-MM-YYYY")}`}
                        />
                      </ImageListItem>
                    ))}
                  </>
                );
              })}
            </ImageList>
          )}
        </>
      )}
    </Modal>
  );
};

export default ModalPreviewImages;
