import React, { useState } from "react";

//MUI
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
//Components
import FilterCollapse from "@components/Filters/FilterCollapse";
// import InputSelect from "@components/Selects/BasicSelect";
// import MultiAutocomplete from "@components/Selects/MultiAutocomplete";

const MyUsersFilter = ({ onChangeFilter }) => {
	const [radioValue, setRadioValue] = useState(1);

	const handleChangeRadio = (event) => {
		const value = event.target.value;
		let options = {};

		switch (value) {
			case "1":
				options = { unique: false, nested: false };
				break;

			case "2":
				options = { unique: true, nested: false };
				break;

			case "3":
				options = { unique: false, nested: true };
				break;

			case "4":
				options = { unique: true, nested: true };
				break;
			default:
				break;
		}

		onChangeFilter(options);
		setRadioValue(value);
	};

	return (
		<>
			<FilterCollapse expand={true}>
				<Grid container spacing={2} marginBottom={"1rem"}>
					<Grid item xs={12}>
						<RadioGroup
							className="color-letras"
							row
							aria-labelledby="controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={radioValue}
							onChange={handleChangeRadio}
						>
							<Grid item xs={12} md={3}>
								<FormControlLabel
									value={1}
									control={<Radio className="color-letras"/>}
									label="Promovidos directos (gral)"
									labelPlacement="top"
								></FormControlLabel>
							</Grid>
							<Grid item xs={12} md={3}>
								<FormControlLabel
									value={2}
									control={<Radio className="color-letras"/>}
									label="Promovidos directos (únicos)"
									labelPlacement="top"
								></FormControlLabel>
							</Grid>
							<Grid item xs={12} md={3}>
								<FormControlLabel
									value={3}
									control={<Radio className="color-letras"/>}
									label="Promovios en red"
									labelPlacement="top"
								></FormControlLabel>
							</Grid>
							<Grid item xs={12} md={3}>
								<FormControlLabel
									value={4}
									control={<Radio className="color-letras"/>}
									label="Promovidos únicos en red"
									labelPlacement="top"
								></FormControlLabel>
							</Grid>
						</RadioGroup>
					</Grid>
				</Grid>
				{/* <Grid container spacing={2} marginBottom={"1rem"}>
          <Grid item xs={12} md={3}>
            <TextField
              label="Nombre"
              size="small"
              name="NombreCompleto"
              //value={filter.NombreCompleto.toUpperCase()}
              //onChange={hadleChangeTextField}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Celular"
              size="small"
              name="Celular"
              //value={filter.Celular}
              //onChange={hadleChangeTextField}
              //   onChange={(e) =>
              //     hadleChangeTextField({
              //       target: {
              //         name: "Celular",
              //         value: isTypePhone(e.target.value)
              //           ? e.target.value
              //           : filter.Celular,
              //       },
              //     })
              //   }
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputSelect
              label="Municipio"
              options={[]}
              name="municipio"
              //   value={
              //     catMunicipalityFilter.length === 2
              //       ? catMunicipalityFilter[1].value
              //       : dataCatalog.Municipio
              //   }
              //onChange={handleChangeMunicipality}
              //disabled={catMunicipality.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MultiAutocomplete
              label="Sección"
              limitTags={3}
              options={[]}
              //   value={
              //     catSectionFilter.length === 1
              //       ? [catSectionFilter[0]]
              //       : dataCatalog.Seccion
              //   }
              // onChange={handleChangeSection}
              //disabled={catSectionFilter.length === 1 ? true : false}
            />
          </Grid>
        </Grid> */}
			</FilterCollapse>
		</>
	);
};

export default MyUsersFilter;
