import { useState, useEffect, useCallback } from "react";

// Material UI
import { Typography, Divider } from "@mui/material";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Colors from "@components/MapsModules/Routes/Colors";
import Filter from "@components/MapsModules/Routes/Filter";
import PolygonsCard from "@components/MapsModules/Routes/PolygonsCard";

// Otros
import CatalogService from "@services/CatalogServices";
import RouteService from "@services/MapServices/RouteServices";
import geojson_ex_pol_data from "@data/polygons/poligonos.json";
import { setVars, getVars } from "@utils/global";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

// Estilos de mapas
import { style_original_rutas, style_rutas } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
  seccion: [
    { title: "Seccion:", name: "seccion", type: "text" },
    { title: "Ruta:", name: "Ruta", type: "text" },
    { title: "Tipo:", name: "TipoSeccion", type: "text" },
    { title: "DLOC:", name: "DLOC", type: "text" },
    { title: "DFED:", name: "DFED", type: "text" },
    { title: "LNOM:", name: "Lnom", type: "text" },
    { title: "Casillas:", name: "TotalCasillas", type: "text" },
  ],
  dfed: [{ title: "DFED:", name: "dfed", type: "text" }],
};

const Maps = () => {
  const { user } = getVars("Token");
  const { height } = useWindowDimensions();

  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });
  const [polygonsOLD, setPolygonsOLD] = useState({ type: "FeatureCollection", features: [] });

  const [selectedSectionsProperties, setSelectedSectionsProperties] = useState([]);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);

  const [filter, setFilter] = useState({
    idMunicipio: "",
    idDF: "",
    idDL: "",
  });

  const [catalogs, setCatalogs] = useState({ municipios: [], DF: [], DL: [] });
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);

  useEffect(() => {
    setInitialVars();
    getEmptyMap();
    loadCatalogs();
    // eslint-disable-next-line
  }, []);

  const setInitialVars = (values) => {
    const item = values ?? [];

    if (!item.includes("itemEdit")) setVars("itemEdit", null);
    if (!item.includes("selectedSections")) setVars("selectedSections", []);
    if (!item.includes("selectedSectionsProperties")) setVars("selectedSectionsProperties", []);
    if (!item.includes("selectedDFED")) setVars("selectedDFED", null);
    if (!item.includes("flagCreatePolygon")) setVars("flagCreatePolygon", false);
    if (!item.includes("valueTab")) setVars("valueTab", 0);
    if (!item.includes("filterRoutes")) setVars("filterRoutes", { idMunicipioReportes: "", idDF: "", idDL: "" });
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [{ id: "municipios_dl", getAll: false }];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
              municipios: catalogsResponse.municipios_dl ?? [],
            };
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  const getSections = (params) => {
    RouteService.getSections({
      page: 0,
      pageSize: 10,
      value: params.value,
      shp: params.shp,
      idEntidad: user.idEstado,
    })
      .then((res) => {
        if (res.results) setPolygons(res.response.data);
        else {
          Swal.fire({ title: res.message, icon: "warning" });
          setPolygons({ type: "FeatureCollection", features: [] });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const handleFilter = async (_filter, polygon) => {
    let data_parameters = {};
    if (!polygon)
      data_parameters = {
        page: 0,
        pageSize: 10,
        filtered: _filter,
        idEntidad: user.idEstado,
      };
    else
      data_parameters = {
        polygon,
        page: 0,
        pageSize: 10,
        filtered: _filter,
        idEntidad: user.idEstado,
      };
    setFilter(_filter);
    setPolygons(null);

    try {
      const result = await RouteService.getSections(data_parameters);
      const { results, response, message } = result;

      if (results) setPolygons(response.data);
      else {
        Swal.fire({ title: message, icon: "warning" });
        setVars("flagCreatePolygon", false);
        setPolygons({ type: "FeatureCollection", features: [] });
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }

    setInitialVars(["filterRoutes", "flagCreatePolygon"]);
    setSelectedSectionsProperties([]);

    // En caso de solo filtrar por municipio
    if (_filter.length === 1) {
      const data_pol_old = geojson_ex_pol_data.features.filter((item) => item.properties.idMunicipioReportes === _filter[0].value);
      setPolygonsOLD({
        type: "FeatureCollection",
        features: data_pol_old,
      });

      try {
        const result = await RouteService.getInformationMunicipality({ page: 0, pageSize: 10, filtered: _filter });
        const { results, response, message } = result;

        if (results) {
          if (response.data.length !== 0) {
            setValuesInfoGeneral(response.data[0]);
          } else Swal.fire({ title: message, icon: "warning" });
        } else Swal.fire({ title: message, icon: "warning" });
      } catch (error) {
        Swal.fire({ title: error.message, icon: "warning" });
      }
    }
  };

  const handleRefreshShape = () => {
    setSelectedSectionsProperties([]);
    handleFilter(filter);
  };

  const handleViewDetail = (e) => {
    setPolygons(null);
    if (e.shp === "dfed") getSections({ value: [e.dfed], shp: "dfed" });
  };

  const handleChangeSections = (array) => setSelectedSectionsProperties(array);

  const FilterComponent = (
    <>
      <Filter catalogs={catalogs} setCatalogs={setCatalogs} loadingCatalogs={isLoadingCatalogs} handleFilter={handleFilter} width={"100%"} />

      {polygons?.features.length !== 0 && getVars("flagCreatePolygon") && (
        <>
          <Divider sx={{ marginY: 1 }} />

          <Typography variant="h6" fontSize={16} align="center">
            Rutas
          </Typography>

          <PolygonsCard
            permissions={{
              Ver: 1,
              Agregar: 1, //middleware.checkMenuActionId("Agregar"),
              Editar: 1, //middleware.checkMenuActionId("Editar"),
              Eliminar: 1, //middleware.checkMenuActionId("Eliminar")
            }}
            polygons={polygons}
            setPolygons={setPolygons}
            selectedSectionsProperties={selectedSectionsProperties}
            handleRefreshShape={handleRefreshShape}
            handleEmptyProperties={() => {
              setVars("selectedSections", []);
              setVars("selectedSectionsProperties", []);
              setSelectedSectionsProperties([]);
            }}
          />
        </>
      )}
    </>
  );

  const drawerLeft = {
    open: true,
    title: "Filtros",
    component: FilterComponent,
    width: { sm: 300, md: 350, lg: 500, xl: 600 }, // Solo valores variables o vacio (se ajusta al contenido)
  };

  const cardInfo = {
    initialValues: {
      title: "INFORMACIÓN",
      elements: elements[polygons?.shp ?? "dfed"],
    },
    handleClick: handleViewDetail,
  };

  const infoGeneral = {
    values: valuesInfoGeneral,
    initialValues: {
      title: "RESUMEN MUNICIPAL",
      elements: [
        { title: "Municipio:", name: "MunicipioReportes", color: "#000", type: "text" },
        { title: "Total Rutas:", name: "TotalRutas", color: "#000", type: "text" },
        { title: "Total Secciones:", name: "TotalSecciones", color: "#000", type: "text" },
        { title: "Total Casillas:", name: "TotalCasillas", color: "#000", type: "text" },
        { title: "C/Con Ruta:", name: "TotalCasillasOcupadas", color: "#000", type: "text" },
        { title: "C/Sin Ruta:", name: "TotalCasillasLibres", color: "#000", type: "text" },
      ],
    },
  };

  const cardComponent = getVars("flagCreatePolygon") ? { component: <Colors />, title: "Colores" } : null;

  // Se utilizan nuevas funciones evitando usar las defaults
  const onEachFeature = (feature, layer, click, params) => {
    const { L } = params;
    const properties = feature.properties;

    if (properties[properties.shp] !== undefined) {
      layer.bindTooltip(properties[properties.shp] + "", { permanent: true, direction: "center" });
      layer.setStyle(style_original_rutas);

      layer.on({
        click: click,
      });

      // En caso de agregar la propiedad highlightFeature
      if (properties.highlightFeature) {
        const flagCreatePolygon = getVars("flagCreatePolygon");

        // Si no hay municipio y DF en el filtro
        if (!flagCreatePolygon) return true;

        const DFED = getVars("selectedDFED");
        // La sección tiene diferente DFED
        if (DFED !== null && properties?.DFED !== DFED) return true;

        if (properties.seccion) {
          // Recuperar el array selectedSections del storage para revisar si el feature se encuentra dentro
          const selectedSections = getVars("selectedSections");
          const selectedSectionsProperties = getVars("selectedSectionsProperties");

          // Agregamos el DFED al storage
          if (selectedSections.length === 0) setVars("selectedDFED", properties.DFED);

          // Busca la seccion del feature seleccionado en el array que tenemos
          const found = selectedSections.find((element) => element === properties.seccion);

          // Si no se encuentra se agrega
          if (found === undefined) {
            if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();

            layer.setStyle(style_rutas(layer.feature, true));

            selectedSections.push(properties.seccion);
            selectedSectionsProperties.push(properties);

            setVars("selectedSections", selectedSections);
            setVars("selectedSectionsProperties", selectedSectionsProperties);
          }

          // Añadimos el nuevo array al state
          const array_properties = getVars("selectedSectionsProperties");

          handleChangeSections(array_properties);
        } else {
          if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
          layer.setStyle(style_rutas(layer.feature, true));
        }
      }
    }
  };

  const highlightFeature = (e, params) => {
    const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;

    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature && !properties.seccion) resetHighlight(selectedFeature);

    setFeature(layer.feature);
    setSelectedFeature(e);

    const flagCreatePolygon = getVars("flagCreatePolygon");

    // Si no hay municipio y DF en el filtro
    if (!flagCreatePolygon) return true;

    // Si no tiene casillas
    if (properties.casillas.length === 0) return true;

    const DFED = getVars("selectedDFED");
    // La sección tiene diferente DFED
    if (DFED !== null && properties?.DFED !== DFED) {
      //layer.bindTooltip("La sección tiene diferente DFED", { permanent: false, direction: "center" });
      return true;
    }

    if (properties.seccion) {
      // Recuperar el array selectedSections del storage para revisar si el feature se encuentra dentro
      const selectedSections = getVars("selectedSections");
      const selectedSectionsProperties = getVars("selectedSectionsProperties");

      // Agregamos el DFED al storage
      if (selectedSections.length === 0) setVars("selectedDFED", properties.DFED);

      // Busca la seccion del feature seleccionado en el array que tenemos
      const found = selectedSections.find((element) => element === properties.seccion);

      // Si no se encuentra se agrega
      if (found === undefined) {
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();

        layer.setStyle(style_rutas(layer.feature, true));

        selectedSections.push(properties.seccion);
        selectedSectionsProperties.push(properties);
        setVars("selectedSections", selectedSections);
        setVars("selectedSectionsProperties", selectedSectionsProperties);
      } else {
        // Si lo encuentra, lo quita
        const selectedSectionsTemp = selectedSections.filter((element) => element !== properties.seccion);
        setVars("selectedSections", selectedSectionsTemp);

        const selectedSectionsPropertiesTemp = selectedSectionsProperties.filter((element) => element.seccion !== properties.seccion);
        setVars("selectedSectionsProperties", selectedSectionsPropertiesTemp);

        resetHighlight(e);
      }

      // Añadimos el nuevo array al state
      const array_properties = getVars("selectedSectionsProperties");

      handleChangeSections(array_properties);
    } else {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_rutas(layer.feature, true));
    }
  };

  return (
    <AdminLayout delPadding={true}>
      <Map
        height={height - 65}
        data={polygons}
        polygonsOLD={polygonsOLD}
        drawerLeft={drawerLeft}
        cardInfo={cardInfo}
        cardComponent={cardComponent}
        infoGeneral={infoGeneral}
        highlightFeature={highlightFeature}
        onEachFeature={onEachFeature}
        centerByFeatures={true}
        styleProp={{
          data: style_original_rutas,
          polygonsOLD: style_original_rutas,
          highlightFeature: style_rutas,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;
