import { useState, useEffect } from "react";

import { Container, Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import InputSelect from "@components/Selects/BasicSelect";
import BarGraph from "@components/Numeralia/Nacional/BarGraph";
import Filter from "@components/Numeralia/Nacional/Filter";
import ListLnom from "@components/Numeralia/Nacional/ListLnom";
import PieChartSexo from "@components/Numeralia/Nacional/PieChartSexo";
import BarChart from "@components/Numeralia/Nacional/BarChart";
import BarChartAge from "@components/Numeralia/Nacional/BarChartAge";
import ListAge from "@components/Numeralia/Nacional/ListAge";
import LnomNational from "@components/Numeralia/Nacional/LnomNational";

import cat_entidad from "@data/json/cat_entidad.json";
import NumeraliaServices from "@services/NumeraliaServices";
import { VIEW_NATIONAL, VIEW_ESTATAL } from "@data/constants/Numeralia";

const Nacional = () => {
  const [dataFiltered, setDataFiltered] = useState({
    idAmbitoAgrupador: VIEW_NATIONAL,
    filtered: [],
    idEstado: 0,
  });
  const [nameGroup, setNameGroup] = useState("");
  const [entity, setEntity] = useState(0);
  const [catEntity, setCatEntity] = useState([]);
  const [dataAge, setDataAge] = useState({
    categories: [],
    Male: [],
    Female: [],
  });
  const [loadingAge, setLoadingAge] = useState(false);

  const handleChangeEntity = (event) => {
    const idEntity = event.target.value;
    setEntity(idEntity);

    setDataFiltered({
      ...dataFiltered,
      idAmbitoAgrupador:
        idEntity === VIEW_NATIONAL ? VIEW_NATIONAL : VIEW_ESTATAL,
      filtered: [],
      idEstado: idEntity,
    });
    setNameGroup(idEntity === VIEW_NATIONAL ? "" : "VISTA ESTATAL");
  };

  const handleChangeSearchFilter = ({ filters }) => {
    setDataFiltered({ ...dataFiltered, filtered: filters });
  };

  const handleClearFilter = () => {
    setDataFiltered({
      ...dataFiltered,
      idAmbitoAgrupador: VIEW_ESTATAL,
      filtered: [],
      idEstado: entity,
    });
    setNameGroup("VISTA ESTATAL");
  };

  useEffect(() => {
    const catEntity = cat_entidad.data.filter((item) => item.value !== 0);
    setCatEntity([{ value: 0, label: "Vista Nacional" }, ...catEntity]);
  }, []);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader
          title="Numeralia Nacional"
          isComponent
          component={
            <InputSelect
              name="idEntidad"
              value={entity}
              options={catEntity}
              onChange={handleChangeEntity}
              label="Entidad"
              sx={{ width: "20%" }}
            />
          }
        />
        {entity !== VIEW_NATIONAL && (
          <Filter
            entity={entity}
            setDataFiltered={setDataFiltered}
            setNameGroup={setNameGroup}
            onSearch={handleChangeSearchFilter}
            onClear={handleClearFilter}
          />
        )}
        <Box marginTop={2}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={4} lg={4}>
              <ListLnom
                dataFiltered={dataFiltered}
                title={`LNOM  ${nameGroup.toUpperCase()}`}
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={8} lg={8}>
              <LnomNational
                title={`PESO ELECTORAL  ${nameGroup.toUpperCase()}`}
                entity={entity}
                nameGroup={nameGroup}
                dataFiltered={dataFiltered}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={4} lg={4}>
              <PieChartSexo
                dataFiltered={dataFiltered}
                title={`SEXO ${nameGroup.toUpperCase()}`}
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={4} lg={4}>
              <BarChart
                dataFiltered={dataFiltered}
                title={`TIPO DE SECCIÓN ${nameGroup.toUpperCase()}`}
                idCategories={"Tipo"}
                idSeries={"Total"}
                API={NumeraliaServices.getNumeraliaTipoSeccionNacional}
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={4} lg={4}>
              <BarChart
                dataFiltered={dataFiltered}
                title={`SECCIÓN DE ALCANCE ${nameGroup.toUpperCase()}`}
                idCategories={"Tipo"}
                idSeries={"LNOM"}
                API={NumeraliaServices.getNumeraliaTipoSeccionNacional}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={12} lg={12}>
              <BarGraph
                title={`P. ML ${nameGroup.toUpperCase()}`}
                dataFiltered={dataFiltered}
                type="total"
                API={NumeraliaServices.getNumeraliaPrioridadMznTotalNacional}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={12} lg={12}>
              <BarGraph
                title={`ML LNOM ${nameGroup.toUpperCase()}`}
                dataFiltered={dataFiltered}
                API={NumeraliaServices.getNumeraliaPrioridadMznLnomNacional}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={8} lg={8}>
              <BarChartAge
                title={`RANGO POR EDAD ${nameGroup.toUpperCase()}`}
                dataFiltered={dataFiltered}
                dataAge={dataAge}
                setDataAge={setDataAge}
                loadingAge={loadingAge}
                setLoadingAge={setLoadingAge}
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={4} lg={4}>
              <ListAge data={dataAge} loading={loadingAge} />
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Nacional;
