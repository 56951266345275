import { useState, useEffect } from "react";
import { useFormik } from "formik";

import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Stack,
  Button,
  InputAdornment,
  Icon,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";
import SummaryCasillas from "./SummaryCasillas";

import sije from "@services/SijeService";
import Swal from "sweetalert2";
import { CapturaOpeningInterface } from "@data/interfaces/SijeInterface";
import { CapturaOpeningSchema } from "@data/schemas/SijeSchema";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";

const ListCasillaOpeningMassive = ({
  title,
  selected,
  setSelected,
  clearForm,
  setClearForm,
  optCasillas = [],
  handleSave,
  handleClear,
  itemUser,
  options,
  setItemUser,
}) => {
  const [catSeccion, setCatSeccion] = useState([]);
  const [catCasillas, setCatCasillas] = useState([]);
  const [loadCasillas, setLoadCasillas] = useState(false);

  const formik = useFormik({
    initialValues: CapturaOpeningInterface,
    validationSchema: CapturaOpeningSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const handleChangeSection = async ({ idSection }) => {
    if (idSection) {
      try {
        setLoadCasillas((prevState) => !prevState);
        const result = await sije.getCasillasBySeccion({
          Seccion: String(idSection),
        });
        const { response, results, message } = result;
        if (results) {
          const casillas = response.data.map((item) => {
            return {
              label: item.NombreCasilla,
              value: item.idCasilla,
            };
          });
          const { Municipio } = response.data.find((item) => item);

          setCatCasillas(casillas);
          formik.setFieldValue("municipio", Municipio);
        } else {
          throw message;
        }
      } catch (error) {
        Swal.fire({
          title: error,
          icon: "warning",
        });
      } finally {
        setLoadCasillas((prevState) => !prevState);
      }
    }
  };

  const handleClickChip = ({ itemChip }) => {
    const { Seccion, id } = itemChip;
    formik.setValues({
      ...formik.values,
      seccion: Seccion,
      casilla: id,
      idUser: itemUser.idUsuario,
    });
    setSelected(id);
    handleChangeSection({ idSection: Seccion });
  };

  useEffect(() => {
    /*  formik.setFieldValue(
      "municipio",
      itemUser.Municipio ? itemUser.Municipio : ""
    ); */
    //formik.setFieldValue("idUser", itemUser.idUsuario);

    const catSeccion = optCasillas.map(({ Seccion }) => ({
      value: Seccion,
      label: `Sección - ${Seccion}`,
    }));
    setCatSeccion(catSeccion);
    // eslint-disable-next-line
  }, [itemUser]);

  useEffect(() => {
    if (clearForm) {
      formik.setValues({
        ...formik.values,
        seccion: "",
        municipio: "",
        casilla: "",
        hora: "",
        NumFuncionarios: "",
        TomadosFila: "",
      });

      setClearForm(false);
    }
  }, [clearForm]);

  useEffect(() => {
    if (options.length === 0) {
      formik.setValues({
        seccion: "",
        municipio: "",
        casilla: "",
        idUser: "",
        hora: "",
        NumFuncionarios: "",
        TomadosFila: "",
      });
      setCatCasillas([]);
      setItemUser({});
    }
    // eslint-disable-next-line
  }, [options]);

  return (
    <>
      <SummaryCasillas
        casillas={optCasillas}
        selected={selected}
        handleClick={handleClickChip}
      />
      <Card className="card-primary">
        <CardContent>
          <Typography textAlign={"center"} variant="body2" fontWeight={600}>
            {title}
          </Typography>
          <Box component={"form"} marginTop={2}>
            <BasicSelect
              disabled
              label="Sección"
              name="seccion"
              options={catSeccion}
              value={formik.values.seccion}
              // onChange={(e) => {
              //   formik.handleChange({
              //     target: {
              //       name: "seccion",
              //       value: e.target.value,
              //     },
              //   });
              //   handleChangeSection(e.target.value);
              // }}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
            <TextField
              disabled
              label="Municipio"
              name="municipio"
              value={formik.values.municipio}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
            <BasicSelect
              disabled
              label="Casilla"
              name="casilla"
              options={catCasillas}
              value={formik.values.casilla}
              onChange={(e) => {
                formik.handleChange({
                  target: {
                    name: "casilla",
                    value: e.target.value,
                  },
                });
              }}
              isLoading={loadCasillas}
              sx={{ width: "100%", mb: 3 }}
            />
            <TextField
              label="Número de funcionario de casilla presente"
              name="NumFuncionarios"
              onBlur={formik.handleBlur}
              onChange={(e) =>
                formik.handleChange({
                  target: {
                    name: "NumFuncionarios",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : formik.values.NumFuncionarios,
                  },
                })
              }
              value={formik.values.NumFuncionarios}
              error={
                formik.touched.NumFuncionarios &&
                !isEmptyOrInvalidString(formik.errors.NumFuncionarios)
              }
              helperText={
                formik.touched.NumFuncionarios &&
                formik.errors.NumFuncionarios &&
                formik.errors.NumFuncionarios
              }
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
            <TextField
              label="¿Cuántos fueron tomados de la fila?"
              name="TomadosFila"
              onBlur={formik.handleBlur}
              onChange={(e) =>
                formik.handleChange({
                  target: {
                    name: "TomadosFila",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : formik.values.TomadosFila,
                  },
                })
              }
              value={formik.values.TomadosFila}
              error={
                formik.touched.TomadosFila &&
                !isEmptyOrInvalidString(formik.errors.TomadosFila)
              }
              helperText={
                formik.touched.TomadosFila &&
                formik.errors.TomadosFila &&
                formik.errors.TomadosFila
              }
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
            <TextField
              label={
                "Hora de instalación de casilla (Formato 24hrs, ejemplo: 13:30)"
              }
              name="hora"
              value={formik.values.hora}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              sx={{ width: "100%", mb: 3 }}
              InputProps={{
                size: "small",
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>schedule</Icon>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.hora &&
                !isEmptyOrInvalidString(formik.errors.hora)
              }
              helperText={
                formik.touched.hora && formik.errors.hora && formik.errors.hora
              }
            />
            <Stack
              direction={"row"}
              marginTop={4}
              spacing={2}
              justifyContent={"center"}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={handleClear}
                color="primaryDark"
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primaryDark"
                onClick={formik.submitForm}
                disabled={formik.values.seccion.length === 0}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ListCasillaOpeningMassive;
