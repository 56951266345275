import Yup from '@utils/Yupi18n';


export const seccionSchema = Yup.number()
  .required()
  .integer()
  .typeError();

export const bingoSchema = Yup.number()
  .integer('Ingrese un número entero')
  .min(1)
  .max(1000, 'El número debe ser menor o igual a 1000');

export const casillaSchema = Yup.number()
  .required();

export const handleKeyPress = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
};

export const AddBingoSchema = Yup.object().shape({
  Seccion: Yup.number()
    .required()
    .integer()
    .typeError()
    .label('Sección'),
  idCasilla: Yup.number()
    .required()
    .integer()
    .typeError()
    .label('Casilla'),
  FolioBingo: Yup.number()
    .required()
    .integer('Ingrese un número entero')
    .min(1)
    .max(1000, 'El número debe ser menor o igual a 1000'),
  OrigenCaptura: Yup.string().required()
});

export const HourMovilizationSchema = Yup.object().shape({
  Seccion: Yup.number()
    .required()
    .integer()
    .label('Sección'),
  Hora: Yup.string()
    .required(),
})