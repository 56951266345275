import React, { useEffect, useState } from "react";

import {
	Dialog,
	DialogTitle,
	Button,
	useMediaQuery,
	Stack,
	IconButton,
	Icon,
	TextField,
	Grid,
	Container,
	Chip,
	Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BasicTable from "@components/Tables/BasicTable";
import PopupConfirmation from "./PopupConfirmation";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import { AttendanceFoundFilterInterface } from "@data/interfaces/CompromisoInterfaces";
import { AttendanceFoundFilterSchema } from "@data/schemas/CompromisoSchemas";
import { isTypePhone } from "@utils/validations";
import PopupForm from "./PopupForm";

const Popup = ({
	open,
	setOpen,
	dataFound,
	setDataFound,
	setData,
	total,
	dataFilter,
	handleRefresh,
	isLoading,
}) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	const esNuevo = 1;
	const colums = [
		{ id: "PaseLista", label: "Confirmar" },
		{ id: "Municipio", label: "Municipio", columnAction: false },
		{ id: "NombreCompleto", label: "Nombre", columnAction: false },
		{ id: "Celular", label: "Celular", columnAction: false },
	];
	const users = dataFound.map((item) => {
		return {
			...item,
			PaseLista:
				item.FechaPaseLista === null ? (
					<>
						{/* <Tooltip title="Confirmar el pase de lista">
              <IconButton onClick={() => handleSelectItem(item)}>
                <Icon>how_to_reg</Icon>
              </IconButton>
            </Tooltip> */}
						<Button
							variant="contained"
							size="small"
							color="primaryDark"
							startIcon={<Icon>how_to_reg</Icon>}
							onClick={() => handleSelectItem(item)}
						>
							Pase de lista
						</Button>
					</>
				) : (
					<Chip
						label="Tiene asistencia"
						color="success"
						size="small"
						icon={<Icon>done_all</Icon>}
					/>
				),
			NombreCompleto: (
				<>
					{item.NombreCompleto}
					<br />
					{item.FechaPaseLista !== null
						? moment(item.FechaPaseLista).format("DD/MM/YYYY H:mm")
						: ""}
				</>
			),
		};
	});

	/*  const [dataValuesFilter, setDataValuesFilter] = useState({
    celular: "",
    nombre: "",
  }); */
	const [data] = useState({
		page: 0,
		pageSize: 10,
		filtered: [],
	});

	const [sweetalert, setSweetAlert] = useState(false);
	const [infoAlert, setInfoAlert] = useState({});

	const formik = useFormik({
		initialValues: AttendanceFoundFilterInterface,
		validationSchema: AttendanceFoundFilterSchema,
		onSubmit: (values) => {
			handleSearchFilter(values);
		},
	});

	const handleChangePagination = (pagination) => {
		setData({ ...data, ...pagination });
	};
	const handleClose = () => {
		setOpen(false);
		setDataFound([]);
		handleClearFilter();
		dataFilter.celular = "";
		dataFilter.nombre = "";
	};

	const handleSelectItem = (item) => {
		setSweetAlert(true);
		setInfoAlert(item);
	};

	const handleSearchFilter = (values) => {
		//setIsLoading(true);
		const { celular, nombre } = values;

		let objFilters = [];

		if (formik.values.celular !== "") {
			objFilters = [
				...objFilters,
				{ id: "Celular", filter: "=", value: celular },
			];
		}
		if (formik.values.nombre !== "") {
			objFilters = [
				...objFilters,
				{
					id: ["Nombre", "Paterno", "Materno"],
					filter: "LIKE",
					value: nombre,
				},
			];
		}
		setData((prevState) => {
			return {
				...prevState,
				filtered: objFilters,
			};
		});
	};

	const handleClearFilter = () => {
		formik.setValues({
			...formik,
			nombre: "",
			celular: "",
		});
	};

	useEffect(() => {
		formik.setValues({
			...formik,
			nombre: dataFilter.nombre,
			celular: dataFilter.celular,
		});
		// eslint-disable-next-line
	}, [dataFilter]);

	const handleOnKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			formik.submitForm();
		}
	};

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			maxWidth="md"
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle id="responsive-dialog-title">
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{"Personas Encontradas"}
					<IconButton onClick={handleClose}>
						<Icon>close</Icon>
					</IconButton>
				</Stack>
			</DialogTitle>

			<Container sx={{ mb: 1 }}>
				<Grid container spacing={2} marginTop={1}>
					<Grid item xs={12} sm={12} md={3} lg={3}>
						<TextField
							sx={{ width: "100%" }}
							label="Celular"
							name="celular"
							size="small"
							className="fixed-input"
							variant="outlined"
							value={formik.values.celular}
							onKeyUp={handleOnKeyPress}
							onChange={(e) =>
								formik.handleChange({
									target: {
										name: "celular",
										value: isTypePhone(e.target.value)
											? e.target.value
											: formik.values.celular,
									},
								})
							}
							onBlur={(e) =>
								formik.handleChange({
									target: {
										name: "celular",
										value: isTypePhone(e.target.value)
											? e.target.value
											: formik.values.celular,
									},
								})
							}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextField
							sx={{ width: "100%" }}
							label="Nombre"
							name="nombre"
							size="small"
							className="fixed-input"
							variant="outlined"
							value={
								formik.values.nombre &&
								formik.values.nombre.length > 0 &&
								formik.values.nombre.toUpperCase()
							}
							onKeyUp={handleOnKeyPress}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={3} lg={3}>
						<Button
							variant="contained"
							color="primaryDark"
							onClick={formik.submitForm}
						>
							Filtrar
						</Button>

						<Button
							variant="text"
							color="primaryDark"
							sx={{ ml: 1 }}
							onClick={handleClearFilter}
						>
							Limpiar
						</Button>
					</Grid>
				</Grid>
			</Container>

			<BasicTable
				rows={users}
				hcolumns={colums}
				total={total}
				pageProp={data.page}
				pageSize={data.pageSize}
				isLoading={isLoading}
				minHeight="0px"
				handleSelectItem={handleSelectItem}
				handleChangePagination={handleChangePagination}
			/>

			<PopupForm
				open={sweetalert}
				item={infoAlert}
				setOpen={setSweetAlert}
				handleRefresh={handleRefresh}
				handleClearFilter={handleClearFilter}
			/>

			{/*  <PopupConfirmation
        open={sweetalert}
        item={infoAlert}
        setOpen={setSweetAlert}
        handleRefresh={handleRefresh}
        handleClearFilter={handleClearFilter}
      /> */}
			{/* <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Disagree
        </Button>
        <Button onClick={handleClose} autoFocus>
          Agree
        </Button>
      </DialogActions> */}
		</Dialog>
	);
};

export default Popup;
