import React, { useEffect, useState, useCallback } from "react";
import {
	Modal,
	Box,
	IconButton,
	Button,
	Icon,
	Card,
	CardHeader,
	CardContent,
	FormControl,
	Stack,
	FormLabel,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormHelperText,
	CardActions,
	Alert,
	AlertTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getVars } from "@utils/global";
import { useFormik } from "formik";
import { AddResponsibilityInterface } from "@interfaces/VolunteerInterfaces";
import { AddResponsibilitySchema } from "@schemas/VolunteerSchemas";
import BasicList from "../Lists/BasicList";
import CloseIcon from "@mui/icons-material/Close";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import VolunteerServices from "@services/VolunteerServices";
import Swal from "sweetalert2";
import VolunteersListItem from "./VolunteersListItem";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";

const AssignRepresentative = ({
	open,
	setOpen,
	title = { tipo: "Propietatario 1", casilla: "POR DEFINIR", seccion: 1 },
}) => {
	const { user } = getVars("Token");
	const [volunteer, setVolunteer] = useState({ NombreCompleto: "" });
	const [flagVolunter, setFlagVolunter] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isLoadigForm, setIsLoadingForm] = useState(false);
	const [statusValidated, setStatusValidated] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [resultAssing, setResultAssing] = useState({
		success: false,
		message: "",
	});
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const pageSize = 10;

	const formik = useFormik({
		initialValues: {
			...AddResponsibilityInterface,
			idUsurioAutorizo: user.id,
			idCompromisoUnico: volunteer.id,
		},
		validationSchema: AddResponsibilitySchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});

	useEffect(() => {
		if (open) {
			let newValues = {
				...formik.values,
				idPefilAmbito: "2-10",
				idPerfil: 2,
				idAmbitoCargo: 10,
				AmbitoValorCargo: parseInt(title.id),
				idMunicipio: title.idMunicipio,
				idPropietario: title.idPropietario,
				ResponsabilidadEtiqueta: `${"rcs"} - ${title.seccion}`,
			};

			formik.setValues({
				...newValues,
			});
		}
	}, [title]);

	useEffect(() => {
		if (open) {
			getList({ page: page, pageSize: pageSize, filtered: filtered });
		}
	}, []);

	const getList = useCallback((data) => {
		setLoading(true);

		VolunteerServices.getList({
			page: data.page,
			pageSize: data.pageSize,
			filtered: data.filtered,
		})
			.then((res) => {
				if (res.results) {
					if (res.response.total > 0) {
						const volunteers = res.response.data.map((volunteer) => {
							return {
								...volunteer,
								id: volunteer.idCompromisoUnico,
								NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
								TieneUsuario: volunteer.TieneUsuario === 1 ? "Si" : "No",
							};
						});
						console.log(volunteers);
						setData(volunteers);
						setTotal(res.response.total);
					}

					if (pageSize >= parseInt(res.response.total)) {
						setHasMore(false);
					}
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
					setHasMore(false);
				}
			})
			.catch((e) => {
				setHasMore(false);
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line
	}, []);

	/*   useEffect(()=>{},[sectionValid]) */

	const fetchHasMore = () => {
		VolunteerServices.getList({
			page: page + 1,
			pageSize: pageSize,
			filtered: filtered,
		})
			.then((res) => {
				if (res.results) {
					const volunteers = res.response.data.map((volunteer) => {
						return {
							...volunteer,
							NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
							TieneUsuario: volunteer.TieneUsuario === 1 ? "Si" : "No",
						};
					});
					setData(data.concat(volunteers));
					let end = (page + 1) * pageSize;
					if (end >= res.response.total) {
						setHasMore(false);
					}
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
					setHasMore(false);
				}
			})
			.catch((e) => {
				setHasMore(false);
				Swal.fire({ title: e.message, icon: "warning" });
			});
		setPage(page + 1);
	};

	const handleClickData = (data) => {
		setStatusValidated(false);
		setFlagVolunter(true);
		formik.handleChange({
			target: {
				name: "idCompromisoUnico",
				value: data.idCompromisoUnico,
			},
		});
		setVolunteer(data);
	};

	const onSubmit = async (values) => {
		setStatusValidated(false);
		const res = await Swal.fire({
			title: "¡Advertencia!",
			html: `<p>La persona no tiene un número de celular registrado; se le asignará la tarea sin darle acceso al sistema. Si requiere que la persona tenga acceso</p>`,
			icon: "warning",
			showCancelButton: true,
			cancelButtonText: "Cancelar",
			confirmButtonText: `Continuar`,
			allowOutsideClick: false,
			allowEscapeKey: false,
			customClass: {
				container: "modal-alert",
			},
		});

		if (res.isConfirmed) {
			setIsLoadingForm(true);
			let newData = values;
			delete newData.idPefilAmbito;

			try {
				const result = await VolunteerServices.createResponsibility(newData);
				const { results, message } = result;
				if (results) {
					setResultAssing({
						success: true,
						message: message,
					});
					setOpen(false);
				} else {
					setResultAssing({
						success: false,
						message: message,
					});
				}
			} catch (error) {
				setResultAssing({
					success: false,
					message: error.message,
				});
			} finally {
				setStatusValidated(true);
				setIsLoadingForm(false);
			}
		}
	};

	const handleChangeRadio = (e) => {
		const name = e.target.name;
		const value = parseInt(e.target.value);
		formik.handleChange({
			target: {
				name: name,
				value: value,
			},
		});
	};

	const evalInputSearch = (text) => {
		let out_text = text.trim();
		let type = "text";
		// const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
		const phoneRegex =
			/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

		if (phoneRegex.test(out_text)) {
			out_text = out_text.replace(/\D/g, "").slice(-10);
			type = "phone";
		} else {
			out_text = text.replace(/\s+/g, "%");
		}

		return {
			type,
			text: out_text,
		};
	};

	const handleSearch = (searchInput) => {
		let filtro = [];
		if (searchInput === "") {
			filtro = [];
		} else {
			const { type, text } = evalInputSearch(searchInput);
			if (type === "phone") {
				filtro = [
					{
						id: `compromisos_unicos.Celular`,
						filter: "=",
						value: `${text}`,
						inheritFilterType: null,
					},
				];
			} else {
				filtro = [
					{
						id: `CONCAT_WS('', compromisos_unicos.Nombre, compromisos_unicos.Paterno, compromisos_unicos.Materno)`,
						filter: "LIKE",
						value: `%${text}%`,
						inheritFilterType: null,
					},
				];
			}
		}
		setFiltered(filtro);
		setPage(0);
		getList({ page: 0, pageSize: pageSize, filtered: filtro });
	};

	return (
		<>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						bgcolor: "white",
						borderRadius: "8px",
						boxShadow: 24,
						p: 2,
						width: "50%", // Ajusta el ancho según sea necesario
						//  maxWidth: "500px", // Establece un ancho máximo para pantallas más pequeñas
						"@media (max-width: 600px)": {
							overflowY: "auto",
							width: "100%", // Ajusta para pantallas más pequeñas
							height: "90%",
						},
					}}
				>
					<IconButton
						aria-label="cancel"
						onClick={() => setOpen(false)}
						sx={{ position: "fixed", left: "3%", top: "2%", color: "#FFFFFF" }}
					>
						<Icon>cancel</Icon>
					</IconButton>
					<Card elevation={0}>
						<CardHeader
							title={`Asignar ${title.tipo}`}
							subheader={`Casilla ${title.casilla} Sección ${title.seccion}`}
							titleTypographyProps={{
								align: "center",
								variant: "h5",
								color: "white",
							}}
							subheaderTypographyProps={{ align: "center", color: "white" }}
							sx={{ backgroundColor: "#152A9E", pt: "25px" }}
						/>
						<CardContent>
							<Grid2
								container
								rowSpacing={1}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							>
								<Grid2 xs={12} sm={8} md={8} lg={8}>
									<Stack
										direction="column"
										justifyContent="center"
										alignItems="center"
										spacing={0.5}
									>
										<BasicList
											Header={false}
											data={data}
											fetchHasMore={fetchHasMore}
											totalItems={total}
											itemComponent={VolunteersListItem}
											hasMore={hasMore}
											finder={true}
											search={handleSearch}
											handleClickItem={(e) => handleClickData(e)}
										/>
									</Stack>
								</Grid2>
								<Grid2 xs={12} sm={12} md={4} lg={4}>
									<Card>
										<CardContent
											sx={{
												overflow: "auto",
												textAlign: "center",
												"& > *": { marginBottom: 3 },
											}}
										>
											<TextField
												required
												label="Responsable"
												name="Responsable"
												variant="outlined"
												value={volunteer.NombreCompleto}
												size="small"
												className="fixed-input"
												InputProps={{
													readOnly: true,
												}}
												InputLabelProps={{
													shrink: true,
												}}
												error={!flagVolunter}
											/>
											{!flagVolunter && (
												<FormHelperText error>
													Seleccione un responsable
												</FormHelperText>
											)}

											<TextField
												error={
													formik.touched.VigenciaINE &&
													!isEmptyOrInvalidString(formik.errors.VigenciaINE)
												}
												helperText={
													formik.touched.VigenciaINE &&
													formik.errors.VigenciaINE &&
													formik.errors.VigenciaINE
												}
												sx={{ width: "100%" }}
												label="Vigencia de INE"
												type="number"
												name="VigenciaINE"
												inputProps={{
													maxLength: 4,
												}}
												onChange={(e) =>
													formik.handleChange({
														target: {
															name: "VigenciaINE",
															value: isTypePhone(e.target.value)
																? e.target.value
																: formik.values.VigenciaINE,
														},
													})
												}
												onBlur={(e) =>
													formik.handleChange({
														target: {
															name: "VigenciaINE",
															value: isTypePhone(e.target.value)
																? e.target.value
																: formik.values.VigenciaINE,
														},
													})
												}
												value={formik.values.VigenciaINE}
												variant="outlined"
												size="small"
												className="fixed-input"
											/>
											<FormControl sx={{ alignItems: "center" }}>
												<FormLabel id="AceptaCuidarOtra">
													¿Aceptaría cuidar otra casilla?
												</FormLabel>
												<RadioGroup
													row
													aria-labelledby="AceptaCuidarOtra"
													name="AceptaCuidarOtra"
													value={formik.values.AceptaCuidarOtra}
													onChange={handleChangeRadio}
												>
													<FormControlLabel
														value={1}
														control={<Radio />}
														label="SI"
													/>
													<FormControlLabel
														value={0}
														control={<Radio />}
														label="NO"
													/>
												</RadioGroup>
												{formik.errors.AceptaCuidarOtra && (
													<FormHelperText error>
														{formik.errors.AceptaCuidarOtra}
													</FormHelperText>
												)}
											</FormControl>
											<FormControl sx={{ alignItems: "center" }}>
												<FormLabel id="TieneExperiencia">
													¿Tiene Experiencia?
												</FormLabel>
												<RadioGroup
													row
													aria-labelledby="TieneExperiencia"
													name="TieneExperiencia"
													value={formik.values.TieneExperiencia}
													onChange={handleChangeRadio}
												>
													<FormControlLabel
														value={1}
														control={<Radio />}
														label="SI"
													/>
													<FormControlLabel
														value={0}
														control={<Radio />}
														label="NO"
													/>
												</RadioGroup>
												{formik.errors.TieneExperiencia && (
													<FormHelperText error>
														{formik.errors.TieneExperiencia}
													</FormHelperText>
												)}
											</FormControl>
											<FormControl sx={{ alignItems: "center" }}>
												<FormLabel id="TieneAuto">¿Tiene Auto?</FormLabel>
												<RadioGroup
													row
													aria-labelledby="TieneAuto"
													name="TieneAuto"
													value={formik.values.TieneAuto}
													onChange={handleChangeRadio}
												>
													<FormControlLabel
														value={1}
														control={<Radio />}
														label="SI"
													/>
													<FormControlLabel
														value={0}
														control={<Radio />}
														label="NO"
													/>
												</RadioGroup>
												{formik.errors.TieneAuto && (
													<FormHelperText error>
														{formik.errors.TieneAuto}
													</FormHelperText>
												)}
											</FormControl>
										</CardContent>
										<CardActions sx={{ textAlign: "center" }}>
											<Grid2 container>
												{statusValidated && (
													<Grid2 item xs={12} sm={12} md={12} lg={12}>
														<Alert
															severity={
																resultAssing.success ? "success" : "error"
															}
														>
															{resultAssing.success ? (
																<>
																	<AlertTitle>
																		{resultAssing.message}
																	</AlertTitle>
																	{/*  Polígono: <strong>{sectionData.Poligono}</strong> */}
																</>
															) : (
																<AlertTitle>{resultAssing.message}</AlertTitle>
															)}
														</Alert>
													</Grid2>
												)}
												<Grid2 item xs={12} sm={12} md={12} lg={12}>
													<LoadingButton
														variant="contained"
														size="small"
														loading={isLoadigForm}
														endIcon={<Icon>save</Icon>}
														loadingPosition="end"
														onClick={formik.submitForm}
													>
														Asignar
													</LoadingButton>
												</Grid2>
											</Grid2>
										</CardActions>
									</Card>
								</Grid2>
							</Grid2>
						</CardContent>
					</Card>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="flex-end"
						spacing={0.5}
					>
						<Button
							size="small"
							variant="contained"
							color="error"
							onClick={() => setOpen(false)}
							sx={{ borderRadius: "10px" }}
							endIcon={<CloseIcon />}
						>
							Cerrar
						</Button>
					</Stack>
				</Box>
			</Modal>
		</>
	);
};

export default AssignRepresentative;
