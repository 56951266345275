export const COLUMNS_DASH_MOBILIZATION = [
	//{ id: "DistritoFederal", label: "DISTRITOFEDERAL" },
	//{ id: "DistritoLocal", label: "DISTRITOLOCAL" },
	{ id: "DLOC", label: "DL" },
	{ id: "DFED", label: "DF" },
	{ id: "Region", label: "Delimitación" },
	{ id: "Municipio", label: "MUNICIPIO" },
	{ id: "Poligono", label: "ZONA" },
	{ id: "Seccion", label: "SECCIÓN" },
	{ id: "LNOM", label: "LNOM" },
	{ id: "Meta", label: "META" },
	{ id: "SIAN", label: "PROMOVIDOS" },
	{ id: "Total", label: "AVANCE" },
	{ id: "Avance", label: "DETECTADOS" },
	{ id: "AvanceCiudadano", label: "OTROS" },
	{ id: "AvancePorcentaje", label: "AVANCE PORCENTAJE" },
	{ id: "Efectividad", label: "EFECTIVIDAD" },
	/* { id: "Hora", label: "HORA" },
  { id: "Tipo", label: "TIPO" },
  { id: "TipoAvanceAC", label: "TIPOAVANCEAC" },
  { id: "TipoAvanceAE", label: "TIPOAVANCEAE" },
  { id: "ColorAvanceCompromisos", label: "COLORAVANCECOMPROMISOS" },
  { id: "ColorEfectividad", label: "COLOREFECTIVIDAD" }, */
];

export const CATALOG_OPTIONS = [
	{ id: "regiones" },
	{ id: "dfs" },
	{ id: "secciones" },
	{ id: "poligonos" },
	{ id: "dls" },
	{ id: "municipios_reportes" },
];

export const PROGRESS_KEYS = ["AvancePorcentaje"];

export const DATA_BINGO_CHART_2 = {
	success: true,
	results: true,
	data: {
		series: [
			{
				name: "LNOM",
				data: [223888, 387660, 1148994, 400810, 442094, 795072, 1184637],
				pointPadding: 0.2,
			},
			{
				name: "META",
				data: [45901, 74334, 236388, 81139, 77091, 152704, 328042],
				pointPadding: 0.2,
			},
			{
				name: "PROMOVIDOS",
				data: [20523, 26062, 123833, 46560, 57543, 93723, 64287],
				pointPadding: 0.2,
			},
			{
				name: "AVANCE",
				data: [22970, 23408, 63380, 24497, 71732, 65086, 43755],
				pointPadding: 0.2,
			},
			{
				name: "SISTEMA",
				data: [1685, 1004, 5318, 2724, 9647, 3039, 927],
				pointPadding: 0.2,
			},
			{
				name: "OTROS",
				data: [21285, 22404, 58062, 21773, 62085, 62047, 42828],
				pointPadding: 0.2,
			},
		],
		categories: ["R1", "R2", "R3", "R4", "R5", "R6", "R7"],
	},
};

export const DATA_MOVILIZACION_ACUMULATIVA = {
	success: true,
	results: true,
	data: {
		Categories: [
			"08:00",
			"09:00",
			"10:00",
			"11:00",
			"12:00",
			"13:00",
			"14:00",
			"15:00",
			"16:00",
			"17:00",
		],
		Series: [
			{
				name: "R1",
				data: [0, 260, 984, 2775, 5012, 7331, 11288, 15099, 22970, 22970],
			},
			{
				name: "R2",
				data: [8, 145, 947, 2838, 5266, 8321, 11491, 15200, 23408, 23408],
			},
			{
				name: "R3",
				data: [
					262, 1872, 5699, 11102, 19097, 29704, 40186, 48270, 63369, 63369,
				],
			},
			{
				name: "R4",
				data: [29, 255, 1111, 3377, 6348, 10243, 14578, 17310, 24493, 24493],
			},
			{
				name: "R5",
				data: [14, 731, 5182, 11853, 21017, 31576, 40536, 49847, 71718, 71718],
			},
			{
				name: "R6",
				data: [
					250, 2070, 5736, 11102, 18083, 27482, 36738, 44825, 65075, 65075,
				],
			},
			{
				name: "R7",
				data: [10, 125, 942, 2472, 5885, 11451, 18898, 25347, 43755, 43755],
			},
		],
		Titulo: "R1 / R2 / R3 / R4 / R5 / R6 / R7",
		HoraPeticion: "13-08-42",
	},
};

export const DATA_DASH = {
	success: true,
	results: true,
	data: [
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_4",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 1,
			Meta: 391,
			Avance: "23",
			AvanceCiudadano: "319",
			Total: "342",
			AvancePorcentaje: "5.8824",
			Efectividad: "-86.5497",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 1580,
			SIAN: 153,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 2,
			Meta: 580,
			Avance: "29",
			AvanceCiudadano: "241",
			Total: "270",
			AvancePorcentaje: "5.0000",
			Efectividad: "-78.5185",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 2040,
			SIAN: 271,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_4",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 3,
			Meta: 394,
			Avance: "18",
			AvanceCiudadano: "201",
			Total: "219",
			AvancePorcentaje: "4.5685",
			Efectividad: "-83.5616",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 1557,
			SIAN: 168,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 4,
			Meta: 285,
			Avance: "26",
			AvanceCiudadano: "132",
			Total: "158",
			AvancePorcentaje: "9.1228",
			Efectividad: "-67.0886",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 958,
			SIAN: 145,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 5,
			Meta: 279,
			Avance: "36",
			AvanceCiudadano: "394",
			Total: "430",
			AvancePorcentaje: "12.9032",
			Efectividad: "-83.2558",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 1067,
			SIAN: 140,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 6,
			Meta: 665,
			Avance: "49",
			AvanceCiudadano: "461",
			Total: "510",
			AvancePorcentaje: "7.3684",
			Efectividad: "-80.7843",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 2977,
			SIAN: 429,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 7,
			Meta: 327,
			Avance: "10",
			AvanceCiudadano: "140",
			Total: "150",
			AvancePorcentaje: "3.0581",
			Efectividad: "-86.6667",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 1345,
			SIAN: 145,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 8,
			Meta: 366,
			Avance: "38",
			AvanceCiudadano: "377",
			Total: "415",
			AvancePorcentaje: "10.3825",
			Efectividad: "-81.6867",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 1385,
			SIAN: 145,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 9,
			Meta: 583,
			Avance: "45",
			AvanceCiudadano: "399",
			Total: "444",
			AvancePorcentaje: "7.7187",
			Efectividad: "-79.7297",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 2366,
			SIAN: 322,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
		{
			DistritoLocal: "XVIII. PENJAMO",
			DLOC: 18,
			DistritoFederal: "13. VALLE DE SANTIAGO",
			DFED: 13,
			Poligono: "1_1",
			Municipio: "ABASOLO",
			Region: "R4",
			Seccion: 10,
			Meta: 501,
			Avance: "64",
			AvanceCiudadano: "613",
			Total: "677",
			AvancePorcentaje: "12.7745",
			Efectividad: "-81.0931",
			Hora: "21:00",
			Tipo: "SWITCH",
			TipoAvanceAC: "AC",
			TipoAvanceAE: "AE",
			LNOM: 1850,
			SIAN: 213,
			ColorAvanceCompromisos: "#FF0001",
			ColorEfectividad: "#FF0001",
		},
	],
	total: 3164,
};
